<template>
  <ValidationObserver ref="form" v-slot="{ valid }">
    <v-card elevation="0" min-height="250">
      <v-card-text :class="isCreate ? '' : 'overrideVCardText'">
        <v-form>
          <v-row>
            <v-col v-if="!isCreate" cols="12" md="6">
              <custom-label item-field-name="Member ID" />
              <ValidationProvider v-slot="{ errors }" name="member ID" rules="required">
                <v-text-field
                  disabled
                  outlined
                  v-model="memberId"
                  :error="!!errors.length"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Client" />
              <ValidationProvider v-slot="{ errors }" name="client" rules="required">
                <autocomplete
                  :disabled="!isCreate || isProfileCreate"
                  :path="'toca/admin/clientProfiles'"
                  :params="{ sort: { created_at: -1 }, limit: -1 }"
                  :item-text="item => getNameFromProfile(item)"
                  item-value="_id"
                  v-model="model.created_by"
                  :clearable="true"
                  :error-messages="errors"
                  :has-items-slot="true"
                  :multiple="true"
                >
                  <template v-slot:items="content">
                    <span>{{ getNameFromProfile(content.item) }}</span>
                  </template>
                </autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Action" />
              <ValidationProvider v-slot="{ errors }" name="action" rules="required">
                <v-select
                  :disabled="!isCreate"
                  outlined
                  v-model="model.action"
                  :items="creditRecordAction"
                  :item-text="item => item.label"
                  :value="item => item.sid"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col v-if="!isCreate" cols="12" md="6">
              <custom-label item-field-name="Credit Transaction Date" />
              <ValidationProvider
                v-slot="{ errors }"
                name="credit_transaction_date"
                rules="required"
              >
                <v-text-field
                  outlined
                  v-model="formattedDate"
                  :error="!!errors.length"
                  :error-messages="errors"
                  readonly
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Credit Amount" />
              <ValidationProvider
                v-slot="{ errors }"
                name="Credit Amount"
                :rules="{ required: true, min_value: 0.00001, decimal: 5 }"
              >
                <v-text-field
                  outlined
                  v-model.number="amount"
                  :error="!!errors.length"
                  :error-messages="errors"
                  type="number"
                />
              </ValidationProvider>
            </v-col>
            <v-col v-if="!isCreate" cols="12" md="6">
              <custom-label item-field-name="Credit Balance" />
              <ValidationProvider v-slot="{ errors }" name="credit balance" rules="required">
                <v-text-field
                  outlined
                  disabled
                  v-model="creditBalance"
                  :error="!!errors.length"
                  :error-messages="errors"
                  type="number"
                />
              </ValidationProvider>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <custom-label item-field-name="Status" />
              <ValidationProvider v-slot="{ errors }" name="status" rules="required">
                <v-select
                  outlined
                  v-model="model.status"
                  :items="creditRecordStatus"
                  :item-text="item => item.label"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col> -->
            <v-col cols="12" md="6">
              <custom-label item-field-name="Remarks" />
              <ValidationProvider v-slot="{ errors }" name="remarks">
                <v-text-field
                  outlined
                  v-model="model.remarks"
                  :error="!!errors.length"
                  :error-messages="errors"
                  type="string"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Action By" />
              <ValidationProvider v-slot="{ errors }" name="action by" rules="required">
                <autocomplete
                  :disabled="!isCreate"
                  :path="'toca/admin/Profiles'"
                  :params="{
                    sort: { created_at: -1 },
                    filter: {
                      $or: [{ role: { $ne: 'toca.client' } }, { _id: clientId }],
                    },
                    limit: -1,
                  }"
                  :item-text="item => getNameFromProfile(item)"
                  item-value="_id"
                  v-model="model.action_by"
                  :clearable="true"
                  :error-messages="errors"
                  :has-items-slot="true"
                >
                  <template v-slot:items="content">
                    <span>{{ getNameFromProfile(content.item) }}</span>
                  </template>
                </autocomplete>
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6" v-if="model.ref_VIP">
              <custom-label item-field-name="Related VIP Purchase" />
              <div>Product : {{ get(model, 'ref_VIP.VIPProduct.name') | parseAndLocalize }}</div>
              <div>Amount : ${{ get(model, 'ref_VIP.amountPayable') | toThousandFilter }}</div>
              <div>Payment Method : {{ get(model, 'ref_VIP.paymentMethod') }}</div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <!---<v-btn color="primary" :disabled="!valid" @click="promptCreate()" v-if="isCreate">-->
        <v-btn color="primary" @click="promptCreate()" v-if="isCreate"> Create</v-btn>
        <v-btn color="primary" @click="promptUpdate()" v-if="!isCreate"> Update</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>

<script>
import api from '@/api';
import { createCreditMultipleRecords, updateCreditRecord, readCreditRecord } from '@/api/credit';
import editDialogMixin from '@/services/editDialogMixin';
import CustomLabel from '../customFormLabel/customLabel';
import { cloneDeep, get } from 'lodash';
import { creditRecordAction, creditRecordStatus } from '@/data/optionsConstants';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { getNameFromProfile } from '@/services/filters';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'CreditRecordEditCard',
  components: {
    CustomLabel,
    Autocomplete,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [editDialogMixin],
  emits: ['create'],
  props: {
    title: {},
    id: {},
    clientId: {},
  },
  data() {
    return {
      creditRecordAction,
      creditRecordStatus,
      model: {
        action: 'topup',
        status: '1$active',
        created_by: this.clientId ? [this.clientId] : [],
      },
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v && !this.isCreate) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['profile']),
    memberId: {
      get() {
        return this.model?.created_by?.sid || '';
      },
    },
    formattedDate: {
      get() {
        return moment(this.model.created_at).format('YYYY-MM-DD hh:mm:ss A');
      },
    },
    creditBalance: {
      get() {
        return this.model?.created_by?.creditBalance || 0;
      },
    },
    actionBy: {
      get() {
        return this.profile.name;
      },
    },
    amount: {
      get() {
        return this.model.amount ? Math.abs(this.model.amount) : 0;
      },
      set(v) {
        this.$set(this.model, 'amount', v);
      },
    },
    isProfileCreate() {
      return this.clientId != null;
    },
  },
  methods: {
    getNameFromProfile,
    get,
    moment,
    async reload() {
      this.model = await readCreditRecord(api, this.id, {
        populate: [
          { path: 'ref_VIP', populate: [{ path: 'VIPProduct' }] },
          { path: 'created_by', select: ['sid', 'name', 'creditBalance'] },
        ],
      });
    },
    async promptCreate() {
      if (!(await this.$refs.form?.validate?.())) {
        return;
      }
      try {
        let payload = cloneDeep(this.model);
        payload.amount = this?.model.action === 'usage' ? -Math.abs(this.amount) : this.amount;
        await createCreditMultipleRecords(api, payload);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Created',
          type: 'success',
          color: 'success',
        });
        this.$emit('create');
        this.$refs.form.reset();
        this.model = {
          action: 'topup',
          status: '1$active',
          created_by: this.clientId ? [this.clientId] : [],
        };
      } catch (err) {
        this.handleError(err);
      }
    },
    async promptUpdate() {
      if (!(await this.$refs.form?.validate?.())) {
        return;
      }
      try {
        let payload = {
          created_by: this.model?.created_by,
          amount: this?.model.action === 'usage' ? -Math.abs(this.amount) : this.amount,
          remarks: this.model?.remarks,
          action_by: this.model?.action_by,
          action: this.model?.action,
          status: this.model?.status,
          ref_VIP: this.model?.ref_VIP,
        };
        await updateCreditRecord(api, this.id, payload);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
        this.$emit('create');
        await this.reload();
      } catch (err) {
        this.handleError(err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.overrideVCardText {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
