<template>
  <v-container>
    <div>
      <h2>{{ title }}</h2>
    </div>
    <v-divider class="mb-5" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card v-if="docs" flat>
        <v-row class="mr-3 ml-3">
          <v-col class="ml-auto" md="auto">
            <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save </v-btn>
          </v-col>
        </v-row>
        <v-row class="mr-3 ml-3">
          <!-- TODO: handle localize string validation -->
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Name(CN)">
              <v-text-field
                :value="localizedName['zh-cn']"
                @input="localizedName = { ...localizedName, 'zh-cn': $event }"
                :error-messages="errors"
                flat
                label="Name(CN)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Name(EN)">
              <v-text-field
                :value="localizedName.en"
                @input="localizedName = { ...localizedName, en: $event }"
                :error-messages="errors"
                flat
                label="Name(EN)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Name(HK)">
              <v-text-field
                :value="localizedName['zh-hk']"
                @input="localizedName = { ...localizedName, 'zh-hk': $event }"
                :error-messages="errors"
                flat
                label="Name(HK)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="SID" rules="required">
              <v-text-field
                v-model="docs.sid"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Sid"
                outlined
                :readonly="!isNew"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mr-3 ml-3">
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true }"
              name="Listed Price"
            >
              <v-text-field
                v-model="docs.listedPrice"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Listed Price ($HKD)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true }"
              name="Discounted Price"
            >
              <v-text-field
                v-model="docs.discountedPrice"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Discounted Price ($HKD)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Description">
              <v-text-field
                v-model="docs.description"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Description"
                outlined
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mr-3 ml-3">
          <v-col cols="12"><h4>Discount</h4></v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="Credit Addition"
            >
              <v-text-field
                v-model="docs.discount.creditAddition"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Credit Addition (積分增加)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="In House Discount"
            >
              <v-text-field
                v-model="docs.discount.inHouseDiscount"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="In House Discount (會員折扣)"
                outlined
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true }"
              name="Vip Index"
            >
              <v-text-field
                v-model="docs.discount.VIPIndex"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Vip Index (優先等級)"
                outlined
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="VIP Room Booking Discount"
            >
              <v-text-field
                v-model="docs.discount.VIPRoomBookingDiscount"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="VIP Room Booking Discount (會員房間預約優惠)"
                outlined
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="Same In House Discount Quota"
            >
              <v-text-field
                v-model="docs.discount.sameInHouseDiscountQuota"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Same In House Discount Quota (同行同等優惠名額)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="Free Instructor Quota"
            >
              <v-text-field
                v-model="docs.discount.freeCoachQuota"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Free Instructor Quota (免費教練課程次數)"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              v-model="docs.discount.specialTwoHourCeiling"
              label="Special Two Hour Ceiling (單日銷費2小時以上以2小時計算)"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              v-model="docs.discount.semiPrivateLaneService"
              label="Semi Private Lane Service (半私人車道服務)"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              v-model="docs.discount.freeRentalOfSkiEquipment"
              label="Free Rental Of Ski Equipment (滑雪用品免租金)"
            />
          </v-col>
        </v-row>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { RESTFUL } from '@/data/constants';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'ServiceProductDetails',
  components: { CustomLabel },
  props: {
    id: {},
  },
  data() {
    return {
      docs: null,
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    title() {
      return this.isNew ? 'New Vip Product' : 'Vip Product Details';
    },
    localizedName: {
      get() {
        return this.parseLocalizedString(this.docs?.name);
      },
      set(val) {
        this.docs.name = JSON.stringify(val);
      },
    },
  },
  methods: {
    parseLocalizedString,
    async reload() {
      if (this.isNew) {
        this.docs = { discount: {} };
      } else {
        this.docs = await crudRead(api, RESTFUL.vipProducts.list, this.id);
      }
    },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.vipProducts.create, this.docs)
          : await crudUpdate(api, RESTFUL.vipProducts.list, this.id, this.docs);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'vipProduct',
            params: {
              id: model._id,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
