import { render, staticRenderFns } from "./PayrollNote.vue?vue&type=template&id=81c8d1fa&scoped=true&"
import script from "./PayrollNote.vue?vue&type=script&lang=js&"
export * from "./PayrollNote.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81c8d1fa",
  null
  
)

export default component.exports