<template>
  <v-container>
    <div class="d-flex justify-space-between mb-2">
      <h2 class="ml-3">Product Category Details</h2>
      <v-btn color="danger" @click="promptDelete" class="mr-3" :disabled="isMaster">Delete</v-btn>
    </div>
    <v-divider class="mb-5 mx-3" />
    <v-card v-if="docs" class="px-3 py-3" flat>
      <v-row>
        <v-col sx="12" sm="6" md="6">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card min-height="400">
              <v-row class="mr-3 ml-3">
                <v-col class="ml-auto" md="auto">
                  <v-btn :disabled="invalid || isMaster" color="primary" @click="saveLeft"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="mr-3 ml-3">
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Product Name" rules="required">
                    <v-text-field
                      v-model="docs.title"
                      :error-messages="errors"
                      flat
                      label="Product Name"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Description" rules="required">
                    <v-textarea
                      v-model="docs.description"
                      :error-messages="errors"
                      flat
                      label="Description"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <!-- <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Discount">
                    <v-text-field
                      outlined
                      v-model.number="docs.discount"
                      type="number"
                      label="Discount"
                      flat
                      placeholder="eg. 0.8 (20% off)"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col> -->
                <!-- <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="URL" rules="required">
                    <v-text-field
                      v-model="docs.navigationUrl"
                      :error-messages="errors"
                      flat
                      label="URL"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->
              </v-row>
            </v-card>
          </ValidationObserver>
        </v-col>
        <v-col style="margin-top: -12px" xs="12" sm="6" md="6">
          <v-card min-height="484">
            <v-card-title v-if="image">
              <v-img :src="image" max-width="200" max-height="250" min-height="230" />
              <v-btn v-if="image" icon @click="removeImage" :disabled="isMaster">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title v-else>
              <v-img
                src="@/assets/TocaLogo.svg"
                max-width="200"
                max-height="250"
                min-height="230"
                contain
              />
            </v-card-title>
            <v-card-text>
              <div class="d-flex mt-3">
                <v-file-input
                  class="flex-grow-1"
                  outlined
                  v-model="newImage"
                  label="Image"
                  flat
                  accept="image/jpeg,image/png"
                />
                <v-btn
                  class="ml-3 flex-shrink-0"
                  @click="saveImage"
                  color="primary"
                  :disabled="isMaster"
                  >Save</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { deleteAttachment, uploadSingleAttachment } from '@/api/attachment';
import { crudDelete, crudRead, crudUpdate } from '@/api/crud';
import { attnThumbHref } from '@/services/filters';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ProductCategoryDetails',
  data() {
    return {
      docs: null,
      newImage: null,
    };
  },
  props: {
    id: {},
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    image() {
      return this.imageId && attnThumbHref(this.docs.featuredImage);
    },
    imageId() {
      return this.docs?.featuredImage;
    },
  },
  methods: {
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      this.docs = await crudRead(api, 'toca/admin/ProductCategories', this.id);
    },
    async removeImage() {
      try {
        try {
          await deleteAttachment(api, this.imageId);
        } catch (e) {
          //no-op
        }
        await crudUpdate(api, 'toca/admin/ProductCategories', this.id, {
          featuredImage: null,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveImage() {
      try {
        const attachment = await uploadSingleAttachment(api, this.newImage);
        await crudUpdate(api, 'toca/admin/ProductCategories', this.id, {
          featuredImage: attachment._id,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveLeft() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        await crudUpdate(api, 'toca/admin/ProductCategories', this.id, {
          title: this.docs.title,
          description: this.docs.description,
          navigationUrl: this.docs.navigationUrl,
          discount: this.docs.discount,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        console.log(e);
      }
    },
    async promptDelete() {
      if (!confirm('Do you confirm to delete?')) return;
      try {
        await crudDelete(api, 'toca/admin/ProductCategories', this.id);
        //TODO: change endpoint / handle in endpoint hook if products (phase2)
        await this.$router.push({ name: 'productCategories' });
        alert('delete success');
      } catch (e) {
        alert('delete fail');
      }
    },
  },
};
</script>

<style scoped></style>
