<template>
  <v-card height="100%" min-height="150" style="position: relative">
    <v-card-title></v-card-title>
    <v-card-subtitle style="padding-bottom: 0px"> {{ content.sid }}</v-card-subtitle>
    <v-card-title>
      <span>{{ content.name }}</span>
      <v-spacer></v-spacer>
      <v-icon large> mdi-chevron-right</v-icon>
      <v-chip class="mr-2 roleChip text-capitalize" label v-if="content.role">
        {{ content.role }}
      </v-chip>
    </v-card-title>
    <v-card-actions style="padding: 16px">
      <!--      <v-chip v-if='content.team' class='primary chipMargin' label>{{ content.team.label }}</v-chip>-->
      <!--      <v-chip class='chipMargin' color='red' dark v-else>No Team</v-chip>-->
      <!--      <v-chip class='chipMargin' color='primary' v-if='content.isSender'>Sender</v-chip>-->
      <v-spacer />
      <slot name="deleteCard"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ServiceProductsCard',
  props: {
    content: {
      default: () => ({}),
    },
  },
};
</script>
<style scoped>
.roleChip {
  position: absolute;
  top: 0;
  right: -8px;
}
</style>

<style scoped></style>
