var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.title))])]),_c('v-divider',{staticClass:"mb-5"}),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.docs)?_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{attrs:{"disabled":invalid || _vm.isMaster,"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save ")]),(_vm.isNew && _vm.isAdmin)?_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":invalid},on:{"click":_vm.applyVIPProductImmediately}},[_vm._v(" Apply VIP Product Immediately (without payment) ")]):_vm._e(),(!_vm.isNew && _vm.docs.paymentStatus === 'pending')?_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":invalid || _vm.isMaster,"color":"secondary"},on:{"click":_vm.createPayment}},[_vm._v("Create Payment ")]):_vm._e()],1)],1),_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"SID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Sid","outlined":"","readonly":!_vm.isNew},model:{value:(_vm.docs.sid),callback:function ($$v) {_vm.$set(_vm.docs, "sid", $$v)},expression:"docs.sid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_vm._v("Status: "),_c('h4',[_vm._v(_vm._s(_vm._f("status")(_vm.docs.status)))])]),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Payment Method","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Payment Method","outlined":"","readonly":!_vm.isNew},model:{value:(_vm.docs.paymentMethod),callback:function ($$v) {_vm.$set(_vm.docs, "paymentMethod", $$v)},expression:"docs.paymentMethod"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Payment Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Payment Status","outlined":"","readonly":"","disabled":""},model:{value:(_vm.docs.paymentStatus),callback:function ($$v) {_vm.$set(_vm.docs, "paymentStatus", $$v)},expression:"docs.paymentStatus"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{ref:"autoComplete",attrs:{"label":"Customer","path":_vm.computedCustomerProfileUrl,"params":{ sort: { created_at: -1 } },"item-text":"name","item-value":"_id","error-messages":errors,"clearable":true,"has-items-slot":true,"return-object":true},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.get(content, 'item.name', '') + ' ' + _vm.displayPhone(_vm.get(content, 'item.phone', ''))))])]}}],null,true),model:{value:(_vm.docs.owner),callback:function ($$v) {_vm.$set(_vm.docs, "owner", $$v)},expression:"docs.owner"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}}),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"VIP Product","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"label":"VIP Product","path":_vm.RESTFUL.vipProducts.list,"params":{ sort: { 'discount.VIPIndex': -1, created_at: -1 } },"item-text":function (x) { return _vm.parseLocalizedString(x.name).en; },"item-value":"_id","error-messages":errors,"clearable":true,"return-object":true},on:{"change":_vm.changeVIPProduct},model:{value:(_vm.docs.VIPProduct),callback:function ($$v) {_vm.$set(_vm.docs, "VIPProduct", $$v)},expression:"docs.VIPProduct"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true },"name":"Amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Amount ($HKD)","outlined":""},model:{value:(_vm.docs.amountPayable),callback:function ($$v) {_vm.$set(_vm.docs, "amountPayable", $$v)},expression:"docs.amountPayable"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Discount")])]),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"Credit Addition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Credit Addition","outlined":""},model:{value:(_vm.docs.discount.creditAddition),callback:function ($$v) {_vm.$set(_vm.docs.discount, "creditAddition", $$v)},expression:"docs.discount.creditAddition"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"In House Discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"In House Discount","outlined":""},model:{value:(_vm.docs.discount.inHouseDiscount),callback:function ($$v) {_vm.$set(_vm.docs.discount, "inHouseDiscount", $$v)},expression:"docs.discount.inHouseDiscount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true },"name":"Vip Index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Vip Index","outlined":""},model:{value:(_vm.docs.discount.VIPIndex),callback:function ($$v) {_vm.$set(_vm.docs.discount, "VIPIndex", $$v)},expression:"docs.discount.VIPIndex"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"VIP Room Booking Discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Room Booking Discount","outlined":""},model:{value:(_vm.docs.discount.VIPRoomBookingDiscount),callback:function ($$v) {_vm.$set(_vm.docs.discount, "VIPRoomBookingDiscount", $$v)},expression:"docs.discount.VIPRoomBookingDiscount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"Same In House Discount Quota"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Same In House Discount Quota","outlined":""},model:{value:(_vm.docs.discount.sameInHouseDiscountQuota),callback:function ($$v) {_vm.$set(_vm.docs.discount, "sameInHouseDiscountQuota", $$v)},expression:"docs.discount.sameInHouseDiscountQuota"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"Free Instructor Quota"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Free Instructor Quota","outlined":""},model:{value:(_vm.docs.discount.freeCoachQuota),callback:function ($$v) {_vm.$set(_vm.docs.discount, "freeCoachQuota", $$v)},expression:"docs.discount.freeCoachQuota"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Special Two Hour Ceiling"},model:{value:(_vm.docs.discount.specialTwoHourCeiling),callback:function ($$v) {_vm.$set(_vm.docs.discount, "specialTwoHourCeiling", $$v)},expression:"docs.discount.specialTwoHourCeiling"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Semi Private Lane Service"},model:{value:(_vm.docs.discount.semiPrivateLaneService),callback:function ($$v) {_vm.$set(_vm.docs.discount, "semiPrivateLaneService", $$v)},expression:"docs.discount.semiPrivateLaneService"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Free Rental Of Ski Equipment"},model:{value:(_vm.docs.discount.freeRentalOfSkiEquipment),callback:function ($$v) {_vm.$set(_vm.docs.discount, "freeRentalOfSkiEquipment", $$v)},expression:"docs.discount.freeRentalOfSkiEquipment"}})],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }