<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="participants"
      :hide-default-footer="true"
      :disable-sort="true"
      :items-per-page="-1"
      v-bind="$attrs"
      :on="$listeners"
      v-model="selected"
      @toggle-select-all="selectAllToggle"
      :show-select="showSelect"
    >
      <template v-if="!showSelect" #header.data-table-select></template>
      <template #item.data-table-select="{ item, isSelected, select }">
        <v-simple-checkbox
          v-if="showSelect && !item._disabled"
          large
          :ripple="false"
          :value="isSelected"
          @input="select"
        />
        <span v-else>
          <div v-if="item._settle">
            <div>Settled</div>
            <div class="caption">{{ item._settle.settleAt | moment('YYYY-MM-DD HH:mm:ss') }}</div>
          </div>
          <div v-else="!item.profile.uuid">No Handbnand</div>
        </span>
      </template>
      <template v-slot:item.profile.phone="{ item }">
        {{ item.profile.phone | displayPhone }}
      </template>
      <template v-slot:item._clockOut="{ item }">
        <div class="d-flex align-center" v-if="item._clockOut">
          <div>{{ item._clockOut | moment('HH:mm:ss') }}</div>
          <v-btn @click="showMoreCheckInOutHistory(item)" icon>
            <v-icon>mdi-view-list-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:item.gear="{ item }">
        <div v-if="get(item, 'gear.boots')">Boots {{ item.gear.boots }}</div>
        <div v-if="get(item, 'gear.bindings')">Bindings {{ item.gear.bindings }}</div>
      </template>
    </v-data-table>
    <v-dialog v-model="checkInOutDialog" max-width="600px">
      <v-card color="white" class="pb-1 px-4">
        <div class="d-flex">
          <v-spacer />
          <v-btn icon small @click="checkInOutDialog = false" class="ma-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <CheckInRecordList :timeRange="checkInOutRange" :client="selectedCheckInOutClient" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CheckInRecordList from '@/components/CheckInRecord/CheckInRecordList.vue';
import { getOid } from '@/services/utils';
import { get } from 'lodash';
import api from '@/api';
import moment from 'moment';

export default {
  components: {
    CheckInRecordList,
  },
  props: {
    booking: { type: Object, default: () => {} },
    value: null,
    showSelect: { type: Boolean, default: false },
  },
  data() {
    return {
      participants: [],
      clockOutRecords: [],

      selectedCheckInOutClient: null,
      checkInOutDialog: false,
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    headers() {
      return [
        { text: 'ID', value: 'profile.sid' },
        { text: 'Name', value: 'profile.name' },
        { text: 'Phone', value: 'profile.phone' },
        { text: 'Gear Rented', value: 'gear' },
        { text: 'Handband', value: 'profile.uuid' },
        { text: 'CheckOut Time', value: '_clockOut' },
        { text: '', value: 'data-table-select', align: 'right', width: '170px' },
      ];
    },
    checkInOutRange() {
      return {
        start: moment(this.booking.time.start).startOf('d').toDate(),
        end: moment(this.booking.time.end).endOf('d').toDate(),
      };
    },
    disabledCount() {
      return this.participants.filter(participant => participant._disabled).length;
    },
  },
  watch: {
    booking: {
      handler(v) {
        if (v) {
          this.reload();
        }
      },
      immediate: true,
    },
  },
  methods: {
    get,
    selectAllToggle() {
      if (this.selected.length + this.disabledCount === this.participants.length) {
        this.selected = [];
      } else {
        this.selected = this.participants.filter(participant => !participant._disabled);
      }
    },
    async reload() {
      await this.reloadCheckInOutTime();
      this.participants = [
        this.booking.mainParticipant,
        ...(this.booking.otherParticipants || []),
      ].map(participant => {
        const profile = participant.profile;
        participant._settle = this.booking.settleHistory.find(settle =>
          settle.participants.includes(getOid(profile)),
        );
        participant._clockOut = this.clockOutRecords.find(
          clockOutRecord => clockOutRecord.client === getOid(profile),
        )?.created_at;

        participant._disabled = !profile.uuid || !!participant._settle;
        participant.id = participant.profile.id;
        return participant;
      });
    },
    async reloadCheckInOutTime() {
      this.clockOutRecords = (
        await api.get(`/toca/master/booking/checkOutRecord/${getOid(this.booking)}`)
      ).data;
      // console.log(this.clockOutRecords);
    },
    async showMoreCheckInOutHistory(item) {
      this.selectedCheckInOutClient = getOid(item.profile);
      this.checkInOutDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
