<template>
  <div>
    <div>Credit Card</div>
    <div class="d-flex" style="gap: 24px">
      <div v-for="{ value, label } in paymentOfCreditCardOptions" :key="value">
        <v-btn
          @click="paymentMethod = value"
          :class="{ selectedPackageItem: paymentMethod === value }"
          >{{ label }}
        </v-btn>
      </div>
    </div>
    <br />
    <div>Other</div>
    <div class="d-flex flex-wrap" style="gap: 24px">
      <div v-for="{ value, label } in paymentOfOthersOptions" :key="value">
        <v-btn
          @click="paymentMethod = value"
          :class="{ selectedPackageItem: paymentMethod === value }"
          >{{ label }}
        </v-btn>
      </div>
    </div>
    <div v-for="errorMessage in errorMessages">
      <span class="error--text">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import { paymentOfCreditCardOptions, paymentOfOthersOptions } from '@/data/optionsConstants';

export default {
  name: 'PaymentMethodSelection',
  props: {
    value: {
      type: String,
      default: '',
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      paymentOfCreditCardOptions,
      paymentOfOthersOptions,
    };
  },
  computed: {
    paymentMethod: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.selectedPackageItem {
  background-color: var(--v-primary-base) !important;
  color: white;
}
</style>
