<template>
  <ListView
    title="Receive Product"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="false"
  >
    <template #filter>
      <div class="d-flex" style="gap: 10px">
        <v-select
          v-model="productIdentityFilter"
          :items="productIdentityOptions"
          outlined
          label="Product"
          placeholder="Product"
          style="max-width: 350px"
        />
        <v-select
          v-model="queryFilter.to"
          :items="storeOptions"
          outlined
          label="Store"
          placeholder="Store"
          clearable
          style="max-width: 350px"
        />
      </div>
    </template>
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment }}
    </template>
    <template v-slot:item.items.product="{ item }">
      <div class="d-flex align-center">
        <v-img
          class="grey lighten-3 ma-2 clickable"
          max-width="80"
          height="80"
          contain
          @click="viewImage(item)"
          :style="haveImage(item) ? {} : { cursor: 'unset' }"
          :src="
            getThumbnailUrlById(
              item.items.unknownProduct ? item.items.attachment : item.items.product.featuredImage,
            )
          "
        />
        <div v-if="item.items.unknownProduct" class="red--text font-weight-bold">
          Unknown Product
        </div>
        <div v-else>
          <div>{{ parseLocalizedString(item.items.product.name).en }}</div>
          <div>{{ parseLocalizedString(item.items.product.name)['zh-hk'] }}</div>
        </div>
      </div>
    </template>
    <template v-slot:item.to="{ item }">
      {{ getStoreName(item.to) }}
    </template>
    <template v-slot:item.remarks="{ item }">
      <div
        v-if="item.remarks"
        class="d-flex align-center overflow-y-auto"
        style="max-height: 90px; width: 250px"
      >
        <div style="flex: 1; max-height: 90px">{{ item.remarks }}</div>
        <v-btn
          class="ml-1"
          color="primary"
          elevation="0"
          fab
          x-small
          text
          @click.stop="openRemarksDialog(item)"
        >
          <v-icon dark>mdi-pencil</v-icon>
        </v-btn>
      </div>
      <v-btn
        v-else
        class="d-block mx-auto"
        fab
        elevation="0"
        dark
        small
        color="primary"
        @click.stop="openRemarksDialog(item)"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-dialog v-model="remarksDialog" max-width="500">
      <v-card>
        <v-card-title>
          Remarks
          <v-spacer />
          <v-btn icon :disabled="loadingUpdateRemarks" @click="remarksDialog = false">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="computedRemarks" auto-grow outlined hide-details="auto"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="loadingUpdateRemarks" @click.stop="saveRemarks">
            Save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CarouselDialog v-model="displayImage" :images="selectedImages" />

    <!-- <ProductEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      :productCategories="productCategories"
      @create="reload"
      @update="reload"
      @delete="reload"
    /> -->
  </ListView>
</template>

<script>
import ProductEditDialog from '@/components/PhysicalProduct/ProductEditDialog';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { mapActions, mapGetters } from 'vuex';
import { parseLocalizedString } from '@/services/utils';
import api from '@/api';
import moment from 'moment';
import { attnThumbHref, getThumbnail } from '@/services/filters';
import CarouselDialog from '@/components/CarouselDialog.vue';

export default {
  name: 'ReceiveProducts',
  data() {
    return {
      api,
      header: [
        { text: 'Date', value: 'created_at' },
        { text: 'Store', value: 'to' },
        { text: 'Product', value: 'items.product' },
        { text: 'Qty', value: 'items.receivedQty' },
        { text: 'Received By', value: 'receivedBy.profiles[0].name' },
        { text: 'Status', value: 'status' },
        { text: 'Remarks', value: 'remarks' },
      ],
      populate: [{ path: 'receivedBy.profiles', model: 'profile', select: 'name' }],
      queryFilter: {},
      productIdentityFilter: null,
      productCategories: [],
      stores: [],
      remarksDialog: false,
      selectedStockTransfer: null,
      loadingUpdateRemarks: false,
      displayImage: false,
      selectedItem: null,
    };
  },
  mixins: [listViewPageMixin],
  components: { ProductEditDialog, CarouselDialog, CarouselDialog },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return RESTFUL.transfers.list;
    },
    filter() {
      const out = {};
      const to = this.queryFilter.to || undefined;
      if (this.productIdentityFilter) {
        Object.assign(out, this.productIdentityFilter);
      }
      Object.assign(out, this.queryFilter, { to });

      return out;
    },
    mergeParams() {
      return {
        unwindItems: true,
      };
    },
    storeOptions() {
      return this.stores.map(store => ({ text: store.name, value: store._id }));
    },
    productIdentityOptions() {
      return [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Existing Product',
          value: { 'items.unknownProduct': { $ne: true } },
        },
        {
          text: 'Unknown Product',
          value: { 'items.unknownProduct': true },
        },
      ];
    },
    computedRemarks: {
      get() {
        return this.selectedStockTransfer?.remarks;
      },
      set(v) {
        this.selectedStockTransfer.remarks = v;
      },
    },
    selectedImages() {
      let list = [];
      if (this.selectedItem?.unknownProduct) {
        if (this.selectedItem?.attachment) list.push(this.selectedItem?.attachment);
      } else {
        if (this.selectedItem?.product?.featuredImage)
          list.push(this.selectedItem?.product?.featuredImage);
        if (this.selectedItem?.product?.images?.length > 0)
          list = list.concat(this.selectedItem?.product?.images);
      }
      return list;
    },
  },
  mounted() {
    this.fetchStores();
  },
  methods: {
    parseLocalizedString,
    ...mapActions('alert', ['updateAlertMessage']),
    getThumbnailUrlById(attachmentId) {
      return getThumbnail(api, attachmentId, 128);
    },
    async fetchStores() {
      this.stores = (
        await api.get(RESTFUL.stores.list, { params: { limit: 50, select: ['name', 'sid'] } })
      )?.data?.docs;
    },
    getStoreName(id) {
      const store = this.stores.find(store => store._id === id);
      return store?.name || '';
    },
    actionDetails() {
      //
    },
    viewImage(item) {
      if (this.haveImage(item)) {
        this.selectedItem = item?.items;
        this.displayImage = true;
      }
    },
    openRemarksDialog(item) {
      this.selectedStockTransfer = JSON.parse(JSON.stringify(item)); // remove reactivity
      this.remarksDialog = true;
    },
    async saveRemarks() {
      const id = this.selectedStockTransfer?._id;
      if (id) {
        this.loadingUpdateRemarks = true;
        try {
          await api.patch(`${RESTFUL.transfers.update}/${id}`, this.selectedStockTransfer);
          await this.updateAlertMessage({
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
          this.remarksDialog = false;
          await this.reload();
        } catch (error) {
          alert(error);
        } finally {
          this.loadingUpdateRemarks = false;
        }
      }
    },
    haveImage(item) {
      return item?.items?.unknownProduct
        ? Boolean(item?.items?.attachment)
        : item?.items?.product?.featuredImage
        ? Boolean(item?.items?.product?.featuredImage)
        : item?.items?.product?.images?.length > 0;
    },
  },
};
</script>
