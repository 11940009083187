<template>
  <v-dialog v-model="valueProxy" max-width="100vw" width="60%" @click:outside="$emit('close')">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card>
        <span class="d-flex justify-space-between">
          <v-card-title style="align-items: center">Edit Package</v-card-title>

          <v-icon @click="close()" style="padding-right: 16px">mdi-close</v-icon>
        </span>

        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Expiry Date"
                  rules="required"
                  v-if="isAdmin"
                >
                  <v-menu
                    v-model="showExpiryDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <custom-label item-field-name="Expiry Date (Admin Only)" />
                      <v-text-field
                        v-model="expiryDate"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="expiryDate"></v-date-picker>
                  </v-menu>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" name="Purchase Date" rules="required">
                  <v-menu
                    v-model="showDatePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    min-width="auto"
                    offset-y
                    transition="scale-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <custom-label item-field-name="Purchase Date" />
                      <v-text-field
                        v-model="pickerDate"
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="pickerDate"></v-date-picker>
                  </v-menu>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, min_value: 0 }"
                  name="Quota Left"
                  v-if="isAdmin"
                >
                  <custom-label item-field-name="Quota left (Admin Only)" />
                  <v-text-field
                    v-model.number="doc.quotaLeft"
                    :error-messages="errors"
                    flat
                    label="Quota Left"
                    outlined
                    type="number"
                  />
                </ValidationProvider>
                <!-- <custom-label item-field-name="Facility" />
                <ValidationProvider v-slot="{ errors }" name="master" rules="required">
                  <autocomplete
                    v-model="doc.commission.to"
                    :path="computedProfileUrl"
                    :error-messages="errors"
                    :params="{
                      sort: { created_at: -1 },
                      filter: {
                        $and: [
                          { $or: [{ status: ProfileStatus.accepted }, { _id: doc.commission.to }] },
                        ],
                      },
                    }"
                    item-text="name"
                    item-value="_id"
                    :clearable="true"
                  />
                </ValidationProvider> -->
                <ValidationProvider name="Remark">
                  <custom-label item-field-name="Remark" />
                  <v-text-field label="Remark" v-model="doc.remarks" outlined flat />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn :disabled="invalid" @click="patchTicket()">Save</v-btn>
          <!--          <v-btn @click="close()">Cancel</v-btn>-->
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { crudUpdate } from '@/api/crud';
import api from '@/api';
import CustomLabel from '@/components/customFormLabel/customLabel';
import moment from 'moment';
import { readProfile, readProfileByMaster } from '@/api/auth/membership';
import { mapGetters } from 'vuex';
import { ProfileStatus } from '@/api/auth/membership';

export default {
  name: 'TicketEditDialog',
  components: { CustomLabel, Autocomplete },
  props: {
    show: Boolean,
    content: {},
  },
  computed: { ...mapGetters('auth', ['isMaster', 'isAdmin']) },
  data() {
    return {
      ProfileStatus,
      doc: {},
      showDatePicker: false,
      showExpiryDatePicker: false,
    };
  },
  watch: {
    show: {
      async handler(v) {
        if (!v) return;
        this.doc = {
          ...this.content,
        };
        await this.$nextTick();
        this.$refs.form.reset();
      },
    },
  },
  methods: {
    async patchTicket() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      var ExpiryValid = moment(this.doc.created_at).isBefore(this.doc.expireAt);
      if (!ExpiryValid) return alert('Expiry Date invalid');
      try {
        this.doc.commission.amountPayable = this.doc.amountPayable * 0.035;
        const payload = {
          quotaLeft: this.doc.quotaLeft,
          created_at: this.doc.created_at,
          expireAt: this.doc.expireAt
            ? this.doc.expireAt
            : moment(this.doc.created_at).add(2, 'year'),
          commission: this.doc.commission,
          remarks: this.doc.remarks,
        };
        await crudUpdate(api, 'toca/admin/TicketsPackages', this.content?._id, payload);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Updated',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
        this.$emit('close');
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
    close() {
      this.$emit('close');
    },
  },
  computed: {
    valueProxy: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    pickerDate: {
      get() {
        return moment(this.doc.created_at).format('YYYY-MM-DD');
      },
      set(v) {
        this.doc.created_at = moment(v).toDate();
        this.showDatePicker = false;
      },
    },
    expiryDate: {
      get() {
        return moment(this.doc.expireAt).format('YYYY-MM-DD');
      },
      set(v) {
        this.doc.expireAt = moment(v).toDate();
        this.showExpiryDatePicker = false;
      },
    },
    computedProfileUrl() {
      return this.isMaster ? 'toca/master/MasterProfiles' : 'toca/admin/MasterProfiles';
    },
  },
};
</script>

<style scoped></style>
