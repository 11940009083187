<template>
  <div v-if="docs" class="service-details">
    <v-tabs v-model="tab" center-active>
      <v-tab :disabled="!isBookingArrivedOrCompleted">Today Color</v-tab>
      <!--      <v-tab :disabled="!isBookingArrivedOrCompleted">Order Product</v-tab>-->
      <v-tab>Hair Condition</v-tab>
      <v-tab>Booking Record</v-tab>
      <v-tab>Paper Record</v-tab>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <color-proportion
            :id="id"
            :clientId="docs.created_by && docs.created_by._id"
            :first-round="docs.serviceRecord.colorProportion.firstRound"
            :second-round="docs.serviceRecord.colorProportion.secondRound"
            :created_by="docs.created_by"
            @updateRound="updateRound"
          />
          <remarks
            :id="id"
            :service-record="docs.serviceRecord"
            :newBeforeServicePhoto.sync="newBeforeServicePhoto"
            :newAfterServicePhoto.sync="newAfterServicePhoto"
            @reload="reload"
          />
          <div class="text-right">
            <!--                Missing API-->
            <v-btn color="primary" @click="patchRemarksAndColorProportion">Save</v-btn>
          </div>
        </v-tab-item>
        <!--        Sold Product Tab-->
        <!--        <v-tab-item>-->
        <!--          <sold-product />-->
        <!--        </v-tab-item>-->
        <!--        Hair Condition Tab-->
        <v-tab-item>
          <hair-condition
            v-if="docs.created_by"
            :id="docs.created_by._id"
            :hair-condition="docs.created_by.hairCondition"
            @reload="reload"
          />
        </v-tab-item>
        <!--        Service Record Tab-->
        <v-tab-item>
          <service-record v-if="docs.created_by" :id="docs.created_by._id" />
        </v-tab-item>
        <v-tab-item>
          <Previous-service-record v-if="docs.created_by" :id="docs.created_by._id" />
          <previous-hard-copy-list
            v-if="docs.created_by"
            :model="docs.created_by"
            @reload="reload"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import { BookingStatus, patchServiceRecord, readBooking } from '@/api/bookings';
import api from '@/api';
import ColorProportion from '@/components/ServiceDetails/colorProportion';
import Remarks from '@/components/ServiceDetails/remarks';
import HairCondition from '@/components/ServiceDetails/hairCondition';
import ServiceRecord from '@/components/ServiceDetails/serviceRecord';
import PreviousServiceRecord from '@/components/ServiceDetails/previousServiceRecord';
import { cloneDeep, get, set } from 'lodash';
import PreviousHardCopyList from '@/components/ServiceDetails/previousHardCopyList';
import { uploadSingleAttachment } from '@/api/attachment';

export default {
  name: 'ServiceDetailsCard',
  components: {
    PreviousHardCopyList,
    ServiceRecord,
    HairCondition,
    Remarks,
    ColorProportion,
    PreviousServiceRecord,
  },
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      docs: null,
      firstRound: null,
      secondRound: null,
      page: 1,
      tab: this.isBookingArrivedOrCompleted ? 0 : 3,
      newBeforeServicePhoto: null,
      newAfterServicePhoto: null,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) this.reload();
      },
    },
    isBookingArrivedOrCompleted(v) {
      this.$set(this, 'tab', v ? 0 : 3);
    },
  },
  computed: {
    isBookingArrivedOrCompleted() {
      return (
        this.docs?.status === BookingStatus.arrived || this.docs?.status === BookingStatus.completed
      );
    },
  },
  methods: {
    async reload() {
      this.docs = await readBooking(api, this.id, {
        populate: [
          'created_by',
          { path: 'serviceDetails.packageSpecified' },
          { path: 'serviceDetails.serviceProduct' },
          { path: 'previousHardCopy' },
          { path: 'serviceRecord.afterServicePhoto' },
          { path: 'serviceRecord.beforeServicePhoto' },
        ],
      });
      if (
        !get(this.docs, 'serviceRecord.colorProportion.firstRound', null) ||
        !get(this.docs, 'serviceRecord.colorProportion.secondRound', null)
      )
        this.$set(this.docs.serviceRecord, 'colorProportion', { firstRound: {}, secondRound: {} });
    },
    async patchRemarksAndColorProportion() {
      try {
        let payload = cloneDeep(this.docs.serviceRecord);
        let beforeServicePhotoAttachment = null;
        let afterServicePhotoAttachment = null;
        if (this.newBeforeServicePhoto) {
          beforeServicePhotoAttachment = await uploadSingleAttachment(
            api,
            this.newBeforeServicePhoto,
          );
          set(payload, 'beforeServicePhoto', beforeServicePhotoAttachment._id);
        }
        if (this.newAfterServicePhoto) {
          afterServicePhotoAttachment = await uploadSingleAttachment(
            api,
            this.newAfterServicePhoto,
          );
          set(payload, 'afterServicePhoto', afterServicePhotoAttachment._id);
        }
        await patchServiceRecord(api, this.id, {
          ...this.docs.serviceRecord,
          beforeServicePhoto: payload.beforeServicePhoto,
          afterServicePhoto: payload.afterServicePhoto,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        console.log('e', e);
      }
    },
    updateRound({ path, val }) {
      set(this, `docs.serviceRecord.colorProportion.${path}`, val);
    },
  },
};
</script>

<style>
.service-details .v-slide-group__prev--disabled {
  display: none !important;
}
</style>
