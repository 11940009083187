<template>
  <div>
    <v-container fluid>
      <v-tabs v-model="profileTabs">
        <v-tab>Profile</v-tab>
        <v-tab v-if="isClient">Product Purchase Record</v-tab>
        <!-- <template v-if="isClient || isMaster">
          <v-tab>Booking</v-tab>
          <v-tab>Reviews</v-tab>
          <v-tab v-if="!isMaster">Physical Product Order</v-tab>
                   <v-tab v-if="isMaster">Payroll Note</v-tab>
          <v-tab>Payroll Records</v-tab>
        </template>
        <template v-if="isClient">
          <v-tab>Waiver</v-tab>
        </template> -->
      </v-tabs>
      <v-tabs-items v-model="profileTabs" touchless>
        <v-tab-item>
          <v-row>
            <v-col cols="12">
              <div class="custom-scroll-section">
                <IdentityDetailsCard :model="model" :userId="userId" @reload="reload" />
              </div>
            </v-col>
          </v-row>
        </v-tab-item>
        <template v-if="isClient || isMaster">
          <v-tab-item>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="d-flex align-baseline">
                  <div class="mainTitle mr-4">
                    {{ model.name | capitalize }} (SID: {{ model.sid }})
                  </div>
                  <div v-if="model.role" class="font-size-14">
                    <div class="itemFieldName d-inline-block" style="min-width: 40px">Role:</div>
                    {{ model.role | translateOptions(roleOptions) }}
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <!-- <v-tab-item> -->
            <IdentityPhysicalProductOrderList
              :id="id"
              :isMaster="isMaster"
              @show="x => showPhysicalProductOrder(x)"
            />
            <!-- </v-tab-item> -->
          </v-tab-item>
        </template>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import IdentityDetailsCard from '@/components/Identity/identityDetailsCard';
import IdentityBookingList from '@/components/Identity/IdentityBookingList';
import IdentityPhysicalProductOrderList from '@/components/Identity/IdentityPhysicalProductOrderList.vue';
import { readProfile, getUserListByProfileId, readProfileByMaster } from '@/api/auth/membership';
import api from '@/api';
import RatingRecordList from '@/components/Rating/RatingRecordList';
import BookingDetail from '@/components/Booking/BookingDetail';
import TicketList from '@/components/Ticket/TicketList';
import { RESTFUL, roles } from '@/data/constants';
import ColumnBasedFourCellsLayout from '@/components/detailPageLayout/ColumnBasedFourCellsLayout';
import { mapGetters } from 'vuex';
import { roleOptions } from '@/data/optionsConstants';
import PayrollNoteByIdentity from '@/components/Payroll/PayrollNoteByIdentity';
import PayrollRecords from '@/components/Payroll/PayrollRecords';
import { crudList } from '@/api/crud';
import moment from 'moment';
import { getOid } from '@/services/utils';
import IdentityWaiver from '@/components/Identity/IdentityWaiver.vue';
import { getMatchedStores } from '@/api/storeUser';

export default {
  props: {
    id: {},
  },
  data() {
    return {
      roles,
      labels: [],
      model: {},
      showBooking: false,
      bookingId: null,
      packageTabs: null,
      profileTabs: null,
      userId: null,
    };
  },
  components: {
    ColumnBasedFourCellsLayout,
    TicketList,
    BookingDetail,
    RatingRecordList,
    IdentityDetailsCard,
    IdentityBookingList,
    IdentityPhysicalProductOrderList,
    PayrollNoteByIdentity,
    PayrollRecords,
    IdentityWaiver,
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    isClient() {
      return this.model?.role === roles.client;
    },
    ...mapGetters('auth', ['role']),
    isMaster() {
      return this.model?.role === roles.master && !this.model?.isFacility;
    },
    isFacility() {
      return this.model?.role === roles.master && this.model?.isFacility;
    },
    isCashier() {
      return this.model?.role === roles.cashier;
    },
    isAdmin() {
      return this.model?.role === roles.admin;
    },
    roleOptions: () => roleOptions,
  },
  methods: {
    showBookingDetails(id) {
      this.bookingId = id;
      this.showBooking = true;
      // this.$router.push({
      //   name: 'booking',
      //   params: {
      //     id,
      //   },
      // });
    },
    showPhysicalProductOrder(id) {
      this.$router.push({
        name: 'orders',
        params: {
          id,
        },
      });
    },
    async reload() {
      if (this.role !== roles.master) {
        this.model = await readProfile(api, this.id, {
          populate: [{ path: 'user' }],
        });
        this.$set(
          this.model,
          'store',
          await getMatchedStores(api, {
            profileIds: this.model?._id,
          }),
        );
        // this.labels = await autocompleteLabels(api);
        this.userId = getOid(this.model.user); //(await getUserListByProfileId(api, this.id)).docs?.[0]?._id;
        const res = await Promise.all([
          this.getEmployeeContract(),
          this.getEmployeePayroll(moment()),
        ]);
        const data = await Promise.all(res);
        this.model.contract = data[0];
        this.model.payroll = data[1];
      } else {
        this.model = await readProfileByMaster(api, this.id);
      }
    },
    async getEmployeeContract() {
      if (!this.model?._id) return;
      try {
        return (
          await crudList(api, 'toca/admin/employeeContracts', {
            filter: { staff: this.model._id, isActivate: true },
            sort: { created_at: -1 },
          })
        ).docs[0];
      } catch (e) {
        console.error(e);
      }
    },
    async getEmployeePayroll(date = null) {
      if (!this.model?._id) return;
      try {
        return (
          await crudList(api, RESTFUL.payrollRecords.list, {
            filter: {
              staff: this.model._id,
              'time.start': { $gte: moment(date).startOf('month') },
              'time.end': { $lte: moment(date).endOf('month') },
            },
            sort: { created_at: -1 },
          })
        ).docs[0];
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fullWidth {
  width: 100%;
}

.font-size-14 {
  font-size: 14px;
}

.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.custom-scroll-section {
  overflow-y: auto;
  height: calc(100vh - 108px);
}
</style>
