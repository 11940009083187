/**
 * @param {AxiosInstance} api
 * @param {{start: number, end: number, interval: string, timezone: string, filter?: string}} data
 * @return {Promise}}
 */
export async function bookingCounts(api, data) {
  return (await api.get('toca/admin/dashboard/bookingCounts', { params: data })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{start: number, end: number, interval: string, timezone: string, filter?: string}} data
 * @return {Promise}}
 */
export async function bookingSales(api, data) {
  return (await api.get('toca/admin/dashboard/bookingSales', { params: data })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{start: number, end: number, interval: string, timezone: string, filter?: string}} data
 * @return {Promise}}
 */
export async function memberCounts(api, data) {
  return (await api.get('toca/admin/dashboard/memberCounts', { params: data })).data;
}

/**
 * @param {AxiosInstance} api
 * @return {Promise<{
 *   homeDistrict: {_id, count: number}[];
 *   officeDistrict: {_id, count: number}[];
 * }>}
 */
export async function memberDistricts(api, data) {
  return (await api.get('toca/admin/dashboard/memberDistricts', { params: data })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{start: number, end: number, timezone: string}} data
 * @return {Promise<{_id, count: number}[]>}
 */
export async function paymentMethods(api, data) {
  return (await api.get('toca/admin/dashboard/paymentMethods', { params: data })).data;
}
