var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"border":"1px solid black"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"ma-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Save' : 'Update')+" ")])],1)],1),_c('v-divider'),(_vm.supportImage)?_c('div',[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("Image")]),(_vm.hasFeaturedImages)?_c('div',[_c('v-carousel',{attrs:{"show-arrows":_vm.hasMultiImage,"hide-delimiters":!_vm.hasMultiImage,"height":"300"}},[_vm._l((_vm.model.featuredImages),function(att){return [_c('v-carousel-item',{attrs:{"src":_vm.attnThumbHref(att, '2k'),"cover":""}},[_c('div',{staticStyle:{"position":"absolute","right":"0px"},style:(_vm.hasMultiImage ? 'bottom: 50px;' : 'bottom: 0px')},[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.confirmRemoveImage(att)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)])]})],2)],1):_c('div',{staticClass:"d-flex justify-center"},[_vm._v("No Featured Image")]),_c('div',{staticClass:"d-flex",staticStyle:{"margin-top":"20px"}},[_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Photo Upload","outlined":"","multiple":_vm.supportMultiImage,"accept":"image/jpeg,image/png"},model:{value:(_vm.newImage),callback:function ($$v) {_vm.newImage=$$v},expression:"newImage"}}),_c('div',{staticStyle:{"margin":"10px 20px"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.uploadImage()}}},[_vm._v(" Upload ")])],1)],1),_c('v-divider')],1):_vm._e(),_c('div',[(_vm.isHomePageButNotHeader)?_c('div',[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("Button Link to")]),_c('ValidationProvider',{attrs:{"name":"Button Link to"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Button Link to","outlined":""},model:{value:(_vm.model.buttonLink),callback:function ($$v) {_vm.$set(_vm.model, "buttonLink", $$v)},expression:"model.buttonLink"}})]}}],null,true)}),_c('v-divider',{staticStyle:{"margin-top":"20px"}})],1):_vm._e(),_c('div',[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),(_vm.isHomePageButNotHeader)?_c('div',[_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(_vm.model['titlezh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'titlezh-HK', $$v)},expression:"model['titlezh-HK']"}})]}}],null,true)})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":"zh-HK content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model['zh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'zh-HK', $$v)},expression:"model['zh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),(_vm.isHomePageButNotHeader)?_c('div',{staticStyle:{"margin-top":"40px"}},[_c('ValidationProvider',{attrs:{"name":"按鈕內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"按鈕內容","outlined":""},model:{value:(_vm.model['buttonContentzh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'buttonContentzh-HK', $$v)},expression:"model['buttonContentzh-HK']"}})]}}],null,true)})],1):_vm._e()],1),_c('v-divider',{staticStyle:{"margin-top":"20px"}}),_c('div',[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),(_vm.isHomePageButNotHeader)?_c('div',[_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(_vm.model['titlezh']),callback:function ($$v) {_vm.$set(_vm.model, 'titlezh', $$v)},expression:"model['titlezh']"}})]}}],null,true)})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":"zh content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model.zh),callback:function ($$v) {_vm.$set(_vm.model, "zh", $$v)},expression:"model.zh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),(_vm.isHomePageButNotHeader)?_c('div',{staticStyle:{"margin-top":"40px"}},[_c('ValidationProvider',{attrs:{"name":"按钮内容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"按钮内容","outlined":""},model:{value:(_vm.model['buttonContentzh']),callback:function ($$v) {_vm.$set(_vm.model, 'buttonContentzh', $$v)},expression:"model['buttonContentzh']"}})]}}],null,true)})],1):_vm._e()],1),_c('v-divider',{staticStyle:{"margin-top":"20px"}}),_c('div',[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),(_vm.isHomePageButNotHeader)?_c('div',[_c('ValidationProvider',{attrs:{"name":"EN Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(_vm.model.titleEn),callback:function ($$v) {_vm.$set(_vm.model, "titleEn", $$v)},expression:"model.titleEn"}})]}}],null,true)})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":"EN content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model.en),callback:function ($$v) {_vm.$set(_vm.model, "en", $$v)},expression:"model.en"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),(_vm.isHomePageButNotHeader)?_c('div',{staticStyle:{"margin-top":"40px"}},[_c('ValidationProvider',{attrs:{"name":"Button Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Button Content","outlined":""},model:{value:(_vm.model['buttonContenten']),callback:function ($$v) {_vm.$set(_vm.model, 'buttonContenten', $$v)},expression:"model['buttonContenten']"}})]}}],null,true)})],1):_vm._e()],1),(_vm.isHomePageAboutUs)?_c('div',[_c('v-divider',{staticStyle:{"margin-top":"20px"}}),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("Content 2")]),_c('div',[_c('h3',[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"zh_HK content 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model['content2zh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'content2zh-HK', $$v)},expression:"model['content2zh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('h3',[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"zh content 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model.content2zh),callback:function ($$v) {_vm.$set(_vm.model, "content2zh", $$v)},expression:"model.content2zh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('h3',[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"EN content 2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model.content2en),callback:function ($$v) {_vm.$set(_vm.model, "content2en", $$v)},expression:"model.content2en"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)]}}])}),_c('CustomDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }