<template>
  <div>
    <div>
      <v-row class="py-5">
        <v-col cols="2"><b>Customer Labels</b></v-col>
        <v-col v-if="userLabels.length" class="d-flex align-start flex-wrap" cols="10">
          <v-chip
            v-for="(item, index) in userLabels"
            :key="index"
            class="mr-5 mb-5"
            style="font-weight: bold"
            color="#96734B"
            label
            outlined
          >
            {{ item }}
          </v-chip>
        </v-col>
        <v-col v-else>
          <u>No Labels Added</u>
        </v-col>
      </v-row>
      <div class="py-3">
        <div class="d-flex justify-space-between">
          <div class="align-self-center" style="min-width: 160px"><b>Color Record</b></div>
        </div>
      </div>
      <!--  replace color formula with data-table requested on 22March 2022    -->
      <!--      <p class="mb-0" v-for="(n, i) in serviceRecordList" :key="n._id">-->
      <!--        <span class="mr-2">{{ `${i + 1}.` }}</span>-->
      <!--        <span class="mr-4">{{ moment(n.time.start).format('YYYY-MM-DD') }}</span>-->
      <!--        <span>{{ colorProportion(n.serviceRecord.colorProportion) }}</span>-->
      <!--      </p>-->
      <v-data-table :headers="headers" :items="serviceRecordList" :hide-default-footer="true">
        <template v-slot:item.Date="{ item }">
          <span class="tableCol">
            {{ moment(item._timetable.time.start).format('YYYY-MM-DD') }}
          </span>
        </template>
        <template v-slot:item.Time="{ item }">
          <span class="tableCol">
            {{
              moment(item._timetable.time.start).format('HH:mm-') +
              moment(item._timetable.time.end).format('HH:mm')
            }}
          </span>
        </template>
        <template v-slot:item.Name="{ item }">
          <!--          <span class="tableCol"> {{ get(item, 'serviceDetails.serviceProduct.name', '-') }}</span>-->
          <span class="tableCol"
            >{{
              get(item, 'serviceDetails.serviceProduct.name', '').replace(
                'Herbal Hair Color Package',
                '',
              ) || '-'
            }}
          </span>
        </template>
        <template v-slot:item.RoundDuration="{ item }">
          <span class="tableCol"
            >{{ get(item, 'serviceRecord.colorProportion.firstRound.duration') }} mins (R1)+
            {{ get(item, 'serviceRecord.colorProportion.secondRound.duration') }} mins (R2)</span
          >
        </template>
        <template v-slot:item.colorProportion="{ item }">
          <span class="tableCol">
            <span>{{ colorProportion(get(item, 'serviceRecord.colorProportion', {})) }}</span>
          </span>
        </template>
        <template v-slot:item.servingTherapist="{ item }">
          <!--          <span class="tableCol">{{ get(item, 'firstAssignedMaster.name', '-') }}</span>-->
          <span class="tableCol"> {{ get(item, '_timetable.master.name', '-') }}</span>
        </template>
        <template v-slot:item.Master="{ item }">
          <span class="tableCol">{{
            get(item, 'serviceDetails.masterSpecified.name') === get(item, '_timetable.master.name')
              ? 'Yes'
              : 'No'
          }}</span>
        </template>
        <template v-slot:item.assistantTherapist="{ item }">
          <!--          <span class="tableCol">{{ get(item, 'firstAssistantMaster.name', '-') }}</span>-->
          <span class="tableCol">{{ get(item, '_timetable.assistantMaster.name', '-') }}</span>
        </template>

        <template v-slot:item.Remarks="{ item }">
          <span class="tableCol"> {{ get(item, 'serviceRecord.remarks', '-') }}</span>
        </template>
      </v-data-table>
    </div>
    <div v-if="firstRound && secondRound">
      <div class="py-3">
        <div class="d-flex justify-space-between">
          <div class="align-self-center" style="min-width: 160px"><b>Today Color</b></div>
          <v-btn :disabled="!serviceRecordList || !serviceRecordList[0]" @click="copyLastRecord"
            >Copy Last Time
          </v-btn>
        </div>
      </div>
      <div class="py-3">
        <color-edit-section
          :colorOptions="colorOptions"
          :round-number="1"
          :target="firstRound"
          @addNew="addNewItem"
          @removeRow="removeRow"
          @cloneRow="x => cloneRow(1, x)"
        />
      </div>
      <div class="py-3">
        <color-edit-section
          :colorOptions="colorOptions"
          :round-number="2"
          :target="secondRound"
          @addNew="addNewItem"
          @removeRow="removeRow"
          @cloneRow="x => cloneRow(2, x)"
        />
      </div>

      <div>
        <div>
          <span>(R) Root 根 | (F) Front 前 | (E) End 尾 | (W) Whole 全 | (T) Top 頂 </span>
          <br />
          <!--          <span>~~> = 搽第二轉 next round | ## = 洗補 next round at basin</span>-->
          <span>~~> = 搽第二轉 next round</span>
        </div>
        <h3 class="mt-5">Color Combination</h3>

        <div>
          <span style="white-space: pre">{{ colorProportion({ firstRound, secondRound }) }}</span>
        </div>

        <!--        <div class="mb-5 text-right">-->
        <!--          <v-btn color="primary" @click="patchColorProportion">Save</v-btn>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import api from '@/api';
import { patchColorProportion } from '@/api/bookings';
import ColorEditSection from '@/components/ServiceDetails/ColorEditSection';
import { colorOption, doseOption } from '@/data/optionsConstants';
import { colorProportion } from '@/services/utils';
import moment from 'moment';
import { crudList } from '@/api/crud';
import { getAdminPreference } from '@/api/systemConfig';

export default {
  name: 'colorProportion',
  components: { ColorEditSection },
  emits: ['updateRound'],
  props: {
    id: {
      type: String,
    },
    clientId: {
      type: String,
    },
    firstRound: {
      type: Object,
      default: () => ({}),
    },
    secondRound: {
      type: Object,
      default: () => ({}),
    },
    created_by: {},
  },
  data() {
    return {
      colorOptions: colorOption,
      serviceRecordList: [],
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'Date',
        },
        {
          text: 'Time',
          align: 'center',
          sortable: false,
          value: 'Time',
        },
        {
          text: 'Service',
          align: 'center',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Color Proportion',
          align: 'center',
          sortable: false,
          value: 'colorProportion',
        },
        {
          text: 'Round Duration',
          align: 'center',
          sortable: false,
          value: 'RoundDuration',
        },
        {
          text: 'Serving Facility',
          align: 'center',
          sortable: false,
          value: 'servingTherapist',
        },
        {
          text: 'Requested Facility',
          align: 'center',
          sortable: false,
          value: 'Master',
        },
        {
          text: 'Assistant Facility',
          align: 'center',
          sortable: false,
          value: 'assistantTherapist',
        },
        {
          text: 'Remarks of the cases',
          align: 'center',
          sortable: false,
          value: 'Remarks',
        },
      ],
    };
  },
  watch: {
    clientId: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    userLabels: {
      get() {
        return get(this.created_by, 'metadata.labels', []);
      },
      set(v) {
        set(this.created_by, 'metadata.labels', v);
      },
    },
  },
  methods: {
    get,
    moment,
    colorProportion,
    // async patchColorProportion() {
    //   let res = { firstRound: this.firstRound, secondRound: this.secondRound };
    //   await patchColorProportion(api, this.id, res);
    //   await this.$store.dispatch('alert/updateAlertMessage', {
    //     msg: 'Saved',
    //     type: 'success',
    //     color: 'success',
    //   });
    //   this.$emit('reload');
    // },

    /** Moved the following update functions to parent in order to prevent props mutation */
    addNewItem(roundNo, index) {
      const target = roundNo === 1 ? this.firstRound : this.secondRound;
      let tempColor = [...(target[`color${index}`] || [])];
      let tempUnit = [...(target[`unit${index}`] || [])];
      tempColor.push(this.colorOptions[0]?.label);
      tempUnit.push(doseOption[0]);
      // this.$set(target, `color${index}`, tempColor);
      // this.$set(target, `unit${index}`, tempUnit);
      const updatePath = roundNo === 1 ? 'firstRound' : 'secondRound';
      const val = {
        ...target,
        [`color${index}`]: tempColor,
        [`unit${index}`]: tempUnit,
      };
      this.$emit('updateRound', { path: updatePath, val: val });
    },
    cloneRow(roundNo, index) {
      const target = roundNo === 1 ? this.firstRound : this.secondRound;
      let tempColor = [...target[`color${index - 1}`]];
      let tempUnit = [...target[`unit${index - 1}`]];
      // this.$set(target, `color${rowNumber}`, tempColor);
      // this.$set(target, `unit${rowNumber}`, tempUnit);
      const updatePath = roundNo === 1 ? 'firstRound' : 'secondRound';
      const val = {
        ...target,
        [`color${index}`]: tempColor,
        [`unit${index}`]: tempUnit,
      };
      this.$emit('updateRound', { path: updatePath, val: val });
    },
    removeRow(roundNo, index) {
      const target = roundNo === 1 ? this.firstRound : this.secondRound;
      // this.$set(target, `color${rowNumber}`, []);
      // this.$set(target, `unit${rowNumber}`, []);
      // this.$set(target, `type${rowNumber}`, null);
      const updatePath = roundNo === 1 ? 'firstRound' : 'secondRound';
      const val = {
        ...target,
        [`color${index}`]: [],
        [`unit${index}`]: [],
        [`type${index}`]: null,
      };
      this.$emit('updateRound', { path: updatePath, val: val });
    },
    copyLastRecord() {
      const last = this.serviceRecordList[0];
      if (!last) return;
      // this.$set(
      //   this,
      //   'firstRound',
      //   JSON.parse(JSON.stringify(last.serviceRecord?.colorProportion?.firstRound)),
      // );
      // this.$set(
      //   this,
      //   'secondRound',
      //   JSON.parse(JSON.stringify(last.serviceRecord?.colorProportion?.secondRound)),
      // );

      this.$emit('updateRound', {
        path: 'firstRound',
        val: JSON.parse(JSON.stringify(last.serviceRecord?.colorProportion?.firstRound)),
      });
      this.$emit('updateRound', {
        path: 'secondRound',
        val: JSON.parse(JSON.stringify(last.serviceRecord?.colorProportion?.secondRound)),
      });
    },
    async reload() {
      try {
        this.colorOptions = (await getAdminPreference(api))?.colorOptions;
      } catch (e) {}
      try {
        const recordRes = await crudList(api, 'toca/admin/bookings', {
          filter: { created_by: this.clientId, status: '99$completed', _id: { $ne: this.id } },
          limit: 5,
          sort: { 'time.start': -1 },
          populate: [
            { path: 'serviceDetails.masterSpecified' },
            { path: 'serviceDetails.serviceProduct' },
            // { path: 'firstAssignedMaster' },
            { path: 'firstAssistantMaster' },
            { path: '_timetable', populate: ['master', 'assistantMaster'] },
          ],
        });
        this.serviceRecordList = recordRes?.docs;
        // console.log('serviceRecordList', this.serviceRecordList);
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="scss">
@media only screen and (max-width: 850px) {
  .responsiveGap {
    gap: 5px !important;
  }
}

.responsiveGap {
  gap: 25px;
}

.inlineSelect {
  ::v-deep .v-input__slot {
    min-width: 80px;
  }
  ::v-deep .v-select__selection {
    min-width: 24px;
  }
}
</style>

<style>
.v-data-table {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
