<template>
  <v-list>
    <!--    <v-list-item>-->
    <!--      <v-list-item-content>-->
    <!--        <v-row class="tableColor">-->
    <!--          <v-col cols="2">Product</v-col>-->
    <!--          <v-col cols="1">Quota</v-col>-->
    <!--          <v-col cols="1">{{ shared ? 'Share By' : 'Shared With' }}</v-col>-->
    <!--          <v-col cols="2">Expiry Date</v-col>-->
    <!--          <v-col cols="2">Purchase Date</v-col>-->
    <!--          <v-col cols="2">Last Used Date</v-col>-->
    <!--          <v-col cols="1">Share Button</v-col>-->
    <!--        </v-row>-->
    <!--        <v-divider />-->
    <!--      </v-list-item-content>-->
    <!--    </v-list-item>-->

    <div v-if="list.length > 0">
      <v-data-table :headers="headers" :items="list" hide-default-footer @reload="reload">
        <template v-slot:item.other="{ item }">
          <div v-if="shared">
            <a v-if="item.created_by && item.created_by.name" @click="viewProfile(item.created_by)">
              By
              {{ get(item, 'created_by.name') }}
            </a>
          </div>
          <div v-if="!shared">
            <a
              v-if="item.sharedWith && item.sharedWith.name"
              @click="viewProfile(item.sharedWith._id)"
            >
              With
              {{ get(item, 'sharedWith.name') }}
            </a>
            <a v-else> --- </a>
          </div>
        </template>
        <template v-slot:item.quota="{ item }">
          {{ get(item, 'quotaLeft') }}
          /{{ get(item, 'quota') }}
        </template>
        <template v-if="shared" v-slot:item.created_by.name="{ item }">
          <v-btn @click="goToProfile(item.created_by._id)">{{
            item.created_by.name
          }}</v-btn></template
        >
        <template v-else v-slot:item.sharedWith.name="{ item }">
          <v-btn @click="goToProfile(item.sharedWith._id)">{{
            item.sharedWith.name
          }}</v-btn></template
        >
        <template v-slot:item.expireAt="{ item }">
          {{ moment(item.expireAt).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ moment(item.created_at).format('DD/MM/YYYY') }}
        </template>
        <template v-slot:item.amountPayable="{ item }">
          {{ get(item, 'amountPayable') | currency }}
        </template>
        <!-- <template v-slot:item.button="{ item }">
          <div v-if="item.sharedWith">
            <v-btn @click="unShareTicket(item)" :disabled="isMaster">Unshare</v-btn>
          </div>
          <div v-else>
            <v-btn @click="shareTicket(item)" :disabled="isMaster">Share</v-btn>
          </div>
        </template> -->
        <template v-slot:item.edit="{ item }">
          <v-btn @click="editTicket(item)" :disabled="isMaster || isCashier">Edit</v-btn>
        </template>
      </v-data-table>

      <!--      <v-list-item v-for="item in list" :key="item._id">-->
      <!--        <v-list-item-content clickable @click="editTicket(item)">-->
      <!--          <v-row>-->
      <!--            <v-col cols="2">{{ get(item, 'product.name', `-`) }}</v-col>-->
      <!--            <v-col cols="1">{{ item.quotaLeft }} / {{ item.quota }}</v-col>-->
      <!--            <v-col cols="1">-->
      <!--              <div v-if="shared">-->
      <!--                <div v-if="item.sharedWith">-->
      <!--                  <p>{{ get(item, 'created_by.name', `-`) }}</p>-->
      <!--                  &lt;!&ndash;                  <v-btn @click="unShareTicket(item)">Unshare</v-btn>&ndash;&gt;-->
      <!--                </div>-->
      <!--                <div v-else>-->
      <!--                  <p>{{ get(item, 'created_by.name', `-`) }}</p>-->
      <!--                  &lt;!&ndash;                  <v-btn @click="shareTicket">Share</v-btn>&ndash;&gt;-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div v-else>-->
      <!--                <div v-if="item.sharedWith">-->
      <!--                  <p>{{ get(item, 'sharedWith.name', `-`) }}</p>-->
      <!--                  &lt;!&ndash;                  <v-btn @click="unShareTicket(item)">Unshare</v-btn>&ndash;&gt;-->
      <!--                </div>-->
      <!--                <div v-else>-->
      <!--                  <p>{{ get(item, 'sharedWith.name', `-`) }}</p>-->
      <!--                  &lt;!&ndash;                  <v-btn @click="shareTicket">Share</v-btn>&ndash;&gt;-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </v-col>-->
      <!--            <v-col cols="2">{{ moment(item.expireAt).format('L') }}</v-col>-->
      <!--            <v-col cols="2">{{ moment(item.created_at).format('L') }}</v-col>-->
      <!--            <v-col cols="2">{{ lastBookingTime(item) }}</v-col>-->
      <!--            <v-col cols="1">-->
      <!--              <div v-if="shared">-->
      <!--                <div v-if="item.sharedWith">-->
      <!--                  <v-btn @click="unShareTicket(item)">Unshare</v-btn>-->
      <!--                </div>-->
      <!--                <div v-else>-->
      <!--                  <v-btn @click="shareTicket">Share</v-btn>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div v-else>-->
      <!--                <div v-if="item.sharedWith">-->
      <!--                  <v-btn @click="unShareTicket(item)">Unshare</v-btn>-->
      <!--                </div>-->
      <!--                <div v-else>-->
      <!--                  <v-btn @click="shareTicket">Share</v-btn>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </v-col>-->
      <!--          </v-row>-->
      <!--          <v-divider />-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->
    </div>
    <div v-else>
      <v-row>
        <v-col style="text-align: center">No Data</v-col>
      </v-row>
    </div>
    <v-pagination class="mt-4" v-model="pageVal" :length="data.pages || 1" />
    <v-btn
      class="mb-2"
      x-large
      @click="createTicket = !createTicket"
      v-if="!shared"
      :disabled="isMaster"
    >
      Add Package</v-btn
    >
    <TicketCreateDialog :show="createTicket" :client="id" @close="closeDialog" @reload="reload" />
    <TicketEditDialog
      :content="ticketEditing"
      :show="editTicketDialog"
      @close="closeDialog"
      @reload="reload"
    />
    <TicketShareDialog
      :content="ticketEditing"
      :show="shareTicketDialog"
      @close="closeDialog"
      @reload="reload"
    />
  </v-list>
</template>

<script>
import TicketCreateDialog from '@/components/Ticket/TicketCreateDialog';
import TicketEditDialog from '@/components/Ticket/TicketEditDialog';
import { get } from 'lodash';
import { crudList } from '@/api/crud';
import api from '@/api';
import moment from 'moment';
import TicketShareDialog from '@/components/Ticket/TicketShareDialog';
import { patchTicketUnShare } from '@/api/ticketPackage';
import { mapGetters } from 'vuex';
import { getOid } from '@/services/utils';

export default {
  name: 'TicketList',
  components: {
    TicketShareDialog,
    TicketCreateDialog,
    TicketEditDialog,
  },
  props: {
    id: {},
    shared: { type: Boolean, default: false },
  },
  data() {
    return {
      headers: [
        { text: 'Product', value: 'product.name', width: '100px' },
        // { text: 'Quota Left', value: 'quotaLeft', width: '50px' },
        { text: 'Quota', value: 'quota', width: '50px' },
        {
          text: 'Shared',
          value: 'other',
          width: '100px',
        },
        { text: 'Remark', value: 'remarks', width: '100px' },
        { text: 'Expiry Date', value: 'expireAt', width: '100px' },
        { text: 'Purchased Date', value: 'created_at', width: '100px' },
        { text: 'Price', value: 'amountPayable', width: '50px' },
        // { text: 'Share Button', value: 'button', width: '50px' },
        { text: 'Edit', value: 'edit', width: '50px' },
      ],
      get,
      list: [],
      data: {},
      page: 1,
      createTicket: false,
      editTicketDialog: false,
      ticketEditing: {},
      unShareTicketDialog: false,
      shareTicketDialog: false,
    };
  },
  methods: {
    moment,
    show(id) {
      this.$emit('show', id);
    },
    closeDialog() {
      this.createTicket = false;
      this.editTicketDialog = false;
      this.shareTicketDialog = false;
      this.unShareTicketDialog = false;
      this.ticketEditing = {};
    },
    async reload() {
      try {
        if (this.shared) {
          this.data = await crudList(api, 'toca/admin/TicketsPackages', {
            filter: { sharedWith: this.id },
            limit: 20,
            page: this.page,
            populate: ['product', 'created_by'],
          });
        } else {
          this.data = await crudList(api, 'toca/admin/TicketsPackages', {
            filter: { created_by: this.id },
            sort: { expireAt: -1 },
            limit: 20,
            page: this.page,
            populate: ['product', 'sharedWith'],
          });
        }
        this.data.docs.map(o => {
          o.isSelf = o.created_by == this.id;
          if (o.isSelf) {
            o.other = o.created_by;
          } else {
            o.other = o.sharedWith;
          }
        });
        this.list = this.data.docs;
      } catch (e) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: e,
        });
      }
    },
    editTicket(item) {
      this.ticketEditing = item;
      if (!this.unShareTicketDialog && !this.shareTicketDialog) {
        this.editTicketDialog = true;
      }
    },
    viewProfile(profile) {
      if (!profile) return;
      window.open(window.location.origin + '/admin/profiles/' + getOid(profile));
    },
    unShareTicket(item) {
      this.unShareTicketDialog = true;
      this.$root.$confirm
        .open('確認取消', '確定要取消共享?', { color: 'red' })
        .then(async confirm => {
          if (confirm) {
            try {
              await patchTicketUnShare(api, item._id);
              this.unShareTicketDialog = false;
              await this.reload();
            } catch (err) {
              await this.$store.dispatch('alert/updateAlertMessage', {
                msg: err,
              });
            }
          } else {
            return;
          }
        });
    },
    shareTicket(item) {
      this.ticketEditing = item;
      this.shareTicketDialog = true;
    },
    lastBookingTime(item) {
      if (item?._last_Booking?._timetableTime?.start) {
        return moment(item._last_Booking._timetableTime.start).format('L');
      } else {
        return '-';
      }
    },
    goToProfile(id) {
      // await this.$router.push({ name: 'Profile', params: { id: getOid(this.created_by) } });
      if (!id) return;
      window.open(window.location.origin + '/admin/profiles/' + id);
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'isCashier']),
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
};
</script>

<style scoped>
.expiry-date {
  word-break: break-all;
}
.tableColor {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
