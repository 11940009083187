<template>
  <v-dialog v-model="valueProxy" @click:outside="$emit('close')" width="60%" max-width="100vw">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card>
        <v-card-title class="d-flex align-center justify-space-between"
          >Add Packages
          <v-spacer />
          <v-icon @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="6" v-if="!hasClient">
                <ValidationProvider v-slot="{ errors }" name="Customer" rules="required">
                  <custom-label item-field-name="Customer" />
                  <autocomplete
                    label="Customer"
                    :path="computedCustomerProfileUrl"
                    :params="{ sort: { created_at: -1 } }"
                    item-text="name"
                    item-value="_id"
                    v-model="client"
                    :error-messages="errors"
                    clearable
                    has-items-slot
                    ref="autoComplete"
                  >
                    <template v-slot:items="content">
                      <span>{{
                        get(content, 'item.name', '') +
                        ' ' +
                        displayPhone(get(content, 'item.phone', ''))
                      }}</span>
                    </template>
                  </autocomplete>
                </ValidationProvider>
                <div v-if="!client">
                  <v-btn @click="createUser = true">Add New Customer</v-btn>
                </div>
                <IdentityEditDialog
                  v-model="createUser"
                  @create="updateProfile"
                  max-width="600"
                ></IdentityEditDialog>
              </v-col>
              <v-col cols="6">
                <ValidationProvider v-slot="{ errors }" name="Package" rules="required">
                  <custom-label item-field-name="Package" />
                  <autocomplete
                    label="Package"
                    :path="computedServiceProductsUrl"
                    return-object
                    item-value="_id"
                    item-text="name"
                    v-model="product"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <custom-label item-field-name="Quota" />
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Quota"
                  :rules="{ required: true, min_value: 0 }"
                >
                  <!--                  <v-text-field-->
                  <!--                    label="Quota"-->
                  <!--                    v-model="doc.quota"-->
                  <!--                    outlined-->
                  <!--                    flat-->
                  <!--                    :error-messages="errors"-->
                  <!--                  />-->
                  <v-select v-model="doc.quota" :items="[10]" label="Quota" outlined />
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="{ required: true, min_value: 0 }"
                  name="Amount Paid"
                >
                  <custom-label item-field-name="Amount Paid" />
                  <v-text-field
                    label="Amount Paid"
                    v-model="doc.amountPayable"
                    outlined
                    flat
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col>
                <ValidationProvider name="Payment Method">
                  <custom-label item-field-name="Payment Method" />
                  <v-autocomplete
                    outlined
                    :search-input.sync="search"
                    :items="computedPaymentMethod"
                    v-model="selectedPaymentMethod"
                  >
                  </v-autocomplete>
                </ValidationProvider>
              </v-col>
              <v-col cols="6">
                <ValidationProvider name="Remark">
                  <custom-label item-field-name="Remark" />
                  <v-text-field label="Remark" v-model="doc.remarks" outlined flat />
                </ValidationProvider>
              </v-col>
              <!-- <v-col cols="6">
                <custom-label item-field-name="Facility" />
                <ValidationProvider v-slot="{ errors }" name="master" rules="required">
                  <autocomplete
                    v-model="doc.commission.to"
                    :path="computedProfileUrl"
                    :error-messages="errors"
                    :params="{
                      sort: { created_at: -1 },
                      filter: { status: ProfileStatus.accepted },
                    }"
                    item-text="name"
                    item-value="_id"
                    :clearable="true"
                  />
                </ValidationProvider>
              </v-col> -->
            </v-row>
            <!-- <v-row>
              <v-col :cols="1">
                <v-simple-checkbox v-model="addTransaction" :ripple="false" />
              </v-col>
              <v-col> <span>Tick to add as NEW Transaction</span> </v-col>
            </v-row> -->
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="addTicket()" :disabled="invalid">Add</v-btn>
          <!--          <v-btn @click="close()">Cancel</v-btn>-->
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { crudCreate, crudRead } from '@/api/crud';
import api from '@/api';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { autocompletePaymentMethod } from '@/api/ticketPackage';
import { get } from 'lodash';
import { displayPhone } from '@/services/filters';
import IdentityEditDialog from '@/components/Identity/IdentityEditDialog';
import { ProfileStatus } from '@/api/auth/membership';
import { mapGetters } from 'vuex';

export default {
  name: 'TicketCreateDialog',
  components: { CustomLabel, Autocomplete, IdentityEditDialog },
  props: {
    show: Boolean,
    client: String,
    hasClient: { type: Boolean, default: true },
  },
  data() {
    return {
      ProfileStatus,
      defaultDoc: { commission: {}, quota: 10 },
      doc: this.defaultDoc,
      listedPrice: 0,
      addTransaction: true,
      paymentMethod: ['Visa', 'Cash', 'Bank Transfer'],
      selectedPaymentMethod: 'Visa',
      search: null,
      createUser: false,
    };
  },
  mounted() {},
  watch: {
    show: {
      async handler(v) {
        this.doc = this.defaultDoc;
        await this.$nextTick();
        this.$refs.form.reset();
      },
    },
    search: {
      immediate: true,
      async handler(v) {
        this.paymentMethod = await autocompletePaymentMethod(api, v);
      },
    },
    'doc.quota'(v) {
      if (!!v) this.calculateAmountPayable();
    },
    listedPrice(v) {
      if (!!v) this.calculateAmountPayable();
    },
  },
  methods: {
    get,
    displayPhone,
    updateProfile() {
      this.createUser = false;
      this.$refs.autoComplete.reload();
    },
    async addPaymentMethodChoices() {
      await crudCreate(api, 'toGetPaymentMethod', this.computedPaymentMethod);
    },

    async addTicket() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      this.doc.paymentMethod = this.selectedPaymentMethod;
      try {
        if (this.addTransaction) {
          this.doc.customer = this.client;
          this.doc.receiptPhotos = [];
          this.doc.approve = true;
          await crudCreate(api, 'toca/admin/TicketsPackage/addManualPurchase', this.doc);
        } else {
          this.doc.created_by = this.client;
          await crudCreate(api, 'toca/admin/TicketsPackages', this.doc);
        }
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Added',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
        this.$emit('close');
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },

    close() {
      this.$emit('close');
    },
    calculateAmountPayable() {
      if (!this.doc.quota || !this.listedPrice) return;
      let modifier = 1;
      //if (this.doc.quota >= 5) modifier = 0.9;
      if (this.doc.quota >= 10) modifier = 0.85;
      this.doc.amountPayable = (this.doc.quota || 0) * this.listedPrice * modifier;
    },
  },

  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'profileId']),
    valueProxy: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
    computedPaymentMethod() {
      if (this.search) {
        return this.paymentMethod.concat([this.search]);
      } else {
        return this.paymentMethod;
      }
    },
    product: {
      set(v) {
        if (v) {
          this.$set(this.doc, 'product', v._id);
          this.$set(this, 'listedPrice', v.listedPrice);
        }
      },
      get() {
        return this.doc.product;
      },
    },
    computedServiceProductsUrl() {
      return this.isMaster ? 'toca/master/ServiceProducts' : 'toca/admin/ServiceProducts';
    },
    computedCustomerProfileUrl() {
      return this.isMaster ? 'toca/master/ClientProfiles' : 'toca/admin/ClientProfiles';
    },
    computedProfileUrl() {
      return this.isMaster ? 'toca/master/MasterProfiles' : 'toca/admin/MasterProfiles';
    },
  },
};
</script>

<style scoped></style>
