<template>
  <div class="pa-2">
    <v-row>
      <v-col cols="12">
        <AttachmentList :isGrid="true" ref="attachmentList" :attachments="model.relinquishment" />
      </v-col>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn class="primary" :loading="isSubmitting" large @click="save">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from '@/api';
import { crudList, crudUpdate } from '@/api/crud';
import AttachmentList from '@/components/Attachment/AttachmentList';
import { getOid } from '@/services/utils';

export default {
  name: 'IdentityWaiver',
  components: {
    AttachmentList,
  },
  props: {
    model: {},
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    crudProfilesPath() {
      return this.isMaster ? 'toca/master/profiles' : 'profiles';
    },
  },
  methods: {
    async save() {
      try {
        this.isSubmitting = true;
        const attachments = (await this.$refs.attachmentList.startUploadAttachments()).map(getOid);
        const model = await crudUpdate(api, this.crudProfilesPath, this.model.id, {
          relinquishment: attachments,
        });
        this.model.relinquishment = model.relinquishment;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style scoped></style>
