import api from '@/api';
import { deleteAttachment, readAttachment, uploadSingleAttachment } from '@/api/attachment';
import { crudCreate, crudList, crudUpdate } from '@/api/crud';
import { CMSPostType } from '@/data/constants';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  data() {
    return {
      contentID: null,
      model: { featuredImage: [] },
      qillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
        readOnly: false,
      },
    };
  },
  components: {
    quillEditor,
  },
  computed: {
    isNew() {
      return !this.contentID;
    },
  },
  async beforeMount() {
    await this.reload();
  },
  methods: {
    async reload() {
      const computedQuery = {
        filter: { 'metadata.type': this.postType },
        sort: { created_at: -1 },
      };
      try {
        const res = await crudList(api, 'cms/admin/posts', computedQuery);
        this.model = res?.docs?.[0] || {};
        this.contentID = this.model?._id;
        if (this.contentID) {
          if (this.model.content) {
            const contentJson = JSON.parse(this.model.content);
            this.model['zh-HK'] = contentJson['zh-HK'];
            this.model.zh = contentJson['zh'];
            this.model.en = contentJson['en'];
          }
          if (this.model.title) {
            const titleJson = JSON.parse(this.model.title);
            this.model['titlezh-HK'] = titleJson['zh-HK'];
            this.model.titlezh = titleJson['zh'];
            this.model.titleEn = titleJson['en'];
          }
          if (this.model.metadata.button) {
            this.model.buttonLink = this.model.metadata.button.link
              ? this.model.metadata.button.link
              : null;
            const buttonContentJson = JSON.parse(this.model.metadata.button.content);
            this.model['buttonContentzh-HK'] = buttonContentJson['zh-HK'];
            this.model.buttonContentzh = buttonContentJson['zh'];
            this.model.buttonContenten = buttonContentJson['en'];
          }

          if (this.model.metadata.type == 'homePage.aboutUs' && this.model.metadata.content2) {
            const content2Json = JSON.parse(this.model.metadata.content2);
            this.model['content2zh-HK'] = content2Json['zh-HK'];
            this.model.content2zh = content2Json['zh'];
            this.model.content2en = content2Json['en'];
          }
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS load ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const contentJsonObj = {
          'zh-HK': this.model['zh-HK'],
          zh: this.model['zh'],
          en: this.model.en,
        };
        const titleJsonObj = {
          'zh-HK': this.model['titlezh-HK'],
          zh: this.model['titlezh'],
          en: this.model.titleEn,
        };
        const content2JsonObj = {
          'zh-HK': this.model['content2zh-HK'],
          zh: this.model.content2zh,
          en: this.model.content2en,
        };
        const buttonContentJsonObj = {
          'zh-HK': this.model['buttonContentzh-HK'],
          zh: this.model.buttonContentzh,
          en: this.model.buttonContenten,
        };

        if (this.isNew) {
          //create
          const payload = {
            metadata: {
              type: this.postType,
              button: {
                link: this.model.buttonLink ? this.model.buttonLink : '',
                content: JSON.stringify(buttonContentJsonObj),
              },
              content2: JSON.stringify(content2JsonObj),
            },
            content: JSON.stringify(contentJsonObj),
            title: JSON.stringify(titleJsonObj),
            featuredImages: this.model.featuredImages,
          };
          const res = await crudCreate(api, 'cms/admin/posts', payload);
          // alert & update
          this.contentID = res._id;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
        } else {
          //patch
          delete this.model.__v;
          delete this.model.updated_at;
          delete this.model.created_at;
          this.model.content = JSON.stringify(contentJsonObj);
          this.model.title = JSON.stringify(titleJsonObj);
          this.model.metadata = {
            type: this.postType,
            button: {
              link: this.model.buttonLink ? this.model.buttonLink : '',
              content: JSON.stringify(buttonContentJsonObj),
            },
            content2: JSON.stringify(content2JsonObj),
          };

          const res = await crudUpdate(api, 'cms/admin/posts', this.contentID, this.model);
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Updated',
            type: 'success',
            color: 'success',
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS post ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async uploadImage() {
      //only upload image, not in CMS post, the user need to press button to save/update
      try {
        if (this.newImage) {
          for (let image of this.newImage) {
            const attachmentSID = await uploadSingleAttachment(api, image);
            if (this.model.featuredImages) {
              if (this.supportMultiImage) {
                this.model.featuredImages.push(attachmentSID._id);
              } else {
                //replace
                this.$set(this.model, 'featuredImages', [attachmentSID._id]);
              }
            } else {
              //create
              this.$set(this.model, 'featuredImages', []);
              this.model.featuredImages.push(attachmentSID._id);
            }
          }
          this.newImage = null;
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `Upload Image ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    removeImage(image) {
      //only remove image on local store. the user need to press button to save/update
      const index = this.model.featuredImages.indexOf(image);
      if (index >= 0) {
        this.model.featuredImages.splice(index, 1);
      }
    },
  },
};
