var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('h2',[_vm._v(_vm._s(_vm.title))])]),_c('v-divider',{staticClass:"mb-5"}),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.docs)?_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{attrs:{"disabled":invalid || _vm.isMaster,"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save ")])],1)],1),_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name(CN)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.localizedName['zh-cn'],"error-messages":errors,"flat":"","label":"Name(CN)","outlined":""},on:{"input":function($event){_vm.localizedName = Object.assign({}, _vm.localizedName, {'zh-cn': $event})}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name(EN)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.localizedName.en,"error-messages":errors,"flat":"","label":"Name(EN)","outlined":""},on:{"input":function($event){_vm.localizedName = Object.assign({}, _vm.localizedName, {en: $event})}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name(HK)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.localizedName['zh-hk'],"error-messages":errors,"flat":"","label":"Name(HK)","outlined":""},on:{"input":function($event){_vm.localizedName = Object.assign({}, _vm.localizedName, {'zh-hk': $event})}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"SID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Sid","outlined":"","readonly":!_vm.isNew},model:{value:(_vm.docs.sid),callback:function ($$v) {_vm.$set(_vm.docs, "sid", $$v)},expression:"docs.sid"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true },"name":"Listed Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Listed Price ($HKD)","outlined":""},model:{value:(_vm.docs.listedPrice),callback:function ($$v) {_vm.$set(_vm.docs, "listedPrice", $$v)},expression:"docs.listedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true },"name":"Discounted Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Discounted Price ($HKD)","outlined":""},model:{value:(_vm.docs.discountedPrice),callback:function ($$v) {_vm.$set(_vm.docs, "discountedPrice", $$v)},expression:"docs.discountedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Description","outlined":""},model:{value:(_vm.docs.description),callback:function ($$v) {_vm.$set(_vm.docs, "description", $$v)},expression:"docs.description"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("Discount")])]),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"Credit Addition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Credit Addition (積分增加)","outlined":""},model:{value:(_vm.docs.discount.creditAddition),callback:function ($$v) {_vm.$set(_vm.docs.discount, "creditAddition", $$v)},expression:"docs.discount.creditAddition"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"In House Discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"In House Discount (會員折扣)","outlined":""},model:{value:(_vm.docs.discount.inHouseDiscount),callback:function ($$v) {_vm.$set(_vm.docs.discount, "inHouseDiscount", $$v)},expression:"docs.discount.inHouseDiscount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, numeric: true },"name":"Vip Index"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Vip Index (優先等級)","outlined":""},model:{value:(_vm.docs.discount.VIPIndex),callback:function ($$v) {_vm.$set(_vm.docs.discount, "VIPIndex", $$v)},expression:"docs.discount.VIPIndex"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"VIP Room Booking Discount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Room Booking Discount (會員房間預約優惠)","outlined":""},model:{value:(_vm.docs.discount.VIPRoomBookingDiscount),callback:function ($$v) {_vm.$set(_vm.docs.discount, "VIPRoomBookingDiscount", $$v)},expression:"docs.discount.VIPRoomBookingDiscount"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"Same In House Discount Quota"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Same In House Discount Quota (同行同等優惠名額)","outlined":""},model:{value:(_vm.docs.discount.sameInHouseDiscountQuota),callback:function ($$v) {_vm.$set(_vm.docs.discount, "sameInHouseDiscountQuota", $$v)},expression:"docs.discount.sameInHouseDiscountQuota"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ numeric: true },"name":"Free Instructor Quota"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Free Instructor Quota (免費教練課程次數)","outlined":""},model:{value:(_vm.docs.discount.freeCoachQuota),callback:function ($$v) {_vm.$set(_vm.docs.discount, "freeCoachQuota", $$v)},expression:"docs.discount.freeCoachQuota"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Special Two Hour Ceiling (單日銷費2小時以上以2小時計算)"},model:{value:(_vm.docs.discount.specialTwoHourCeiling),callback:function ($$v) {_vm.$set(_vm.docs.discount, "specialTwoHourCeiling", $$v)},expression:"docs.discount.specialTwoHourCeiling"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Semi Private Lane Service (半私人車道服務)"},model:{value:(_vm.docs.discount.semiPrivateLaneService),callback:function ($$v) {_vm.$set(_vm.docs.discount, "semiPrivateLaneService", $$v)},expression:"docs.discount.semiPrivateLaneService"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"6"}},[_c('v-checkbox',{attrs:{"label":"Free Rental Of Ski Equipment (滑雪用品免租金)"},model:{value:(_vm.docs.discount.freeRentalOfSkiEquipment),callback:function ($$v) {_vm.$set(_vm.docs.discount, "freeRentalOfSkiEquipment", $$v)},expression:"docs.discount.freeRentalOfSkiEquipment"}})],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }