<template>
  <ListView
    title="Product Order"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="false"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
  >
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment('YYYY/MM/DD HH:mm') }}
    </template>
    <template v-slot:item.status="{ item }">
      <span :style="{ color: getColor(item.status) }">
        {{ item.status | status }}
      </span>
      <span style="color: red">
        {{ item.isVoided ? '(Voided)' : '' }}
      </span>
    </template>
    <template v-slot:item._product="{ item }">
      <div v-for="{ product } in item.items">{{ product.name }}</div>
      <div v-for="{ product } in item.vipItems">{{ product.name }}</div>
      <div v-for="{ product } in item.bookingItems">{{ product.name }}</div>
    </template>
    <template v-slot:btn>
      <!-- <v-btn color="primary" class="mr-4" @click="actionCreate"
        ><v-icon>mdi-plus</v-icon> Physical Product Order</v-btn
      >
      <v-btn color="primary" @click="createTicket = true"><v-icon>mdi-plus</v-icon>Package</v-btn> -->
    </template>
    <TicketCreateDialog
      :show="createTicket"
      @close="createTicket = false"
      @reload="reload"
      :hasClient="false"
    />
    <ProductOrderEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      :productCategories="productCategories"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </ListView>
</template>

<script>
import ProductOrderEditDialog from '@/components/PhysicalProductOrder/ProductOrderEditDialog';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { listProductCategories } from '@/api/productCategory';
import { mapGetters } from 'vuex';
import TicketCreateDialog from '@/components/Ticket/TicketCreateDialog';

import api from '@/api';

export default {
  name: 'PhysicalProductOrders',
  data() {
    return {
      header: [
        { text: 'Order Date', value: 'created_at' },
        { text: 'Sid', value: 'sid' },
        { text: 'Total Amount', value: 'amountPayable' },
        { text: 'Payment Method', value: 'paymentMethod' },
        { text: 'Payment Status', value: 'paymentStatus' },
        { text: 'Contact Name', value: 'contactName' },
        { text: 'Contact Phone', value: 'contactPhone' },
        { text: 'Order Status', value: 'status' },
        { text: 'Product', value: '_product' },
      ],
      productCategories: [],
      createTicket: false,
      populate: [{ path: 'items[0].commission.to', model: 'MasterProfile', select: 'name' }],
      createProduct: false,
    };
  },
  mixins: [listViewPageMixin],
  components: {
    ProductOrderEditDialog,
    TicketCreateDialog,
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'isAdmin']),
    // used in mixin
    restfulURL: () => RESTFUL.physicalProductOrders.list,
  },
  mounted() {
    this.getProductCategories();
  },
  methods: {
    async getProductCategories() {
      this.productCategories = await listProductCategories(api);
    },
    getColor(status) {
      //TODO: modify for mutliple color mapping for future use
      if (status === '98$cancelled') return 'red';
    },
  },
};
</script>
