<template>
  <div class="pa-10">
    <orderSummary
      :order="order"
      :product-categories="productCategories"
      :transaction="transaction"
    ></orderSummary>
  </div>
</template>

<script>
import { readOrder, readOrderPOS } from '@/api/physicalProductOrder';
import api from '@/api';
import orderSummary from '@/components/PhysicalProductOrder/OrderSummary';
import { listProductCategories } from '@/api/productCategory';
import { getStores } from '@/api/store';
import { getProfileIDbyUserID } from '@/api/Identities';
import { getStockTransferByPosOrderId } from '@/api/stockTransfer';
import { getOid } from '@/services/utils';

export default {
  name: 'OrderDetails',
  components: { orderSummary },
  props: {
    id: {},
  },
  data() {
    return {
      order: {},
      transaction: {},
      attachmentHref: '',
      productCategories: [],
    };
  },
  async beforeMount() {
    const storeList = (await getStores(api, { limit: -1 })).docs.map(x => x);

    let posData = await readOrderPOS(api, this.id, {
      populate: [
        { path: 'items.commission.to', model: 'MasterProfile', select: ['name'] },
        { path: 'created_by', model: 'profile', select: ['sid', 'name', 'phone'] },
        { path: 'client', model: 'profile', select: ['sid', 'name', 'phone'] },
        { path: 'posPaymentDetails.waivedBy', model: 'profile', select: ['sid', 'name', 'phone'] },
      ],
    });

    this.transaction = posData.transaction;
    let order = posData.order;

    const userId = posData.order?.createdBy;
    const staffProfile = await getProfileIDbyUserID(api, userId);
    if (staffProfile.length > 0) {
      const profile = staffProfile[0];
      order = { ...order, createdBy: profile };
    }

    const storeID = posData.order?.store;
    const storeDetail = storeList.find(store => store._id.toString() == storeID);
    if (storeDetail) order = { ...order, store: storeDetail };

    if (order.collectionMethod == 'other_store' && !order.collectionStore) {
      const stockTransferData = await getStockTransferByPosOrderId(api, getOid(order), {
        populate: [
          {
            path: 'from',
            model: 'Store',
            select: ['name'],
          },
          {
            path: 'to',
            model: 'Store',
            select: ['name'],
          },
        ],
      });
      if (stockTransferData) order = { ...order, collectionStoreDetail: stockTransferData?.from };
    } else if (order.collectionMethod == 'other_store') {
      const collectionStore = storeList.find(
        store => store._id.toString() == order.collectionStore,
      );
      if (storeDetail) order = { ...order, collectionStoreDetail: collectionStore };
    }
    this.order = order;

    this.productCategories = await listProductCategories(api);
  },
  methods: {},
};
</script>

<style scoped></style>
