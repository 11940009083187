<template>
  <div class="py-3">
    <div class="mb-5">
      <div class="align-self-center" style="min-width: 160px"><b>Recommend</b></div>
    </div>
    <div class="d-flex justify-center align-center">
      <v-sheet style="width: 100%">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(product, i) in products" :key="`product-${i}`">
            <ProductSelectionCard
              :product="product"
              @actionClickCheckBox="({ e, p }) => actionClickCheckBox(e, p)"
              @actionClickCard="actionClickCard"
              @addToCart="() => {}"
              @increment="increment"
              @decrement="decrement"
            />
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
import { get } from 'lodash';
import ProductSelectionCard from '@/components/PhysicalProduct/ProductSelectionCard.vue';

export default {
  name: 'RecommendList',
  components: { ProductSelectionCard },
  props: {
    products: { type: Array, default: () => [] },
  },
  data() {
    return {
      imageSize: 100,
    };
  },
  methods: {
    get,
    attnThumbHref,
    increment(product) {
      product.selectedQty += 1;
      this.$emit('addToCart', product);
    },
    decrement(product) {
      if (product.selectedQty > 1) {
        product.selectedQty -= 1;
        this.$emit('addToCart', product);
      }
    },
    // toggleCheckbox(product, i) {
    //   this.products.map((el, index) => {
    //     if (el._id === product._id && el.checkbox === true && index !== i) {
    //       el.checkbox = false;
    //       this.$emit('removeFromCart', product);
    //     }
    //   });
    //   if (product.checkbox) this.$emit('addToCart', product);
    //   else this.$emit('removeFromCart', product);
    // },
    actionClickCheckBox(hasClick, product) {
      //TODO fix interaction with product search list
      if (hasClick) {
        this.$emit('addToCart', product);
      } else {
        this.$emit('removeFromCart', product);
      }
    },
    actionClickCard(product) {
      this.$set(product, 'checkbox', !product.checkbox);
      this.actionClickCheckBox(product.checkbox, product);
    },
  },
};
</script>

<style lang="scss" scoped></style>
