var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"90vw","max-width":_vm.maxWidth},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{staticClass:"d-flex flex-column",attrs:{"min-height":"70vh"}},[_c('v-card-title',[_c('span',{staticClass:"mainTitle",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1),_c('v-card-text',{staticClass:"flex-1"},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Name"}}),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error":!!errors.length,"error-messages":errors,"hide-details":"auto"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),(!_vm.isFacility)?_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Phone"}}),_c('phone-validator',{ref:"phoneValidator",attrs:{"phone":_vm.model.phone},on:{"update:phone":function($event){return _vm.$set(_vm.model, "phone", $event)}}})],1):_vm._e(),(_vm.isAdmin || _vm.isCashier)?_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Password"}}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"autocomplete":"new-password","error":!!errors.length,"error-messages":errors,"hide-details":"auto","outlined":"","type":"password"},model:{value:(_vm.model.pw),callback:function ($$v) {_vm.$set(_vm.model, "pw", $$v)},expression:"model.pw"}})]}}],null,true)})],1):_vm._e(),(_vm.isMaster || _vm.isFacility)?_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Servicing Capacity"}}),_c('ValidationProvider',{attrs:{"name":"Servicing Capacity","rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","min":0,"error":!!errors.length,"error-messages":errors,"hide-details":"auto"},model:{value:(_vm.model.servicingCapacity),callback:function ($$v) {_vm.$set(_vm.model, "servicingCapacity", $$v)},expression:"model.servicingCapacity"}})]}}],null,true)})],1):_vm._e(),(_vm.isFacility)?_c('v-col',{attrs:{"cols":"12"}},[_c('custom-label',{attrs:{"item-field-name":"Booking Capacity"}}),_c('ValidationProvider',{attrs:{"name":"Booking Capacity","rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","min":0,"error":!!errors.length,"error-messages":errors,"hide-details":"auto"},model:{value:(_vm.model.bookingCapacity),callback:function ($$v) {_vm.$set(_vm.model, "bookingCapacity", $$v)},expression:"model.bookingCapacity"}})]}}],null,true)})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":!valid},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Create ")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":valid},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Update ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }