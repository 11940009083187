var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"90%","max-width":"1000px"},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('ListView',{attrs:{"title":"Bound Booking Product","enable-create":false,"search":_vm.search,"data":_vm.data,"page":_vm.page,"header":_vm.header},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.sid))]),_c('div',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,'YYYY/MM/DD HH:mm')))])]}},{key:"item.payment",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.paymentMethod))]),_c('div',[_vm._v("$"+_vm._s(_vm._f("toThousandFilter")(item.amountPayable)))])]}},{key:"item.contact",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.contactName))]),_c('div',[_vm._v(_vm._s(item.contactPhone))])]}},{key:"item._product",fn:function(ref){
var item = ref.item;
return _vm._l((item.bookingItems),function(bookingItem,index){return _c('div',{staticClass:"d-flex align-center my-2",staticStyle:{"gap":"8px"}},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(bookingItem.product.name)+" ")]),_c('div',{staticClass:"flex-shrink-0"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("$"+_vm._s(_vm._f("toThousandFilter")(bookingItem.subtotal)))])]),_c('v-btn',{staticClass:"primary",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.selectBookingItem(item, bookingItem)}}},[_c('v-icon',[_vm._v("mdi-link-variant-plus")])],1)],1)})}},{key:"btn",fn:function(){return undefined},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }