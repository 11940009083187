/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {Promise<string[]>}
 */
import { crudRead, crudUpdate, crudDelete } from '@/api/crud';
import { RESTFUL } from '@/data/constants';

export async function displayPayrollDetails(api, id, options) {
  return await crudRead(api, RESTFUL.payrollRecords.read, id, options);
}
export async function masterDisplayPayrollDetails(api, id, options) {
  return await crudRead(api, RESTFUL.payrollRecords.masterRead, id, options);
}
/**
 * @param {AxiosInstance} api
 * @param {string} master
 * @param {IDateRange} time
 * @param {string[]} formulaItems
 * @param {boolean} commit
 * @param {string} id? [optional] [payroll record id]
 * @return {Promise<PayrollRecord>}
 */
export async function computePayroll(api, master, time, formulaItems, commit, id) {
  //TODO: group the parameter into data
  return (
    await api.post(`toca/admin/payrollRecord/computePayroll`, {
      master: master,
      time: time,
      formulaItems: formulaItems,
      commit: commit,
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} search
 * @return {Promise<string[]>}
 */
export async function getPayrollConstant(api, search) {
  return (await api.get(`toca/admin/payrollConstants`, { params: { search: search } })).data;
}

export async function updatePayroll(api, id, data) {
  return crudUpdate(api, RESTFUL.payrollRecords.update, id, data); //data: data that u need to patch, for eg: { name: "Abc" }
}
/**
 * @param {AxiosInstance} api
 * @param {{page?: number, search?: string}} [options]
 * @return {Promise<IPayrollRecord[]>}
 */
export async function adminListPayrollRecords(api, options) {
  return (
    await api.get(`toca/admin/payrollRecords`, {
      params: options,
      //   filter:{
      //   'time.start': { $gte: yearStartDate },
      //   'time.end': { $lte: yearEndDate },
      //   }
      // },
      // sort: { 'time.start': 1 },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{page?: number, search?: string}} [options]
 * @return {Promise<IPayrollRecord[]>}
 */
export async function masterListPayrollRecord(api, options) {
  return (await api.get(`toca/master/payrollRecords`, { params: options })).data;
}

/**
 * @param {AxiosInstance} api
 * @param {number} year
 * @return {Promise<IPayrollRecord[]>}
 */
export async function masterGetPayrollRecordsOfAYear(api, year, page) {
  return (
    await api.get(`toca/master/payrollRecord/getPayrollRecordsOfAYear`, {
      params: {
        year: year,
        page: page,
      },
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {{page?: number, search?: string}} [options]
 * @return {Promise<IPayrollRecord[]>}
 */
export async function masterReadPayrollRecordDetails(api, id, options) {
  return (await api.get(`toca/master/payrollRecord/${id}`, { params: options })).data;
}

export async function deletePayroll(api, id) {
  return crudDelete(api, RESTFUL.payrollRecords.list, id);
}

export async function getLatestPayrollRecordOfMonths(api, id, options) {
  return (
    await api.get(`toca/master/payrollRecord/getLatestPayrollRecordOfMonths/${id}`, {
      params: options,
    })
  ).data;
}

export async function getPayrollNotePdf(api, id, payload) {
  return (await api.get(`/toca/admin/payrollRecord/exportPayrollPDF/${id}?download=1`, payload))
    .data;
}
