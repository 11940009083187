var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"95%","max-width":"1200px"},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"min-height":"250"}},[_c('v-card-title',[_c('span',{staticClass:"mainTitle",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s('Physical Product Order')+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('div',{staticClass:"pa-3",staticStyle:{"overflow-y":"auto","overflow-x":"hidden"}},[_c('div',{staticClass:"pb-3"},[_c('h3',[_vm._v("Order items")])]),_c('ProductOrderItems',{attrs:{"categories":_vm.productCategories},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}}),_c('div',{staticClass:"green-theme d-flex justify-end mt-3 mb-2"},[_c('div',[_vm._v("Sub-Total: "+_vm._s(_vm._f("currency")(_vm.computedSubTotal)))])]),_c('v-divider',{staticClass:"mb-2"}),_c('h3',[_vm._v("Contact Information")]),_c('ValidationProvider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{ref:"autoComplete",attrs:{"label":"Customer","path":_vm.computedCustomerProfileUrl,"params":{ sort: { created_at: -1 } },"item-text":"name","item-value":"_id","error-messages":errors,"clearable":true,"has-items-slot":true,"return-object":true},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.get(content, 'item.name', '') + ' ' + _vm.displayPhone(_vm.get(content, 'item.phone', ''))))])]}}],null,true),model:{value:(_vm.profile),callback:function ($$v) {_vm.profile=$$v},expression:"profile"}})]}}],null,true)}),(!_vm.profile)?_c('div',[_c('v-btn',{on:{"click":function($event){_vm.createUser = true}}},[_vm._v("Add New Customer")])],1):_vm._e(),_c('IdentityEditDialog',{attrs:{"max-width":"600"},on:{"create":_vm.updateProfile},model:{value:(_vm.createUser),callback:function ($$v) {_vm.createUser=$$v},expression:"createUser"}}),_c('v-divider',{staticClass:"my-2"}),_c('div',[_c('h3',[_vm._v("Seller")]),_c('autocomplete',{ref:"autoComplete",attrs:{"label":"Facility","path":_vm.computedProfileUrl,"params":{
                sort: { created_at: -1 },
                filter: { role: { $ne: 'toca.client' }, status: '1$accepted' },
              },"item-text":"name","item-value":"_id","clearable":true,"has-items-slot":true,"return-object":true,"disabled":!_vm.isCreate && !_vm.isAdmin && !_vm.isCashier},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.get(content, 'item.name', '') + ' ' + _vm.displayPhone(_vm.get(content, 'item.phone', ''))))])]}}],null,true),model:{value:(_vm.agent),callback:function ($$v) {_vm.agent=$$v},expression:"agent"}})],1),_c('v-divider',{staticClass:"my-2"}),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',[_c('h3',[_vm._v("Payment")]),_c('ValidationProvider',{attrs:{"name":"Payment Option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('PaymentMethodSelection',{staticClass:"mb-4",attrs:{"error-messages":errors},model:{value:(_vm.model.paymentMethod),callback:function ($$v) {_vm.$set(_vm.model, "paymentMethod", $$v)},expression:"model.paymentMethod"}})]}}],null,true)})],1),_c('v-divider'),_c('div',{staticClass:"d-flex justify-space-between"},[_c('h4',[_vm._v("Sub-Total:")]),_c('div',[_vm._v("HK"+_vm._s(_vm._f("currency")(_vm.computedSubTotal)))])]),(_vm.profile)?_c('div',[_c('v-checkbox',{attrs:{"color":"primary","label":"Credit Deduction (1Credit = $1)"},model:{value:(_vm.enableCreditDeduction),callback:function ($$v) {_vm.enableCreditDeduction=$$v},expression:"enableCreditDeduction"}}),_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"green-theme mr-3"},[_vm._v(_vm._s(_vm.profile.creditBalance || 0))]),_c('v-text-field',{staticStyle:{"max-width":"150px"},attrs:{"dense":"","outlined":"","disabled":!_vm.enableCreditDeduction,"label":"Please fill in"},model:{value:(_vm.model.creditBalanceDeduction),callback:function ($$v) {_vm.$set(_vm.model, "creditBalanceDeduction", _vm._n($$v))},expression:"model.creditBalanceDeduction"}})],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',[_vm._v("Total")]),_c('h2',{staticClass:"primary--text"},[_vm._v("HK"+_vm._s(_vm._f("currency")(_vm.computedTotal)))])])],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.doClose()}}},[_vm._v("Close")]),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Create ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }