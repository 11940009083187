<template>
  <v-container>
    <v-row>
      <!-- Header -->
      <v-col class="d-flex align-center">
        <img class="mr-auto" :src="TocaLogo" alt="Toca Logo" />
        <div class="d-flex align-center no-print">
          <v-btn @click="print" color="primary" class="mr-2">Print</v-btn>
          <v-select
            dense
            hide-details="auto"
            style="max-width: 150px"
            outlined
            v-model="selectedPayroll"
            :items="payrollList"
            item-value="_id"
            ><template #item="{ item }">{{ item.date | moment('MMM YYYY') }}</template>
            <template #selection="{ item }">{{
              item.date | moment('MMM YYYY')
            }}</template></v-select
          >
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <PayrollNote ref="payrollNote" :value="selectedPayroll" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TocaLogo from '@/assets/TocaLogo.svg';
import moment from 'moment';
import { positions } from '@/data/constants';
import { get } from 'lodash';
import api from '@/api';
import { getLatestPayrollRecordOfMonths } from '@/api/payroll';
import PayrollNote from './PayrollNote.vue';
export default {
  name: 'PayrollNoteByIdentity',
  components: {
    PayrollNote,
  },
  props: {
    //Profile
    model: {},
  },
  data() {
    return {
      TocaLogo,
      positions,
      lastDayOfMonth: 31,
      tableFullVersion: false,
      selectedPayroll: null,
      payrollList: [],
    };
  },
  computed: {
    selectedYear() {
      return moment(this.model?.time).year();
    },
    selectedMonthYear: {
      get() {
        return moment(this.model?.time).format('MMM YYYY');
      },
      set(newValue) {
        this.lastDayOfMonth = moment(newValue).endOf('month').format('DD');
        this.$set(this.model, 'time', moment(newValue).startOf('month').format('MMM YYYY'));
      },
    },
  },
  async mounted() {
    this.loadLatestPayrollRecordOfMonths();
  },
  methods: {
    get,
    async loadLatestPayrollRecordOfMonths() {
      this.payrollList = await getLatestPayrollRecordOfMonths(api, this.model.id);
      if (this.payrollList.length > 0) {
        this.selectedPayroll = this.payrollList[0]._id;
      }
    },
    print() {
      this.$refs.payrollNote.print();
    },
  },
};
</script>

<style lang="scss" scoped></style>
