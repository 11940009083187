var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-row',{staticClass:"py-5"},[_c('v-col',{attrs:{"cols":"2"}},[_c('b',[_vm._v("Customer Labels")])]),(_vm.userLabels.length)?_c('v-col',{staticClass:"d-flex align-start flex-wrap",attrs:{"cols":"10"}},_vm._l((_vm.userLabels),function(item,index){return _c('v-chip',{key:index,staticClass:"mr-5 mb-5",staticStyle:{"font-weight":"bold"},attrs:{"color":"#96734B","label":"","outlined":""}},[_vm._v(" "+_vm._s(item)+" ")])}),1):_c('v-col',[_c('u',[_vm._v("No Labels Added")])])],1),_vm._m(0),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.serviceRecordList,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.Date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.moment(item._timetable.time.start).format('YYYY-MM-DD'))+" ")])]}},{key:"item.Time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.moment(item._timetable.time.start).format('HH:mm-') + _vm.moment(item._timetable.time.end).format('HH:mm'))+" ")])]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, 'serviceDetails.serviceProduct.name', '').replace( 'Herbal Hair Color Package', '' ) || '-')+" ")])]}},{key:"item.RoundDuration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, 'serviceRecord.colorProportion.firstRound.duration'))+" mins (R1)+ "+_vm._s(_vm.get(item, 'serviceRecord.colorProportion.secondRound.duration'))+" mins (R2)")])]}},{key:"item.colorProportion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_c('span',[_vm._v(_vm._s(_vm.colorProportion(_vm.get(item, 'serviceRecord.colorProportion', {}))))])])]}},{key:"item.servingTherapist",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.get(item, '_timetable.master.name', '-')))])]}},{key:"item.Master",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, 'serviceDetails.masterSpecified.name') === _vm.get(item, '_timetable.master.name') ? 'Yes' : 'No'))])]}},{key:"item.assistantTherapist",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, '_timetable.assistantMaster.name', '-')))])]}},{key:"item.Remarks",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.get(item, 'serviceRecord.remarks', '-')))])]}}])})],1),(_vm.firstRound && _vm.secondRound)?_c('div',[_c('div',{staticClass:"py-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_vm._m(1),_c('v-btn',{attrs:{"disabled":!_vm.serviceRecordList || !_vm.serviceRecordList[0]},on:{"click":_vm.copyLastRecord}},[_vm._v("Copy Last Time ")])],1)]),_c('div',{staticClass:"py-3"},[_c('color-edit-section',{attrs:{"colorOptions":_vm.colorOptions,"round-number":1,"target":_vm.firstRound},on:{"addNew":_vm.addNewItem,"removeRow":_vm.removeRow,"cloneRow":function (x) { return _vm.cloneRow(1, x); }}})],1),_c('div',{staticClass:"py-3"},[_c('color-edit-section',{attrs:{"colorOptions":_vm.colorOptions,"round-number":2,"target":_vm.secondRound},on:{"addNew":_vm.addNewItem,"removeRow":_vm.removeRow,"cloneRow":function (x) { return _vm.cloneRow(2, x); }}})],1),_c('div',[_vm._m(2),_c('h3',{staticClass:"mt-5"},[_vm._v("Color Combination")]),_c('div',[_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(_vm.colorProportion({ firstRound: _vm.firstRound, secondRound: _vm.secondRound })))])])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"align-self-center",staticStyle:{"min-width":"160px"}},[_c('b',[_vm._v("Color Record")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"align-self-center",staticStyle:{"min-width":"160px"}},[_c('b',[_vm._v("Today Color")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',[_vm._v("(R) Root 根 | (F) Front 前 | (E) End 尾 | (W) Whole 全 | (T) Top 頂 ")]),_c('br'),_c('span',[_vm._v("~~> = 搽第二轉 next round")])])}]

export { render, staticRenderFns }