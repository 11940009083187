<template>
  <v-container>
    <v-card flat>
      <v-card-title>{{ title }}</v-card-title>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row class="ma-3">
          <v-col class="ml-auto" md="auto">
            <v-btn color="primary" @click="reload" style="margin-right: 20px"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
            <v-btn :disabled="invalid" color="primary" @click="save">
              {{ isNew ? 'Save' : 'Update' }}
            </v-btn>
          </v-col>
        </v-row>
        <div id="title">
          <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
            <v-text-field
              v-model="model['titleZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="標題"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
            <v-text-field
              v-model="model.titleZh"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="标题"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Title" rules="required">
            <v-text-field
              v-model="model.titleEn"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Title"
              outlined
            />
          </ValidationProvider>
          <v-divider style="margin-bottom: 20px" />
        </div>
        <div id="classes">
          <div v-for="(item, index) in model.classes" :key="index">
            <h3 style="margin-bottom: 10px">Classes {{ index + 1 }}</h3>
            <div id="featuredImage">
              <div style="margin-bottom: 40px">
                <div v-if="item.featuredImage">
                  <v-img :src="attnThumbHref(item.featuredImage, 512)"></v-img>
                </div>
                <div v-else class="d-flex justify-center">No Featured Image</div>
              </div>
              <div style="margin-top: 10px">
                <v-row>
                  <v-col cols="9">
                    <v-file-input
                      v-model="item.newImage"
                      class="flex-grow-1"
                      flat
                      label="Photo Upload"
                      outlined
                      accept="image/jpeg,image/png"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="flex-shrink-0"
                      style="margin-top: 10px"
                      color="primary"
                      @click="uploadImage(index)"
                      :disabled="!item.newImage"
                      >Upload Image
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div id="classesTitle">
              <v-row>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="類別標題" rules="required">
                    <v-text-field
                      v-model="item['titleZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="類別標題"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="类别标题" rules="required">
                    <v-text-field
                      v-model="item.titleZh"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="类别标题"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="Classes Title" rules="required">
                    <v-text-field
                      v-model="item.titleEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="Classes Title"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="videoLink">
              <ValidationProvider v-slot="{ errors }" name="Classes Video Link">
                <v-text-field
                  v-model="item.videoLink"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="Classes Video Link"
                  outlined
                />
              </ValidationProvider>
            </div>
            <div id="classesContent">
              <div style="margin-bottom: 10px">
                <h4 class="mainTitle CMSTitle">中文繁體版本</h4>
                <ValidationProvider v-slot="{ errors }" name="課程內容" rules="required">
                  <quillEditor ref="quill" :options="qillOptions" v-model="item['contentZh-HK']" />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div style="margin-bottom: 10px">
                <h4 class="mainTitle CMSTitle">中文简体版本</h4>
                <ValidationProvider v-slot="{ errors }" name="课程内容" rules="required">
                  <quillEditor ref="quill" :options="qillOptions" v-model="item.contentZh" />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div style="margin-bottom: 10px">
                <h4 class="mainTitle CMSTitle">English Version</h4>
                <ValidationProvider v-slot="{ errors }" name="Classes Content" rules="required">
                  <quillEditor ref="quill" :options="qillOptions" v-model="item.contentEn" />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <v-divider style="margin-bottom: 20px" />
            </div>
          </div>
        </div>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { CMSPostType } from '../../data/constants';
import { crudCreate, crudList, crudUpdate } from '@/api/crud';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { generatorObjectFromJSONFormat } from '@/services/CMS';
import { attnThumbHref } from '@/services/filters';
import { uploadSingleAttachment } from '@/api/attachment';

export default {
  name: 'homePageDiscoverClassesCMS',
  components: {
    quillEditor,
  },
  data() {
    return {
      title: 'Home Page Discover Classes',
      postType: CMSPostType.homePageDiscoverClasses,
      contentID: null,
      model: {},
      qillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
        readOnly: false,
      },
    };
  },
  computed: {
    isNew() {
      return !this.contentID;
    },
  },
  async beforeMount() {
    await this.reload();
  },
  methods: {
    attnThumbHref,
    async reload() {
      const computedQuery = {
        filter: { 'metadata.type': this.postType },
        sort: { created_at: -1 },
      };
      try {
        const res = await crudList(api, 'cms/admin/posts', computedQuery);
        this.model = res?.docs?.[0] || {};
        this.contentID = this.model?._id;

        if (this.contentID) {
          if (this.model.title) {
            let result = generatorObjectFromJSONFormat('title', this.model.title);
            Object.assign(this.model, result);
          }
          if (this.model.metadata.items) {
            let classes = this.model.metadata.items.map(item => {
              let obj = {
                featuredImage: item.featuredImage,
                videoLink: item.videoLink,
              };
              let title = generatorObjectFromJSONFormat('title', item.title);
              let content = generatorObjectFromJSONFormat('content', item.content);

              Object.assign(obj, title);
              Object.assign(obj, content);
              return obj;
            });
            this.$set(this.model, 'classes', []);
            classes.map((item, index) => {
              this.$set(this.model.classes, index, item);
            });
          }
        } else {
          let emptyClasses = [];
          for (let i = 0; i < 3; i++) {
            const emptyObject = {
              ['titleZh-HK']: '',
              titleZh: '',
              titleEn: '',
            };
            emptyClasses.push(emptyObject);
          }
          this.$set(this.model, 'classes', []);
          emptyClasses.map((item, index) => {
            this.$set(this.model.classes, index, item);
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS load ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const mainTitleJsonObj = {
          'zh-HK': this.model['titleZh-HK'],
          zh: this.model['titleZh'],
          en: this.model.titleEn,
        };

        let tempItems = [];
        tempItems = this.model.classes.map(item => {
          const titleJsonObj = {
            'zh-HK': item['titleZh-HK'],
            zh: item['titleZh'],
            en: item.titleEn,
          };
          const contentJsonObj = {
            'zh-HK': item['contentZh-HK'],
            zh: item['contentZh'],
            en: item.contentEn,
          };
          return {
            videoLink: item.videoLink,
            featuredImage: item.featuredImage,
            title: JSON.stringify(titleJsonObj),
            content: JSON.stringify(contentJsonObj),
          };
        });

        // if (this.isNew) {
        if (this.isNew) {
          //create
          const payload = {
            metadata: {
              type: this.postType,
              items: tempItems,
            },
            content: '',
            title: JSON.stringify(mainTitleJsonObj),
          };
          const res = await crudCreate(api, 'cms/admin/posts', payload);
          // alert & update
          this.contentID = res._id;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
        } else {
          // update
          delete this.model.__v;
          delete this.model.updated_at;
          delete this.model.created_at;
          this.model.title = JSON.stringify(mainTitleJsonObj);
          this.model.content = '';
          this.model.metadata = {
            type: this.postType,
            items: tempItems,
          };

          const res = await crudUpdate(api, 'cms/admin/posts', this.contentID, this.model);
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Updated',
            type: 'success',
            color: 'success',
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS post ${error}`,
          type: 'error',
          color: 'error',
        });
      } finally {
        this.reload();
      }
    },
    async uploadImage(targetIndex) {
      const newImage = this.model.classes[targetIndex].newImage;
      if (!newImage) {
        return;
      }
      const attachment = await uploadSingleAttachment(api, newImage);
      this.model.classes[targetIndex].featuredImage = attachment._id;
      this.model.classes[targetIndex].newImage = null;
    },
  },
};
</script>
