<template>
  <div v-if="serviceRecordList" class="py-3">
    <div class="mb-5 d-flex justify-space-between">
      <div class="align-self-center" style="min-width: 160px"><b>Booking Record</b></div>
      <!--      <v-btn @click="reload">Refresh</v-btn>-->
    </div>
    <v-data-table
      :headers="headers"
      :items="serviceRecordList"
      :items-per-page="limit"
      :server-items-length="totalLength"
      @update:page="changePage"
      @update:items-per-page="changeRowsPerPage"
      @click:row="x => navDetails(x)"
    >
      <template v-slot:item.Date="{ item }">
        <span class="tableCol">
          {{ moment(item._timetable.time.start).format('YYYY-MM-DD') }}
        </span>
      </template>
      <template v-slot:item.Time="{ item }">
        <span class="tableCol">
          {{
            moment(item._timetable.time.start).format('HH:mm-') +
            moment(item._timetable.time.end).format('HH:mm')
          }}
        </span>
      </template>
      <template v-slot:item.Name="{ item }">
        <!--        <span class="tableCol"> {{ get(item, 'serviceDetails.serviceProduct.name', '-') }}</span>-->
        <span class="tableCol"
          >{{
            get(item, 'serviceDetails.serviceProduct.name', '--').replace(
              'Herbal Hair Color Package',
              '',
            ) || '-'
          }}
        </span>
        <!--        <span class="tableCol">-->
        <!--          {{-->
        <!--            get(-->
        <!--              item,-->
        <!--              `'serviceDetails.serviceProduct.name'.replace('Herbal Hair Color Package','')`,-->
        <!--              '-',-->
        <!--            )-->
        <!--          }}</span-->
        <!--        >-->
      </template>
      <template v-slot:item.colorProportion="{ item }">
        <span class="tableCol">
          <span>{{
            item.isVoided ? 'N/A' : colorProportion(item.serviceRecord.colorProportion)
          }}</span>
        </span>
      </template>
      <template v-slot:item.RoundDuration="{ item }">
        <span class="tableCol"
          >{{ get(item, 'serviceRecord.colorProportion.firstRound.duration') }} mins (R1)+
          {{ get(item, 'serviceRecord.colorProportion.secondRound.duration') }} mins (R2)</span
        >
      </template>
      <template v-slot:item.servingTherapist="{ item }">
        <!-- <span class="tableCol"> {{ get(item, 'firstAssignedMaster.name', '-') }}</span> -->
        <!--        <span class="tableCol"> {{ get(item, 'serviceDetails.masterSpecified.name', '-') }}</span>-->
        <span class="tableCol"> {{ get(item, '_timetable.master.name', '-') }}</span>
      </template>

      <template v-slot:item.Master="{ item }">
        <span class="tableCol">{{
          get(item, 'serviceDetails.masterSpecified.name') === get(item, '_timetable.master.name')
            ? 'Yes'
            : 'No'
        }}</span>
      </template>
      <template v-slot:item.assistantTherapist="{ item }">
        <!--        <span class="tableCol"> {{ get(item, 'firstAssistantMaster.name', '-') }}</span>-->
        <span class="tableCol">{{ get(item, '_timetable.assistantMaster.name', '-') }}</span>
      </template>
      <template v-slot:item.Remarks="{ item }">
        <span class="tableCol"> {{ get(item, 'serviceRecord.remarks', '-') }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';
import { crudList } from '@/api/crud';
import api from '@/api';
import { colorProportion } from '@/services/utils';

export default {
  name: 'serviceRecord',
  props: {
    id: String,
  },
  data() {
    return {
      page: 1,
      totalLength: 0,
      serviceRecordList: [],
      limit: 5,
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'Date',
        },
        {
          text: 'Time',
          align: 'center',
          sortable: false,
          value: 'Time',
        },
        {
          text: 'Service',
          align: 'center',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Color Proportion',
          align: 'center',
          sortable: false,
          value: 'colorProportion',
        },
        {
          text: 'Round Duration',
          align: 'center',
          sortable: false,
          value: 'RoundDuration',
        },
        {
          text: 'Serving Facility',
          align: 'center',
          sortable: false,
          value: 'servingTherapist',
        },
        {
          text: 'Requested Facility',
          align: 'center',
          sortable: false,
          value: 'Master',
        },
        {
          text: 'Assistant Facility',
          align: 'center',
          sortable: false,
          value: 'assistantTherapist',
        },
        {
          text: 'Remarks of the cases',
          align: 'center',
          sortable: false,
          value: 'Remarks',
        },
      ],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  methods: {
    get,
    moment,
    colorProportion,
    changeRowsPerPage(val) {
      this.limit = val;
      this.reload();
    },
    changePage(page) {
      this.page = page;
      this.reload();
    },
    async reload() {
      try {
        const recordRes = await crudList(api, 'toca/admin/bookings', {
          filter: { created_by: this.id, status: '99$completed' },
          limit: this.limit,
          page: this.page,
          populate: [
            { path: 'serviceDetails.masterSpecified' },
            { path: 'serviceDetails.serviceProduct' },
            { path: 'firstAssistantMaster' },
            { path: '_timetable', populate: ['master', 'assistantMaster'] },
          ],
          sort: { 'time.start': -1 },
        });
        this.totalLength = recordRes.total;
        this.serviceRecordList = recordRes.docs;
      } catch (e) {}
    },
    navDetails(x) {
      const b = this.$router.resolve({ name: 'booking', params: { id: String(x._id) } }).href;
      const url = window.location.origin + b;
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.tableCol {
  color: var(--v-primary-base);
  font-weight: 500;
}
</style>
