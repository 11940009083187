<template>
  <v-container>
    <div>
      <h2>
        {{ title }}
      </h2>
    </div>
    <CreditRecordEditCard :id="id" />
  </v-container>
</template>

<script>
import CreditRecordEditCard from '@/components/CreditRecord/CreditRecordEditCard.vue';

export default {
  name: 'creditRecordDetail',
  components: { CreditRecordEditCard },
  mixins: [],
  props: {
    pageType: {},
    id: {},
  },
  data() {
    return {
      model: {},
      title: 'Edit Credit Record',
    };
  },
  computed: {},
  /*async mounted() {
      await this.reload();
    },*/
  methods: {},
};
</script>

<style scoped lang="scss"></style>
