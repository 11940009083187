<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" lg="6">
        <slot name="Main">
          <!--          <p>this is the Main slot</p>-->
        </slot>
        <slot name="BottomLeft">
          <!--          <p>this is the BottomLeft slot</p>-->
        </slot>
      </v-col>
      <v-col cols="12" lg="6">
        <slot name="TopRight">
          <!--          <p>this is the TopRight slot</p>-->
        </slot>
        <slot name="BottomRight">
          <!--          <p>this is the BottomRight slot</p>-->
        </slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ColumnBasedFourCellsLayout',
};
</script>

<style scoped></style>
