<template>
  <div class="py-3">
    <div class="mb-5">
      <div class="align-self-center" style="min-width: 160px"><b>Order Product</b></div>
    </div>
    <div class="d-flex justify-center align-center">
      <v-tabs v-model="tab" color="primary" background-color="#F5F5F5" grow>
        <v-tab :class="{ buttonEffectTab: tab === 0 }">Order</v-tab>
        <v-tab :class="{ buttonEffectTab: tab === 1 }">Cart</v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-row class="mt-1">
              <v-col cols="2">
                <div class="d-flex justify-center">
                  <h4 class="ma-1 ml-3">Category:</h4>
                </div>
              </v-col>
              <v-col cols="10">
                <v-autocomplete
                  dense
                  filled
                  solo
                  clearable
                  label="Select a category"
                  v-model="selectedCategory"
                  :items="categories"
                  item-text="title"
                  item-value="_id"
                  @change="getProductByCategory"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <div class="my-2">
              <v-text-field solo filled prepend-inner-icon="mdi-magnify" v-model="searchVal" />
            </div>
            <v-row no-gutters>
              <v-col v-for="(product, i) in products" :key="`product-${i}`" cols="3" class="d-flex">
                <ProductSelectionCard
                  :product="product"
                  @actionClickCheckBox="({ e, p }) => actionClickCheckBox(e, p)"
                  @actionClickCard="actionClickCard"
                  @addToCart="addToCart"
                  @increment="increment"
                  @decrement="decrement"
                />
              </v-col>
            </v-row>
            <div class="d-flex justify-center">
              <LoadMoreButton
                :hasMore="hasMoreProduct"
                :is-loading="isLoadingProduct"
                @loadMore="loadMore"
              />
            </div>
          </v-tab-item>
          <v-tab-item>
            <div>
              <template>
                <v-divider class="mt-3" />
                <v-row class="d-flex align-center justify-center justify-space-between">
                  <v-col cols="2"></v-col>
                  <v-col cols="2">Product Name</v-col>
                  <v-col cols="2">Category</v-col>
                  <v-col cols="2">Unit Price</v-col>
                  <v-col cols="1">Quantity</v-col>
                  <v-col cols="1">SubTotal</v-col>
                  <v-col cols="1"></v-col>
                </v-row>
                <v-divider />
              </template>
            </div>
            <div v-for="product in selectedProducts" :key="product._id">
              <cart-item-row
                :item="product"
                :productCategories="categories"
                :hideBinIcon="isBookingCompleted"
                @remove="remove"
              ></cart-item-row>
              <v-divider class="my-3" />
            </div>
            <div class="d-flex flex-end justify-end">
              <h4>Total:</h4>
              <h4 class="ml-3">HK$ {{ computedTotal }}</h4>
            </div>
            <div class="d-flex flex-end justify-end">
              <v-btn
                color="primary"
                @click="promptSaveProductOrder"
                :disabled="!masterHasAuth || isBookingCompleted"
                >Submit To Invoice
              </v-btn>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
import { get, debounce } from 'lodash';
import { listPhysicalProducts } from '@/api/productCategory';
import api from '@/api';
import CartItemRow from '@/components/ServiceDetails/CartItemRow';
import { BookingStatus, patchPurchasedProducts } from '@/api/bookings';
import LoadMoreButton from '@/components/LoadMoreButton';
import { getOid } from '@/services/utils';
import { mapGetters } from 'vuex';
import ProductSelectionCard from '@/components/PhysicalProduct/ProductSelectionCard.vue';

export default {
  name: 'ProductCategoryList',
  components: { LoadMoreButton, CartItemRow, ProductSelectionCard },
  props: {
    id: String,
    categories: [],
    booking: {},
    docs: {},
    selectedProducts: [],
  },
  data() {
    return {
      tab: null,
      imageSize: 100,
      selectedCategory: '',
      products: [],
      isLoadingProduct: false,
      data: {},
      page: 1,
      limit: 8,
      searchVal: '',
      debouncedSearch: debounce(this.getProductList, 400),
    };
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'profileId', 'isAdmin', 'isCashier']),
    computedTotal() {
      let total = 0;
      this.selectedProducts.map(el => {
        el.discountedPrice
          ? (total += el.discountedPrice * el.selectedQty)
          : (total += el.listedPrice * el.selectedQty);
      });
      return Number(total.toFixed(2));
    },
    hasMoreProduct() {
      if (Object.keys(this.data).length > 0) {
        return this.data.pages > this.data.page;
      } else {
        return false;
      }
    },
    masterHasAuth() {
      return (
        this.isAdmin ||
        this.isCashier ||
        (this.isMaster && this.profileId === getOid(this.docs?._timetable?.master))
      );
    },
    isBookingCompleted() {
      return this.booking?.status === BookingStatus.completed;
    },
  },
  watch: {
    booking: {
      handler(v) {
        if (v?.purchasedProducts?.length > 0) {
          const list = v.purchasedProducts.map(el => ({
            ...el.product,
            checkbox: true,
            selectedQty: el.qty,
          }));
          this.$emit('updateCart', list);
        }
      },
    },
    searchVal() {
      this.data = {};
      this.products = [];
      this.page = 1;
      this.debouncedSearch(this.page);
    },
  },
  methods: {
    get,
    attnThumbHref,
    increment(product) {
      product.selectedQty += 1;
      this.addToCart(product);
    },
    decrement(product) {
      if (product.selectedQty > 0) {
        product.selectedQty -= 1;
        this.addToCart(product);
      }
    },
    async loadMore() {
      this.page += 1;
      await this.getProductList(this.page);
    },
    async getProductByCategory() {
      this.data = {};
      this.products = [];
      this.page = 1;
      await this.getProductList(this.page);
    },
    async getProductList(page) {
      this.isLoadingProduct = true;
      try {
        const data = await listPhysicalProducts(api, {
          filter: this.selectedCategory ? { category: this.selectedCategory } : {},
          limit: this.limit,
          page: page,
          search: this.searchVal,
        });
        this.data = data;
        const products = this.products.concat(data.docs);
        for (const product of products) {
          const p = this.selectedProducts.find(el => el._id === product._id);
          if (!p) {
            this.$set(product, 'selectedQty', 0);
          } else {
            this.$set(product, 'selectedQty', p.selectedQty);
            this.$set(product, 'checkbox', p.checkbox);
          }
        }
        this.products = products;
      } catch (e) {}
      this.isLoadingProduct = false;
    },
    addToCart(product) {
      if (product.selectedQty === 0) {
        product.selectedQty = 1; // set default qty to 1
      }
      if (product && product.selectedQty > 0) {
        const p = this.selectedProducts.find(el => el._id === product._id);
        if (p) {
          p.selectedQty = product.selectedQty;
        } else {
          this.selectedProducts.push(product);
        }
      }
    },
    actionClickCheckBox(hasClick, product) {
      if (hasClick) {
        this.addToCart(product);
      } else {
        this.removeFromCart(product);
      }
    },
    removeFromCart(product) {
      if (product) {
        const i = this.selectedProducts.findIndex(el => el._id === product._id);
        if (i >= 0) {
          this.selectedProducts.splice(i, 1);
          product.selectedQty = 0;
        }
      }
    },
    actionClickCard(product) {
      this.$set(product, 'checkbox', !product.checkbox);
      this.actionClickCheckBox(product.checkbox, product);
    },
    async promptSaveProductOrder() {
      let orderProducts = [];
      this.selectedProducts.map(el =>
        orderProducts.push({
          product: el._id,
          qty: el.selectedQty,
          // deprecated
          // amountPayable: el.discountedPrice
          //   ? el.discountedPrice * el.selectedQty
          //   : el.listedPrice * el.selectedQty,
        }),
      );
      try {
        const model = await patchPurchasedProducts(api, this.id, orderProducts);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.$emit('submit', model);
      } catch (e) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: e,
        });
      }
    },
    remove(item) {
      const i = this.selectedProducts.findIndex(el => el._id === item._id);
      this.selectedProducts.splice(i, 1);
      const p = this.products.find(el => el._id === item._id);
      if (p) {
        p.selectedQty = 0;
        p.checkbox = false;
      }
      // product checkbox set false on recommendation list
      this.$emit('remove', item);
    },
  },
};
</script>

<style lang="scss">
.stripeClass {
  text-decoration: line-through red;
}

.discountClass {
  margin-left: 5px;
  color: red;
}

.custom-number-input input {
  padding-left: 0 !important;
  text-align: center;
  color: var(--v-primary-base);
}

.buttonEffectTab {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white !important;
}
</style>
