<template>
  <v-container>
    <div class="d-flex justify-space-between mb-2">
      <h2 class="ml-3">{{ title }}</h2>
<!--      <v-btn v-if="!isNew" color="danger" @click="promptDelete" class="mr-3" :disabled="isMaster"-->
      <!--        >Delete</v-btn-->
<!--      >-->
    </div>
    <v-divider class="mb-5 mx-3" />
    <v-card v-if="docs" class="px-3 py-3" flat>
      <v-row>
        <v-col sx="12" sm="12" md="6">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card min-height="600" class="mb-6">
              <v-row class="mr-3 ml-3">
                <v-col cols="12" class="d-flex align-center">
                  <h4>Sid: {{ docs.sid }}</h4>
                  <v-spacer />
                  <v-btn
                    :disabled="invalid || isMaster"
                    color="primary"
                    :loading="loading"
                    @click="saveLeft"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mr-3 ml-3">
                <!-- **** Notice: This field is intended for POS use only ****-->
                <v-col sx="12" sm="6" md="6">
                  <div v-if="!isNew" class="d-flex itemFieldName mb-2" style="gap: 10px">
                    On shelves:
                    <v-switch v-model="docs.isActiveForPOS" class="mt-0 pt-0 ml-3" hide-details />
                  </div>
                </v-col>
              </v-row>
              <v-row class="mr-3 ml-3">
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Product Name (HK)" rules="required">
                    <v-text-field
                      v-model="localizedName['zh-hk']"
                      :error-messages="errors"
                      flat
                      label="Product Name (HK)"
                      outlined
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Product Name (EN)" rules="required">
                    <v-text-field
                      v-model="localizedName.en"
                      :error-messages="errors"
                      flat
                      label="Product Name (EN)"
                      outlined
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="Product Category" rules="required">
                    <v-select
                      v-model="docs.category"
                      :items="productCategories"
                      item-text="title"
                      item-value="_id"
                      label="Category"
                      style="max-width: 300px"
                      outlined
                      :disabled="loading"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="Product Supplier">
                    <v-select
                      v-model="docs.supplier"
                      :items="productSuppliers"
                      item-text="name"
                      item-value="_id"
                      label="Supplier"
                      style="max-width: 300px"
                      outlined
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <div>
                    <div class="d-flex">
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="barcode"
                        :rules="
                          existingBarcode.length > 0
                            ? null
                            : { uniqueBarcodes: [computedBarcodeList] }
                        "
                      >
                        <v-text-field
                          v-model="docs.barcode[0]"
                          :error-messages="errors"
                          flat
                          label="Barcode"
                          outlined
                          :disabled="loading || existingBarcode.length > 0"
                        >
                        </v-text-field>
                      </ValidationProvider>
                    </div>
                    <div v-if="docs && docs.barcode && docs.barcode.length > 1">
                      <template
                        v-for="(itemBarcode, index) in docs &&
                        docs.barcode &&
                        docs.barcode.slice(1)"
                      >
                        <div class="d-flex align-baseline">
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="barcode"
                            :rules="
                              existingBarcode && !!existingBarcode[index + 1]
                                ? null
                                : { required: true, uniqueBarcodes: [computedBarcodeList] }
                            "
                          >
                            <v-text-field
                              v-model="docs.barcode[index + 1]"
                              :error-messages="errors"
                              flat
                              label="Barcode"
                              outlined
                              :disabled="
                                loading || (existingBarcode && !!existingBarcode[index + 1])
                              "
                            >
                            </v-text-field>
                          </ValidationProvider>
                          <v-btn
                            v-if="index + 2 > existingBarcode.length"
                            icon
                            :disabled="loading"
                            @click="deleteBarcode(index + 1)"
                          >
                            <v-icon>mdi-minus-circle-outline </v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </div>
                    <v-btn color="primary" :disabled="loading" @click="addBarcode">
                      <v-icon color="white">mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <small class="error--text">
                    Barcode(s) will not be able to edit or delete once saved.
                  </small>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="Assign Priority" rules="required">
                    <v-text-field
                      v-model="docs.priority"
                      :error-messages="errors"
                      flat
                      label="Assign Priority"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->

                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Description">
                    <v-textarea
                      v-model="docs.description"
                      :error-messages="errors"
                      flat
                      label="Description"
                      outlined
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="listed Price"
                    :rules="{ required: true, min_value: 0 }"
                  >
                    <v-text-field
                      type="number"
                      v-model="docs.listedPrice"
                      :error-messages="errors"
                      flat
                      prefix="$"
                      label="Listed Price"
                      outlined
                      min="0"
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Discounted Price"
                    :rules="{
                      required: true,
                      min_value: 0,
                      max_value: docs.listedPrice,
                    }"
                  >
                    <v-text-field
                      type="number"
                      v-model.number="docs.discountedPrice"
                      @input="syncDiscountedPrice($event)"
                      :error-messages="errors"
                      flat
                      prefix="$"
                      label="Discounted Price"
                      outlined
                      min="0"
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" name="Cost" :rules="{ min_value: 0 }">
                    <v-text-field
                      type="number"
                      v-model.number="docs.cost"
                      :error-messages="errors"
                      flat
                      prefix="$"
                      min="0"
                      label="Cost"
                      outlined
                      :disabled="loading"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <v-switch
                    v-model="docs.nonDiscountable"
                    label="Is Non Discountable"
                    :disabled="loading"
                  ></v-switch>
                  <!-- <p>Is Non Discountable {{ docs.nonDiscountable ? 'enabled' : 'disabled' }}</p> -->
                </v-col>
                <v-col cols="6">
                  <v-switch
                    v-model="docs.skipStockCount"
                    label="Skip Stock Count"
                    :disabled="loading"
                  ></v-switch>
                  <!-- <p>Is Non Discountable {{ docs.nonDiscountable ? 'enabled' : 'disabled' }}</p> -->
                </v-col>
                <!-- <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" name="Stock" rules="required">
                    <v-text-field
                      type="number"
                      v-model="docs.stock"
                      :error-messages="errors"
                      flat
                      label="Stock"
                      outlined
                    />
                    <span class="red--text">** -1 means infinite stock</span>
                  </ValidationProvider>
                </v-col> -->
                <v-col cols="12" v-if="docs.categoryDiscountedPrice">
                  <v-card elevation="4">
                    <v-list-item two-line class="d-flex justify-space-between">
                      <v-list-item-content
                        >Discounted Price according to category</v-list-item-content
                      >
                      <v-list-item-content class="red--text">
                        HK${{ get(docs, 'categoryDiscountedPrice', '-') }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                <!-- <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" name="Stock" rules="required">
                    <v-text-field
                      type="number"
                      v-model="docs.stock"
                      :error-messages="errors"
                      flat
                      label="Stock"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->
                <!-- <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Commission Rate"
                    :rules="{ required: true, min_value: 0, max_value: 100, decimal: 1 }"
                  >
                    <v-text-field
                      type="number"
                      v-model="computedCommissionRate"
                      suffix="%"
                      :error-messages="errors"
                      flat
                      label="Commission Rate"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->
                <!-- <v-col cols="12">
                  <v-select
                    label="Options"
                    outlined
                    multiple
                    :items="productRemarksOptions"
                    chips
                    v-model="docs.remarksOptions"
                  ></v-select>
                </v-col> -->
                <!--                <v-col cols="12">-->
                <!--                  <v-card>-->
                <!--                    <v-card-title class="justify-center">Tags</v-card-title>-->
                <!--                    <v-list-item>-->
                <!--                      <v-list-item-content>-->
                <!--                        <v-chip-->
                <!--                          class="mr-5 mb-5"-->
                <!--                          label-->
                <!--                          style="font-weight: bold"-->
                <!--                          color="#96734B"-->
                <!--                          outlined-->
                <!--                          close-->
                <!--                          close-icon="mdi-close-circle"-->
                <!--                          :key="index"-->
                <!--                          v-for="(item, index) in docs.tags"-->
                <!--                          @click:close="deleteProductTag(index)"-->
                <!--                          >{{ item }}-->
                <!--                        </v-chip>-->
                <!--                        <v-autocomplete-->
                <!--                          outlined-->
                <!--                          :search-input.sync="search"-->
                <!--                          :items="tags"-->
                <!--                          @input="x => addProductTag(x)"-->
                <!--                          append-outer-icon="mdi-plus-circle-outline"-->
                <!--                          @click:append-outer="addProductTag()"-->
                <!--                        >-->
                <!--                        </v-autocomplete>-->
                <!--                      </v-list-item-content>-->
                <!--                    </v-list-item>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->
              </v-row>
            </v-card>
          </ValidationObserver>
        </v-col>
        <v-col style="margin-top: -12px" xs="12" sm="12" md="6">
          <v-row>
            <v-col cols="12">
              <v-card min-height="484">
                <v-card-title>
                  <h2>Featured Image</h2>
                </v-card-title>
                <v-card-text>
                  <div class="d-flex justify-center" style="gap: 12px">
                    <template v-if="featuredImage">
                      <v-img :src="featuredImage" max-width="200" max-height="250" min-height="230">
                        <template v-slot:placeholder>
                          <v-img
                            src="@/assets/TocaLogo.svg"
                            max-width="200"
                            max-height="250"
                            min-height="230"
                            contain
                          />
                        </template>
                      </v-img>
                    </template>
                    <template v-else>
                      <v-img
                        src="@/assets/TocaLogo.svg"
                        max-width="200"
                        max-height="250"
                        min-height="230"
                        contain
                      />
                    </template>
                    <v-btn
                      v-if="featuredImage"
                      class="align-self-end"
                      icon
                      @click="removeFeaturedImage"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex mt-3">
                    <v-file-input
                      class="flex-grow-1"
                      outlined
                      v-model="newFeaturedImage"
                      label="Featured Image"
                      flat
                      accept="image/jpeg,image/png"
                      :loading="updateFeaturedImageLoading"
                      :disabled="updateFeaturedImageLoading"
                      hide-details="auto"
                    />
                    <v-btn
                      v-if="!isNew"
                      class="ml-3 flex-shrink-0"
                      @click="saveFeaturedImage"
                      color="primary"
                      :disabled="isMaster"
                      :loading="updateFeaturedImageLoading"
                      style="margin-top: 10px"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card min-height="484">
                <v-card-title>
                  <h2>Other Images</h2>
                </v-card-title>
                <v-card-text>
                  <div class="d-flex overflow-x-auto" style="gap: 12px">
                    <template v-if="otherImages.length > 0">
                      <div
                        v-for="(image, index) in otherImages"
                        :key="image"
                        class="d-flex flex-column justify-center"
                        style="gap: 12px"
                      >
                        <v-img :src="image" max-width="200" max-height="250" min-height="230">
                          <template v-slot:placeholder>
                            <v-img
                              src="@/assets/TocaLogo.svg"
                              max-width="200"
                              max-height="250"
                              min-height="230"
                              contain
                            />
                          </template>
                        </v-img>
                        <v-btn
                          class="align-self-center"
                          icon
                          :disabled="updateImagesLoading"
                          @click="removeOtherImage(index)"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <template v-else>
                      <v-img
                        src="@/assets/TocaLogo.svg"
                        class="mx-auto"
                        max-width="200"
                        max-height="250"
                        min-height="230"
                        contain
                      />
                    </template>
                  </div>
                  <div class="d-flex mt-3">
                    <v-file-input
                      class="flex-grow-1"
                      outlined
                      show-size
                      counter
                      multiple
                      v-model="newOtherImages"
                      label="Other Images"
                      accept="image/jpeg,image/png"
                      :loading="updateImagesLoading"
                      :disabled="updateImagesLoading"
                    />
                    <v-btn
                      v-if="!isNew"
                      class="ml-3 flex-shrink-0"
                      @click="saveOtherImage"
                      color="primary"
                      :disabled="isMaster"
                      :loading="updateImagesLoading"
                      style="margin-top: 10px"
                    >
                      Save
                    </v-btn>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <ProductActivityLogList :id="docs._id" />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import {
  deleteAttachment,
  uploadMultipleAttachment,
  uploadSingleAttachment,
} from '@/api/attachment';
import { crudCreate, crudDelete, crudRead, crudUpdate } from '@/api/crud';
import { attnThumbHref } from '@/services/filters';
import { mapActions, mapGetters } from 'vuex';
import { RESTFUL } from '@/data/constants';
import { listProductCategories } from '@/api/productCategory';
import { listSuppliers } from '@/api/suppliers';
import { get } from 'lodash';
import { autocompleteTags, getBarcodeList } from '@/api/physicalProduct';
import { parseLocalizedString } from '@/services/utils';
import { productRemarksOptions } from '@/data/optionsConstants';
import ProductActivityLogList from '@/components/ProductActivityLog/ProductActivityLogList.vue';
import { ValidationProvider, Validator } from 'vee-validate';

Validator.extend('uniqueBarcodes', {
  getMessage: (field, params, data) => {
    return 'Barcode must be unique.';
  },
  validate: (value, [barcodes]) => {
    if (value) return barcodes?.filter(barcode => barcode === value)?.length === 1;
    else return true;
  },
});

export default {
  name: 'ProductDetails',
  components: { ProductActivityLogList, ValidationProvider },
  props: {
    id: {},
  },
  data() {
    return {
      productRemarksOptions,
      docs: null,
      newFeaturedImage: null,
      newOtherImages: null,
      productCategories: [],
      productSuppliers: [],
      tags: [],
      search: null,
      loading: false,
      updateFeaturedImageLoading: false,
      updateImagesLoading: false,
      barcodeList: [],
      existingBarcode: [],
      localizedName: {},
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
    search: {
      immediate: true,
      async handler(v) {
        this.tags = await autocompleteTags(api, v);
      },
    },
    localizedName: {
      deep: true,
      async handler(v) {
        if (this.localizedName?.['zh-hk'])
          this.localizedName['zh-cn'] = this.localizedName['zh-hk'];
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    title() {
      return this.isNew ? 'New Product' : 'Product Details';
    },
    restfulURL() {
      return this.isMaster ? RESTFUL.physicalProducts.masterList : RESTFUL.physicalProducts.list;
    },
    featuredImage() {
      return this.featuredImageId && attnThumbHref(this.docs.featuredImage);
    },
    featuredImageId() {
      return this.docs?.featuredImage;
    },
    otherImages() {
      let imageSrcList = [];
      if (this.otherImagesIds) {
        for (const image of this.docs.images) {
          imageSrcList.push(attnThumbHref(image));
        }
      }
      return imageSrcList;
    },
    otherImagesIds() {
      return this.docs?.images;
    },
    computedCommissionRate: {
      // rule {decimal: 1} is required in this logic
      get() {
        return (Math.round(this.docs?.commissionRate * 10000) / 100).toString();
      },
      set(v) {
        this.$set(this.docs, 'commissionRate', Math.round(v * 100) / 10000);
      },
    },
    localizedNameHK: {
      get() {
        return this.parseLocalizedString(this.docs?.name)?.['zh-hk'];
      },
      set(v) {
        if (typeof this.docs?.name !== 'object')
          this.docs.name = this.parseLocalizedString(this.docs?.name);
        this.docs.name['zh-hk'] = v;
        this.docs.name['zh-cn'] = v;
        this.docs.name = JSON.stringify(this.docs.name);
      },
    },
    localizedNameEN: {
      get() {
        return this.parseLocalizedString(this.docs?.name)?.en;
      },
      set(v) {
        if (typeof this.docs?.name !== 'object')
          this.docs.name = this.parseLocalizedString(this.docs?.name);
        this.docs.name.en = v;
        this.docs.name = JSON.stringify(this.docs.name);
      },
    },
    computedBarcodeList() {
      return this.barcodeList.concat(this.docs.barcode);
    },
  },
  mounted() {
    this.getBarcodeList();
  },
  methods: {
    get,
    parseLocalizedString,
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      if (this.isNew) {
        this.docs = {
          discount: {},
        };
      } else {
        this.docs = await crudRead(api, this.restfulURL, this.id);
        if (typeof this.docs?.name === 'string')
          this.docs.name = this.parseLocalizedString(this.docs.name);
        this.existingBarcode = JSON.parse(JSON.stringify({ barcode: this.docs.barcode }))?.barcode;
        this.localizedName = this.parseLocalizedString(this.docs?.name);
      }
      this.getProductCategories();
      this.getProductSuppliers();
    },
    async getProductCategories() {
      this.productCategories = await listProductCategories(api);
    },
    async getProductSuppliers() {
      this.productSuppliers = await listSuppliers(api);
    },
    async getBarcodeList() {
      this.barcodeList = await getBarcodeList(api);
    },
    async removeFeaturedImage() {
      try {
        try {
          await deleteAttachment(api, this.featuredImageId);
        } catch (e) {
          //no-op
        }
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          featuredImage: null,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async removeOtherImage(index) {
      try {
        try {
          await deleteAttachment(api, this.otherImagesIds[index]);
        } catch (e) {
          //no-op
        }
        this.otherImagesIds.splice(index, 1);
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          images: this.otherImagesIds,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveFeaturedImage() {
      try {
        this.updateFeaturedImageLoading = true;
        const attachment = await uploadSingleAttachment(api, this.newFeaturedImage);
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          featuredImage: attachment._id,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      } finally {
        this.updateFeaturedImageLoading = false;
      }
    },
    async saveOtherImage() {
      try {
        this.updateImagesLoading = true;
        const attachmentIds = await uploadMultipleAttachment(api, this.newOtherImages);
        const newAttachmentIds = this.otherImagesIds.concat(attachmentIds);
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          images: newAttachmentIds,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      } finally {
        this.updateImagesLoading = false;
      }
    },
    async saveLeft() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        this.loading = true;
        if (this.isNew) {
          if (this.newFeaturedImage) {
            const attachmentId = await uploadSingleAttachment(api, this.newFeaturedImage);
            this.docs.featuredImage = attachmentId._id;
          }
          if (this.newOtherImages?.length > 0) {
            const attachmentIds = await uploadMultipleAttachment(api, this.newOtherImages);
            this.docs.images = attachmentIds;
          }
        }
        this.docs.barcode = Array.isArray(this.docs?.barcode)
          ? this.docs?.barcode?.filter(x => x !== null)
          : this.docs.barcode;
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.physicalProducts.create, {
              ...this.docs,
              name: JSON.stringify(this.localizedName),
            })
          : await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
              name: JSON.stringify(this.localizedName),
              category: this.docs.category,
              description: this.docs.description,
              listedPrice: this.docs.listedPrice,
              cost: this.docs.cost,
              discountedPrice: this.docs.discountedPrice,
              stock: this.docs.stock,
              tags: this.docs.tags,
              priority: this.docs.priority,
              remarksOptions: this.docs.remarksOptions,
              supplier: this.docs.supplier,
              nonDiscountable: this.docs.nonDiscountable,
              skipStockCount: this.docs.skipStockCount,
              barcode: this.docs.barcode,
              isActiveForPOS: this.docs.isActiveForPOS,
            });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'physicalProduct',
            params: {
              id: model._id,
            },
          });
        } else {
          this.reload();
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async promptDelete() {
      if (!confirm('Do you confirm to delete?')) return;
      try {
        await crudDelete(api, RESTFUL.physicalProducts.read, this.id);
        //TODO: change endpoint / handle in endpoint hook if products (phase2)
        await this.$router.push({ name: 'physicalProducts' });
        alert('delete success');
      } catch (e) {
        alert('delete fail');
      }
    },
    syncDiscountedPrice(e) {
      this.docs.discountedPrice = Number(e);
    },
    addProductTag(x) {
      if (x) {
        this.docs.tags.push(x);
        return;
      }
      if (!this.search) {
        return;
      } else {
        this.docs.tags.push(this.search);
      }
      this.docs.tags = [...new Set(this.docs.tags)];
    },
    deleteProductTag(index) {
      this.docs.tags.splice(index, 1);
    },
    addBarcode() {
      this.docs.barcode = this.docs.barcode.length === 0 ? [null] : this.docs.barcode;
      this.docs.barcode.push(null);
    },
    deleteBarcode(index) {
      this.docs.barcode.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
