<template>
  <v-row>
    <v-col :cols="12">
      <div class="d-flex justify-space-between align-center">
        <h2>Transaction</h2>
        <h4
          v-if="order.isVoided"
          class="red--text mr-3"
          style="display: block; font-size: 25px; border: 2px solid; text-align: center"
        >
          Voided
        </h4>
        <!-- <v-btn large v-else color="error" class="mr-3" @click="actionVoid">Void</v-btn> -->
      </div>
    </v-col>
    <!-- <v-col :cols="12">
      <v-card>
        <v-card-title class="font-weight-bold">Customer Profile</v-card-title>
        <v-card-title>
          <v-row>
            <v-col :cols="12" :md="6" class="font-weight-bold">
              Order Number: #{{ get(order, 'sid', '-') }}
            </v-col>
            <v-col :cols="12" :md="6"> SID: {{ get(order, 'client.sid', '-') }} </v-col>
            <v-col :cols="12" :md="6"> Name: {{ get(order, 'client.name', '-') }} </v-col>
            <v-col :cols="12" :md="6"> Mobile: {{ get(order, 'client.phone', '-') }} </v-col>
            <v-col :cols="12" :md="6">
              Date: {{ get(order, 'created_at', '-') | moment('YYYY/MM/DD hh:mm') }}
            </v-col>
            <v-col :cols="12" :md="6"> Seller: {{ get(orderStaff, 'name', '-') }} </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col> -->
    <v-col :cols="12">
      <v-card>
        <v-card-title class="font-weight-bold"
          >Order Summary
          <span v-if="order.orderType == 'PreOrder'" class="ml-5 red--text"
            >(Pre Order)</span
          ></v-card-title
        >
        <v-card-title>
          <v-row>
            <v-col :cols="12" :md="6" class="font-weight-bold">
              Order Number: #{{ get(order, 'sid', '-') }}
            </v-col>
            <v-col :cols="12" :md="6">
              Created Date: {{ get(order, 'createdAt', '-') | moment('YYYY/MM/DD hh:mm:ss') }}
            </v-col>
            <v-col :cols="12" :md="6"> Store: {{ get(order, 'store.name', '-') }} </v-col>
            <v-col :cols="12" :md="6" class="d-flex align-baseline" v-if="order">
              Customer:&nbsp;<ProfileNameTag :profile="order.client" />
            </v-col>
            <v-col :cols="12" :md="6" class="d-flex align-baseline">
              Created by:&nbsp;<ProfileNameTag :profile="order.createdBy" />
            </v-col>
            <v-col :cols="12" :md="6">
              <div v-if="order.collectionMethod">
                Collection Method: {{ computedCollectionMethod }}
              </div>
              <div v-if="order.collectionStoreDetail">
                Collection Store: {{ get(order, 'collectionStoreDetail.name', '-') }}
              </div>
            </v-col>
            <v-col v-if="computedRemarks" :cols="12" :md="12" class="d-flex flex-column pt-0">
              <v-divider class="my-3" />
              Remarks: {{ computedRemarks }}
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col :cols="12" v-if="get(order, 'shippingInfo.address.line1', false)">
      <v-card>
        <v-card-title class="font-weight-bold red--text">Delivery Information</v-card-title>
        <v-card-title>
          <v-row>
            <v-col :cols="12" :md="6">
              Contact Name: {{ get(computedShippingInfo, 'name', '-') }}
            </v-col>
            <v-col :cols="12" :md="6">
              Contact Mobile: {{ get(computedShippingInfo, 'phone', '-') }}
            </v-col>
            <v-col :cols="12" :md="6" class="d-flex align-baseline">
              Delivery Address: {{ get(computedShippingInfo, 'address.line1', '-') }}
            </v-col>
            <v-col v-if="computedDeliveryStatus" :cols="12" :md="6" class="d-flex align-baseline">
              Delivery Status: {{ computedDeliveryStatus }}
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col :cols="12">
      <v-card>
        <v-card-title class="font-weight-bold">Payment Information</v-card-title>
        <v-card-title>
          <v-row>
            <v-col :cols="12" :md="6">
              Payment Date:
              {{ get(transaction, 'updatedAt', '-') | moment('YYYY/MM/DD hh:mm:ss') }}
            </v-col>
            <v-col :cols="12" :md="6">
              Payment Method: {{ get(transaction, 'mode', '-') | paymentMethod }}
            </v-col>
            <v-col :cols="12" :md="6">
              Payment Status: {{ get(order, 'status', '-') | status }}
            </v-col>
            <v-col
              v-if="get(order, 'posPaymentDetails.waivedBy')"
              class="d-flex align-baseline"
              :cols="12"
              :md="6"
            >
              Waived By:&nbsp;<ProfileNameTag :profile="get(order, 'posPaymentDetails.waivedBy')" />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col>
    <v-col :cols="12">
      <v-card>
        <v-card-title class="font-weight-bold">Product Information</v-card-title>
        <v-card-title>
          <v-row>
            <v-col :cols="12">
              <template v-for="item in order.items">
                <ProductItemRow :item="item" :product-categories="productCategories" />
                <v-divider class="my-3" />
              </template>
              <template v-for="item in order.vipItems">
                <ProductItemRow :item="item" />
                <v-divider class="my-3" />
              </template>
              <template v-for="item in order.bookingItems">
                <ProductItemRow :item="item" isBooking />
                <v-divider class="my-3" />
              </template>
            </v-col>
            <v-col :cols="12">
              <div class="d-flex justify-space-between">
                <h4>Sub-Total:</h4>
                <h4 class="grey--text text--darken-1">
                  HK$ {{ computedSubTotal | toThousandFilter }}
                </h4>
              </div>
              <!-- <div class="d-flex justify-space-between" v-if="get(order, 'discount.value')">
                <h4>Discount:</h4>
                <h4 class="grey--text text--darken-1">
                  {{
            get(order, 'discount.type') === 'byPercentage'
              ? `${toThousandFilter(get(order, 'discount.value'))}%`
              : `HK$ ${(toThousandFilter(get(order, 'discount.value')))}`,

                  }}
                </h4>
              </div> -->
              <div
                class="d-flex justify-space-between"
                v-if="computedSubTotal != get(order, '_amount', '-')"
              >
                <h4>Discount:</h4>
                <h4 class="grey--text text--darken-1">
                  HK$ {{ computedDiscount | toThousandFilter }}
                </h4>
              </div>
              <div class="d-flex justify-space-between" v-if="get(order, 'serviceCharge.value')">
                <h4>Service Charge:</h4>
                <h4 class="grey--text text--darken-1">
                  {{
            get(order, 'serviceCharge.type') === 'byPercentage'
              ? `${toThousandFilter(get(order, 'serviceCharge.value'))}%`
              : `HK$ ${toThousandFilter(get(order, 'serviceCharge.value'))}`,
                  }}
                </h4>
              </div>
              <div class="d-flex justify-space-between mt-5">
                <h4 class="green-theme">Total:</h4>
                <h4 class="grey--text text--darken-1">
                  <span v-if="computedCreditDeduction !== 'N/A'" class="d-flex justify-end">
                    <VIcon>mdi-alpha-c-circle</VIcon>
                    &nbsp;{{ computedCreditDeduction | toThousandFilter }}
                  </span>
                  <span v-if="get(order, '_amount') !== computedCreditDeduction"
                    >HK$ {{ get(order, '_amount', '-') | toThousandFilter }}</span
                  >
                </h4>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { get } from 'lodash';
import ProductItemRow from '@/components/PhysicalProductOrder/ProductItemRow';
import {
  physicalProductOrderStatusOption,
  physicalProductOrderPaymentStatusOption,
} from '@/data/optionsConstants';
import { paymentOfOthers, roles } from '@/data/constants';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import { attnThumbHref, getThumbnail, toThousandFilter } from '@/services/filters';
import { uploadSingleAttachment } from '@/api/attachment';
import { createPayment } from '@/api/payment';
import moment from 'moment';
import { patchOrder, voidOrder } from '@/api/physicalProductOrder';
import { patchTicketUnShare } from '@/api/ticketPackage';
import PhysicalProductOrder from '@/views/PhysicalProductOrder';
import ProfileNameTag from '@/components/ProfileNameTag.vue';

export default {
  name: 'OrderSummary',
  components: { ProductItemRow, ProfileNameTag },
  props: {
    order: {},
    productCategories: [],
    transaction: {},
  },
  data() {
    return {
      physicalProductOrderStatusOption,
      physicalProductOrderPaymentStatusOption,
      attachmentHref: '',
      attachmentFile: null,
      uploadedAttachmentId: '',
    };
  },
  mounted() {},
  computed: {
    computedPosOrder() {
      return this.order;
    },
    computedRemarks() {
      return this.order?.metadata?.remarks || this.order?.remarks;
    },
    computedSubTotal() {
      if (this.order?.bookingItems?.length) return this.order?.bookingItems?.[0]?.subtotal;
      else if (this.order?.status == 'paid' && this.order?._totalAmount)
        return this.order?._totalAmount;
      return this.order.items?.reduce((acc, obj) => {
        let discountAmount = 0;
        if (obj.discountItem.length > 0) {
          const considerDiscount = obj.discountItem[0];
          if (considerDiscount?.effect?.type == 'fixed')
            discountAmount = Number.parseFloat(considerDiscount.effect.value);
          else if (considerDiscount?.effect?.type == 'percent')
            discountAmount =
              obj.qty *
              obj.discountedPrice *
              (Number.parseFloat(considerDiscount.effect.value) / 100);
        }
        return (
          acc +
          Number((parseFloat(obj.discountedPrice) * parseInt(obj.qty) - discountAmount).toFixed(2))
        );
      }, 0);
    },
    hasAttachment() {
      return !!this.attachmentHref;
    },
    hasUploadedAttachment() {
      return Boolean(this.uploadedAttachmentId);
    },
    computedCreditDeduction() {
      return this.order?.creditDeduction ? this.order.creditDeduction : 'N/A';
    },
    computedExpiredTime() {
      return this.order ? moment(this.order.created_at).add(2, 'd') : null;
    },
    showTransactionReminder() {
      // don't show for cash and octopusCard
      return !(
        [paymentOfOthers.cash, paymentOfOthers.octopusCard].includes(this.order.paymentMethod) &&
        this.order?.payment
      );
    },
    // orderStaff() {
    //   return this.order?.created_by?.role !== roles.client ? this.order?.created_by : null;
    // },
    computedDeliveryStatus() {
      const deliveryStatus = {
        '0$pending': 'Pending',
        '5$shipped': 'Completed',
        '10$in_transit': 'In Transit',
        '98$delivered': 'Delivered',
        '99$completed': 'Completed',
      };

      return deliveryStatus[this.computedPosOrder?.shippingInfo?.deliveryStatus];
    },
    computedShippingInfo() {
      return this.computedPosOrder?.shippingInfo || this.order?.shippingInfo;
    },
    computedDiscount() {
      if (this.order?.bookingItems?.length) return this.order?.bookingItems?.[0]?.discountAmount;
      else if (this.order?._discount && this.order?.status == 'paid') {
        return this.order?._discount;
      }
      const subTotal = this.order.items?.reduce((acc, obj) => {
        let discountAmount = 0;
        if (obj.discountItem.length > 0) {
          const considerDiscount = obj.discountItem[0];
          if (considerDiscount?.effect?.type == 'fixed')
            discountAmount = Number.parseFloat(considerDiscount.effect.value);
          else if (considerDiscount?.effect?.type == 'percent')
            discountAmount =
              obj.qty *
              obj.discountedPrice *
              (Number.parseFloat(considerDiscount.effect.value) / 100);
        }
        return acc + parseFloat(obj.discountedPrice) * parseInt(obj.qty) - discountAmount;
      }, 0);
      return Number((subTotal - this.order?._amount).toFixed(2));
    },
    computedCollectionMethod() {
      const collectionMethodString = this.computedPosOrder.collectionMethod;
      let formattedString = '-';
      if (collectionMethodString)
        formattedString = collectionMethodString
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');

      return formattedString;
    },
  },
  watch: {
    order: {
      handler(value) {
        this.reload();
      },
      immediate: true,
    },
  },
  methods: {
    toThousandFilter,
    async reload() {
      // this.uploadedAttachmentId = this.order?.payment?.gateway_result?.clientInfo?.attachments?.[0];
      // if (this.uploadedAttachmentId)
      //   this.attachmentHref = getThumbnail(api, this.uploadedAttachmentId, 512);
    },
    get,
    attnThumbHref,
    //TODO: populate category
    async getProductCategories() {
      this.productCategories = await listProductCategories(api);
    },
    getCategoryNameById(categoryId) {
      return (
        this.productCategories.find(el => {
          return el._id === categoryId;
        })?.title || '-'
      );
    },
    addDepositAttachment(e) {
      this.attachmentFile = e;
      const url = URL.createObjectURL(this.attachmentFile);
      this.attachmentHref = url;
    },
    removeImage() {
      this.attachmentFile = {};
      this.attachmentHref = '';
    },
    async save() {
      try {
        if (this.hasAttachment && this.attachmentFile) {
          this.uploadedAttachmentId = (await uploadSingleAttachment(api, this.attachmentFile))._id;
          const payload = {
            gateway: 'manual',
            attachments: [this.uploadedAttachmentId],
          };
          await createPayment(api, this.order._id, payload);
        }
        const order = await patchOrder(api, this.order._id, this.order);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Order Updated',
          type: 'success',
          color: 'success',
        });
      } catch (err) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: err.message,
          type: 'error',
          color: 'error',
        });
      }
    },
    actionVoid() {
      this.$root.$confirm
        .open('Confirm', 'Confirm to void this order?', 'Confirm')
        .then(async confirm => {
          if (confirm) {
            try {
              await voidOrder(api, this.order._id);
              await this.$store.dispatch('alert/updateAlertMessage', {
                msg: 'Order Voided',
                type: 'success',
                color: 'success',
              });
              await this.$router.push({ name: 'transactions' });
            } catch (err) {
              await this.$store.dispatch('alert/updateAlertMessage', {
                msg: err,
              });
            }
          } else {
            return;
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.green-theme {
  color: var(--v-primary-base);
}
.order-status-input ::v-deep .v-text-field__details {
  display: none;
}
.payment-status-input {
  flex: none;
}
.v-file-input {
  margin-top: 108px !important;
  margin-left: 9px !important;
}
</style>
