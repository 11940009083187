/**
 * @param {AxiosInstance} api
 * @param {string} [search]
 * @return {Promise<string[]>}
 */
import { merge } from 'lodash';

export async function listSuppliers(api, options) {
  return (await api.get('toca/suppliers', { params: merge({ limit: 100 }, options) })).data.docs;
}
