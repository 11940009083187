<template>
  <div v-if="created_by" class="py-3">
    <div class="mb-5 d-flex">
      <div class="align-self-center" style="min-width: 160px"><b>Customer labels</b></div>
      <v-spacer />
      <v-btn small @click="goToProfile">View Profile</v-btn>
    </div>
    <v-row>
      <v-col cols="2"><b>SID</b></v-col>
      <v-col cols="4">{{ get(created_by, 'sid', '-') }}</v-col>
      <v-col cols="2"><b>Profile Icon</b></v-col>
      <v-col cols="4">
        <div v-if="image">
          <v-img :src="image" max-height="150" max-width="200" />
        </div>
        <div v-else>
          <v-avatar color="indigo" tile>
            <v-icon dark> mdi-account-circle </v-icon>
          </v-avatar>
        </div>
      </v-col>
      <v-col class="mb-5" cols="2"><b>Name</b></v-col>
      <v-col class="mb-5" cols="4">{{ get(created_by, 'name', '-') }}</v-col>
      <v-col class="mb-5" cols="2"><b>Status</b></v-col>
      <v-col class="mb-5" cols="4">{{ get(created_by, 'status', '-') | status }}</v-col>
      <v-col class="mb-5" cols="2"><b>Mobile No.</b></v-col>
      <v-col class="mb-5" cols="4">{{ get(created_by, 'phone', '-') }}</v-col>
      <v-col class="mb-5" cols="6" />
      <v-col class="mb-5" cols="2"><b>Refer by:</b></v-col>
      <v-col class="mb-5" cols="4"
        ><span class="mr-2">{{ get(created_by, 'howToKnowBN', 'N/A') }}</span>
        <span>
          {{
            created_by.howToKnowBN === 'referred by'
              ? `${get(created_by, 'howToKnowBNName', '-')}
          ${get(created_by, 'howToKnowBNPhone', '-')}`
              : ''
          }}</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card elevation="0" outlined>
          <div style="padding: 15px">
            <v-row>
              <v-col cols="2"><b>Customer Labels</b></v-col>
              <v-col class="d-flex align-start flex-wrap" cols="10">
                <v-chip
                  v-for="(item, index) in userLabels"
                  :key="index"
                  class="mr-5 mb-5"
                  style="font-weight: bold"
                  color="#96734B"
                  label
                  outlined
                >
                  {{ item }}
                </v-chip>
                <v-icon color="primary" @click="remarksPopup = true">
                  mdi-plus-circle-outline
                </v-icon>
                <remarksDialog
                  v-model="remarksPopup"
                  :user-labels="userLabels"
                  :docs="docs"
                  @saveLabel="x => saveCustomerLabels(x)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="2"><b>Discount Labels:</b></v-col>
              <v-col class="d-flex align-start flex-wrap" cols="10">
                <v-chip
                  v-for="(item, index) in userDiscountLabels"
                  :key="index"
                  class="mr-5 mb-5"
                  color="#96734B"
                  label
                  outlined
                  style="font-weight: bold"
                >
                  {{ item }}
                </v-chip>
                <v-icon color="primary" @click="discountLabelsPopup = true">
                  mdi-plus-circle-outline
                </v-icon>
                <remarksDialog
                  v-model="discountLabelsPopup"
                  :discountLabels="discountLabels"
                  :userDiscountLabels="userDiscountLabels"
                  :show-discount-labels="true"
                  @saveDiscountLabel="x => saveCustomerDiscountLabels(x)"
                />
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto"> <b>Watched toca 3minutes Introduction</b> </v-col>
      <!--TODO kenobi check-->
      <v-col>
        <v-simple-checkbox
          :value="watchedBN3minIntroduction"
          :ripple="false"
          @input="
            v => {
              patchWatchedBN3minIntroduction(v);
            }
          "
        />
      </v-col>
    </v-row>
    <!--    <v-row>-->
    <!--      <v-col cols="auto"> Previous Hardcopy </v-col>-->
    <!--      <div class="d-flex flex-wrap">-->
    <!--        <template v-for="att of created_by.previousHardCopy">-->
    <!--          <v-sheet :key="att._id" class="px-3 py-1">-->
    <!--            <a :href="attnDownloadHref(att)" target="_blank">-->
    <!--              <v-img :src="attnThumbHref(att, 128)" max-height="128px" max-width="128px" />-->
    <!--            </a>-->
    <!--          </v-sheet>-->
    <!--        </template>-->
    <!--      </div>-->
    <!--    </v-row>-->
  </div>
</template>

<script>
import { get, set } from 'lodash';
import remarksDialog from '@/components/Identity/remarksDialog';
import { attnDownloadHref, attnThumbHref } from '@/services/filters';
import { updateClientDiscountLabels, updateClientLabels } from '@/api/auth/membership';
import api from '@/api';
import { crudUpdate } from '@/api/crud';
import { getOid } from '@/services/utils';

export default {
  name: 'customerProfile',
  components: { remarksDialog },
  props: {
    created_by: {},
    docs: {},
  },
  data() {
    return {
      remarksPopup: false,
      discountLabelsPopup: false,
      discountLabels: [],
      showDiscountLabels: [],
    };
  },
  computed: {
    image() {
      return this.created_by?.avatar ? attnThumbHref(this.created_by.avatar) : null;
    },
    userLabels: {
      get() {
        return get(this.created_by, 'metadata.labels', []);
      },
      set(v) {
        set(this.created_by, 'metadata.labels', v);
      },
    },
    userDiscountLabels: {
      get() {
        return get(this.created_by, 'metadata.discountLabels', []);
      },
      set(v) {
        set(this.created_by, 'metadata.discountLabels', v);
      },
    },

    watchedBN3minIntroduction: {
      get() {
        return get(this.created_by, 'watchedBN3minIntroduction');
      },
      set(v) {
        set(this.created_by, 'watchedBN3minIntroduction', v);
      },
    },
    id() {
      return getOid(this.created_by);
    },
  },
  methods: {
    get,
    attnDownloadHref,
    attnThumbHref,
    goToProfile() {
      // await this.$router.push({ name: 'Profile', params: { id: getOid(this.created_by) } });
      window.open(window.location.origin + '/admin/profiles/' + getOid(this.created_by));
    },
    async patchWatchedBN3minIntroduction(v) {
      try {
        const res = await crudUpdate(api, 'toca/admin/ClientProfiles', this.id, {
          watchedBN3minIntroduction: v,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.created_by.watchedBN3minIntroduction = res.watchedBN3minIntroduction;
        this.$emit('update:created_by', this.created_by);
      } catch (e) {}
    },
    async saveCustomerLabels(item) {
      try {
        await updateClientLabels(api, this.id, item);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.userLabels = item;
        this.$emit('update:created_by', this.created_by);
        this.remarksPopup = false;
      } catch (e) {}
    },

    async saveCustomerDiscountLabels(item) {
      try {
        await updateClientDiscountLabels(api, this.id, item);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.userDiscountLabels = item;
        this.$emit('update:created_by', this.created_by);
        this.discountLabelsPopup = false;
      } catch (e) {}
    },

    //^^^^^^^^^^^^^^^^^^^^
  },
};
</script>

<style scoped></style>
