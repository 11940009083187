<template>
  <v-dialog
    v-model="valueProxy"
    width="95%"
    max-width="1200px"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card min-height="250">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px"> {{ 'Physical Product Order' }} </span>
          <v-spacer />
          <v-btn icon @click="valueProxy = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div style="overflow-y: auto; overflow-x: hidden" class="pa-3">
            <div class="pb-3">
              <h3>Order items</h3>
            </div>
            <ProductOrderItems :categories="productCategories" v-model="selectedItems" />
            <!-- <template v-for="item in selectedItems">
              <CartItemRow :key="item._id" :item="item" />
            </template> -->
            <div class="green-theme d-flex justify-end mt-3 mb-2">
              <div>Sub-Total: {{ computedSubTotal | currency }}</div>
            </div>
            <v-divider class="mb-2" />
            <h3>Contact Information</h3>
            <ValidationProvider v-slot="{ errors }" name="Customer" rules="required">
              <autocomplete
                label="Customer"
                :path="computedCustomerProfileUrl"
                :params="{ sort: { created_at: -1 } }"
                item-text="name"
                item-value="_id"
                v-model="profile"
                :error-messages="errors"
                :clearable="true"
                :has-items-slot="true"
                :return-object="true"
                ref="autoComplete"
              >
                <template v-slot:items="content">
                  <span>{{
                    get(content, 'item.name', '') +
                    ' ' +
                    displayPhone(get(content, 'item.phone', ''))
                  }}</span>
                </template>
              </autocomplete>
            </ValidationProvider>
            <div v-if="!profile">
              <v-btn @click="createUser = true">Add New Customer</v-btn>
            </div>
            <IdentityEditDialog
              v-model="createUser"
              @create="updateProfile"
              max-width="600"
            ></IdentityEditDialog>
            <v-divider class="my-2" />
            <div>
              <h3>Seller</h3>
              <autocomplete
                label="Facility"
                :path="computedProfileUrl"
                :params="{
                  sort: { created_at: -1 },
                  filter: { role: { $ne: 'toca.client' }, status: '1$accepted' },
                }"
                item-text="name"
                item-value="_id"
                v-model="agent"
                :clearable="true"
                :has-items-slot="true"
                :return-object="true"
                :disabled="!isCreate && !isAdmin && !isCashier"
                ref="autoComplete"
              >
                <template v-slot:items="content">
                  <span>{{
                    get(content, 'item.name', '') +
                    ' ' +
                    displayPhone(get(content, 'item.phone', ''))
                  }}</span>
                </template>
              </autocomplete>
            </div>
            <v-divider class="my-2" />
            <v-form v-model="valid">
              <!-- <v-checkbox
                v-if="profile"
                v-model="syncProfile"
                color="primary"
                label="Same as User Profile"
                @change="syncUserProfile"
              ></v-checkbox> -->
              <div>
                <h3>Payment</h3>
                <ValidationProvider v-slot="{ errors }" name="Payment Option" rules="required">
                  <PaymentMethodSelection
                    class="mb-4"
                    v-model="model.paymentMethod"
                    :error-messages="errors"
                  />

                  <!-- <v-radio-group :error-messages="errors" v-model="model.paymentMethod" column>
                    <v-radio label="Cash" color="primary" value="cash"></v-radio>
                    <v-radio color="primary" value="payme">
                      <template v-slot:label>
                        <div class="d-flex">
                          <div>PayMe</div>
                          <div class="green-theme">(PayMe no. +852 93602768)</div>
                        </div>
                      </template>
                    </v-radio>
                    <v-radio label="Bank Transfer/ATM" color="primary" value="bank"></v-radio>
                  </v-radio-group> -->
                </ValidationProvider>
              </div>
              <v-divider />
              <div class="d-flex justify-space-between">
                <h4>Sub-Total:</h4>
                <div>HK{{ computedSubTotal | currency }}</div>
              </div>
              <!-- <div class="d-flex justify-space-between">
                <h4>Delivery Fees</h4>
                <div>HK${{ computedDeliveryFee }}</div>
              </div> -->
              <div v-if="profile">
                <v-checkbox
                  v-model="enableCreditDeduction"
                  color="primary"
                  label="Credit Deduction (1Credit = $1)"
                ></v-checkbox>
                <div class="d-flex">
                  <!-- <img
                    src="@/assets/icons/ico_credit_plant.svg"
                    style="width: 22px; height: 28px; margin-right: 10px"
                    alt=""
                  /> -->
                  <h4 class="green-theme mr-3">{{ profile.creditBalance || 0 }}</h4>
                  <v-text-field
                    v-model.number="model.creditBalanceDeduction"
                    dense
                    outlined
                    :disabled="!enableCreditDeduction"
                    style="max-width: 150px"
                    label="Please fill in"
                  ></v-text-field>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <h2>Total</h2>
                <h2 class="primary--text">HK{{ computedTotal | currency }}</h2>
              </div>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="doClose()">Close</v-btn>
          <v-btn color="primary" @click="promptCreate()" :disabled="invalid" v-if="isCreate">
            Create
          </v-btn>
          <!-- <v-btn color="primary" @click="promptUpdate()" :disabled="invalid" v-if="!isCreate">
            Update
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import ProductOrderItems from './ProductOrderItems';
import CustomLabel from '@/components/customFormLabel/customLabel';
import editDialogMixin from '@/services/editDialogMixin';
import { uploadMultipleAttachment, uploadSingleAttachment } from '@/api/attachment';
import api from '@/api';
import { submitOrder } from '@/api/physicalProductOrder';
import { crudCreate } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import ApiError from '@/api/ApiError';
import { displayPhone } from '@/services/filters';
import { get } from 'lodash';
import { IClientProfile } from '@/api/auth/membership';
import { getOid, oidEquals } from '@/services/utils';
import IdentityEditDialog from '@/components/Identity/IdentityEditDialog';
import PaymentMethodSelection from '@/components/PaymentMethodSelection.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductOrderEditDialog',
  components: {
    CustomLabel,
    ProductOrderItems,
    Autocomplete,
    IdentityEditDialog,
    PaymentMethodSelection,
  },
  mixins: [editDialogMixin],
  props: {
    title: {},
    productCategories: [],
  },
  data() {
    return {
      model: {},
      profile: null, // autocomplete to select a customer , or create a new customer
      selectedItems: [], //syn with product selector
      valid: false,
      enableCreditDeduction: false,
      createUser: false,
      agent: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'isCashier', 'isMaster']),
    computedSubTotal() {
      //   let total = 0;
      //   for (const item of this.selectedItems) {
      //     if (item.physicalProduct?.discountedPrice) {
      //       total += item?.physicalProduct?.discountedPrice * item.physicalProductQuantity;
      //     } else if (item.physicalProduct?.listedPrice) {
      //       total += item?.physicalProduct?.listedPrice * item.physicalProductQuantity;
      //     }
      //   }
      //   return total;
      let total = 0;
      this.selectedItems.map(el => {
        el.discountedPrice
          ? (total += el.discountedPrice * el.selectedQty)
          : (total += el.listedPrice * el.selectedQty);
      });
      return total;
    },
    computedTotal() {
      let total = this.computedSubTotal; //+ this.computedDeliveryFee;
      if (
        this.enableCreditDeduction &&
        this.model.creditBalanceDeduction <= this.profile.creditBalance &&
        this.model.creditBalanceDeduction > 0
      ) {
        total -= this.model.creditBalanceDeduction;
      }
      return total;
    },
    computedCustomerProfileUrl() {
      return this.isMaster ? 'toca/master/ClientProfiles' : 'toca/admin/ClientProfiles';
    },
    computedProfileUrl() {
      return this.isMaster ? 'toca/master/Profiles' : 'toca/admin/Profiles';
    },
  },
  watch: {
    profile(val) {
      if (!val) {
        this.enableCreditDeduction = false;
      }
    },
  },
  mounted() {
    this.reload();
    if (this.isMaster) {
      this.agent = this.$store.getters['auth/profile'];
    }
  },
  methods: {
    get,
    displayPhone,
    async reload() {
      this.model = {};
      this.selectedItems = [];
      this.profile = null;
    },
    createPayload() {
      // TODO: handle no delivery details
      // let addresses;
      // if (this.model.deliveryOption === 'storePickup') {
      //   addresses = null;
      // } else {
      //   addresses = {
      //     line1: this.model?.deliveryDetails?.line1,
      //     district: this.model?.deliveryDetails?.district,
      //   };
      // }
      //   const items = this.selectedItems.map(el => {
      //     return {
      //       product: el.physicalProduct._id,
      //       qty: el.physicalProductQuantity,
      //       subtotal: el.subTotal,
      //       cart: el._id,
      //     };
      //   });
      const items = this.selectedItems.map(el => {
        const amount = el.discountedPrice ? el.discountedPrice : el.listedPrice;
        return {
          product: el._id,
          qty: el.selectedQty,
          subtotal: Number((amount * el.selectedQty).toFixed(2)),
          commission: {
            to: this.agent?._id,
          },
          //cart: el._id,
        };
      });
      const order = {
        items: items,
        commit: true,
        paymentMethod: this.model.paymentMethod,
        clientId: getOid(this.profile),
        contactName: this.profile.name, //this.model.name,
        contactPhone: this.profile.phone, //this.model.phone,
        // Assume only will storePickup
        shippingInfo: {
          address: null,
          carrier: 'storePickup', //this.model.deliveryOption'',
          //name: this.model?.deliveryDetails?.name,
          //phone: this.model?.deliveryDetails?.phone,
          deliveryFee: 0, //this.computedDeliveryFee,
        },
        creditDeduction: this.enableCreditDeduction ? this.model.creditBalanceDeduction : null,
      };
      return order;
    },
    async promptCreate() {
      try {
        if (await this.$refs.form.validate()) {
          // if (!this.profile) {
          //   await this.register();
          // }
          const payload = this.createPayload();
          const order = await submitOrder(api, payload);
          if (order?.PhysicalProductOrder?.paymentMethod === 'stripe') {
            //TODO TOCONFIRM
          } else if (order.commit) {
            //   await this.$router.push({
            //     path: `/orderConfirm/manual/${order.PhysicalProductOrder._id}`,
            //   });
            this.valueProxy = false;
            this.$emit('create');
          }
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Order has been submitted',
            type: 'success',
            color: 'successLight',
          });
        }
      } catch (err) {
        if (err.code === 'invalid_params' && this.model.creditBalanceDeduction)
          this.model.creditBalanceDeduction = 0;
        this.handleError(err);
      }
    },
    updateProfile() {
      this.createUser = false;
      this.$refs.autoComplete.reload();
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
  },
};
</script>

<style scoped></style>
