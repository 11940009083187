var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('h2',[_vm._v("Service Product Details")])]),_c('v-divider',{staticClass:"mb-5"}),(_vm.docs)?_c('div',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{attrs:{"disabled":invalid || _vm.isMaster,"color":"primary"},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1),_c('v-form',[_c('v-row',{staticClass:"mr-3 ml-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Sid"}}),_c('ValidationProvider',{attrs:{"name":"sid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Service Price","error":!!errors.length,"error-messages":errors},model:{value:(_vm.docs.sid),callback:function ($$v) {_vm.$set(_vm.docs, "sid", $$v)},expression:"docs.sid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Enabled"}}),_c('ValidationProvider',{attrs:{"name":"enabled"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-0 pt-0 ml-3",attrs:{"hide-details":""},model:{value:(_vm.docs.enabled),callback:function ($$v) {_vm.$set(_vm.docs, "enabled", $$v)},expression:"docs.enabled"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name(CN)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.localizedName['zh-cn'],"error-messages":errors,"flat":"","label":"Name(CN)","outlined":""},on:{"input":function($event){_vm.localizedName = Object.assign({}, _vm.localizedName, {'zh-cn': $event})}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name(EN)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.localizedName.en,"error-messages":errors,"flat":"","label":"Name(EN)","outlined":""},on:{"input":function($event){_vm.localizedName = Object.assign({}, _vm.localizedName, {en: $event})}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Name(HK)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"value":_vm.localizedName['zh-hk'],"error-messages":errors,"flat":"","label":"Name(HK)","outlined":""},on:{"input":function($event){_vm.localizedName = Object.assign({}, _vm.localizedName, {'zh-hk': $event})}}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Listed Price"}}),_c('ValidationProvider',{attrs:{"name":"listedPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Listed Price","error":!!errors.length,"error-messages":errors,"type":"number"},model:{value:(_vm.docs.listedPrice),callback:function ($$v) {_vm.$set(_vm.docs, "listedPrice", $$v)},expression:"docs.listedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Description"}}),_c('ValidationProvider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Description","error":!!errors.length,"error-messages":errors},model:{value:(_vm.docs.description),callback:function ($$v) {_vm.$set(_vm.docs, "description", $$v)},expression:"docs.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Discounted Price"}}),_c('ValidationProvider',{attrs:{"name":"discountedPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Discounted Price","error":!!errors.length,"error-messages":errors,"type":"number"},model:{value:(_vm.docs.discountedPrice),callback:function ($$v) {_vm.$set(_vm.docs, "discountedPrice", $$v)},expression:"docs.discountedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Featured Image"}}),_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Featured Image Upload","outlined":"","accept":"image/jpeg,image/png"},model:{value:(_vm.newFeaturedImage),callback:function ($$v) {_vm.newFeaturedImage=$$v},expression:"newFeaturedImage"}}),(_vm.featuredImage)?_c('span',[_c('h3',[_vm._v("Featured Image")]),_c('v-sheet',{staticClass:"px-3 py-1"},[_c('v-img',{attrs:{"src":_vm.attnThumbHref(_vm.featuredImage, 128),"max-height":"128px","max-width":"128px"}}),_c('a',{attrs:{"href":_vm.attnDownloadHref(_vm.featuredImage),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.featuredImage.filename)+" ("+_vm._s(_vm._f("byte")(_vm.featuredImage.length))+") ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removePreviousFeaturedImage(_vm.featuredImage)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Image"}}),_c('ValidationProvider',{attrs:{"name":"Images","rules":_vm.imageRule},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Image Upload","outlined":"","accept":"image/jpeg,image/png","error":!!errors.length,"error-messages":errors},model:{value:(_vm.newImage),callback:function ($$v) {_vm.newImage=$$v},expression:"newImage"}})]}}],null,true)}),_vm._l((_vm.images),function(att){return _c('span',{key:("i-" + (att._id))},[_c('h3',[_vm._v("Image")]),_c('v-sheet',{key:att._id,staticClass:"px-3 py-1"},[_c('v-img',{attrs:{"src":_vm.attnThumbHref(att, 128),"max-height":"128px","max-width":"128px"}}),_c('a',{attrs:{"href":_vm.attnDownloadHref(att),"target":"_blank"}},[_vm._v(" "+_vm._s(att.filename)+" ("+_vm._s(_vm._f("byte")(att.length))+") ")]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removePreviousImage(att)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)],1)],1)})],2),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Session Type"}}),_c('ValidationProvider',{attrs:{"name":"sessionType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"Session Type","items":_vm.sessionTypeOptions,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.docs.sessionType),callback:function ($$v) {_vm.$set(_vm.docs, "sessionType", $$v)},expression:"docs.sessionType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Game Type"}}),_c('ValidationProvider',{attrs:{"name":"gameType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"Game Type","items":_vm.gameTypeOptions,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.docs.gameType),callback:function ($$v) {_vm.$set(_vm.docs, "gameType", $$v)},expression:"docs.gameType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Facility"}}),_c('ValidationProvider',{attrs:{"name":"Facility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"path":_vm.computedAutoCompleteEndpoint,"params":{ filter: { status: '1$accepted', isFacility: true } },"clearable":"","item-text":"name","item-value":"_id","label":"Facility","error-messages":errors},model:{value:(_vm.computedFacility),callback:function ($$v) {_vm.computedFacility=$$v},expression:"computedFacility"}})]}}],null,true)})],1)],1)],1)]}}],null,false,2533070552)})],1):_c('div',{staticClass:"d-flex justify-center",staticStyle:{"padding-top":"20%"}},[_c('h2',[_vm._v("No Record to found")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }