var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"ma-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Save' : 'Update')+" ")])],1)],1),_c('div',{attrs:{"id":"title"}},[_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(_vm.model['titleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'titleZh-HK', $$v)},expression:"model['titleZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(_vm.model.titleZh),callback:function ($$v) {_vm.$set(_vm.model, "titleZh", $$v)},expression:"model.titleZh"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(_vm.model.titleEn),callback:function ($$v) {_vm.$set(_vm.model, "titleEn", $$v)},expression:"model.titleEn"}})]}}],null,true)}),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}})],1),_c('div',{attrs:{"id":"content"}},[_c('ValidationProvider',{attrs:{"name":"內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"內容","outlined":""},model:{value:(_vm.model['contentZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'contentZh-HK', $$v)},expression:"model['contentZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"内容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"内容","outlined":""},model:{value:(_vm.model.contentZh),callback:function ($$v) {_vm.$set(_vm.model, "contentZh", $$v)},expression:"model.contentZh"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Content","outlined":""},model:{value:(_vm.model.contentEn),callback:function ($$v) {_vm.$set(_vm.model, "contentEn", $$v)},expression:"model.contentEn"}})]}}],null,true)}),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}})],1),_c('div',{attrs:{"id":"package"}},_vm._l((_vm.model.VIPPackage),function(packageItem,index){return _c('div',{key:index},[_c('h3',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Package "+_vm._s(index + 1))]),_c('div',{attrs:{"id":"VIPPackage"}},[_c('ValidationProvider',{attrs:{"name":"VIPPackage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"path":'toca/admin/VIPProducts',"item-text":"name","item-value":function (item) { return item; },"label":"VIP Package","clearable":true,"error-messages":errors,"has-items-slot":true},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.getVIPPackageInfo(content.item)))])]}}],null,true),model:{value:(packageItem.VIPPackage),callback:function ($$v) {_vm.$set(packageItem, "VIPPackage", $$v)},expression:"packageItem.VIPPackage"}})]}}],null,true)})],1),_c('div',{attrs:{"id":"packageIconBackgroundColor"}},[_c('v-row',[_c('v-col',{attrs:{"col":"6"}},[_c('ValidationProvider',{attrs:{"name":"VIPIcon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIPIcon","outlined":""},model:{value:(packageItem.icon),callback:function ($$v) {_vm.$set(packageItem, "icon", $$v)},expression:"packageItem.icon"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"6"}},[_c('ValidationProvider',{attrs:{"name":"VIPBackgroundColor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Background Color","outlined":""},model:{value:(packageItem.bgColor),callback:function ($$v) {_vm.$set(packageItem, "bgColor", $$v)},expression:"packageItem.bgColor"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"packageTitle"}},[_c('v-row',[_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP標題","outlined":""},model:{value:(packageItem['titleZh-HK']),callback:function ($$v) {_vm.$set(packageItem, 'titleZh-HK', $$v)},expression:"packageItem['titleZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP标题","outlined":""},model:{value:(packageItem.titleZh),callback:function ($$v) {_vm.$set(packageItem, "titleZh", $$v)},expression:"packageItem.titleZh"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Title","outlined":""},model:{value:(packageItem.titleEn),callback:function ($$v) {_vm.$set(packageItem, "titleEn", $$v)},expression:"packageItem.titleEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"packagePrice"}},[_c('v-row',[_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP價格","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP價格","outlined":""},model:{value:(packageItem['priceZh-HK']),callback:function ($$v) {_vm.$set(packageItem, 'priceZh-HK', $$v)},expression:"packageItem['priceZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP价格","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP价格","outlined":""},model:{value:(packageItem.priceZh),callback:function ($$v) {_vm.$set(packageItem, "priceZh", $$v)},expression:"packageItem.priceZh"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIPPrice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Price","outlined":""},model:{value:(packageItem.priceEn),callback:function ($$v) {_vm.$set(packageItem, "priceEn", $$v)},expression:"packageItem.priceEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"packageButtonText"}},[_c('v-row',[_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP按鈕文字","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP按鈕文字","outlined":""},model:{value:(packageItem['buttonTextZh-HK']),callback:function ($$v) {_vm.$set(packageItem, 'buttonTextZh-HK', $$v)},expression:"packageItem['buttonTextZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIP按钮文字","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP按钮文字","outlined":""},model:{value:(packageItem.buttonTextZh),callback:function ($$v) {_vm.$set(packageItem, "buttonTextZh", $$v)},expression:"packageItem.buttonTextZh"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"VIPButtonText","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Button Text","outlined":""},model:{value:(packageItem.buttonTextEn),callback:function ($$v) {_vm.$set(packageItem, "buttonTextEn", $$v)},expression:"packageItem.buttonTextEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"packageButtonSetting"}},[_c('v-row',[_c('v-col',{attrs:{"col":"6"}},[_c('ValidationProvider',{attrs:{"name":"VIPButtonLink","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Button Link","outlined":""},model:{value:(packageItem.buttonLink),callback:function ($$v) {_vm.$set(packageItem, "buttonLink", $$v)},expression:"packageItem.buttonLink"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"6"}},[_c('ValidationProvider',{attrs:{"name":"VIPButtonColor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"VIP Button Color","outlined":""},model:{value:(packageItem.buttonColor),callback:function ($$v) {_vm.$set(packageItem, "buttonColor", $$v)},expression:"packageItem.buttonColor"}})]}}],null,true)})],1)],1)],1),_c('div',[_c('v-row',[_c('v-col',{attrs:{"col":"6"}},[_c('ValidationProvider',{attrs:{"name":"BackgroundColorLight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Background Color Light","outlined":""},model:{value:(packageItem.bgColorLight),callback:function ($$v) {_vm.$set(packageItem, "bgColorLight", $$v)},expression:"packageItem.bgColorLight"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"6"}},[_c('ValidationProvider',{attrs:{"name":"fontColorLight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Font Color Light","outlined":""},model:{value:(packageItem.fontColorLight),callback:function ($$v) {_vm.$set(packageItem, "fontColorLight", $$v)},expression:"packageItem.fontColorLight"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"packageFeatureText"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('h4',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"VIP詳情","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(packageItem['featuresTextZh-HK']),callback:function ($$v) {_vm.$set(packageItem, 'featuresTextZh-HK', $$v)},expression:"packageItem['featuresTextZh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('h4',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"VIP详情","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(packageItem.featuresTextZh),callback:function ($$v) {_vm.$set(packageItem, "featuresTextZh", $$v)},expression:"packageItem.featuresTextZh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('h4',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"VIPFeature","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(packageItem.featuresTextEn),callback:function ($$v) {_vm.$set(packageItem, "featuresTextEn", $$v)},expression:"packageItem.featuresTextEn"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-divider',{staticStyle:{"margin-top":"30px","margin-bottom":"20px"}})],1)])}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }