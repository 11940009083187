<template>
  <div class="pdf-container" v-resize="debounceToOnResize">
    <div class="app-header">
      <template v-if="isLoading"> Loading... </template>

      <template v-else>
        <span v-if="showAllPages"> {{ pageCount }} page(s) </span>

        <span v-else>
          <button :disabled="page <= 1" @click="page--">❮</button>

          {{ page }} / {{ pageCount }}

          <button :disabled="page >= pageCount" @click="page++">❯</button>
        </span>
        <!-- <label class="right">
          <input v-model="showAllPages" type="checkbox" />
          Show all pages
        </label> -->
      </template>
    </div>

    <div class="app-content" :style="{ opacity: isLoading ? 0 : 1 }">
      <vue-pdf-embed
        ref="pdfRef"
        :source="source"
        :page="page"
        @password-requested="handlePasswordRequest"
        @rendered="handleDocumentRender"
      />
    </div>
  </div>
</template>

<script>
// For Vue2 ,
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
// For Vue3: import VuePdfEmbed from 'vue-pdf-embed'

import { debounce } from 'lodash';
export default {
  components: {
    VuePdfEmbed,
  },
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      page: null,
      pageCount: 1,
      showAllPages: true,
      debounceToOnResize: debounce(this.onResize, 500),
    };
  },
  watch: {
    showAllPages() {
      this.page = this.showAllPages ? null : 1;
    },
  },
  methods: {
    handleDocumentRender(args) {
      this.isLoading = false;
      this.pageCount = this.$refs.pdfRef.pageCount;
    },
    handlePasswordRequest(callback, retry) {
      callback(prompt(retry ? 'Enter password again' : 'Enter password'));
    },
    print() {
      this.$refs.pdfRef.print();
    },
    onResize() {
      this.$refs.pdfRef.render();
    },
  },
};
</script>
<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
<style lang="scss" scoped>
.pdf-container {
  .app-header {
    padding: 16px;
    font-size: 24px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    background-color: #555;
    color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-content {
    background-color: #ddd;
    padding: 24px 16px;
    transition: 0.2s;
  }

  .right {
    float: right;
  }
}
</style>
