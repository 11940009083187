var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',[(_vm.list.length > 0)?_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.list,"hide-default-footer":""},on:{"reload":_vm.reload},scopedSlots:_vm._u([{key:"item.other",fn:function(ref){
var item = ref.item;
return [(_vm.shared)?_c('div',[(item.created_by && item.created_by.name)?_c('a',{on:{"click":function($event){return _vm.viewProfile(item.created_by)}}},[_vm._v(" By "+_vm._s(_vm.get(item, 'created_by.name'))+" ")]):_vm._e()]):_vm._e(),(!_vm.shared)?_c('div',[(item.sharedWith && item.sharedWith.name)?_c('a',{on:{"click":function($event){return _vm.viewProfile(item.sharedWith._id)}}},[_vm._v(" With "+_vm._s(_vm.get(item, 'sharedWith.name'))+" ")]):_c('a',[_vm._v(" --- ")])]):_vm._e()]}},{key:"item.quota",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get(item, 'quotaLeft'))+" /"+_vm._s(_vm.get(item, 'quota'))+" ")]}},(_vm.shared)?{key:"item.created_by.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.goToProfile(item.created_by._id)}}},[_vm._v(_vm._s(item.created_by.name))])]}}:{key:"item.sharedWith.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.goToProfile(item.sharedWith._id)}}},[_vm._v(_vm._s(item.sharedWith.name))])]}},{key:"item.expireAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.expireAt).format('DD/MM/YYYY'))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format('DD/MM/YYYY'))+" ")]}},{key:"item.amountPayable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(_vm.get(item, 'amountPayable')))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.isMaster || _vm.isCashier},on:{"click":function($event){return _vm.editTicket(item)}}},[_vm._v("Edit")])]}}],null,true)})],1):_c('div',[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"}},[_vm._v("No Data")])],1)],1),_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.data.pages || 1},model:{value:(_vm.pageVal),callback:function ($$v) {_vm.pageVal=$$v},expression:"pageVal"}}),(!_vm.shared)?_c('v-btn',{staticClass:"mb-2",attrs:{"x-large":"","disabled":_vm.isMaster},on:{"click":function($event){_vm.createTicket = !_vm.createTicket}}},[_vm._v(" Add Package")]):_vm._e(),_c('TicketCreateDialog',{attrs:{"show":_vm.createTicket,"client":_vm.id},on:{"close":_vm.closeDialog,"reload":_vm.reload}}),_c('TicketEditDialog',{attrs:{"content":_vm.ticketEditing,"show":_vm.editTicketDialog},on:{"close":_vm.closeDialog,"reload":_vm.reload}}),_c('TicketShareDialog',{attrs:{"content":_vm.ticketEditing,"show":_vm.shareTicketDialog},on:{"close":_vm.closeDialog,"reload":_vm.reload}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }