<template>
  <v-dialog
    v-model="valueProxy"
    width="60%"
    max-width="100vw"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card min-height="250">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px">
            {{ title }}
          </span>
          <v-spacer />
          <v-btn icon @click="valueProxy = false">
            <v-icon> mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Sid" />
                <ValidationProvider v-slot="{ errors }" name="sid" rules="required">
                  <v-text-field
                    outlined
                    v-model="model.sid"
                    label="Sid"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Enabled" />
                <ValidationProvider v-slot="{ errors }" name="enabled" rule="required">
                  <v-switch v-model="model.enabled" class="mt-0 pt-0 ml-3" hide-details />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Name" />
                <ValidationProvider v-slot="{ errors }" name="name">
                  <v-text-field
                    outlined
                    v-model="model.name"
                    label="Name"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Listed Price" />
                <ValidationProvider v-slot="{ errors }" name="listedPrice">
                  <v-text-field
                    outlined
                    v-model="model.listedPrice"
                    label="Listed Price"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                  />
                </ValidationProvider>
              </v-col>
              <!--              <v-col cols="12" md="6">-->
              <!--                <custom-label item-field-name="ND iff Index" />-->
              <!--                <ValidationProvider v-slot="{ errors }" name="NDiffIndex"-->
              <!--                  :rules="{ min_value: 1, max_value: 99 }">-->
              <!--                  <v-text-field-->
              <!--                    outlined-->
              <!--                    v-model="model.NDiffIndex"-->
              <!--                    label="ND iff Index"-->
              <!--                    :error="!!errors.length"-->
              <!--                    :error-messages="errors"-->
              <!--                    type="number"-->
              <!--                  />&lt;!&ndash;TODO need to change label&ndash;&gt;-->
              <!--                </ValidationProvider>-->
              <!--              </v-col>-->
              <v-col cols="12" md="6">
                <custom-label item-field-name="Description" />
                <ValidationProvider v-slot="{ errors }" name="description">
                  <v-text-field
                    outlined
                    v-model="model.description"
                    label="Description"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Discounted Price" />
                <ValidationProvider v-slot="{ errors }" name="discountedPrice">
                  <v-text-field
                    outlined
                    v-model="model.discountedPrice"
                    label="Discounted Price"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                  />
                </ValidationProvider>
              </v-col>
              <!-- <v-col cols="12" md="6">
                <custom-label item-field-name="Discounted Price" />
                <ValidationProvider v-slot="{ errors }" name="discountedPrice10">
                  <v-text-field
                    outlined
                    v-model="model.discountedPrice10"
                    label="Discounted Price"
                    :error="!!errors.length"
                    :error-messages="errors"
                    type="number"
                  />
                </ValidationProvider>
              </v-col> -->
              <v-col cols="12" md="6">
                <custom-label item-field-name="Featured Image" />
                <v-file-input
                  v-model="newFeaturedImage"
                  class="flex-grow-1"
                  flat
                  label="Featured Image Upload"
                  outlined
                  accept="image/jpeg,image/png"
                />
                <!--TODO change the label and accept format-->
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Images" />
                <ValidationProvider v-slot="{ errors }" name="gameType" rules="required">
                  <v-file-input
                    v-model="newImage"
                    class="flex-grow-1"
                    flat
                    label="Image Upload"
                    outlined
                    accept="image/jpeg,image/png"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                  <!--TODO change the label and accept format-->
                </ValidationProvider>
              </v-col>
              <!--              <v-col cols="12" md="6">-->
              <!--                <custom-label item-field-name="Commission Rate" />-->
              <!--                <ValidationProvider v-slot="{ errors }" name="commissionRate">-->
              <!--                  <v-text-field-->
              <!--                    outlined-->
              <!--                    v-model="model.commissionRate"-->
              <!--                    label="Commission Rate"-->
              <!--                    :error="!!errors.length"-->
              <!--                    :error-messages="errors"-->
              <!--                    type="number"-->
              <!--                  />-->
              <!--                </ValidationProvider>-->
              <!--              </v-col>-->
              <v-col cols="12" md="6">
                <custom-label item-field-name="Session Type" />
                <ValidationProvider v-slot="{ errors }" name="sessionType" rules="required">
                  <v-select
                    outlined
                    v-model="model.sessionType"
                    label="Session Type"
                    :items="sessionTypeOptions"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Game Type" />
                <ValidationProvider v-slot="{ errors }" name="gameType" rules="required">
                  <v-select
                    outlined
                    v-model="model.gameType"
                    label="Game Type"
                    :items="gameTypeOptions"
                    :error="!!errors.length"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Facility" />
                <ValidationProvider v-slot="{ errors }" name="Facility" rules="required">
                  <autocomplete
                    v-model="computedFacility"
                    :path="computedAutoCompleteEndpoint"
                    :params="{ filter: { status: '1$accepted', isFacility: true } }"
                    clearable
                    item-text="name"
                    item-value="_id"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="doClose()">Close</v-btn>
          <v-btn color="primary" :disabled="invalid" @click="promptCreate()" v-if="isCreate">
            Create
          </v-btn>
          <v-btn color="primary" :disabled="invalid" @click="promptUpdate()" v-if="!isCreate">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import api from '@/api';
import ApiError from '@/api/ApiError';
import { crudCreate } from '@/api/crud';
import { RESTFUL } from '@/data/constants';
import editDialogMixin from '@/services/editDialogMixin';
import CustomLabel from '../customFormLabel/customLabel';
import { sessionTypeOptions, gameTypeOptions } from '@/data/optionsConstants';
import { uploadSingleAttachment } from '@/api/attachment';
import Autocomplete from '@/components/Autocomplete/Autocomplete';

export default {
  name: 'ServiceProductsEditDialog',
  components: { CustomLabel, Autocomplete },
  mixins: [editDialogMixin],
  props: {
    serviceList: {
      type: Array,
      default: () => ['N1', 'N2', 'N3', 'N4', 'N5'],
    },
    title: { default: 'Service Product' },
  },
  data() {
    return {
      sessionTypeOptions,
      gameTypeOptions,
      newFeaturedImage: null,
      newImage: null,
    };
  },
  computed: {
    computedAutoCompleteEndpoint() {
      return this.isAdmin ? 'toca/admin/masterProfiles' : 'toca/master/masterProfiles';
    },
    computedFacility: {
      get() {
        return this.model?.facilities[0];
      },
      set(v) {
        this.model.facilities = [v];
      },
    },
  },
  mounted() {
    this.reload();
  },
  methods: {
    async reload() {
      this.model = {
        enabled: true,
        facilities: [],
      };
    },
    async promptCreate() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const newImage = await uploadSingleAttachment(api, this.newImage);
        this.model = {
          ...this.model,
          images: newImage._id,
        };
        if (this.newFeaturedImage) {
          const newFeaturedImage = await uploadSingleAttachment(api, this.newFeaturedImage);
          this.model = {
            ...this.model,
            featuredImage: newFeaturedImage._id,
          };
        }

        await crudCreate(api, RESTFUL.serviceProducts.create, this.model);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Created',
          type: 'success',
          color: 'success',
        });
        this.$emit('create');
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
  },
};
</script>
