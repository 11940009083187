var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"ma-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Save' : 'Update')+" ")])],1)],1),_c('div',{attrs:{"id":"title"}},[_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(_vm.model['titleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'titleZh-HK', $$v)},expression:"model['titleZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(_vm.model.titleZh),callback:function ($$v) {_vm.$set(_vm.model, "titleZh", $$v)},expression:"model.titleZh"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(_vm.model.titleEn),callback:function ($$v) {_vm.$set(_vm.model, "titleEn", $$v)},expression:"model.titleEn"}})]}}],null,true)}),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}})],1),_c('div',{attrs:{"id":"classes"}},_vm._l((_vm.model.classes),function(item,index){return _c('div',{key:index},[_c('h3',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Classes "+_vm._s(index + 1))]),_c('div',{attrs:{"id":"featuredImage"}},[_c('div',{staticStyle:{"margin-bottom":"40px"}},[(item.featuredImage)?_c('div',[_c('v-img',{attrs:{"src":_vm.attnThumbHref(item.featuredImage, 512)}})],1):_c('div',{staticClass:"d-flex justify-center"},[_vm._v("No Featured Image")])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Photo Upload","outlined":"","accept":"image/jpeg,image/png"},model:{value:(item.newImage),callback:function ($$v) {_vm.$set(item, "newImage", $$v)},expression:"item.newImage"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"flex-shrink-0",staticStyle:{"margin-top":"10px"},attrs:{"color":"primary","disabled":!item.newImage},on:{"click":function($event){return _vm.uploadImage(index)}}},[_vm._v("Upload Image ")])],1)],1)],1)]),_c('div',{attrs:{"id":"classesTitle"}},[_c('v-row',[_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"類別標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"類別標題","outlined":""},model:{value:(item['titleZh-HK']),callback:function ($$v) {_vm.$set(item, 'titleZh-HK', $$v)},expression:"item['titleZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"类别标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"类别标题","outlined":""},model:{value:(item.titleZh),callback:function ($$v) {_vm.$set(item, "titleZh", $$v)},expression:"item.titleZh"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"4"}},[_c('ValidationProvider',{attrs:{"name":"Classes Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Classes Title","outlined":""},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"videoLink"}},[_c('ValidationProvider',{attrs:{"name":"Classes Video Link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Classes Video Link","outlined":""},model:{value:(item.videoLink),callback:function ($$v) {_vm.$set(item, "videoLink", $$v)},expression:"item.videoLink"}})]}}],null,true)})],1),_c('div',{attrs:{"id":"classesContent"}},[_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('h4',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"課程內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item['contentZh-HK']),callback:function ($$v) {_vm.$set(item, 'contentZh-HK', $$v)},expression:"item['contentZh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('h4',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"课程内容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item.contentZh),callback:function ($$v) {_vm.$set(item, "contentZh", $$v)},expression:"item.contentZh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('h4',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"Classes Content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item.contentEn),callback:function ($$v) {_vm.$set(item, "contentEn", $$v)},expression:"item.contentEn"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}})],1)])}),0)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }