<template>
  <v-card class="mb-3">
    <v-card-title
      class="justify-center borderBottom"
      style="font-size: 22px; padding-top: 5px; padding-bottom: 0px"
    >
      Customer Profile
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="6" style="border-right: 1px solid #b9b9b9">
        <div style="padding: 16px">
          <p>
            <u class="size">Basic Information</u>
          </p>
          <div>
            <p class="size">SID: {{ get(docs, 'sid', '-') }}</p>
          </div>
          <div class="d-flex">
            <p class="size">Name: {{ get(docs, 'name', '-') }}</p>
            <div @click="goToProfile" style="cursor: pointer">
              <!--              <u class="mr-3">More Details </u>-->
              <v-icon class="ml-2" small color="primary"> mdi-open-in-new</v-icon>
            </div>
          </div>
          <div>
            <p class="size">Mobile: {{ get(docs, 'phone', '-') | displayPhone }}</p>
          </div>
        </div>
      </v-col>
      <v-col cols="6">
        <div style="padding: 16px">
          <div class="d-flex justify-space-between">
            <div v-if="docs._VIP_activated">
              <p class="size">VIP : {{ get(docs._VIP_activated.VIPProduct, 'name', '-') }}</p>
            </div>
            <div v-else class="size">VIP : N/A</div>
          </div>
          <!-- <p class="d-flex justify-space-between">
            <u class="size">Refer By:</u>
          </p>

          <p class="mr-2">{{ get(docs, 'howToKnowBN', 'N/A') }}</p>
          <span>
            {{
              docs && docs.howToKnowBN === 'referred by'
                ? `${get(docs, 'howToKnowBNName', '-')}
                ${get(docs, 'howToKnowBNPhone', '-')}`
                : ''
            }}</span
          >

          <u class="size">Discount Labels:</u>
          <v-card-text>
            <div class="d-flex align-start flex-wrap">
              <v-chip
                v-for="(item, index) in userDiscountLabels"
                :key="index"
                class="mr-5 mb-5"
                color="#96734B"
                label
                outlined
                style="font-weight: bold"
              >
                {{ item }}
              </v-chip>
              <v-icon color="primary" @click="remarksPopup = true">
                mdi-plus-circle-outline
              </v-icon>
              <remarksDialog
                v-model="remarksPopup"
                :discountLabels="discountLabels"
                :userDiscountLabels="userDiscountLabels"
                :show-discount-labels="true"
                @saveDiscountLabel="x => saveCustomerDiscountLabels(x)"
              />
            </div>
          </v-card-text> -->
          <!--          ////^^^^^^^^^^^^^^^^^^-->
          <!--            <v-icon @click="remarksPopup = true" class="text-right" color="primary">-->
          <!--              mdi-plus-circle-outline-->
          <!--            </v-icon>-->
          <!--            <remarksDialog-->
          <!--              v-model="remarksPopup"-->
          <!--              :user-labels="userLabels"-->
          <!--              @saveLabel="x => saveCustomerLabels(x)"-->
          <!--            />-->
          <!--          </p>-->
          <!--          <p>-->
          <!--            <v-chip-->
          <!--              class="mr-5 mb-5"-->
          <!--              v-for="(item, index) in userLabels"-->
          <!--              :key="index"-->
          <!--              label-->
          <!--              outlined-->
          <!--              style="font-weight: bold"-->
          <!--              color="#96734B"-->
          <!--            >-->
          <!--              {{ item }}-->
          <!--            </v-chip>-->
          <!--          </p>-->
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import api from '@/api';
import {
  readProfile,
  readProfileByMaster,
  updateClientLabels,
  updateClientDiscountLabels,
} from '@/api/auth/membership';
import remarksDialog from '@/components/Identity/remarksDialog';
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import { getOid } from '@/services/utils';
import CustomLabel from '../customFormLabel/customLabel';

export default {
  name: 'customerProfileCard',
  components: {
    remarksDialog,
  },
  props: {
    id: String,
    clientDetails: { type: Object, default: () => {} },
  },
  data() {
    return {
      docs: null,
      userLabels: [],
      remarksPopup: false,
      userDiscountLabels: [],
      discountLabels: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.reload();
      },
    },
  },
  methods: {
    get,
    async reload() {
      this.docs = this.clientDetails;
      this.docs = this.isAdmin
        ? await readProfile(api, this.id, {
            populate: [{ path: '_VIP_activated', populate: ['VIPProduct'] }],
          })
        : await readProfileByMaster(api, this.id, {
            populate: [{ path: '_VIP_activated', populate: ['VIPProduct'] }],
          });
      this.userLabels = this.docs.metadata.labels;
      this.userDiscountLabels = this.docs.metadata.discountLabels;
    },
    async saveCustomerLabels(item) {
      try {
        await updateClientLabels(api, this.id, item);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.userLabels = item;
        this.remarksPopup = false;
      } catch (e) {}
    },

    async saveCustomerDiscountLabels(item) {
      try {
        await updateClientDiscountLabels(api, this.id, item);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.userDiscountLabels = item;
        this.remarksPopup = false;
      } catch (e) {}
    },

    //^^^^^^^^^^^^^^^^^^^^
    goToProfile() {
      window.open(window.location.origin + '/admin/profiles/' + this.id);
    },
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'isAdmin']),
  },
};
</script>

<style scoped>
.size {
  font-size: 18px;
  font-weight: bold;
}
</style>
