<template>
  <v-container>
    <v-card flat>
      <v-card-title>{{ pageTitle }}</v-card-title>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row class="ma-3">
          <v-col class="ml-auto" md="auto">
            <v-btn color="primary" @click="reload" style="margin-right: 20px"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
            <v-btn :disabled="invalid" color="primary" @click="save">
              {{ isNew ? 'Save' : 'Update' }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider style="margin-bottom: 20px" />
        <div>
          <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
            <v-text-field
              v-model="model['titleZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="標題"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
            <v-text-field
              v-model="model.titleZh"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="标题"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Title" rules="required">
            <v-text-field
              v-model="model.titleEn"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Title"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="WhatsApp No" rules="required">
            <v-text-field
              v-model="model.whatsaap_no"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="WhatsApp No"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Google Map Link" rules="required">
            <v-text-field
              v-model="model.content"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Google Map Link"
              outlined
            />
          </ValidationProvider>
          <v-divider style="margin-bottom: 20px" />
        </div>
        <div style="margin-left: 30px" v-for="(item, index) in model.data" :key="index">
          <h2 class="mainTitle CMSTitle">中文繁體版本</h2>
          <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
            <v-text-field
              v-model="item['titleZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="標題"
              outlined
            />
            <div style="margin-bottom: 20px">
              <ValidationProvider v-slot="{ errors }" name="Zh-HK content" rules="required">
                <quillEditor ref="quill" :options="qillOptions" v-model="item['contentZh-HK']" />
                <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <h2 class="mainTitle CMSTitle">中文简体版本</h2>
            <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
              <v-text-field
                v-model="item.titleZh"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="标题"
                outlined
              />
            </ValidationProvider>
            <div style="margin-bottom: 20px">
              <ValidationProvider v-slot="{ errors }" name="Zh content" rules="required">
                <quillEditor ref="quill" :options="qillOptions" v-model="item.contentZh" />
                <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <h2 class="mainTitle CMSTitle">English Version</h2>
            <ValidationProvider v-slot="{ errors }" name="Title" rules="required">
              <v-text-field
                v-model="item.titleEn"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Title"
                outlined
              />
            </ValidationProvider>
            <div style="margin-bottom: 20px">
              <ValidationProvider v-slot="{ errors }" name="En content" rules="required">
                <quillEditor ref="quill" :options="qillOptions" v-model="item.contentEn" />
                <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <v-divider style="margin-bottom: 20px" />
          </ValidationProvider>
        </div>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { CMSPostType } from '../../data/constants';
import { crudCreate, crudList, crudUpdate } from '@/api/crud';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  name: 'contactUs',
  components: {
    quillEditor,
  },
  data() {
    return {
      pageTitle: 'Contact Us',
      postType: CMSPostType.contactUs,
      contentID: null,
      model: {},
      qillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
        readOnly: false,
      },
    };
  },
  computed: {
    isNew() {
      return !this.contentID;
    },
  },
  async beforeMount() {
    await this.reload();
  },
  methods: {
    async reload() {
      const computedQuery = {
        filter: { 'metadata.type': this.postType },
        sort: { created_at: -1 },
      };
      try {
        const res = await crudList(api, 'cms/admin/posts', computedQuery);
        this.model = res?.docs?.[0] || {};
        this.contentID = this.model?._id;

        if (this.contentID) {
          if (this.model.title) {
            const titleJson = JSON.parse(this.model.title);
            this.model['titleZh-HK'] = titleJson['zh-HK'];
            this.model.titleZh = titleJson['zh'];
            this.model.titleEn = titleJson['en'];
          }

          this.model.whatsaap_no = this.model.metadata.whatsaap_no
            ? this.model.metadata.whatsaap_no
            : '';

          if (this.model.metadata.data) {
            this.model.data = this.model.metadata.data.map(x => {
              let item = {};

              const dataTitleJson = JSON.parse(x.title);
              item['titleZh-HK'] = dataTitleJson['zh-HK'];
              item.titleZh = dataTitleJson['zh'];
              item.titleEn = dataTitleJson['en'];

              const contentJson = JSON.parse(x.content);
              item['contentZh-HK'] = contentJson['zh-HK'];
              item.contentZh = contentJson['zh'];
              item.contentEn = contentJson['en'];
              return item;
            });
          }
        } else {
          let tempData = [];
          for (let i = 0; i < 5; i++) {
            const emptyObject = {
              ['titleZh-HK']: '',
              titleZh: '',
              titleEn: '',

              ['contentZh-HK']: '',
              contentZh: '',
              contentEn: '',
            };
            tempData.push(emptyObject);
          }

          this.model.data = tempData;
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS load ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const mainTitleJsonObj = {
          'zh-HK': this.model['titleZh-HK'],
          zh: this.model['titleZh'],
          en: this.model.titleEn,
        };

        let tempData = [];
        tempData = this.model.data.map(item => {
          const titleJsonObj = {
            'zh-HK': item['titleZh-HK'],
            zh: item['titleZh'],
            en: item.titleEn,
          };
          const contentJsonObj = {
            'zh-HK': item['contentZh-HK'],
            zh: item['contentZh'],
            en: item.contentEn,
          };
          return {
            title: JSON.stringify(titleJsonObj),
            content: JSON.stringify(contentJsonObj),
          };
        });

        if (this.isNew) {
          //create
          const payload = {
            metadata: {
              type: this.postType,
              data: tempData,
              whatsaap_no: this.model.whatsaap_no,
            },
            content: this.model.content,
            title: JSON.stringify(mainTitleJsonObj),
          };
          const res = await crudCreate(api, 'cms/admin/posts', payload);
          // alert & update
          this.contentID = res._id;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
        } else {
          // update
          delete this.model.__v;
          delete this.model.updated_at;
          delete this.model.created_at;
          this.model.title = JSON.stringify(mainTitleJsonObj);
          this.model.metadata = {
            type: this.postType,
            data: tempData,
            whatsaap_no: this.model.whatsaap_no,
          };

          const res = await crudUpdate(api, 'cms/admin/posts', this.contentID, this.model);
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Updated',
            type: 'success',
            color: 'success',
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS post ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
  },
};
</script>
