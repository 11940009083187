<template>
  <v-dialog v-model="valueProxy" width="90%" max-width="1000px">
    <v-card min-height="250">
      <v-card-title>
        <span class="mainTitle" style="font-size: 20px"> {{ 'Product' }} </span>
        <v-spacer />
        <v-btn icon @click="valueProxy = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-1">
          <v-col cols="2">
            <div class="d-flex justify-center">
              <h4 class="ma-1 ml-3">Category:</h4>
            </div>
          </v-col>
          <v-col cols="10">
            <v-autocomplete
              dense
              filled
              solo
              clearable
              label="Select a category"
              v-model="selectedCategory"
              :items="categories"
              item-text="title"
              item-value="_id"
              @change="getProductByCategory"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <div class="my-2">
          <v-text-field solo filled prepend-inner-icon="mdi-magnify" v-model="searchVal" />
        </div>
        <v-row no-gutters>
          <v-col v-for="(product, i) in products" :key="`product-${i}`" cols="3" class="d-flex">
            <ProductSelectionCard
              :product="product"
              @actionClickCheckBox="({ e, p }) => actionClickCheckBox(e, p)"
              @actionClickCard="actionClickCard"
              @addToCart="addToCart"
              @increment="increment"
              @decrement="decrement"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-center">
          <LoadMoreButton
            :hasMore="hasMoreProduct"
            :is-loading="isLoadingProduct"
            @loadMore="loadMore"
          />
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
import { debounce, get } from 'lodash';
import { listPhysicalProducts } from '@/api/productCategory';
import api from '@/api';
import LoadMoreButton from '@/components/LoadMoreButton';
import { mapGetters } from 'vuex';
import editDialogMixin from '@/services/editDialogMixin';
import ProductSelectionCard from '@/components/PhysicalProduct/ProductSelectionCard.vue';

export default {
  name: 'ProductCategoryList',
  components: { LoadMoreButton, ProductSelectionCard },
  mixins: [editDialogMixin],
  props: {
    categories: [],
    selectedProducts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageSize: 100,
      selectedCategory: '',
      products: [],
      isLoadingProduct: false,
      localData: {},
      page: 1,
      limit: 8,
      searchVal: '',
      debouncedSearch: debounce(this.getProductList, 400),
    };
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'profileId', 'isAdmin']),
    localSelectedProducts: {
      get() {
        return this.selectedProducts;
      },
      set(val) {
        this.$emit('update:selectedProducts', val);
      },
    },
    hasMoreProduct() {
      if (Object.keys(this.localData).length > 0) {
        return this.localData.pages > this.localData.page;
      } else {
        return false;
      }
    },
  },
  watch: {
    searchVal() {
      this.localData = {};
      this.products = [];
      this.page = 1;
      this.debouncedSearch(this.page);
    },
  },
  methods: {
    get,
    attnThumbHref,
    async reload() {
      this.products = [];
      this.selectedCategory = '';
    },
    increment(product) {
      product.selectedQty += 1;
      this.addToCart(product);
    },
    decrement(product) {
      if (product.selectedQty > 0) {
        product.selectedQty -= 1;
        this.addToCart(product);
      }
    },
    async loadMore() {
      this.page += 1;
      await this.getProductList(this.page);
    },
    async getProductByCategory() {
      this.localData = {};
      this.products = [];
      this.page = 1;
      await this.getProductList(this.page);
    },
    async getProductList(page) {
      const sort = { priority: -1, updated_at: -1, created_at: -1 };
      this.isLoadingProduct = true;
      try {
        const localData = await listPhysicalProducts(api, {
          filter: this.selectedCategory ? { category: this.selectedCategory } : {},
          limit: this.limit,
          page: page,
          sort: sort,
          search: this.searchVal,
        });
        this.localData = localData;
        const products = this.products.concat(localData.docs);
        for (const product of products) {
          const p = this.selectedProducts.find(el => el._id === product._id);
          if (!p) {
            this.$set(product, 'selectedQty', 0);
          } else {
            this.$set(product, 'selectedQty', p.selectedQty);
            this.$set(product, 'checkbox', p.checkbox);
          }
        }
        this.products = products;
      } catch (e) {}
      this.isLoadingProduct = false;
    },
    addToCart(product) {
      if (product.selectedQty === 0) {
        product.selectedQty = 1; // set default qty to 1
      }
      if (product && product.selectedQty > 0) {
        const p = this.localSelectedProducts.find(el => el._id === product._id);
        if (p) {
          p.selectedQty = product.selectedQty;
        } else {
          this.localSelectedProducts.push(product);
        }
      }
    },
    actionClickCheckBox(hasClick, product) {
      if (hasClick) {
        this.addToCart(product);
      } else {
        this.removeFromCart(product);
      }
    },
    removeFromCart(product) {
      if (product) {
        const i = this.localSelectedProducts.findIndex(el => el._id === product._id);
        if (i >= 0) {
          this.localSelectedProducts.splice(i, 1);
          product.selectedQty = 0;
        }
      }
    },
    actionClickCard(product) {
      this.$set(product, 'checkbox', !product.checkbox);
      this.actionClickCheckBox(product.checkbox, product);
    },
    remove(item) {
      const i = this.localSelectedProducts.findIndex(el => el._id === item._id);
      this.localSelectedProducts.splice(i, 1);
      const p = this.products.find(el => el._id === item._id);
      if (p) {
        p.selectedQty = 0;
        p.checkbox = false;
      }
    },
    editItemQty(item, qty) {
      const i = this.localSelectedProducts.findIndex(el => el._id === item._id);
      this.$set(this.localSelectedProducts, i, {
        ...this.localSelectedProducts[i],
        selectedQty: qty,
      });
      const p = this.products.find(el => el._id === item._id);
      if (p) {
        p.selectedQty = qty;
      }
    },
  },
};
</script>

<style lang="scss">
.stripeClass {
  text-decoration: line-through red;
}

.discountClass {
  margin-left: 5px;
  color: red;
}

.custom-number-input input {
  padding-left: 0 !important;
  text-align: center;
  color: var(--v-primary-base);
}

.buttonEffectTab {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white !important;
}
</style>
