<template>
  <v-container fluid :key="key">
    <v-row>
      <v-col class="selectListCol" cols="3">
        <div class="d-flex">
          <v-select class="selectedListBar" outlined :items="range" v-model="selectedRange" />
        </div>
      </v-col>
      <template v-if="selectedRange == 'select'">
        <div class="d-flex align-center selectListCol">
          <v-menu
            v-model="startTimeDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedStart"
                label="From"
                prepend-icon="mdi-calendar"
                style="min-width: max-content"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="selectedStart"
              @input="startTimeDialog = false"
              header-color="primary"
              @change="passEndTime()"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center selectListCol">
          <!--            <input type="date" class="input" v-model="selectedEnd" @input="passEndTime()" />-->
          <v-menu
            v-model="endTimeDialog"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="selectedEnd"
                label="To"
                prepend-icon="mdi-calendar"
                style="max-width: max-content"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              header-color="primary"
              v-model="selectedEnd"
              @input="endTimeDialog = false"
              @change="passEndTime()"
              :max="computedMaxEndTime"
            >
            </v-date-picker>
          </v-menu>
        </div>
      </template>
    </v-row>

    <v-row>
      <v-col cols="12" lg="6">
        <div>
          <bar-chart
            :chart-data="chartData.bookingSales"
            :height="400"
            y-label="HKD $"
            title="Booking Sales"
          />
        </div>
      </v-col>

      <v-col cols="12" lg="6">
        <div v-if="chartData.paymentMethods.labels.length !== 0">
          <PieChart title="Payment Methods" :chart-data="chartData.paymentMethods" :height="400" />
        </div>
        <div v-if="chartData.paymentMethods.labels.length === 0" style="height: 400px">
          <PieChart title="Payment Methods" :chart-data="chartData.paymentMethods" :height="200" />
          <div class="text-center title">
            <p>No Data</p>
          </div>
        </div>
      </v-col>
    </v-row>
    <!--    <v-row>-->
    <!--      <v-col cols="4">-->
    <!--        <div v-if="completedBooking">-->
    <!--          <h3>Completed Service: {{ completedBooking }}</h3>-->
    <!--        </div>-->
    <!--        <div v-if="!completedBooking">-->
    <!--          <h3>Completed Service: 0</h3>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row>-->
    <!--      <v-col cols="10">-->
    <!--        <div v-if="sourceOfBookingWidth">-->
    <!--          <h3>Source of Booking</h3>-->
    <!--          <div style="display: flex">-->
    <!--            <div-->
    <!--              style="text-align: center"-->
    <!--              :style="{ width: sourceOfBookingWidth + '%', 'background-color': rndColor() }"-->
    <!--            >-->
    <!--              App ({{ completedBookingFromApp }})-->
    <!--            </div>-->
    <!--            <div-->
    <!--              v-if="sourceOfBookingWidth"-->
    <!--              style="text-align: center; width: 100%"-->
    <!--              :style="{-->
    <!--                width: 100 - sourceOfBookingWidth + '%',-->
    <!--                'background-color': rndColor(0.7),-->
    <!--              }"-->
    <!--            >-->
    <!--              Offline ({{ completedBookingFromOffLine }})-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--        <div v-else>-->
    <!--          <h3 v-if="!sourceOfBookingWidth">Source of Booking: No Data</h3>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <!--    <v-row>-->
    <!--      <v-col cols="4">-->
    <!--        <div v-if="isRedoCounts">-->
    <!--          <h3>Redo Cases: {{ isRedoCounts }}</h3>-->
    <!--        </div>-->
    <!--        <div v-if="!isRedoCounts">-->
    <!--          <h3>Redo Cases: 0</h3>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
    <v-divider class="my-3" />
    <v-row>
      <v-col cols="6">
        <div v-if="memberCounts">
          <h3>New Registered Member (via Online/Admin): {{ memberCounts.new }}</h3>
        </div>
        <div v-if="!memberCounts">
          <h3>New Registered Member (via Online/Admin): No Data</h3>
        </div>
      </v-col>
    </v-row>
    <!--    <v-row style="margin-bottom: 50px">-->
    <!--      <v-col cols="6">-->
    <!--        <div v-if="chartData.homeDistrict.labels.length !== 0">-->
    <!--          <PieChart title="Home Districts" :chart-data="chartData.homeDistrict" :height="400" />-->
    <!--        </div>-->
    <!--        <div v-if="chartData.homeDistrict.labels.length === 0" style="height: 400px">-->
    <!--          <PieChart title="Home Districts" :chart-data="chartData.homeDistrict" :height="200" />-->
    <!--          <div class="text-center title">-->
    <!--            <p>No Data</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--      <v-col cols="6">-->
    <!--        <div v-if="chartData.officeDistrict.labels.length !== 0">-->
    <!--          <PieChart title="Office Districts" :chart-data="chartData.officeDistrict" :height="400" />-->
    <!--        </div>-->
    <!--        <div v-if="chartData.officeDistrict.labels.length === 0" style="height: 400px">-->
    <!--          <PieChart title="Office Districts" :chart-data="chartData.officeDistrict" :height="200" />-->
    <!--          <div class="text-center title">-->
    <!--            <p>No Data</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-col>-->
    <!--    </v-row>-->
  </v-container>
</template>

<script>
import api from '@/api';
import {
  bookingCounts,
  bookingSales,
  memberCounts,
  memberDistricts,
  paymentMethods,
} from '@/api/dashboard';
import BarChart from '@/components/Chart/BarChart';
import PieChart from '@/components/Chart/PieChart';
import moment from 'moment';

function rgb2css(rgb) {
  return rgb.length > 3
    ? `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${rgb[3]})`
    : `rgb(${rgb[0]},${rgb[1]},${rgb[2]})`;
}

function rnd(brightness = 0) {
  return (brightness + Math.random() * (1 - brightness)) * 255;
}

function rndColor(brightness = 0) {
  const c = [rnd(brightness), rnd(brightness), rnd(brightness)];
  return rgb2css(c);
}

function rndBackgroundColor(count) {
  const c = Array.from({ length: count }).map(() => [rnd(), rnd(), rnd()]);
  return {
    backgroundColor: c.map(rgb2css),
    hoverBackgroundColor: c.map(x => rgb2css(x.map(y => y * 0.95))),
  };
}

export default {
  name: 'dashboard',
  components: { BarChart, PieChart },
  data() {
    return {
      startTimeDialog: false,
      endTimeDialog: false,
      key: 0,
      interval: ['year', 'month', 'week', 'date', 'day', 'all'],
      range: [
        { text: 'Today', value: 1 },
        { text: 'Yesterday', value: 2 },
        { text: 'Last 7 days', value: 7 },
        // { text: 'Daily', value: 30 },
        // { text: 'Monthly', value: 365 },
        { text: 'Choose the date', value: 'select' },
      ],
      selectedInterval: 'day',
      selectedRange: 1,
      timezone: '+08:00',
      start: null,
      end: null,
      sourceOfBookingWidth: null,
      completedBooking: null,
      memberCounts: null,
      memberDistricts: null,
      bookingSales: null,
      paymentMethods: null,
      completedBookingFromOffLine: null,
      completedBookingFromApp: null,
      isRedoCounts: null,
      selectedStart: '',
      selectedEnd: '',
      handleDate: '',
    };
  },
  watch: {
    selectedRange: {
      immediate: true,
      async handler(v) {
        if (v) {
          switch (v) {
            case 2:
              this.start = +moment()
                .subtract(v - 1, 'day')
                .startOf('day'); //the value should be subtract 1
              this.end = +moment()
                .subtract(v - 1, 'day')
                .endOf('day');
              break;
            case 7:
              this.start = +moment().subtract(v, 'day').startOf('day');
              this.end = +moment()
                .subtract(v - 6, 'day')
                .endOf('day');
              this.handleDate = 7;
              break;
            // case 30:
            //   this.start = +moment().startOf('month')
            //   this.end = +moment().endOf('day')
            //   break;
            // case 365:
            //   this.start = +moment().startOf('year')
            //   this.end = +moment().endOf('month')
            //   break;
            case 'select':
              this.handleDate = 'select';
              break;
            default:
              this.start = +moment().startOf('day');
              this.end = +moment().endOf('day');
              break;
          }

          if (v === 365) {
            this.selectedInterval = 'month';
          } else {
            this.selectedInterval = 'day';
          }

          await this.reload();
          this.key++;
        }
      },
    },
  },
  computed: {
    computedMaxEndTime() {
      return moment().format('YYYY-MM-DD');
    },
    chartData() {
      const { homeDistrict, officeDistrict } = this.memberDistricts || {
        homeDistrict: [],
        officeDistrict: [],
      };
      const pm = this.paymentMethods || [];
      const bs = this.bookingSales || [];

      return {
        homeDistrict: {
          labels: homeDistrict.map(x => x._id),
          datasets: [
            {
              data: homeDistrict.map(x => x.count),
              ...rndBackgroundColor(homeDistrict.length),
            },
          ],
        },
        officeDistrict: {
          labels: officeDistrict.map(x => x._id),
          datasets: [
            {
              data: officeDistrict.map(x => x.count),
              ...rndBackgroundColor(officeDistrict.length),
            },
          ],
        },
        paymentMethods: {
          labels: pm.map(x => x._id),
          datasets: [
            {
              data: pm.map(x => x.count),
              ...rndBackgroundColor(pm.length),
            },
          ],
        },
        bookingSales: {
          labels: bs.map(i =>
            moment(new Date(i._id.replace(/-/g, '/') + ' 00:00:00').toISOString()).format('DD/MMM'),
          ),
          datasets: [
            {
              data: bs.map(i => i.amountPayable),
              barThickness: 20,
              ...rndBackgroundColor(bs.length),
            },
          ],
        },
      };
    },
  },
  methods: {
    rndColor,
    async reload() {
      const payload = {
        interval: this.selectedInterval,
        timezone: this.timezone,
        end: this.end,
        start: this.start,
      };
      const [counts, sales, mc, md, pm] = await Promise.all([
        bookingCounts(api, payload),
        bookingSales(api, payload),
        memberCounts(api, payload),
        memberDistricts(api, payload),
        paymentMethods(api, payload),
      ]);

      if (this.handleDate === 7 || this.handleDate === 'select') {
        const arr = [];
        const dateArr = sales.map(x => x._id);

        for (
          let i = moment(this.start).startOf('day');
          i < moment(this.end).endOf('day');
          i = i.add(1, 'days')
        ) {
          const date = i.format('YYYY-M-D');
          if (dateArr.includes(date)) {
            const k = sales.find(x => x._id === date);
            arr.push(k);
          } else {
            arr.push({
              _id: date,
              time: moment(i).toDate(),
              count: 0,
              amountPayable: 0,
            });
          }
        }
        this.bookingSales = arr;
      } else {
        this.bookingSales = sales;
      }

      for (const arr of [md.homeDistrict, md.officeDistrict, pm]) {
        const empty = [];
        let i;
        while ((i = arr.findIndex(x => !x._id)) >= 0) {
          const temp = arr[i];
          temp._id = 'N/A';
          arr.splice(i, 1);
          empty.push(temp);
        }
        arr.push(...empty);
      }
      this.memberCounts = mc;
      this.memberDistricts = md;
      this.sourceOfBookingWidth = (counts.fromApp / (counts.fromApp + counts.fromOffline)) * 100;
      this.completedBooking = counts.statusCompleted;
      this.completedBookingFromApp = counts.fromApp;
      this.completedBookingFromOffLine = counts.fromOffline;
      this.isRedoCounts = counts.isRedo;
      this.paymentMethods = pm;
    },

    async passEndTime() {
      // if ((!this.selectedStart && !this.selectedEnd) || (!this.selectedStart && this.selectedEnd) || (this.selectedStart && !this.selectedEnd)) {
      //   return;
      // }
      if (!(this.selectedStart && this.selectedEnd)) {
        return;
      }
      this.start = +moment(this.selectedStart);
      this.end = +moment(this.selectedEnd).endOf('day');
      await this.reload();
      this.key++;
    },
  },
};
</script>

<style scoped>
.selectListCol {
  flex-grow: 0;
  margin-right: 24px;
}
.selectedListBar {
  width: 100%;
}

.title {
  font-style: normal;
}
</style>
