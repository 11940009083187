<template>
  <v-container>
    <v-card flat>
      <v-card-title>{{ title }}</v-card-title>
      <CMSEdit :postType="postType" />
    </v-card>
  </v-container>
</template>

<script>
import { CMSPostType } from '../../data/constants';
import CMSEdit from '../../components/CMS/CMSEdit.vue';

export default {
  name: 'privacyPolicyCMS',
  components: { CMSEdit },

  data() {
    return {
      title: 'Privacy Policy',
      postType: CMSPostType.privacyPolicy,
    };
  },
};
</script>
