var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"ma-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Save' : 'Update')+" ")])],1)],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),_c('div',[_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(_vm.model['titleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'titleZh-HK', $$v)},expression:"model['titleZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(_vm.model.titleZh),callback:function ($$v) {_vm.$set(_vm.model, "titleZh", $$v)},expression:"model.titleZh"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(_vm.model.titleEn),callback:function ($$v) {_vm.$set(_vm.model, "titleEn", $$v)},expression:"model.titleEn"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"WhatsApp No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"WhatsApp No","outlined":""},model:{value:(_vm.model.whatsaap_no),callback:function ($$v) {_vm.$set(_vm.model, "whatsaap_no", $$v)},expression:"model.whatsaap_no"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Google Map Link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Google Map Link","outlined":""},model:{value:(_vm.model.content),callback:function ($$v) {_vm.$set(_vm.model, "content", $$v)},expression:"model.content"}})]}}],null,true)}),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}})],1),_vm._l((_vm.model.data),function(item,index){return _c('div',{key:index,staticStyle:{"margin-left":"30px"}},[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(item['titleZh-HK']),callback:function ($$v) {_vm.$set(item, 'titleZh-HK', $$v)},expression:"item['titleZh-HK']"}}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"Zh-HK content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item['contentZh-HK']),callback:function ($$v) {_vm.$set(item, 'contentZh-HK', $$v)},expression:"item['contentZh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(item.titleZh),callback:function ($$v) {_vm.$set(item, "titleZh", $$v)},expression:"item.titleZh"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"Zh content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item.contentZh),callback:function ($$v) {_vm.$set(item, "contentZh", $$v)},expression:"item.contentZh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"En content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item.contentEn),callback:function ($$v) {_vm.$set(item, "contentEn", $$v)},expression:"item.contentEn"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}})]}}],null,true)})],1)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }