<template>
  <v-container>
    <div>
      <h2>{{ title }}</h2>
    </div>
    <v-divider class="mb-5" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card v-if="docs" flat>
        <v-row class="mr-3 ml-3">
          <v-col class="ml-auto" md="auto">
            <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save </v-btn>

            <v-btn
              class="ml-2"
              v-if="isNew && isAdmin"
              :disabled="invalid"
              @click="applyVIPProductImmediately"
            >
              Apply VIP Product Immediately (without payment)
            </v-btn>

            <v-btn
              class="ml-2"
              v-if="!isNew && docs.paymentStatus === 'pending'"
              :disabled="invalid || isMaster"
              color="secondary"
              @click="createPayment"
              >Create Payment
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mr-3 ml-3">
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="SID" rules="required">
              <v-text-field
                v-model="docs.sid"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Sid"
                outlined
                :readonly="!isNew"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6"
            >Status:
            <h4>{{ docs.status | status }}</h4></v-col
          >
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Payment Method" rules="required">
              <v-text-field
                v-model="docs.paymentMethod"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Payment Method"
                outlined
                :readonly="!isNew"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Payment Status" rules="required">
              <v-text-field
                v-model="docs.paymentStatus"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Payment Status"
                outlined
                readonly
                disabled
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mr-3 ml-3">
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="Customer" rules="required">
              <autocomplete
                label="Customer"
                :path="computedCustomerProfileUrl"
                :params="{ sort: { created_at: -1 } }"
                item-text="name"
                item-value="_id"
                v-model="docs.owner"
                :error-messages="errors"
                :clearable="true"
                :has-items-slot="true"
                :return-object="true"
                ref="autoComplete"
              >
                <template v-slot:items="content">
                  <span>{{
                    get(content, 'item.name', '') +
                    ' ' +
                    displayPhone(get(content, 'item.phone', ''))
                  }}</span>
                </template>
              </autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6"></v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider v-slot="{ errors }" name="VIP Product" rules="required">
              <autocomplete
                label="VIP Product"
                :path="RESTFUL.vipProducts.list"
                :params="{ sort: { 'discount.VIPIndex': -1, created_at: -1 } }"
                :item-text="x => parseLocalizedString(x.name).en"
                item-value="_id"
                v-model="docs.VIPProduct"
                :error-messages="errors"
                :clearable="true"
                :return-object="true"
                @change="changeVIPProduct"
              >
              </autocomplete>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true }"
              name="Amount"
            >
              <v-text-field
                v-model="docs.amountPayable"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Amount ($HKD)"
                outlined
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="mr-3 ml-3">
          <v-col cols="12"><h4>Discount</h4></v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="Credit Addition"
            >
              <v-text-field
                v-model="docs.discount.creditAddition"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Credit Addition"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="In House Discount"
            >
              <v-text-field
                v-model="docs.discount.inHouseDiscount"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="In House Discount"
                outlined
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, numeric: true }"
              name="Vip Index"
            >
              <v-text-field
                v-model="docs.discount.VIPIndex"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Vip Index"
                outlined
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="VIP Room Booking Discount"
            >
              <v-text-field
                v-model="docs.discount.VIPRoomBookingDiscount"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="VIP Room Booking Discount"
                outlined
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="Same In House Discount Quota"
            >
              <v-text-field
                v-model="docs.discount.sameInHouseDiscountQuota"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Same In House Discount Quota"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ numeric: true }"
              name="Free Instructor Quota"
            >
              <v-text-field
                v-model="docs.discount.freeCoachQuota"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Free Instructor Quota"
                outlined
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              v-model="docs.discount.specialTwoHourCeiling"
              label="Special Two Hour Ceiling"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              v-model="docs.discount.semiPrivateLaneService"
              label="Semi Private Lane Service"
            />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-checkbox
              v-model="docs.discount.freeRentalOfSkiEquipment"
              label="Free Rental Of Ski Equipment"
            />
          </v-col>
        </v-row>
      </v-card>
    </ValidationObserver>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudCreate, crudRead, crudUpdate } from '@/api/crud';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { RESTFUL } from '@/data/constants';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { displayPhone } from '@/services/filters';
import { get } from 'lodash';
import { createVipProductPurchaseRecord } from '@/api/VIPProductPurchaseRecord';
import { createVipPayment } from '@/api/payment';
import { getOid } from '@/services/utils';
import ApiError from '@/api/ApiError';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'ServiceProductDetails',
  components: { CustomLabel, Autocomplete },
  props: {
    id: {},
  },
  data() {
    return {
      RESTFUL,
      docs: null,
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'isAdmin']),
    isNew() {
      return this.id === 'new';
    },
    title() {
      return this.isNew ? 'New Vip Product Purchase Record' : 'Vip Product Purchase Record';
    },
    computedCustomerProfileUrl() {
      return this.isMaster ? 'toca/master/ClientProfiles' : 'toca/admin/ClientProfiles';
    },
    computedProfileUrl() {
      return this.isMaster ? 'toca/master/Profiles' : 'toca/admin/Profiles';
    },
  },
  methods: {
    parseLocalizedString,
    get,
    displayPhone,
    async reload() {
      if (this.isNew) {
        this.docs = { paymentStatus: 'pending', status: '0$inactive', discount: {} };
      } else {
        this.docs = await crudRead(api, RESTFUL.vipProductPurchaseRecords.list, this.id);
      }
    },
    async applyVIPProductImmediately() {
      if (!(await this.$refs.form?.validate?.())) {
        return;
      }
      const clientActivatedVIP = (
        await crudRead(api, RESTFUL.clientProfile.read, getOid(this.docs.owner), {
          populate: ['_VIP_activated'],
        })
      )?.$$populatedVirtuals?._VIP_activated;

      //let deltaVipIndex =
      let action;
      if (!clientActivatedVIP?.discount?.VIPIndex) {
        action = 'Add New';
      } else if (this.docs.discount.VIPIndex === clientActivatedVIP.discount.VIPIndex) {
        action = 'Maintain';
      } else if (this.docs.discount.VIPIndex > clientActivatedVIP.discount.VIPIndex) {
        action = 'Upgrade';
      } else if (this.docs.discount.VIPIndex < clientActivatedVIP.discount.VIPIndex) {
        action = 'Downgrade';
      }

      const confirm = await this.$root.$confirm.open(
        'Confirm',
        `Do you confirm to ${action} VIP Record`,
        {
          color: 'secondary',
          width: '30%',
        },
        'OK',
      );
      if (confirm) {
        this.docs.status = '1$active';
        this.save();
      }
    },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        const model = this.isNew
          ? (await createVipProductPurchaseRecord(api, { ...this.docs, commit: true }))
              ?.VIPProductPurchaseRecord // crudCreate(api, RESTFUL.vipProductPurchaseRecords.create, this.docs)
          : await crudUpdate(api, RESTFUL.vipProductPurchaseRecords.list, this.id, this.docs);

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'vipRecord',
            params: {
              id: model._id,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    changeVIPProduct(product) {
      if (product && this.isNew) {
        this.docs.discount = product.discount;
        this.docs.amountPayable = product.discountedPrice;
      }
    },
    async createPayment() {
      //TODO: should alert user if the client have active vipProductPurchaseRecordDetails

      const confirm = await this.$root.$confirm.open(
        'Confirm to make the payment?',
        'Once you mark the payment status to success , the Vip record will be activated automatically ',
        {
          color: 'secondary',
          width: '30%',
        },
        'OK',
      );

      if (!confirm) return;

      //TODO: should upload the attachments get the d  , below id is just a dummy id in order to pass the checking in server
      const payload = {
        gateway: 'manual',
        attachments: getOid(this.docs),
      };
      try {
        await createVipPayment(api, getOid(this.docs), payload);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Paid',
          type: 'success',
          color: 'success',
        });

        this.$router.replace({
          name: 'vipRecord',
          params: {
            id: getOid(this.docs),
          },
        });
      } catch (e) {
        const ae = ApiError.wrap(e);
        console.log({ ae });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: ae.message,
        });
      }
    },
  },
};
</script>
