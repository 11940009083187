<template>
  <div v-if="docs">
    <customer-profile-card :id="clientId" :clientDetails="docs.created_by" />
    <v-card class="mb-5">
      <div class="invoice-heading">
        <b class="invoice-title">Invoice</b>
      </div>
      <v-card-title class="justify-space-between text-left borderBottom">
        <div>
          No.<span class="font-weight-bold">{{ get(docs, 'sid') }}</span>
          <br />
          <span>
            Booking Time: {{ computedBookingDate }} {{ computedBookingTimeStart || '-' }} ~
            {{ computedBookingTimeEnd || '-' }}
          </span>
          <div>
            Amount : $ {{ get(docs, 'amountPayable') | toThousandFilter }} (
            <span class="text-capitalize">{{ get(docs, 'paymentMethod') }}</span>
            <span v-if="docs && docs._productOrder">
              : {{ docs._productOrder.sid }}
              <v-btn icon small @click="unBoundProductOrder">
                <v-icon small>mdi-link-variant-off</v-icon>
              </v-btn>
            </span>
            )
            <template v-if="get(docs, 'paymentMethod') === 'manual'">
              <v-btn
                v-if="docs && !docs._productOrder"
                x-small
                @click="unboundBookingsSelectorDialog = true"
                outlined
              >
                Bind POS Payment
              </v-btn>
              <UnboundBookingsSelectorDialog
                :time="posPaymentTimeRange"
                v-model="unboundBookingsSelectorDialog"
                @select="boundBookingItem"
              />
            </template>
          </div>
          <div v-if="docs && !docs.paid && docs.reservationExpiryAt" class="red--text caption">
            <div>The customer is processing the payment for this booking</div>
            <div>
              Reservation will expire at
              {{ get(docs, 'reservationExpiryAt') | moment('DD/MM/YYYY HH:mm:ss') }}
            </div>
          </div>
          <div>Remarks: {{ get(docs, 'remarks') }}</div>
        </div>
        <div>
          <v-dialog v-model="showTimeTableEditDialog" max-width="600px">
            <v-card>
              <v-card-title> Invoice Edit</v-card-title>
              <v-card-text style="padding-bottom: 0; padding-top: 20px">
                <v-text-field
                  v-model="localTimeTable.Seat"
                  placeholder="Set Seat No"
                  label="Seat No"
                  outlined
                />
              </v-card-text>
              <v-card-actions class="d-flex justify-space-between">
                <v-btn @click="actionCancel"> Cancel</v-btn>
                <v-btn @click="promptSaveTimeTable"> Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <template v-if="isAdmin">
            <v-btn class="void-btn" v-if="!docs.isVoided" @click="actionVoid(id)" color="error">
              Void
            </v-btn>
          </template>
          <div>
            <v-chip v-if="docs.isVoided === true" large color="red" label outlined>
              <b>Voided by: &nbsp;</b
              ><span>{{ get(docs, ['voidedBy', 'name'], '-') }}</span> &nbsp;&nbsp;&nbsp;&nbsp;
              <b>Voided at: &nbsp;</b><span>{{ docs.voidedAt | moment }}</span>
            </v-chip>
          </div>
          <!--          <v-switch-->
          <!--            :disabled="completedQuotation || !masterHasAuth"-->
          <!--            class="mr-5 align-self-center"-->
          <!--            color="#e6c407"-->
          <!--            inset-->
          <!--            v-model="docs.calledCustomerForBookingConfirmation"-->
          <!--            :label="`Called customer confirm booking`"-->
          <!--            @change="x => markCalledCustomerForBookingConfirmation(x)"-->
          <!--          />-->

          <!-- {{ date || '-' }} {{ computedTimeStart || '-' }} ~ {{ computedTimeEnd || '-' }} -->
        </div>
      </v-card-title>

      <div class="flex" style="display: flex">
        <template v-if="docs.status === `97$cancelled` || docs.status === `98$absent`">
          <v-btn class="ml-5 align-self-center" @click="resume">Resume</v-btn>
        </template>
        <v-spacer />
        <!--        <v-switch-->
        <!--          :disabled="completedQuotation || !masterHasAuth"-->
        <!--          class="mr-5 align-self-center"-->
        <!--          color="primary"-->
        <!--          inset-->
        <!--          v-model="docs.late"-->
        <!--          :label="`Late`"-->
        <!--          @change="x => markLate(x)"-->
        <!--        />-->
        <v-btn-toggle
          v-if="false"
          :disabled="completedQuotation || !masterHasAuth"
          mandatory
          @change="
            v => {
              oldstatus = docs.status;
              this.docs.status = v;
              if (v === '97$cancelled' || v === '98$absent') {
                cancelBookingDialog = true;
              } else {
                markStatusToggle();
              }
            }
          "
          color="primary"
          class="mt-5 mb-5 mr-5"
          :value="docs.status"
        >
          <!-- <v-btn
            :disabled="completedQuotation"
            :value="item"
            v-for="item in ['0$created', '1$arrived', '97$cancelled', '98$absent']"
            :key="item"
            >{{ item | status }}
          </v-btn> -->
          <v-btn
            :disabled="completedQuotation || !masterHasAuth"
            :value="item"
            v-for="item in ['0$created', '1$arrived', '97$cancelled']"
            :key="item"
            >{{ item | status }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-divider />
      <div style="padding: 16px">
        <template>
          <div>
            <v-row>
              <v-col cols="9">
                <v-card class="mb-5" elevation="2">
                  <v-card-title>Service</v-card-title>
                  <v-card-text>
                    <div class="text-h4">
                      {{ docs.serviceDetails.serviceProduct.name }}
                    </div></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9">
                <v-card class="mb-5" elevation="2">
                  <v-card-title>Facility</v-card-title>
                  <v-card-text>
                    <div class="text-h4">
                      {{ docs.serviceDetails.masterSpecified.name }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="text-center" cols="1" />
              <v-col cols="2"> </v-col>
            </v-row>
            <v-row>
              <v-col cols="9">
                <v-card class="mb-5" elevation="2">
                  <v-card-title>Instructor</v-card-title>
                  <v-card-text>
                    <v-simple-table v-if="docs.coaches.length" class="mb-2">
                      <tbody>
                        <template>
                          <tr v-for="coach in docs.coaches" :key="`c-${coach._id}`">
                            <td>{{ coach.sid }}</td>
                            <td>{{ coach.name }}</td>
                            <td>{{ coach.phone | displayPhone }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </v-simple-table>
                    <v-simple-table v-if="docs.nonSpecifyCoachNumber">
                      <tbody>
                        <tr>
                          <td>
                            Number of Unspecified Instructors :
                            <span>{{ docs.nonSpecifyCoachNumber }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <v-simple-table v-if="!docs.coaches.length && !docs.nonSpecifyCoachNumber">
                      <tbody>
                        <tr>
                          <td colspan="3">N/A</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                    <div class="text-right mt-4">
                      <h3>
                        Total Instructors:
                        {{ get(docs, 'coaches.length', 0) + get(docs, 'nonSpecifyCoachNumber', 0) }}
                      </h3>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </template>
        <v-divider style="margin: 40px 0px 40px 0px" />
        <v-row>
          <v-col cols="12"><h1>Settlement</h1></v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title>Participant(s)</v-card-title>
              <v-card-text>
                <BookingParticipantTable
                  :show-select="!completedQuotation && isToday"
                  :booking="docs"
                  v-model="selectedParticipants"
                ></BookingParticipantTable>
              </v-card-text>
              <template v-if="!completedQuotation && isToday">
                <v-card-title>Time Summary</v-card-title>
                <v-card-text>
                  <v-simple-table v-if="docs.time">
                    <tbody>
                      <tr>
                        <th class="text-h6 font-weight-regular">Start Time</th>
                        <td class="text-h6 py-4">
                          {{ docs.time.start | moment('HH:mm:ss') }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-h6 font-weight-regular">End Time</th>
                        <td class="text-h6 py-4">
                          {{ docs.time.end | moment('HH:mm:ss') }}
                        </td>
                      </tr>
                      <tr>
                        <th class="text-h6 font-weight-regular">Current Time</th>
                        <td class="text-h6 py-4">{{ this.currentTime | moment('HH:mm:ss') }}</td>
                      </tr>
                      <tr :class="{ 'red--text': isOverTime }">
                        <th class="text-h6 font-weight-regular">Overtime</th>
                        <td class="text-h6 py-4">{{ overTimeText }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </template>
              <template v-if="!completedQuotation && isOverTime">
                <v-card-title>Consume Extension Product</v-card-title>
                <v-card-text>
                  <div class="text-h5">
                    <div>
                      VIP two hours ceiling discount:
                      <v-icon v-if="isSpecialTwoHourCeiling">mdi-check-circle</v-icon>
                      <v-icon v-else>mdi-close-circle</v-icon>
                    </div>
                    <div>
                      Main participant own extension hours: {{ maximumConsumableHours }}
                      <v-btn @click="reloadClientExtensionProducts" icon>
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      Suggested consumption hours:
                      <span class="font-weight-bold">{{ suggestedConsumptionHours }}</span>
                    </div>
                  </div>
                  <br />
                  <ValidationProvider v-slot="{ errors }" name="Consumption Hours" rules="required">
                    <v-text-field
                      label="Consumption Hours"
                      class="text-right"
                      type="number"
                      :min="0"
                      :max="maximumConsumableHours"
                      outlined
                      v-model.number="consumeHours"
                      :error="!!errors.length"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </v-card-text>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <v-divider />
      <slot name="action">
        <div class="d-flex justify-lg-space-between">
          <div class="pl-5 d-flex flex-column justify-center align-start flex-grow-1">
            <template v-if="!completedQuotation">
              <!-- <p v-if="!validCreditDeduct" class="mb-0 text-error">* Not enough Credit</p>
              <p v-if="!validAdminDeduct" class="mb-0 text-error">
                * Cash Discount/ Credit Deduction Excess
              </p>
              <p v-if="!formValid" class="mb-0 text-error">
                * Must select either Treatment or Package
              </p>
              <p v-if="noSaved" class="mb-0 text-error">
                * Press "Compute & Save" before "Complete"
              </p>
              <p v-if="!isArrived" class="mb-0 text-error">
                * Please mark "Arrived" before "Complete"
              </p> -->
              <p v-if="!isToday" class="mb-0 text-error">* Not Today Booking</p>

              <p v-if="!selectedParticipants.length" class="mb-0 text-error">
                * Please Select Participant
              </p>
              <p v-if="notEnoughExtensionHour" class="mb-0 text-error">
                * The main participant does not have enough extension hours to consume.
              </p>
            </template>
          </div>
          <!-- <div class="flex-grow-1 text-end">
            <v-dialog v-model="completeDialog" width="500">
              <v-card>
                <v-card-title> Do you confirm to complete quotation?</v-card-title>
                <v-card-text>
                  Completed quotation will not be editable! Do you confirm to complete quotation?
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="completeDialog = false"> Cancel</v-btn>
                  <v-btn color="primary" text persistent @click="markCompleted"> Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              :disabled="
                !formValid ||
                completedQuotation ||
                !validCreditDeduct ||
                !validAdminDeduct ||
                !masterHasAuth
              "
              class="mt-5 mb-5 mr-5"
              color="primary"
              @click="save"
            >
              Compute & Save
            </v-btn>
          </div> -->
          <div class="flex-grow-1 text-end">
            <v-dialog v-model="settleDialog" width="500">
              <v-card>
                <v-card-title> Do you confirm to settle?</v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <tbody>
                      <tr v-for="{ profile } in selectedParticipants" :key="`p-${profile._id}`">
                        <td>
                          {{ profile.sid }}
                        </td>
                        <td>
                          {{ profile.name }}
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <!-- {{ selectedParticipants }} -->
                  <div v-if="isOverTime" class="mt-4 text-h5">
                    <div>
                      Total extension product hours:
                      <span class="font-weight-bold">{{ consumeHours }}</span>
                    </div>
                    <div class="red--text caption" v-if="consumeHours < suggestedConsumptionHours">
                      *Less than Suggested Consumption Hours
                    </div>
                  </div>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="settleDialog = false"> Cancel</v-btn>
                  <v-btn color="primary" text persistent @click="markSettled"> Confirm</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-btn
              :disabled="disableSettle"
              class="mt-5 mb-5 mr-5"
              color="primary"
              @click="settleDialog = true"
            >
              Settle
            </v-btn>
          </div>
        </div>
      </slot>
    </v-card>
    <v-dialog v-model="cancelBookingDialog" max-width="600">
      <v-card>
        <v-card-title style="align-items: center">
          Confirm to update the status of this booking?
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="d-flex justify-space-around">
              <v-btn @click="close">Back</v-btn>
              <v-btn @click="cancel">Update</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api';
import {
  completeBooking,
  computePayment,
  markLate,
  markPaid,
  markStatus,
  readBooking,
  markCalledCustomerForBookingConfirmation,
  voidBooking,
  masterReadBooking,
  validStayTime,
  settleBooking,
} from '@/api/bookings';
import { crudList, crudUpdate } from '@/api/crud';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import CustomerProfileCard from '@/components/Booking/customerProfileCard';
import DatePickerWrapperInputMenu from '@/components/DatePickerWrapperInputMenu';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { getClientTickets } from '@/api/ticketPackage';
import { getOid, oidEquals } from '@/services/utils';
import { getPaymentPreference, getPaymentPreferenceByMaster } from '@/api/systemConfig';
import { paymentOfCreditCardOptions, paymentOfOthersOptions } from '@/data/optionsConstants';
import { mapGetters } from 'vuex';
import { RESTFUL } from '@/data/constants';
import { timeRange } from '@/services/filters';
import { listProductCategories } from '@/api/productCategory';
import ProductItemRow from '@/components/PhysicalProductOrder/ProductItemRow';
import BookingParticipantTable from '@/components/Booking/BookingParticipantTable.vue';
import ApiError from '@/api/ApiError';
import { updateOrderBookingItemsBounding } from '@/api/physicalProductOrder';
import UnboundBookingsSelectorDialog from '@/components/PhysicalProductOrder/UnboundBookingsSelectorDialog.vue';

export default {
  name: 'bookingDetailCard',
  components: {
    CustomerProfileCard,
    Autocomplete,
    ProductItemRow,
    BookingParticipantTable,
    UnboundBookingsSelectorDialog,
  },
  emits: ['update', 'cancel'],
  props: {
    id: {
      type: String,
    },
    bookingDetails: { type: Object, default: () => {} },
  },
  data() {
    return {
      moment,
      priceList: {
        singleTreatmentPrice: 0,
        otherDiscountPercentage: 0,
        masterSpecified: 0,
      },
      paymentOfCreditCardOptions,
      paymentOfOthersOptions,
      oldstatus: null,
      cancelBookingDialog: false,
      calendarDialog: false,
      timeDialog: false,
      serviceSubTotal: null,
      serviceProductList: [],
      clientId: null,
      completeDialog: null,
      paymentDialog: null,
      packageCheckBox: false,
      singleTreatmentCheckBox: false,
      otherDiscountCheckBox: false,
      selectedPackage: null,
      timetable: {},
      paymentList: null,
      selectedCustomerLabelList: [],
      showTimeTableEditDialog: false,
      customerLabelField: null,
      clientPackageList: [],
      otherDiscountList: [],
      docs: null,
      localTimeTable: {
        Date: null,
        TimeStart: null,
        TimeEnd: null,
      },
      debounceComputePayment: debounce(this.tryCallComputedPayment, 1000),
      selectedPaymentMethod: 'Cash',
      localAssistantMaster: null,
      noSaved: true,
      lastCompletedBooking: null,
      categories: [],
      booking: {},
      isVoided: Boolean,

      selectedParticipants: [],
      consumeHours: 0,
      clientExtensionProducts: [],
      currentTime: new Date(), //may dynamic update by real time
      settleDialog: false,
      unboundBookingsSelectorDialog: false,
    };
  },
  watch: {
    bookingDetails: {
      immediate: true,
      handler(v) {
        if (v) {
          this.docs = v;
          this.reload();
          //this.debounceComputePayment();
        }
      },
    },
    // packageCheckBox: {
    //   handler(v) {
    //     if (!v) {
    //       this.singleTreatmentCheckBox = true;
    //       if (!this.docs?.serviceDetails?.packageSpecified) return;
    //       this.docs.serviceDetails.packageSpecified = null;
    //     } else {
    //       this.singleTreatmentCheckBox = false;
    //     }
    //   },
    // },
    suggestedConsumptionHours(v) {
      this.consumeHours = Math.min(this.maximumConsumableHours, v);
    },
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'isAdmin', 'profileId', 'isCashier']),
    cancelledOrAbsent() {
      return this.docs.status === `98$absent` || this.docs.status === `97$cancelled`;
    },
    completedQuotation() {
      return (
        this.docs.status === `98$absent` ||
        this.docs.status === '99$completed' ||
        this.docs.status === `97$cancelled` ||
        this.docs.isVoided
      );
    },
    // computedProductOrderSubtotal() {
    //   return this.docs.purchasedProducts.reduce((acc, el) => {
    //     acc += parseFloat(el.subTotal);
    //     return acc;
    //   }, 0);
    // },
    date: {
      set(v) {
        this.timetable.time.start = moment(v);
      },
      get() {
        return this.timetable?.time?.start
          ? moment(this.timetable.time.start).format('DD/MM/YYYY')
          : null;
      },
    },
    // computedPackage() {
    //   const res = this.clientPackageList.find(
    //     x => x._id === this.docs.serviceDetails.packageSpecified,
    //   );
    //   if (!res) return null;
    //   return res;
    // },
    // computedServiceProduct() {
    //   const res = this.serviceProductList.find(
    //     x => x._id === this.docs.serviceDetails.serviceProduct,
    //   );
    //   if (!res) return null;
    //   return res;
    // },
    computedBookingDate() {
      return this.docs?.time?.start ? moment(this.docs.time.start).format('DD/MM/YYYY') : null;
    },
    computedBookingTimeStart() {
      return this.docs?.time?.start ? moment(this.docs.time.start).format('HH:mm') : null;
    },
    computedBookingTimeEnd() {
      return this.docs?.time?.end ? moment(this.docs.time.end).format('HH:mm') : null;
    },
    computedTimeStart() {
      return this.timetable?.time?.start ? moment(this.timetable.time.start).format('HH:mm') : null;
    },
    computedTimeEnd() {
      return this.timetable?.time?.end ? moment(this.timetable.time.end).format('HH:mm') : null;
    },
    computedAmount() {
      if (!this.paymentList) return;
      return this.paymentList;
    },
    computedAutoCompleteEndpoint() {
      return this.isAdmin ? 'toca/admin/masterProfiles' : 'toca/master/masterProfiles';
    },
    formValid() {
      return this.docs.serviceDetails && !!this.docs.serviceDetails.serviceProduct;
    },
    validCreditDeduct() {
      return (
        get(this.docs, 'creditDeduct', 0) <= get(this.docs, 'created_by.creditBalance', 0) &&
        this.computedAmount?.amountPayable >= 0
      );
    },
    validAdminDeduct() {
      return (
        // get(this.docs, 'adminDeduct', 0) <= get(this.docs, 'amountPayable', 0) &&
        this.computedAmount?.amountPayable >= 0
      );
    },
    isArrived() {
      return this.docs.status === '1$arrived';
    },
    masterHasAuth() {
      return (
        this.isAdmin ||
        this.isCashier ||
        (this.isMaster && this.profileId === getOid(this.docs?._timetable?.master))
      );
    },
    maximumConsumableHours() {
      if (!this.clientExtensionProducts.length) return 0;
      return Math.floor(
        this.clientExtensionProducts.reduce((total, item) => total + item.extensionMinutesLeft, 0) /
          60,
      );
    },
    overTime() {
      if (!this.docs?.time?.end)
        return {
          hours: 0,
          minutes: 0,
        };
      if (this.isSpecialTwoHourCeiling) {
        if (this.bookingDurationHours >= 2) {
          return {
            hours: 0,
            minutes: 0,
          };
        }
      }
      const startTime = moment(this.docs.time.end);
      const endTime = moment(this.currentTime);
      // calculate total duration
      const duration = moment.duration(endTime.diff(startTime));
      // duration in hours
      const hours = Math.max(0, parseInt(duration.asHours()));
      // duration in minutes
      const minutes = Math.max(0, parseInt(duration.asMinutes()) % 60);
      return {
        hours,
        minutes,
      };
    },
    isOverTime() {
      return this.overTime.hours + this.overTime.minutes > 0;
    },
    overTimeText() {
      if (!this.docs?.time?.end) return '-';
      if (this.isSpecialTwoHourCeiling) {
        if (this.bookingDurationHours >= 2) {
          return 'N/A (Unlimited Player)';
        }
      }
      const { hours, minutes } = this.overTime;
      const hoursText = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
      const minutesText = minutes > 0 ? `${minutes} minute${minutes > 1 ? 's' : ''}` : '';
      let out = hoursText + (hoursText && minutesText ? ' and ' : '') + minutesText;
      return out || 'N/A';
    },
    bookingDurationHours() {
      return moment
        .duration(moment(this.docs.time.end).diff(moment(this.docs.time.start)))
        .asHours();
    },
    suggestedConsumptionHours() {
      if (this.isSpecialTwoHourCeiling) {
        if (this.bookingDurationHours < 2) {
          return 1 * this.selectedParticipants.length;
        } else {
          return 0;
        }
      }
      return (
        (this.overTime.hours + (this.overTime.minutes > 0 ? 1 : 0)) *
        this.selectedParticipants.length
      );
    },
    disableSettle() {
      if (this.selectedParticipants.length === 0) return true;
      if (typeof this.consumeHours !== 'number') return true;
      return (
        !this.formValid ||
        this.completedQuotation ||
        // !this.validCreditDeduct ||
        // !this.validAdminDeduct ||
        !this.masterHasAuth ||
        this.notEnoughExtensionHour
      );
    },
    isSpecialTwoHourCeiling() {
      return !!this.docs?.created_by?._VIP_activated?.discount?.specialTwoHourCeiling;
    },
    isToday() {
      return moment().isSame(this.docs.time.start, 'day');
    },
    notEnoughExtensionHour() {
      //this.maximumConsumableHours
      return this.consumeHours > this.maximumConsumableHours;
    },
    posPaymentTimeRange() {
      return {
        start: null,
        end: null,
      };
    },
  },
  methods: {
    get,
    oidEquals,
    timeRange,
    close() {
      this.$set(this.docs, 'status', this.oldstatus);
      this.cancelBookingDialog = false;
    },
    async cancel() {
      // this.docs.status = '97$cancelled';
      await this.markStatusToggle();
      this.cancelBookingDialog = false;
    },
    async resume() {
      try {
        this.docs = await crudUpdate(api, 'toca/admin/bookings', getOid(this.docs), {
          status: '0$created',
        });
        this.$emit('update', this.docs?._timetable?._id);
      } catch (e) {}
    },

    async reload() {
      // if (!this.id || !this.bookingDetails) {
      //   const res = this.isAdmin
      //     ? await getPaymentPreference(api)
      //     : await getPaymentPreferenceByMaster(api);
      //   this.otherDiscountList = res?.otherDiscount || [];
      //   return;
      // }

      //if (this.docs._timetable) this.localAssistantMaster = this.docs._timetable.assistantMaster;
      //if (!this.docs.creditDeduct) this.docs.creditDeduct = 0;

      this.clientId = getOid(this.docs.created_by);
      const serviceDetails = this.docs.serviceDetails;
      this.timetable = this.docs._timetable;
      await this.reloadClientExtensionProducts();
      this.setLocalTimeTable(this.timetable);

      //this.singleTreatmentCheckBox = !(this.packageCheckBox = !!serviceDetails.packageSpecified);
    },

    async actionVoid(id) {
      if (!confirm('Do you confirm to void?')) return;
      try {
        await voidBooking(api, id);
        await this.updateAlertMessage({
          msg: 'Voided',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        // alert('failed to void');
      }
      this.$emit('reload');
    },

    handleMasterSpecified() {
      if (this.docs._timetable) {
        this.docs.serviceDetails.needExtraFeeForMasterSpecified = oidEquals(
          get(this.docs, 'serviceDetails.masterSpecified'),
          get(this.docs, '_timetable.master'),
        );
      }
    },
    setLocalTimeTable(timeTable) {
      let temp = {
        Date: null,
        TimeStart: null,
        TimeEnd: null,
      };
      if (timeTable && timeTable.time) {
        const { start, end } = timeTable.time;
        if (start) {
          temp.Date = moment(start).format('YYYY-MM-DD');
          temp.TimeStart = moment(start).format('HH:mm');
        }
        if (end) {
          temp.TimeEnd = moment(end).format('HH:mm');
        }
      }
      this.localTimeTable = temp;
    },
    async promptSaveTimeTable() {
      // if (!confirm('do you confirm to update quotation?')) return;
      // convert localTimeTable
      const { Date, TimeStart, TimeEnd } = this.localTimeTable;
      let payload = {
        time: {
          start: moment(Date + ' ' + TimeStart, 'YYYY-MM-DD HH:mm'),
          end: moment(Date + ' ' + TimeEnd, 'YYYY-MM-DD HH:mm'),
        },
      };
      try {
        await crudUpdate(api, 'toca/admin/timetables', this.docs?._timetable?._id, payload);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Update Success',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
        this.$emit('update', this.docs?._timetable?._id);
        this.showTimeTableEditDialog = false;
        this.timeDialog = false;
      } catch (e) {}
    },
    actionCancel() {
      this.showTimeTableEditDialog = false;
      this.setLocalTimeTable(this.timetable);
    },
    async markLate(late) {
      await markLate(api, this.id, late);
      this.$emit('update', this.docs?._timetable?._id);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: late ? 'Mark Late' : 'Mark Not Late',
        type: 'success',
        color: 'success',
      });
    },

    async markCalledCustomerForBookingConfirmation(calledCustomerForBookingConfirmation) {
      await markCalledCustomerForBookingConfirmation(
        api,
        this.id,
        calledCustomerForBookingConfirmation,
      );
      this.$emit('update', this.docs?._timetable?._id);
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: calledCustomerForBookingConfirmation
          ? 'Called customer confirm booking'
          : 'Have Not Called customer confirm booking',
        type: 'success',
        color: 'success',
      });
    },

    openCompleteDialog() {
      this.paymentDialog = false;
      this.completeDialog = true;
    },
    async markStatusToggle() {
      await markStatus(api, this.id, this.docs.status);
      this.$emit('update', this.docs?._timetable?._id);

      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Mark ' + this.$options.filters['status'](this.docs.status),
        type: 'success',
        color: 'success',
      });
    },
    async markCompleted() {
      try {
        await completeBooking(api, this.id, {
          serviceDetails: this.docs?.serviceDetails,
          remarks: this.docs?.remarks,
          otherDiscount: this.docs?.otherDiscount,
          creditDeduct: this.docs?.creditDeduct,
          adminDeduct: this.docs?.adminDeduct,
          ...this.paymentList,
          paymentMethod: this.selectedPaymentMethod,
          assistantMaster: this.localAssistantMaster,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Mark Completed',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
        this.$emit('update', this.docs?._timetable?._id);
        //this.$set(this.docs, 'status', `99$completed`);
        this.completeDialog = false;
        this.$emit('close');
      } catch (e) {
        if (e.code === 'insufficient_balance') {
          alert('Insufficient credit balance');
        } else if (
          e.subcode === 'invalid_status_time_in_future' ||
          (e.code === 'invalid_status' && e.message?.includes('invalid_status_time_in_future'))
        ) {
          alert('Start time not yet meet');
        } else {
          alert('Booking Status must be arrived');
        }
        this.completeDialog = false;
      }
    },
    async tryCallComputedPayment() {
      try {
        if (this.docs.serviceDetails && this.docs.serviceDetails.serviceProduct) {
          this.paymentList = await computePayment(api, this.docs);
        } else {
          this.paymentList = null;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async save() {
      try {
        await crudUpdate(api, 'toca/admin/bookings', this.id, {
          serviceDetails: this.docs?.serviceDetails,
          remarks: this.docs?.remarks,
          amountPayable: this.computedAmount?.amountPayable,
          assistantMaster: this.docs?.assistantMaster,
          otherDiscount: this.docs?.otherDiscount,
          creditDeduct: this.docs?.creditDeduct,
          adminDeduct: this.docs?.adminDeduct,
        });
        await this.patchTimeTableAssistantMaster();
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.noSaved = false;
        this.$emit('update', this.docs?._timetable?._id);
        // this.$emit('close');   //[CHECK] disabled auto close for the convenience of 'marked completed' flow
        this.$emit('reload');
      } catch (e) {
        console.log(e);
      }
    },
    async markSettled() {
      try {
        await settleBooking(api, this.id, {
          clientIds: this.selectedParticipants.map(({ profile }) => getOid(profile)),
          consumeHours: this.consumeHours,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Settled',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
        this.$emit('update', this.docs?._timetable?._id);
        this.settleDialog = false;
        this.$emit('close');
      } catch (e) {
        const ae = ApiError.wrap(e);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: ae.message,
        });
        // if (e.code === 'insufficient_balance') {
        //   alert('Insufficient credit balance');
        // } else if (
        //   e.subcode === 'invalid_status_time_in_future' ||
        //   (e.code === 'invalid_status' && e.message?.includes('invalid_status_time_in_future'))
        // ) {
        //   alert('Start time not yet meet');
        // } else {
        //   alert('Booking Status must be arrived');
        // }
        // this.completeDialog = false;
      }
    },
    async reloadClientExtensionProducts() {
      this.clientExtensionProducts = (
        await api.get(`/toca/master/clientProfile/extensionProducts/${this.clientId}`, {
          params: {
            sessionType: this.docs.serviceDetails.serviceProduct.sessionType,
            gameType: this.docs.serviceDetails.serviceProduct.gameType,
          },
        })
      ).data;
    },
    async boundBookingItem({ productOrder, bookingItem }) {
      const confirm = await this.$root.$confirm.open(
        'Confirm',
        `Do you bound the payment ${productOrder.sid}? `,
        {
          color: 'secondary',
          width: '30%',
        },
        'OK',
      );
      if (confirm) {
        await updateOrderBookingItemsBounding(api, getOid(bookingItem), this.docs._id);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Bounded',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
        this.unboundBookingsSelectorDialog = false;
      }
    },
    async unBoundProductOrder() {
      const confirm = await this.$root.$confirm.open(
        'Confirm',
        `Do you unbound the payment?`,
        {
          color: 'secondary',
          width: '30%',
        },
        'OK',
      );
      if (confirm) {
        const bookingItem = this.docs._productOrder.bookingItems.find(
          item => (item.booking = this.docs._id),
        );
        await updateOrderBookingItemsBounding(api, bookingItem._id, null);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Unbounded',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invoice-heading {
  text-align: center;
  margin-top: 10px;
}

.invoice-title {
  padding-top: 8px;
  font-size: 22px;
  color: var(--v-primary-base);
}

::v-deep {
  .v-input--is-disabled.v-select > .v-input__control > .v-input__slot {
    cursor: default !important;
  }
}

.paymentBox {
  background-color: #f48a03;
  height: 35px;
  width: 100px;
}

.size {
  font-size: 18px;
}

.cursorPointer {
  cursor: pointer;
}

.cursorInitial {
  cursor: initial;
}

.sharedTag {
  right: 0px;
  bottom: 0px;
  position: absolute;
  border-left: 1px solid #b9b9b9;
  border-top: 1px solid #b9b9b9;
  padding: 5px;
}

.selectedPackageItem {
  background-color: var(--v-primary-base) !important;
  color: white;
}

.selectedDifferentHairLength {
  background-color: #f48a03 !important;
  color: white;
}

.chipOrange {
  color: #debc95;
}

.borderBottom {
  border-bottom: 1px solid #b9b9b9;
}

.mt-5.mb-5 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.total-title-style {
  position: absolute;
  white-space: nowrap;
  right: 208px;
  color: var(--v-primary-base);
}
</style>
