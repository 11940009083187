<template>
  <ListView
    title="CreditRecord"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
  >
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment('YYYY-MM-DD hh:mm:ss A') }}
    </template>
    <template v-slot:item.amount="{ item }">
      {{ item.amount | toThousandFilter }}
    </template>
    <credit-record-edit-dialog v-model="editDialog" title="Create Credit Record" @reload="reload" />
  </ListView>
</template>

<script>
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import { mapGetters } from 'vuex';
import CreditRecordEditDialog from '@/components/CreditRecord/CreditRecordEditDialog.vue';

export default {
  name: 'CreditRecord',
  components: { CreditRecordEditDialog },
  data() {
    return {
      header: [
        { text: 'Date', value: 'created_at' },
        { text: 'Client', value: 'created_by.name' },
        { text: 'VIP Record Id', value: 'ref_VIP.sid' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Credit', value: 'amount' },
        { text: 'Status', value: 'status' },
      ],
      productCategories: [],
      sort: { created_at: -1 },
      populate: ['ref_VIP', 'created_by'],
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return RESTFUL.creditRecord.list;
    },
  },
  mounted() {},
  methods: {},
};
</script>
