/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {Object} payload
 */
export async function createPayment(api, id, payload) {
  return (await api.post(`toca/ProductOrders/admin/${id}/createPayment`, payload)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {Object} payload
 */
export async function createVipPayment(api, id, payload) {
  return (await api.post(`toca/master/VIPProductPurchaseRecord/${id}/createPayment`, payload)).data;
}
