<template>
  <ListView
    title="CheckIn/Out"
    @reload="reload"
    @create="actionCreate"
    @navDetails="() => {}"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="false"
  >
    <template v-slot:item.created_at="{ item }">
      <div>{{ item.created_at | moment('YYYY/MM/DD HH:mm:ss') }}</div>
    </template>
    <template v-slot:item.recordType="{ item }">
      <div v-if="item.recordType === 'checkIn'">Check In</div>
      <div v-else-if="item.recordType === 'checkOut'">Check Out</div>
      <div v-else>{{ item.recordType }}</div>
    </template>
  </ListView>
</template>

<script>
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import api from '@/api';
import { mapGetters } from 'vuex';

export default {
  name: 'CheckInRecordList',
  props: {
    client: null,
    timeRange: null,
  },
  data() {
    return {
      productCategories: [],
      sort: { created_at: 1 },
      populate: [
        { path: 'client', select: ['name'] },
        { path: 'recorded_by', select: ['name'], model: 'profile' },
      ],
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return RESTFUL.checkInRecord.list;
    },
    header() {
      return [
        { text: 'Time', value: 'created_at' },
        ...(this.client ? [] : [{ text: 'Client', value: 'client.name' }]),
        { text: 'Type', value: 'recordType' },
        { text: 'Recorded By', value: 'recorded_by.name' },
      ];
    },
    filter() {
      let out = {};
      if (this.client) out['client'] = this.client;
      if (this.timeRange) {
        out['created_at'] = {
          ...(!!this.timeRange.start && { $gte: this.timeRange?.start }),
          ...(!!this.timeRange.end && { $lte: this.timeRange?.end }),
        };
      }
      return out;
    },
  },
  mounted() {},
  methods: {},
};
</script>
