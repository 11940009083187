<template>
  <ListView
    title="VIP Action Log"
    @reload="reload"
    @create="actionCreate"
    @navDetails="() => {}"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="false"
  >
    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | moment('YYYY-MM-DD hh:mm:ss A') }}
    </template>
  </ListView>
</template>

<script>
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import { mapGetters } from 'vuex';

export default {
  name: 'CreditRecord',
  data() {
    return {
      header: [
        { text: 'Date', value: 'created_at' },
        { text: 'Action', value: 'action' },
        { text: 'Staff', value: 'action_by.name' },
        { text: 'Client', value: 'created_by.name' },
        { text: 'VIP Record Id', value: 'ref_VIP.sid' },
      ],
      productCategories: [],
      sort: { created_at: -1 },
      populate: ['created_by', 'action_by', 'ref_VIP'],
    };
  },
  mixins: [listViewPageMixin],
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return RESTFUL.vipActionLogs.list;
    },
  },
  mounted() {},
  methods: {},
};
</script>
