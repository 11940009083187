<template>
  <v-card elevation="0" outlined>
    <v-row no-gutters>
      <v-col cols="2">
        <div
          class="d-flex full-height flex-column align-center"
          style="border-right: 1px solid #e0e0e0"
        >
          <b style="color: #4d4d4d; padding-top: 43px">Round {{ roundNumber }}</b>
          <v-select
            v-if="roundNumber === 1"
            :items="durationOption"
            v-model="target.duration"
            placeholder="30 mins"
            class="inlineSelect my-5 mx-5"
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <div>{{ item }} mins</div>
            </template>
          </v-select>

          <v-select
            v-if="roundNumber === 2"
            :items="durationOption2"
            v-model="target.duration"
            class="inlineSelect my-5 mx-5"
            outlined
            dense
          >
            <template v-slot:selection="{ item, index }">
              <div v-if="item === `Only 1 Round`">{{ item }}</div>
              <div v-else>{{ item }} mins</div>
            </template>
          </v-select>
        </div>
      </v-col>
      <v-col cols="10">
        <template v-for="pIndex in 3">
          <div :key="`firstRound-${pIndex}`" class="borderBottom">
            <div style="padding: 15px">
              <div
                v-if="!(roundNumber === 2 && pIndex === 1)"
                class="d-flex justify-space-between responsiveGap"
                style="padding-top: 20px; padding-bottom: 20px"
              >
                <div
                  class="d-flex align-center flex-grow-0 flex-shrink-1 flex-wrap"
                  style="gap: 12px"
                >
                  <span> {{ pIndex - (roundNumber - 1) }}. </span>
                  <div
                    :key="item + i"
                    v-for="(item, i) in target[`color${pIndex}`]"
                    class="d-flex align-center flex-shrink-1"
                    style="gap: 12px; flex-basis: 200px"
                  >
                    <div
                      class="d-flex align-center flex-grow-0 flex-shrink-1"
                      style="gap: 12px; border: 1px solid black; padding: 5px; border-radius: 5px"
                    >
                      <v-select
                        :key="`select-${i}`"
                        v-model="target[`color${pIndex}`][i]"
                        :items="colorOptions"
                        class="inlineSelect"
                        dense
                        hide-details
                        item-text="label"
                        item-value="label"
                        outlined
                      />
                      <div
                        :key="`div-${i}`"
                        :style="{
                          backgroundColor: getColor(get(target, [`color${pIndex}`, i])),
                        }"
                        class="flex-shrink-0"
                        style="width: 16px; height: 16px"
                      >
                        &nbsp;
                      </div>
                      <v-select
                        v-model="target[`unit${pIndex}`][i]"
                        :items="doseOption"
                        class="inlineSelect"
                        dense
                        hide-details
                        outlined
                        placeholder="Dose"
                      />
                      <template v-if="i >= 1">
                        <v-icon
                          @click="
                            () => {
                              target[`unit${pIndex}`].splice(i, 1);
                              target[`color${pIndex}`].splice(i, 1);
                            }
                          "
                        >
                          mdi-minus-circle-outline
                        </v-icon>
                      </template>
                    </div>

                    <template v-if="target[`color${pIndex}`].length > i + 1">
                      <v-icon small>mdi-plus-thick</v-icon>
                    </template>
                  </div>
                </div>
                <!--                              Second Color-->
                <v-icon
                  class="flex-shrink-0 flex-grow-0"
                  color="primary"
                  @click="$emit('addNew', roundNumber, pIndex)"
                  >mdi-plus-circle-outline
                </v-icon>
              </div>
              <div class="d-flex justify-space-between">
                <template v-if="roundNumber === 2 && pIndex === 1">
                  <v-checkbox v-model="washAndDye" label="洗補"></v-checkbox>
                </template>
                <v-radio-group v-model="target[`type${pIndex}`]" row v-else>
                  <template>
                    <v-radio class="mr-16" color="primary" label="根" value="hairRoot" />
                    <v-radio class="mr-16" color="primary" label="前" value="hairFront" />
                    <v-radio class="mr-16" color="primary" label="尾" value="hairTail" />
                    <v-radio class="mr-16" color="primary" label="全" value="full" />
                    <v-radio class="mr-16" color="primary" label="頭頂" value="top" />
                  </template>
                </v-radio-group>
                <!--     temp hide for phase  1           -->
                <!--                <v-btn v-if="pIndex !== 1" @click="$emit('cloneRow', pIndex)"-->
                <!--                  >Clone from above row</v-btn-->
                <!--                >-->
                <v-icon
                  v-if="!(roundNumber === 2 && pIndex === 1)"
                  @click="$emit('removeRow', roundNumber, pIndex)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </div>
            </div>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { doseOption, durationOption, durationOption2 } from '@/data/optionsConstants';
import { get } from 'lodash';

export default {
  name: 'ColorEditSection',
  props: {
    target: {},
    roundNumber: {
      default: 1,
    },
    colorOptions: [],
  },
  emits: ['cloneRow', 'addNew'],
  data() {
    return {
      doseOption,
      durationOption,
      durationOption2,
    };
  },
  computed: {
    washAndDye: {
      get() {
        return this.target.type1 === 'washAndDye';
      },
      set(v) {
        this.$set(this.target, 'type1', v ? 'washAndDye' : null);
      },
    },
  },
  methods: {
    get,
    getColor(firstRound) {
      if (firstRound) {
        const res = this.colorOptions.find(x => x.label === firstRound);
        if (res) return res.value;
        return `#ffffff`;
      }
      return `#ffffff`;
    },
  },
};
</script>

<style scoped></style>
