var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.serviceRecordList)?_c('div',{staticClass:"py-3"},[_vm._m(0),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.serviceRecordList,"items-per-page":_vm.limit,"server-items-length":_vm.totalLength},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeRowsPerPage,"click:row":function (x) { return _vm.navDetails(x); }},scopedSlots:_vm._u([{key:"item.Date",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.moment(item._timetable.time.start).format('YYYY-MM-DD'))+" ")])]}},{key:"item.Time",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.moment(item._timetable.time.start).format('HH:mm-') + _vm.moment(item._timetable.time.end).format('HH:mm'))+" ")])]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, 'serviceDetails.serviceProduct.name', '--').replace( 'Herbal Hair Color Package', '' ) || '-')+" ")])]}},{key:"item.colorProportion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_c('span',[_vm._v(_vm._s(item.isVoided ? 'N/A' : _vm.colorProportion(item.serviceRecord.colorProportion)))])])]}},{key:"item.RoundDuration",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, 'serviceRecord.colorProportion.firstRound.duration'))+" mins (R1)+ "+_vm._s(_vm.get(item, 'serviceRecord.colorProportion.secondRound.duration'))+" mins (R2)")])]}},{key:"item.servingTherapist",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.get(item, '_timetable.master.name', '-')))])]}},{key:"item.Master",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, 'serviceDetails.masterSpecified.name') === _vm.get(item, '_timetable.master.name') ? 'Yes' : 'No'))])]}},{key:"item.assistantTherapist",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(_vm._s(_vm.get(item, '_timetable.assistantMaster.name', '-')))])]}},{key:"item.Remarks",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tableCol"},[_vm._v(" "+_vm._s(_vm.get(item, 'serviceRecord.remarks', '-')))])]}}],null,false,1503206980)})],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5 d-flex justify-space-between"},[_c('div',{staticClass:"align-self-center",staticStyle:{"min-width":"160px"}},[_c('b',[_vm._v("Booking Record")])])])}]

export { render, staticRenderFns }