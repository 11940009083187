<template>
  <div v-if="serviceRecord" class="py-3">
    <div class="py-3 mb-5">
      <div class="align-self-center" style="min-width: 160px"><b>Remarks</b></div>
    </div>
    <v-row align="center">
      <v-col cols="auto">
        <u>Remarks of that case</u>
      </v-col>
      <v-col>
        <div style="max-width: 740px">
          <v-textarea
            v-model="serviceRecord.remarks"
            hide-details
            outlined
            placeholder="Type Here"
            rows="3"
          ></v-textarea></div
      ></v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="auto">
        <u>Before Service Photo</u>
      </v-col>
      <v-col>
        <div v-if="beforeServicePhoto" class="d-flex">
          <a :href="beforeServiceFullImage" target="_blank">
            <v-img :src="beforeServicePhoto" max-height="150" max-width="200"> </v-img>
            {{
              serviceRecord &&
              serviceRecord.beforeServicePhoto.created_at | calendarDate('YYYY/MM/DD (ddd) HH:mm')
            }}
          </a>
          <v-btn icon @click="removeImage('beforeServicePhoto')">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>

        <v-file-input
          v-if="!beforeServicePhoto"
          v-model="computedNewBeforeServicePhoto"
          class="flex-grow-1"
          hide-details
          label="Image"
          outlined
          style="max-width: 740px"
          accept="image/jpeg,image/png"
        />
      </v-col>
    </v-row>
    <v-row align="center" class="mb-5">
      <v-col cols="auto">
        <u>After Service Photo</u>
      </v-col>
      <v-col>
        <div v-if="afterServicePhoto" class="d-flex">
          <a :href="afterServiceFullImage" target="_blank">
            <v-img :src="afterServicePhoto" max-height="150" max-width="200"> </v-img>
            {{
              serviceRecord &&
              serviceRecord.afterServicePhoto.created_at | calendarDate('YYYY/MM/DD (ddd) HH:mm')
            }}
          </a>
          <v-btn icon @click="removeImage('afterServicePhoto')">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>

        <v-file-input
          v-if="!afterServicePhoto"
          v-model="computedNewAfterServicePhoto"
          class="flex-grow-1"
          hide-details
          label="Image"
          outlined
          style="max-width: 740px"
          accept="image/jpeg,image/png"
        />
      </v-col>
    </v-row>
    <!--    <div class="text-right">-->
    <!--      <v-btn color="primary" @click="patchRemarks">Save</v-btn>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { attnDownloadHref, attnThumbHref } from '@/services/filters';
import { cloneDeep } from 'lodash';
import { deleteAttachment, uploadSingleAttachment } from '@/api/attachment';
import api from '@/api';
import { crudUpdate } from '@/api/crud';
import { patchServiceRecord } from '@/api/bookings';
import { getOid } from '@/services/utils';

export default {
  name: 'remarks',
  props: {
    id: {
      type: String,
    },
    serviceRecord: {
      type: Object,
    },
    newBeforeServicePhoto: {
      required: true,
    },
    newAfterServicePhoto: {
      required: true,
    },
  },
  data() {
    return {
      docs: null,
    };
  },
  computed: {
    computedNewBeforeServicePhoto: {
      get() {
        return this.newBeforeServicePhoto;
      },
      set(v) {
        this.$emit('update:newBeforeServicePhoto', v);
      },
    },
    computedNewAfterServicePhoto: {
      get() {
        return this.newAfterServicePhoto;
      },
      set(v) {
        this.$emit('update:newAfterServicePhoto', v);
      },
    },
    beforeServiceFullImage() {
      return this.beforeServicePhoto
        ? attnDownloadHref(this.serviceRecord.beforeServicePhoto)
        : null;
    },
    afterServiceFullImage() {
      return this.afterServicePhoto ? attnDownloadHref(this.serviceRecord.afterServicePhoto) : null;
    },
    beforeServicePhoto() {
      return this.serviceRecord.beforeServicePhoto
        ? attnThumbHref(this.serviceRecord.beforeServicePhoto)
        : null;
    },
    afterServicePhoto() {
      return this.serviceRecord.afterServicePhoto
        ? attnThumbHref(this.serviceRecord.afterServicePhoto)
        : null;
    },
  },
  methods: {
    // async patchRemarks() {
    //   try {
    //     let payload = cloneDeep(this.serviceRecord);
    //     let beforeServicePhotoAttachment = null;
    //     let afterServicePhotoAttachment = null;
    //     if (this.newBeforeServicePhoto) {
    //       beforeServicePhotoAttachment = await uploadSingleAttachment(
    //         api,
    //         this.newBeforeServicePhoto,
    //       );
    //       payload.beforeServicePhoto = beforeServicePhotoAttachment._id;
    //     }
    //     if (this.newAfterServicePhoto) {
    //       afterServicePhotoAttachment = await uploadSingleAttachment(
    //         api,
    //         this.newAfterServicePhoto,
    //       );
    //       payload.afterServicePhoto = afterServicePhotoAttachment._id;
    //     }
    //     await patchServiceRecord(api, this.id, {
    //       beforeServicePhoto: payload.beforeServicePhoto,
    //       afterServicePhoto: payload.afterServicePhoto,
    //       remarks: this.serviceRecord.remarks,
    //     });
    //
    //     await this.$store.dispatch('alert/updateAlertMessage', {
    //       msg: 'Saved',
    //       type: 'success',
    //       color: 'success',
    //     });
    //     this.$emit('reload');
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    async removeImage(type) {
      try {
        if (!confirm('Confirm to delete?')) return;
        const payload = cloneDeep(this.serviceRecord);
        payload[type] = null;
        await deleteAttachment(api, getOid(this.serviceRecord[type]));
        await crudUpdate(api, 'toca/admin/bookings', this.id, {
          serviceRecord: payload,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped></style>
