<template>
  <v-container>
    <v-card flat>
      <v-card-title>{{ title }}</v-card-title>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row class="ma-3">
          <v-col class="ml-auto" md="auto">
            <v-btn color="primary" @click="reload" style="margin-right: 20px"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
            <v-btn :disabled="invalid" color="primary" @click="save">
              {{ isNew ? 'Save' : 'Update' }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider style="margin-bottom: 20px" />
        <div>
          <ValidationProvider v-slot="{ errors }" name="頁面" rules="required">
            <v-text-field
              v-model="model['titleZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="頁面"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="頁面" rules="required">
            <v-text-field
              v-model="model.titleZh"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="頁面"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Page" rules="required">
            <v-text-field
              v-model="model.titleEn"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Page"
              outlined
            />
          </ValidationProvider>
        </div>
        <v-divider style="margin-bottom: 20px" />
        <div v-for="(item, index) in model.question" :key="index" style="margin-left: 40px">
          <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
            <v-text-field
              v-model="item['questionZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="標題"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
            <v-text-field
              v-model="item.questionZh"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="标题"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Title" rules="required">
            <v-text-field
              v-model="item.questionEn"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Title"
              outlined
            />
          </ValidationProvider>
          <div
            v-for="(optionItem, optionIndex) in item.options"
            :key="optionIndex"
            style="margin-left: 40px"
          >
            <h2 class="mainTitle CMSTitle">中文繁體版本</h2>
            <ValidationProvider v-slot="{ errors }" name="問題">
              <v-text-field
                v-model="optionItem['questionZh-HK']"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="問題"
                outlined
              />
              <div style="margin-bottom: 20px">
                <ValidationProvider v-slot="{ errors }" name="Zh-HK content" rules="required">
                  <quillEditor
                    ref="quill"
                    :options="qillOptions"
                    v-model="optionItem['answerZh-HK']"
                  />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <h2 class="mainTitle CMSTitle">中文简体版本</h2>
              <ValidationProvider v-slot="{ errors }" name="问题">
                <v-text-field
                  v-model="optionItem.questionZh"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="问题"
                  outlined
                />
              </ValidationProvider>
              <div style="margin-bottom: 20px">
                <ValidationProvider v-slot="{ errors }" name="Zh content" rules="required">
                  <quillEditor
                    ref="quill"
                    :options="qillOptions"
                    v-model="optionItem['answerZh']"
                  />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <h2 class="mainTitle CMSTitle">English Version</h2>

              <ValidationProvider v-slot="{ errors }" name="question">
                <v-text-field
                  v-model="optionItem.questionEn"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="question"
                  outlined
                />
              </ValidationProvider>
              <div style="margin-bottom: 20px">
                <ValidationProvider v-slot="{ errors }" name="EN content" rules="required">
                  <quillEditor
                    ref="quill"
                    :options="qillOptions"
                    v-model="optionItem['answerEn']"
                  />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <v-divider style="margin-bottom: 30px" />
            </ValidationProvider>
          </div>
          <v-divider style="margin: 20px 0px" />
        </div>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { CMSPostType } from '../../data/constants';
import { crudCreate, crudList, crudUpdate } from '@/api/crud';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

export default {
  name: 'FAQ',
  components: {
    quillEditor,
  },
  data() {
    return {
      title: 'FAQ',
      postType: CMSPostType.FAQ,
      contentID: null,
      model: {},
      qillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
        readOnly: false,
      },
    };
  },
  computed: {
    isNew() {
      return !this.contentID;
    },
  },
  async beforeMount() {
    await this.reload();
  },
  methods: {
    async reload() {
      const computedQuery = {
        filter: { 'metadata.type': this.postType },
        sort: { created_at: -1 },
      };
      try {
        const res = await crudList(api, 'cms/admin/posts', computedQuery);
        this.model = res?.docs?.[0] || {};
        this.contentID = this.model?._id;
        if (this.contentID) {
          if (this.model.title) {
            const titleJson = JSON.parse(this.model.title);
            this.model['titleZh-HK'] = titleJson['zh-HK'];
            this.model.titleZh = titleJson['zh'];
            this.model.titleEn = titleJson['en'];
          }
          if (this.model.metadata.data) {
            this.model.question = this.model.metadata.data.map(item => {
              const questionJson = JSON.parse(item.question);
              let option = item.options.map(optionItem => {
                const optionQuestionJson = JSON.parse(optionItem.question);

                if (optionItem.answer) {
                  const optionAnswerJson = JSON.parse(optionItem.answer);
                  return {
                    ['questionZh-HK']: optionQuestionJson['zh-HK'],
                    questionZh: optionQuestionJson['zh'],
                    questionEn: optionQuestionJson['en'],
                    ['answerZh-HK']: optionAnswerJson['zh-HK'],
                    answerZh: optionAnswerJson['zh'],
                    answerEn: optionAnswerJson['en'],
                  };
                }
                return {
                  ['questionZh-HK']: optionQuestionJson['zh-HK'],
                  questionZh: optionQuestionJson['zh'],
                  questionEn: optionQuestionJson['en'],
                };
              });

              return {
                ['questionZh-HK']: questionJson['zh-HK'],
                questionZh: questionJson['zh'],
                questionEn: questionJson['en'],
                options: option,
              };
            });
          }
        } else {
          let tempData = [];
          for (let i = 0; i < 4; i++) {
            const emptyObject = {
              options: [{}, {}],
            };
            tempData.push(emptyObject);
          }

          this.model.question = tempData;
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS load ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const mainTitleJsonObj = {
          'zh-HK': this.model['titleZh-HK'],
          zh: this.model['titleZh'],
          en: this.model.titleEn,
        };

        let tempData = [];
        tempData = this.model.question.map(item => {
          const questionJsonObj = {
            'zh-HK': item['questionZh-HK'],
            zh: item['questionZh'],
            en: item.questionEn,
          };
          let option = item.options.map(optionItem => {
            const optionQuestionJsonObj = {
              'zh-HK': optionItem['questionZh-HK'],
              zh: optionItem['questionZh'],
              en: optionItem.questionEn,
            };
            const optionAnswerJsonObj = {
              'zh-HK': optionItem['answerZh-HK'],
              zh: optionItem['answerZh'],
              en: optionItem.answerEn,
            };
            return {
              answer: JSON.stringify(optionAnswerJsonObj),
              question: JSON.stringify(optionQuestionJsonObj),
            };
          });

          return {
            question: JSON.stringify(questionJsonObj),
            options: option,
          };
        });
        if (this.isNew) {
          //create
          const payload = {
            metadata: {
              type: this.postType,
              data: tempData,
            },
            title: JSON.stringify(mainTitleJsonObj),
          };
          const res = await crudCreate(api, 'cms/admin/posts', payload);
          // alert & update
          this.contentID = res._id;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
        } else {
          // update
          delete this.model.__v;
          delete this.model.updated_at;
          delete this.model.created_at;
          delete this.model.question;
          this.model.title = JSON.stringify(mainTitleJsonObj);
          this.model.metadata = {
            type: this.postType,
            data: tempData,
          };

          const res = await crudUpdate(api, 'cms/admin/posts', this.contentID, this.model);
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Updated',
            type: 'success',
            color: 'success',
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS post ${error}`,
          type: 'error',
          color: 'error',
        });
      } finally {
        this.reload();
      }
    },
  },
};
</script>
