<template>
  <v-dialog v-model="valueProxy" width="60%" max-width="100vw">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card min-height="250">
        <ValidationObserver ref="form" v-slot="{ invalid }">
          <v-card-title>
            <span class="mainTitle" style="font-size: 20px">Update Product List </span>
            <v-spacer />
            <v-btn icon @click="valueProxy = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <ValidationProvider v-slot="{ errors }" name="Product List File" rules="required">
              <div class="d-flex mt-3">
                <v-file-input
                  class="flex-grow-1"
                  outlined
                  v-model="inputProductListFile"
                  label="Product List File"
                  flat
                  accept=".csv"
                  :error="!!errors.length"
                  :error-messages="errors"
                />

                <v-btn
                  class="ml-3 flex-shrink-0"
                  @click="uploadProductList"
                  color="primary"
                  :loading="uploadIsLoadingRef"
                  :disabled="uploadIsLoadingRef"
                  >Upload</v-btn
                >
              </div>
            </ValidationProvider>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="getProductList" :disabled="uploadIsLoadingRef">
              Get Current Product List
            </v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import api from '@/api';
import { RESTFUL } from '@/data/constants';
import ApiError from '@/api/ApiError';
import { ValidationProvider } from 'vee-validate';
import moment from 'moment';
import { updatePhysicalProductByCSV } from '@/api/physicalProduct';

import { get, merge } from 'lodash';

export default {
  name: 'UpdateProductListDialog',
  components: { ValidationProvider },
  props: {
    value: { type: Boolean, default: false },
  },
  data() {
    return {
      inputProductListFile: null,
      uploadIsLoadingRef: false,
    };
  },
  watch: {},
  mounted() {},
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    async getProductList() {
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Downloading',
        type: 'success',
        color: 'success',
      });
      try {
        const response = await api.get('/toca/admin/PhysicalProduct/exportCSV/productsList', {
          responseType: 'blob',
          //   params: merge({}),
        });
        if (response.status >= 400) {
          const res = await response.data.text();
          const errorObj = JSON.parse(res);
          const errorMessage = errorObj.message;
          throw errorMessage;
        } else {
          const href = URL.createObjectURL(response.data);
          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `productList_${moment().format('DDMMYYYY')}.csv`);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }
      } catch (error) {
        const message = 'Download failed, ' + error;
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: message,
          type: 'error',
          color: 'error',
        });
      }
    },
    async uploadProductList() {
      if (!(await this.$refs.form.validate())) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Some fields are missing',
          type: 'error',
          color: 'error',
        });
        return;
      }
      try {
        this.uploadIsLoadingRef = true;
        const file = this.inputProductListFile;
        await updatePhysicalProductByCSV(api, file);
        this.$emit('close');
        this.$emit('reload');
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: error,
          type: 'error',
          color: 'error',
        });
      } finally {
        this.uploadIsLoadingRef = false;
      }
    },
  },
};
</script>

<style scoped></style>
