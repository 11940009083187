var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"min-height":"250"}},[_c('v-card-title',[_c('span',{staticClass:"mainTitle",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Sid"}}),_c('ValidationProvider',{attrs:{"name":"sid","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Sid","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.sid),callback:function ($$v) {_vm.$set(_vm.model, "sid", $$v)},expression:"model.sid"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Enabled"}}),_c('ValidationProvider',{attrs:{"name":"enabled","rule":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"mt-0 pt-0 ml-3",attrs:{"hide-details":""},model:{value:(_vm.model.enabled),callback:function ($$v) {_vm.$set(_vm.model, "enabled", $$v)},expression:"model.enabled"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Name"}}),_c('ValidationProvider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Name","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Listed Price"}}),_c('ValidationProvider',{attrs:{"name":"listedPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Listed Price","error":!!errors.length,"error-messages":errors,"type":"number"},model:{value:(_vm.model.listedPrice),callback:function ($$v) {_vm.$set(_vm.model, "listedPrice", $$v)},expression:"model.listedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Description"}}),_c('ValidationProvider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Description","error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Discounted Price"}}),_c('ValidationProvider',{attrs:{"name":"discountedPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Discounted Price","error":!!errors.length,"error-messages":errors,"type":"number"},model:{value:(_vm.model.discountedPrice),callback:function ($$v) {_vm.$set(_vm.model, "discountedPrice", $$v)},expression:"model.discountedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Featured Image"}}),_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Featured Image Upload","outlined":"","accept":"image/jpeg,image/png"},model:{value:(_vm.newFeaturedImage),callback:function ($$v) {_vm.newFeaturedImage=$$v},expression:"newFeaturedImage"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Images"}}),_c('ValidationProvider',{attrs:{"name":"gameType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Image Upload","outlined":"","accept":"image/jpeg,image/png","error":!!errors.length,"error-messages":errors},model:{value:(_vm.newImage),callback:function ($$v) {_vm.newImage=$$v},expression:"newImage"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Session Type"}}),_c('ValidationProvider',{attrs:{"name":"sessionType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"Session Type","items":_vm.sessionTypeOptions,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.sessionType),callback:function ($$v) {_vm.$set(_vm.model, "sessionType", $$v)},expression:"model.sessionType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Game Type"}}),_c('ValidationProvider',{attrs:{"name":"gameType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","label":"Game Type","items":_vm.gameTypeOptions,"error":!!errors.length,"error-messages":errors},model:{value:(_vm.model.gameType),callback:function ($$v) {_vm.$set(_vm.model, "gameType", $$v)},expression:"model.gameType"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Facility"}}),_c('ValidationProvider',{attrs:{"name":"Facility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"path":_vm.computedAutoCompleteEndpoint,"params":{ filter: { status: '1$accepted', isFacility: true } },"clearable":"","item-text":"name","item-value":"_id","error-messages":errors},model:{value:(_vm.computedFacility),callback:function ($$v) {_vm.computedFacility=$$v},expression:"computedFacility"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.doClose()}}},[_vm._v("Close")]),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Create ")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Update ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }