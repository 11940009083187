<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-row>
          <v-col v-if="isNot('booking')" :cols="3"><b>Booking Section</b></v-col>
          <v-col v-if="isNot('client')" :cols="2"><b>Customer</b></v-col>
          <v-col v-if="isNot('master')" :cols="2"><b>Facility</b></v-col>
          <v-col :cols="2"><b>Rating</b></v-col>
          <v-col :cols="3"><b>Review</b></v-col>
          <v-col :cols="2"><b>Suggestion</b></v-col>
        </v-row>
        <v-divider />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="item in data.docs" :key="item._id">
      <v-list-item-content>
        <v-row>
          <v-col v-if="isNot('booking')" :cols="3">{{
            item.booking && item.booking.time | datetimeRange
          }}</v-col>
          <v-col v-if="isNot('client')" :cols="2">{{
            item.created_by && item.created_by.name
          }}</v-col>
          <v-col v-if="isNot('master')" :cols="2">{{ item.master && item.master.name }}</v-col>
          <v-col :cols="2">
            {{ translateRating(item.rating) }}
          </v-col>
          <v-col :cols="3">
            <span v-for="n in item.reviewTags" :key="item._id + '-' + n"
              >{{ converter(n) || n }} <br
            /></span>
          </v-col>
          <v-col :cols="2">
            <span class="textWrap">
              {{ item.review || '-' }}
            </span>
          </v-col>
        </v-row>
        <v-divider />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="!data.docs.length" class="text-center">
      <v-row>
        <v-col> No Data </v-col>
      </v-row>
    </v-list-item>
    <v-pagination class="mt-4" v-model="pageVal" :length="data.pages || 1" />
  </v-list>
</template>

<script>
import { crudList } from '@/api/crud';
import api from '@/api';
import { mapGetters } from 'vuex';
import { roles } from '@/data/constants';

export default {
  name: 'RatingRecordList',
  props: {
    id: String,
    type: String,
  },
  data() {
    return {
      page: 1,
      data: {
        docs: [],
        pages: 1,
      },
      ratingTranslation: [
        { text: 'Bad', value: 1 },
        { text: 'Poor', value: 2 },
        { text: 'Fair', value: 3 },
        { text: 'Good', value: 4 },
        { text: 'Excellent', value: 5 },
      ],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['role']),
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
  methods: {
    isNot(v) {
      return this.type !== v;
    },
    async reload() {
      const filter = {
        booking: { booking: this.id },
        client: { created_by: this.id },
        master: { master: this.id },
      }[this.type];
      if (this.role !== roles.master) {
        this.data = await crudList(api, 'toca/admin/clientFeedbacks', {
          filter,
          page: this.page,
          populate: [{ path: 'booking' }, { path: 'created_by' }, { path: 'master' }],
        });
      } else {
        this.data = await crudList(api, 'toca/master/clientFeedbacks', {
          filter,
          page: this.page,
          populate: [{ path: 'booking' }, { path: 'created_by' }, { path: 'master' }],
        });
      }
    },
    translateRating(rating) {
      const out = this.ratingTranslation.find(x => x.value === rating);
      return out?.text || '-';
    },
    converter(v) {
      if (v === 'positiveAmbience') {
        return '✓Ambience';
      } else if (v === 'positiveService') {
        return '✓Service';
      } else if (v === 'positiveColourEffect') {
        return '✓ColourEffect';
      } else if (v === 'positiveProfessionalism') {
        return '✓Professionalism';
      } else if (v === 'negativeAmbience') {
        return 'xAmbience';
      } else if (v === 'negativeService') {
        return 'xService';
      } else if (v === 'negativeColourEffect') {
        return 'xColourEffect';
      } else if (v === 'negativeProfessionalism') {
        return 'xProfessionalism';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.textWrap {
  overflow-wrap: break-word;
  white-space: normal;
}
</style>
