<template>
  <v-container>
    <v-card flat>
      <v-card-title>{{ pageTitle }}</v-card-title>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row>
          <v-col>
            <autocomplete
              v-model="coachID"
              :path="'toca/admin/MasterProfiles'"
              :params="{
                filter: {
                  role: 'toca.master',
                  isFacility: { $ne: true },
                  status: { $ne: '99$rejected' },
                },
              }"
              clearable
              :item-text="item => getNameFromProfile(item)"
              item-value="_id"
              label="Instructor"
              @input="reload"
          /></v-col>
          <v-col class="ml-auto" md="auto">
            <v-btn color="primary" @click="reload" style="margin-right: 20px"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
            <v-btn :disabled="invalid" color="primary" @click="save">
              {{ isNew ? 'Save' : 'Update' }}
            </v-btn>
          </v-col>
        </v-row>
        <v-divider style="margin-bottom: 20px" />
        <div v-if="coachID">
          <h1>Instructor ListView Introduction</h1>

          <div class="d-flex justify-center">
            <div v-if="model.coachImage">
              <v-img :src="attnThumbHref(model.coachImage, 512)"></v-img>
            </div>
            <div v-else>No Image</div>
          </div>
          <div id="coachListView">
            <div style="margin-top: 10px">
              <v-row>
                <v-col cols="11">
                  <v-file-input
                    v-model="newCoachImage"
                    class="flex-grow-1"
                    flat
                    label="Photo Upload"
                    outlined
                    accept="image/jpeg,image/png"
                  />
                </v-col>
                <v-col cols="1">
                  <v-btn
                    :disabled="!newCoachImage"
                    class="flex-shrink-0"
                    style="margin-top: 10px"
                    color="primary"
                    @click="uploadImage('coachImage')"
                    >Upload Image
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div>
              <h2 class="mainTitle CMSTitle">中文繁體版本</h2>
              <ValidationProvider v-slot="{ errors }" name="教練顯示名稱" rules="required">
                <v-text-field
                  v-model="model['titleZh-HK']"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="教練顯示名稱"
                  outlined
                />
              </ValidationProvider>
              <div style="margin-bottom: 20px">
                <ValidationProvider v-slot="{ errors }" name="內容" rules="required">
                  <quillEditor ref="quill" :options="qillOptions" v-model="model['contentZh-HK']" />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <h2 class="mainTitle CMSTitle">中文简体版本</h2>
              <ValidationProvider v-slot="{ errors }" name="教练显示名称" rules="required">
                <v-text-field
                  v-model="model.titleZh"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="教练显示名称"
                  outlined
                />
              </ValidationProvider>
              <div style="margin-bottom: 20px">
                <ValidationProvider v-slot="{ errors }" name="內容" rules="required">
                  <quillEditor ref="quill" :options="qillOptions" v-model="model.contentZh" />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <h2 class="mainTitle CMSTitle">English Version</h2>
              <ValidationProvider
                v-slot="{ errors }"
                name="Instructor Display Name"
                rules="required"
              >
                <v-text-field
                  v-model="model.titleEn"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="Instructor Display Name"
                  outlined
                />
              </ValidationProvider>
              <div style="margin-bottom: 20px">
                <ValidationProvider v-slot="{ errors }" name="content" rules="required">
                  <quillEditor ref="quill" :options="qillOptions" v-model="model.contentEn" />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div id="coachDetailPage" v-if="model.detail">
            <v-divider style="margin-bottom: 20px" />
            <h2>Instructor Detail Page</h2>
            <div id="coachDetailTitle">
              <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
                <v-text-field
                  v-model="model.detail['titleZh-HK']"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="標題"
                  outlined
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
                <v-text-field
                  v-model="model.detail['titleZh']"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="标题"
                  outlined
                />
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }" name="Title" rules="required">
                <v-text-field
                  v-model="model.detail.titleEn"
                  :error="!!errors.length"
                  :error-messages="errors"
                  flat
                  label="Title"
                  outlined
                />
              </ValidationProvider>
            </div>
            <div id="coachDetailDisplayName">
              <v-row>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="教練名稱" rules="required">
                    <v-text-field
                      v-model="model.detail['coachNameZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="教練名稱"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="教练名称" rules="required">
                    <v-text-field
                      v-model="model.detail['coachNameZh']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="教练名称"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="Coach Name" rules="required">
                    <v-text-field
                      v-model="model.detail.coachNameEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="Instructor Name"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="serviceTitle">
              <v-row>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="服務名稱" rules="required">
                    <v-text-field
                      v-model="model.detail['serviceTitleZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="服務名稱"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="服务名称" rules="required">
                    <v-text-field
                      v-model="model.detail['serviceTitleZh']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="服务名称"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="Service Title" rules="required">
                    <v-text-field
                      v-model="model.detail.serviceTitleEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="Service Title"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="serviceYear">
              <v-row>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="服務年資" rules="required">
                    <v-text-field
                      v-model="model.detail['serviceYearZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="服務年資"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="服务年资" rules="required">
                    <v-text-field
                      v-model="model.detail['serviceYearZh']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="服务年资"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="3">
                  <ValidationProvider v-slot="{ errors }" name="Years of service" rules="required">
                    <v-text-field
                      v-model="model.detail.serviceYearEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="Years of service"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="coachDetailSection">
              <div
                v-for="(item, index) in model.detail.sections"
                :key="index"
                style="margin-left: 40px"
              >
                <v-divider style="margin-bottom: 20px" />
                <h2>Section {{ index + 1 }}</h2>
                <div class="d-flex justify-center">
                  <div v-if="item.featuredImageId">
                    <v-img :src="attnThumbHref(item.featuredImageId, 512)"></v-img>
                  </div>
                  <div v-else>No Image</div>
                </div>
                <div style="margin-top: 10px">
                  <v-row>
                    <v-col cols="11">
                      <v-file-input
                        v-model="item.newImage"
                        class="flex-grow-1"
                        flat
                        label="Photo Upload"
                        outlined
                        accept="image/jpeg,image/png"
                      />
                    </v-col>
                    <v-col cols="1">
                      <v-btn
                        class="flex-shrink-0"
                        style="margin-top: 10px"
                        color="primary"
                        @click="uploadImage('sectionImage', index)"
                        :disabled="!item.newImage"
                        >Upload Image
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <v-divider style="margin-bottom: 20px" />
                <div style="margin-bottom: 20px">
                  <h2 class="mainTitle CMSTitle">中文繁體版本</h2>
                  <ValidationProvider v-slot="{ errors }" name="內容" rules="required">
                    <quillEditor ref="quill" :options="qillOptions" v-model="item['titleZh-HK']" />
                    <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div style="margin-bottom: 20px">
                  <h2 class="mainTitle CMSTitle">中文简体版本</h2>
                  <ValidationProvider v-slot="{ errors }" name="內容" rules="required">
                    <quillEditor ref="quill" :options="qillOptions" v-model="item.titleZh" />
                    <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div style="margin-bottom: 20px">
                  <h2 class="mainTitle CMSTitle">English Version</h2>
                  <ValidationProvider v-slot="{ errors }" name="內容" rules="required">
                    <quillEditor ref="quill" :options="qillOptions" v-model="item.titleEn" />
                    <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <h1>Please select Instructor</h1>
        </div>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { CMSPostType } from '../../data/constants';
import { crudCreate, crudList, crudUpdate } from '@/api/crud';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { attnThumbHref, getNameFromProfile } from '@/services/filters';
import { uploadSingleAttachment } from '@/api/attachment';
import { generatorObjectFromJSONFormat } from '@/services/CMS';

export default {
  name: 'CMSCoach',
  components: {
    quillEditor,
    Autocomplete,
  },
  data() {
    return {
      pageTitle: 'Instructor',
      postType: CMSPostType.coach,
      contentID: null,
      coachID: null,
      newCoachImage: null,
      model: {},
      qillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
        readOnly: false,
      },
    };
  },
  computed: {
    isNew() {
      return !this.contentID;
    },
  },
  async beforeMount() {
    // await this.reload();
  },
  methods: {
    attnThumbHref,
    getNameFromProfile,
    async reload() {
      if (!this.coachID) {
        return;
      }
      const computedQuery = {
        filter: { 'metadata.type': this.postType, 'metadata.coachId': this.coachID },
        sort: { created_at: -1 },
      };
      try {
        const res = await crudList(api, 'cms/admin/posts', computedQuery);
        this.model = res?.docs?.[0] || {};
        this.contentID = this.model?._id;

        if (this.contentID) {
          if (this.model.title) {
            const titleJson = JSON.parse(this.model.title);
            this.model['titleZh-HK'] = titleJson['zh-HK'];
            this.model.titleZh = titleJson['zh'];
            this.model.titleEn = titleJson['en'];
          }
          if (this.model.content) {
            const contentJson = JSON.parse(this.model.content);
            this.model['contentZh-HK'] = contentJson['zh-HK'];
            this.model.contentZh = contentJson['zh'];
            this.model.contentEn = contentJson['en'];
          }
          if (this.model.featuredImages) {
            this.model.coachImage = this.model.featuredImages[0];
          }
          if (this.model.metadata.data) {
            // this.model.detail = {};
            this.$set(this.model, 'detail', {});
            if (this.model.metadata.data.title) {
              let result = generatorObjectFromJSONFormat('title', this.model.metadata.data.title);
              Object.assign(this.model.detail, result);
            }
            if (this.model.metadata.data.name) {
              let result = generatorObjectFromJSONFormat(
                'coachName',
                this.model.metadata.data.name,
              );
              Object.assign(this.model.detail, result);
            }
            if (this.model.metadata.data.service_title) {
              let result = generatorObjectFromJSONFormat(
                'serviceTitle',
                this.model.metadata.data.service_title,
              );
              Object.assign(this.model.detail, result);
            }

            if (this.model.metadata.data.service_year) {
              let result = generatorObjectFromJSONFormat(
                'serviceYear',
                this.model.metadata.data.service_year,
              );
              Object.assign(this.model.detail, result);
            }
            if (this.model.metadata.data.sections) {
              let sections = this.model.metadata.data.sections.map(item => {
                let obj = {
                  featuredImageId: item.featuredImageId ? item.featuredImageId : null,
                  newImage: null,
                };
                let titleResult = generatorObjectFromJSONFormat('title', item.title);
                Object.assign(obj, titleResult);

                return obj;
              });
              this.$set(this.model.detail, 'sections', []);
              sections.map((item, index) => {
                this.$set(this.model.detail.sections, index, item);
              });
            }
          }
        } else {
          let tempDetail = {
            sections: [{}, {}],
          };
          this.model.detail = tempDetail;
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS load ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const coachNameJsonObj = {
          'zh-HK': this.model['titleZh-HK'],
          zh: this.model['titleZh'],
          en: this.model.titleEn,
        };

        const coachIntroJsonObj = {
          'zh-HK': this.model['contentZh-HK'],
          zh: this.model['contentZh'],
          en: this.model.contentEn,
        };

        const dataTitleJsonObj = {
          'zh-HK': this.model.detail['titleZh-HK'],
          zh: this.model.detail['titleZh'],
          en: this.model.detail.titleEn,
        };

        const dataCoachNameJsonObj = {
          'zh-HK': this.model.detail['coachNameZh-HK'],
          zh: this.model.detail['coachNameZh'],
          en: this.model.detail.coachNameEn,
        };
        const dataServiceTitleJsonObj = {
          'zh-HK': this.model.detail['serviceTitleZh-HK'],
          zh: this.model.detail['serviceTitleZh'],
          en: this.model.detail.serviceTitleEn,
        };
        const dataServiceYearJsonObj = {
          'zh-HK': this.model.detail['serviceYearZh-HK'],
          zh: this.model.detail['serviceYearZh'],
          en: this.model.detail.serviceYearEn,
        };

        let sectionsData = [];
        sectionsData = this.model.detail.sections.map(item => {
          const titleJsonObj = {
            'zh-HK': item['titleZh-HK'],
            zh: item['titleZh'],
            en: item.titleEn,
          };
          return {
            title: JSON.stringify(titleJsonObj),
            featuredImageId: item.featuredImageId,
          };
        });

        if (this.isNew) {
          // create
          const payload = {
            title: JSON.stringify(coachNameJsonObj),
            content: JSON.stringify(coachIntroJsonObj),
            featuredImages: [this.model.coachImage],
            metadata: {
              type: this.postType,
              coachId: this.coachID,
              data: {
                title: JSON.stringify(dataTitleJsonObj),
                name: JSON.stringify(dataCoachNameJsonObj),
                service_title: JSON.stringify(dataServiceTitleJsonObj),
                service_year: JSON.stringify(dataServiceYearJsonObj),
                sections: sectionsData,
              },
            },
          };
          const res = await crudCreate(api, 'cms/admin/posts', payload);
          // alert & update
          this.contentID = res._id;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
        } else {
          // update
          delete this.model.__v;
          delete this.model.updated_at;
          delete this.model.created_at;
          this.model.title = JSON.stringify(coachNameJsonObj);
          this.model.content = JSON.stringify(coachIntroJsonObj);
          this.model.featuredImages = [this.model.coachImage];
          this.model.metadata = {
            type: this.postType,
            coachId: this.coachID,
            data: {
              title: JSON.stringify(dataTitleJsonObj),
              name: JSON.stringify(dataCoachNameJsonObj),
              service_title: JSON.stringify(dataServiceTitleJsonObj),
              service_year: JSON.stringify(dataServiceYearJsonObj),
              sections: sectionsData,
            },
          };

          const res = await crudUpdate(api, 'cms/admin/posts', this.contentID, this.model);
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Updated',
            type: 'success',
            color: 'success',
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS post ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async uploadImage(path, index) {
      try {
        let attachment;
        if (path == 'coachImage') {
          attachment = await uploadSingleAttachment(api, this.newCoachImage);
          this.model.coachImage = attachment._id;
          this.newCoachImage = null;
        } else if (path == 'sectionImage') {
          const newImage = this.model.detail.sections[index].newImage;
          if (!newImage) {
            return;
          }
          attachment = await uploadSingleAttachment(api, newImage);
          this.model.detail.sections[index].featuredImageId = attachment._id;
          this.model.detail.sections[index].newImage = null;
        }

        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Upload Image',
          type: 'success',
          color: 'success',
        });
        this.$emit('reload');
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `upload Image ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
  },
};
</script>
