<template>
  <div>
    <v-container v-if="docs && docs._id">
      <div class="d-flex">
        <h2 v-if="selectedPage === `client`">Booking Details</h2>
        <h2 v-if="selectedPage === `quotation`">Booking Details</h2>
        <h2 v-if="selectedPage === `serviceDetails`">Service Details</h2>
        <!-- <div class="ml-auto">
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div> -->
      </div>
      <v-divider class="mb-5" />
      <div v-if="false" class="mb-2 d-flex">
        <v-btn
          class="mr-5"
          :class="{
            buttonEffect: selectedPage === `client`,
          }"
          @click="selectedPage = `client`"
          >Customer Profile
        </v-btn>
        <v-btn
          class="mr-5"
          :class="{
            buttonEffect: selectedPage === `quotation`,
          }"
          @click="selectedPage = `quotation`"
          >Invoice
        </v-btn>
        <!-- <v-btn
          class="mr-5"
          @click="
            selectedPage = `serviceDetails`;
            reload();
          "
          :class="{
            buttonEffect: selectedPage === `serviceDetails`,
          }"
          >Service Details
        </v-btn> -->
        <!-- <v-btn
          class="mr-5"
          :class="{
            buttonEffect: selectedPage === `productOrder`,
          }"
          @click="selectedPage = `productOrder`"
          >Product Order
        </v-btn> -->
      </div>
      <!--    <div v-if="completedQuotation && selectedPage === `quotation`" class="mb-5 d-flex justify-end">-->
      <!--      <v-btn class="void-btn" v-if="!docs.isVoided" @click="actionVoid(id)" color="error">-->
      <!--        Void-->
      <!--      </v-btn>-->
      <!--      <v-chip v-else large color="red" label outlined>-->
      <!--        <b>Voided by: &nbsp;</b><span>{{ get(docs, ['voidedBy', 'name'], '-') }}</span> &nbsp;-->
      <!--        <b>Voided at: &nbsp;</b><span>{{ docs.voidedAt | moment }}</span>-->
      <!--      </v-chip>-->
      <div class="showCompleted">
        <v-chip class="ml-3" v-if="completedQuotation" large color="red" label outlined>
          Completed
        </v-chip>
      </div>

      <customer-profile
        v-if="selectedPage === `client` && docs.created_by"
        :id="docs.created_by._id"
        :created_by="docs.created_by"
        :docs="docs"
      />
      <booking-detail-card
        @reload="reload"
        :id="id"
        :bookingDetails="docs"
        v-if="selectedPage === `quotation`"
        @update="x => $emit('update', x)"
        @cancel="x => $emit('cancel', x)"
        header-right
      />
      <service-details-card
        :id="id"
        v-if="selectedPage === `serviceDetails`"
        @update="x => $emit('update', x)"
        @cancel="x => $emit('cancel', x)"
        @close="x => $emit('close', x)"
      />
      <product-order
        :id="id"
        :docs="docs"
        v-if="selectedPage === `productOrder`"
        @update="reload"
      ></product-order>
    </v-container>
    <v-fade-transition v-else>
      <div style="position: absolute; top: 50%; left: 50%">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </v-fade-transition>
  </div>
</template>

<script>
import api from '@/api';
import { masterReadBooking, readBooking, voidBooking } from '@/api/bookings';
import BookingDetailCard from '@/components/Booking/BookingDetailCard';
import { get } from 'lodash';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ServiceDetailsCard from '@/components/ServiceDetails/ServiceDetailsCard';
import CustomerProfile from '@/components/ServiceDetails/customerProfile';
import ProductOrder from '@/components/ServiceDetails/productOrder';

export default {
  name: 'bookingDetails',
  components: { ProductOrder, CustomerProfile, ServiceDetailsCard, BookingDetailCard },
  data() {
    return {
      tab: null,
      selectedPage: `quotation`,
      customerLabelList: [],
      docs: null,
    };
  },
  props: {
    id: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'profileId']),
    completedQuotation: function () {
      return this.docs.status === `99$completed`;
    },
    populate() {
      return [
        // 'firstAssignedMaster',
        // 'firstAssistantMaster',
        { path: 'created_by', populate: ['_VIP_activated'] },
        {
          path: 'serviceDetails.serviceProduct',
        },
        {
          path: 'serviceDetails.masterSpecified',
          select: ['name'],
        },
        'coaches',
        { path: 'otherParticipants.profile', select: ['name', 'sid', 'phone', 'uuid'] },
        {
          path: 'mainParticipant.profile',
          select: ['name', 'sid', 'phone', 'uuid'],
        },
        { path: '_timetable', populate: ['master'] },
        {
          path: 'voidedBy',
          select: ['name'],
        },
        {
          path: '_productOrder',
          select: ['sid', 'bookingItems._id'],
        },
        // { path: 'purchasedProducts.product' },
      ];
    },
  },
  methods: {
    get,
    moment,
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      if (!this.isMaster) {
        this.docs = await readBooking(api, this.id, {
          populate: this.populate,
        });
      } else {
        this.docs = await masterReadBooking(api, this.id, {
          populate: this.populate,
        });
      }
    },
    async actionVoid(id) {
      if (!confirm('Do you confirm to void?')) return;
      try {
        await voidBooking(api, id);
        await this.updateAlertMessage({
          msg: 'Voided',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        alert('failed to void');
      }
      await this.reload();
    },
    close() {
      this.docs = null;
      this.$emit('close');
    },
  },
  mounted() {
    this.reload();
  },
  watch: {
    immediate: true,
    id() {
      this.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.placeholder {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.buttonEffect {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white;
}
.void-btn {
  height: 52px !important;
  width: 112px;
  font-size: 17px;
}
.showCompleted {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}
</style>
