<template>
  <div>
    <v-row class="d-flex align-center justify-center justify-space-between" no-gutters>
      <v-col cols="1">
        <div v-if="item.product.featuredImage">
          <v-img
            :max-height="imageSize"
            :max-width="imageSize"
            :min-height="imageSize"
            :min-width="imageSize"
            :key="item.featuredImage"
            :src="attnThumbHref(item.product.featuredImage, 128)"
          ></v-img>
        </div>
        <div v-else>
          <v-img
            :max-height="imageSize"
            :max-width="imageSize"
            :min-height="imageSize"
            :min-width="imageSize"
            contain
            src="@/assets/TocaLogo.svg"
          />
        </div>
      </v-col>
      <v-col cols="2">
        <h4 class="d-inline-flex ml-3">
          {{ get(item.product, 'title["zh-cn"]', '-') | parseAndLocalize }}
        </h4>
      </v-col>
      <v-col cols="1">
        <div class="d-flex" style="font-size: 17px">
          <div>HK$</div>
          <div class="ml-1">
            <div
              :class="
                item.discountedPrice && item.discountedPrice !== item.listedPrice
                  ? 'stripeClass'
                  : ''
              "
            >
              {{ get(item, 'listedPrice', '-') | toThousandFilter }}
            </div>
            <div v-if="item.discountedPrice !== item.listedPrice">
              {{ get(item, 'discountedPrice', '-') | toThousandFilter }}
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="1">
        <div class="d-flex align-center justify-center">
          <v-chip style="width: 100%" outlined class="d-flex justify-center">
            x{{ get(item, 'qty', '-') }}
          </v-chip>
        </div>
      </v-col>
      <v-col cols="2">
        <div class="d-flex" style="font-size: 17px">
          <div>HK$</div>
          <div class="ml-1">{{ computedSubTotal(item) | toThousandFilter }}</div>
        </div>
      </v-col>
      <v-col cols="2" v-if="item.discountItem.length > 0" style="font-size: 12px">
        <div v-for="(discount, value) in item.discountItem" :key="value">
          <div>{{ computedDiscountItem(discount) }}</div>
          <div v-if="discount.title">({{ get(discount, 'title') }})</div>
        </div>
      </v-col>
      <v-col cols="2" v-else style="font-size: 17px">No Discount</v-col>
      <v-col cols="2">
        <div class="d-flex">
          <h4>HK$</h4>
          <h4 class="ml-3">{{ computedSalesTotal(item) | toThousandFilter }}</h4>
        </div>
      </v-col>
      <v-col cols="12" v-if="computedRemarks">
        <h4>Remarks: {{ computedRemarks }}</h4>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
import { get } from 'lodash';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import ImgWithPlaceholder from '@/components/imgWithPlaceholder';

export default {
  name: 'ProductItemRow',
  components: { ImgWithPlaceholder },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    productCategories: { type: Array, default: () => [] },
  },
  data() {
    return {
      imageSize: 100,
    };
  },
  computed: {
    computedRemarks() {
      return this.item?.posItem?.customRemark || this.item?.customRemark;
    },
  },
  methods: {
    get,
    attnThumbHref,
    getCategoryNameById(categoryId) {
      return (
        this.productCategories.find(el => {
          return el._id === categoryId;
        })?.title || '-'
      );
    },
    computedSubTotal(item) {
      const amount = item.discountedPrice ? item.discountedPrice : item.listedPrice;
      return Number((amount * item.qty).toFixed(2));
    },
    computedSalesTotal(item) {
      const amount = item.discountedPrice ? item.discountedPrice : item.listedPrice;
      let discountAmount = 0;
      if (item.discountItem.length > 0) {
        const considerDiscount = item.discountItem[0];
        if (considerDiscount?.effect?.type == 'fixed')
          discountAmount = Number.parseFloat(considerDiscount.effect.value);
        else if (considerDiscount?.effect?.type == 'percent')
          discountAmount =
            item.qty *
            item.discountedPrice *
            (Number.parseFloat(considerDiscount.effect.value) / 100);
      }
      return Number((amount * item.qty - discountAmount).toFixed(2));
    },
    computedDiscountItem(discountItem) {
      let output;
      if (discountItem?.effect?.type == 'percent')
        output = `Discount ${discountItem?.effect?.value}%`;
      else if (discountItem?.effect?.type == 'fixed') {
        output = `Fixed Discount HK$ ${discountItem?.effect?.value}`;
      }

      return output;
    },
  },
};
</script>

<style>
.stripeClass {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}
</style>
