var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',[_c('v-col',[_c('autocomplete',{attrs:{"path":'toca/admin/MasterProfiles',"params":{
              filter: {
                role: 'toca.master',
                isFacility: { $ne: true },
                status: { $ne: '99$rejected' },
              },
            },"clearable":"","item-text":function (item) { return _vm.getNameFromProfile(item); },"item-value":"_id","label":"Instructor"},on:{"input":_vm.reload},model:{value:(_vm.coachID),callback:function ($$v) {_vm.coachID=$$v},expression:"coachID"}})],1),_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Save' : 'Update')+" ")])],1)],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),(_vm.coachID)?_c('div',[_c('h1',[_vm._v("Instructor ListView Introduction")]),_c('div',{staticClass:"d-flex justify-center"},[(_vm.model.coachImage)?_c('div',[_c('v-img',{attrs:{"src":_vm.attnThumbHref(_vm.model.coachImage, 512)}})],1):_c('div',[_vm._v("No Image")])]),_c('div',{attrs:{"id":"coachListView"}},[_c('div',{staticStyle:{"margin-top":"10px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Photo Upload","outlined":"","accept":"image/jpeg,image/png"},model:{value:(_vm.newCoachImage),callback:function ($$v) {_vm.newCoachImage=$$v},expression:"newCoachImage"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"flex-shrink-0",staticStyle:{"margin-top":"10px"},attrs:{"disabled":!_vm.newCoachImage,"color":"primary"},on:{"click":function($event){return _vm.uploadImage('coachImage')}}},[_vm._v("Upload Image ")])],1)],1)],1),_c('div',[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"教練顯示名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"教練顯示名稱","outlined":""},model:{value:(_vm.model['titleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'titleZh-HK', $$v)},expression:"model['titleZh-HK']"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model['contentZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'contentZh-HK', $$v)},expression:"model['contentZh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"教练显示名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"教练显示名称","outlined":""},model:{value:(_vm.model.titleZh),callback:function ($$v) {_vm.$set(_vm.model, "titleZh", $$v)},expression:"model.titleZh"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model.contentZh),callback:function ($$v) {_vm.$set(_vm.model, "contentZh", $$v)},expression:"model.contentZh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"Instructor Display Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Instructor Display Name","outlined":""},model:{value:(_vm.model.titleEn),callback:function ($$v) {_vm.$set(_vm.model, "titleEn", $$v)},expression:"model.titleEn"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",attrs:{"options":_vm.qillOptions},model:{value:(_vm.model.contentEn),callback:function ($$v) {_vm.$set(_vm.model, "contentEn", $$v)},expression:"model.contentEn"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)]),(_vm.model.detail)?_c('div',{attrs:{"id":"coachDetailPage"}},[_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),_c('h2',[_vm._v("Instructor Detail Page")]),_c('div',{attrs:{"id":"coachDetailTitle"}},[_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(_vm.model.detail['titleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'titleZh-HK', $$v)},expression:"model.detail['titleZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(_vm.model.detail['titleZh']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'titleZh', $$v)},expression:"model.detail['titleZh']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(_vm.model.detail.titleEn),callback:function ($$v) {_vm.$set(_vm.model.detail, "titleEn", $$v)},expression:"model.detail.titleEn"}})]}}],null,true)})],1),_c('div',{attrs:{"id":"coachDetailDisplayName"}},[_c('v-row',[_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"教練名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"教練名稱","outlined":""},model:{value:(_vm.model.detail['coachNameZh-HK']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'coachNameZh-HK', $$v)},expression:"model.detail['coachNameZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"教练名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"教练名称","outlined":""},model:{value:(_vm.model.detail['coachNameZh']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'coachNameZh', $$v)},expression:"model.detail['coachNameZh']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"Coach Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Instructor Name","outlined":""},model:{value:(_vm.model.detail.coachNameEn),callback:function ($$v) {_vm.$set(_vm.model.detail, "coachNameEn", $$v)},expression:"model.detail.coachNameEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"serviceTitle"}},[_c('v-row',[_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"服務名稱","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"服務名稱","outlined":""},model:{value:(_vm.model.detail['serviceTitleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'serviceTitleZh-HK', $$v)},expression:"model.detail['serviceTitleZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"服务名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"服务名称","outlined":""},model:{value:(_vm.model.detail['serviceTitleZh']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'serviceTitleZh', $$v)},expression:"model.detail['serviceTitleZh']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"Service Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Service Title","outlined":""},model:{value:(_vm.model.detail.serviceTitleEn),callback:function ($$v) {_vm.$set(_vm.model.detail, "serviceTitleEn", $$v)},expression:"model.detail.serviceTitleEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"serviceYear"}},[_c('v-row',[_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"服務年資","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"服務年資","outlined":""},model:{value:(_vm.model.detail['serviceYearZh-HK']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'serviceYearZh-HK', $$v)},expression:"model.detail['serviceYearZh-HK']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"服务年资","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"服务年资","outlined":""},model:{value:(_vm.model.detail['serviceYearZh']),callback:function ($$v) {_vm.$set(_vm.model.detail, 'serviceYearZh', $$v)},expression:"model.detail['serviceYearZh']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"col":"3"}},[_c('ValidationProvider',{attrs:{"name":"Years of service","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Years of service","outlined":""},model:{value:(_vm.model.detail.serviceYearEn),callback:function ($$v) {_vm.$set(_vm.model.detail, "serviceYearEn", $$v)},expression:"model.detail.serviceYearEn"}})]}}],null,true)})],1)],1)],1),_c('div',{attrs:{"id":"coachDetailSection"}},_vm._l((_vm.model.detail.sections),function(item,index){return _c('div',{key:index,staticStyle:{"margin-left":"40px"}},[_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),_c('h2',[_vm._v("Section "+_vm._s(index + 1))]),_c('div',{staticClass:"d-flex justify-center"},[(item.featuredImageId)?_c('div',[_c('v-img',{attrs:{"src":_vm.attnThumbHref(item.featuredImageId, 512)}})],1):_c('div',[_vm._v("No Image")])]),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"11"}},[_c('v-file-input',{staticClass:"flex-grow-1",attrs:{"flat":"","label":"Photo Upload","outlined":"","accept":"image/jpeg,image/png"},model:{value:(item.newImage),callback:function ($$v) {_vm.$set(item, "newImage", $$v)},expression:"item.newImage"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-btn',{staticClass:"flex-shrink-0",staticStyle:{"margin-top":"10px"},attrs:{"color":"primary","disabled":!item.newImage},on:{"click":function($event){return _vm.uploadImage('sectionImage', index)}}},[_vm._v("Upload Image ")])],1)],1)],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item['titleZh-HK']),callback:function ($$v) {_vm.$set(item, 'titleZh-HK', $$v)},expression:"item['titleZh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item.titleZh),callback:function ($$v) {_vm.$set(item, "titleZh", $$v)},expression:"item.titleZh"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"內容","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)}),0)],1):_vm._e()]):_c('div',[_c('h1',[_vm._v("Please select Instructor")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }