<template>
  <v-dialog v-model="valueProxy" width="90%" max-width="1000px">
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn @click="valueProxy = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <ListView
        title="Bound Booking Product"
        @reload="reload"
        @create="actionCreate"
        @navDetails="actionDetails"
        :enable-create="false"
        :search.sync="search"
        :data="data"
        :page.sync="page"
        :header="header"
      >
        <template v-slot:item.order="{ item }">
          <div class="font-weight-bold">{{ item.sid }}</div>
          <div>{{ item.created_at | moment('YYYY/MM/DD HH:mm') }}</div>
        </template>
        <template v-slot:item.payment="{ item }">
          <div class="text-capitalize">{{ item.paymentMethod }}</div>
          <div>${{ item.amountPayable | toThousandFilter }}</div>
        </template>
        <template v-slot:item.contact="{ item }">
          <div>{{ item.contactName }}</div>
          <div>{{ item.contactPhone }}</div>
        </template>
        <template v-slot:item._product="{ item }">
          <div
            v-for="(bookingItem, index) in item.bookingItems"
            class="d-flex align-center my-2"
            style="gap: 8px"
          >
            <div class="flex-grow-1">
              {{ bookingItem.product.name }}
            </div>
            <div class="flex-shrink-0">
              <span class="font-weight-bold">${{ bookingItem.subtotal | toThousandFilter }}</span>
            </div>
            <v-btn small class="primary" @click.stop="selectBookingItem(item, bookingItem)"
              ><v-icon>mdi-link-variant-plus</v-icon></v-btn
            >
          </div>
          <!-- <div v-for="({ product, subtotal }, index) in item.bookingItems">
            {{ product.name }}
            <span class="font-weight-bold">${{ subtotal | toThousandFilter }}</span>
            <v-btn @click="selectBookingItem"><v-icon>mdi-link-variant-plus</v-icon></v-btn>
          </div> -->
        </template>
        <template v-slot:btn> </template>
      </ListView>
    </v-card>
  </v-dialog>
</template>

<script>
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { listProductCategories } from '@/api/productCategory';
import { mapGetters } from 'vuex';

import api from '@/api';
import moment from 'moment';

export default {
  name: 'UnboundBookingsSelectorDialog',
  model: {
    prop: 'show',
    event: 'update:show',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Object,
      default: () => {
        return {
          start: moment().startOf('d').toDate(),
          end: moment().endOf('d').toDate(),
        };
      },
    },
  },
  data() {
    return {
      header: [
        { text: 'Order Date', value: 'order' },
        { text: 'Payment', value: 'payment' },
        { text: 'Contact', value: 'contact' },
        // { text: 'Order Status', value: 'status' },
        { text: 'Product', value: '_product' },
      ],
      productCategories: [],
      createTicket: false,
      populate: [],
      createProduct: false,
      sort: {
        created_at: -1,
      },
    };
  },
  mixins: [listViewPageMixin],
  components: {},
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'isAdmin']),
    // used in mixin
    restfulURL: () => RESTFUL.physicalProductOrders.list,
    filter() {
      const out = {
        paymentStatus: 'paid',

        bookingItems: {
          $elemMatch: { booking: null },
        },
      };
      if (this.time.start || this.time.end) {
        out.created_at = {};
        out.created_at.$gte = this.time.start || undefined;
        out.created_at.$lte = this.time.end || undefined;
      }
      return out;
    },
    valueProxy: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
  },
  watch: {
    valueProxy: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  mounted() {},
  methods: {
    actionDetails(item) {
      //
    },
    selectBookingItem(productOrder, bookingItem) {
      this.$emit('select', { productOrder, bookingItem });
    },
  },
};
</script>
