<template>
  <div v-if="hairCondition" class="py-3">
    <div class="mb-5">
      <div class="align-self-center" style="min-width: 160px"><b>Hair Condition</b></div>
    </div>
    <v-card elevation="0" outlined style="position: relative">
      <div class="lastUpdatedLabel">Last updated: {{ compareDate || '-' }}</div>
      <div style="padding: 15px">
        <v-row>
          <v-col align-self="center" cols="auto" style="min-width: 200px">Gray Hair</v-col>
          <v-col>
            <!--                      replace Gray Hair-->
            <div class="d-flex" style="gap: 40px">
              <v-radio-group v-model="hairCondition.gray" row style="color: initial">
                <div
                  style="display: flex"
                  v-for="(item, i) in hairConditionReferencePhoto"
                  :key="`gray-${i}`"
                >
                  <div class="text-center">
                    <v-radio class="align-start" color="primary" :value="String(i)">
                      <template v-slot:label>
                        <img-with-placeholder :item="item" />
                      </template>
                    </v-radio>
                  </div>
                </div>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" cols="auto" style="min-width: 200px">Scalp Condition</v-col>
          <v-col style="margin-left: 29px">
            <div class="d-flex">
              <v-select
                v-model="hairCondition.scalp"
                :items="scalpSituationOption"
                class="mr-5"
                hide-details
                dense
                outlined
                style="max-width: 200px"
                @change="hairCondition.scalp !== `Other` ? (hairCondition.otherScalp = null) : null"
              />
              <v-text-field
                v-if="get(hairCondition, `scalp`) === 'Other'"
                v-model="hairCondition.otherScalp"
                hide-details
                outlined
                dense
                style="max-width: 200px"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" cols="auto" style="min-width: 200px"
            >What had been done on hair</v-col
          >
          <v-col class="d-flex">
            <div class="d-flex" style="gap: 40px">
              <v-radio-group v-model="hairCondition.workDone" row style="color: initial">
                <div
                  style="display: flex"
                  v-for="(item, i) in hairThicknessOption"
                  :key="`thick-${i}`"
                >
                  <div class="text-center">
                    <v-radio class="align-start" color="primary" :value="String(i)">
                      <template v-slot:label>
                        <img-with-placeholder :item="item" />
                      </template>
                    </v-radio>
                  </div>
                </div>
              </v-radio-group>
            </div>
            <v-text-field
              v-if="get(hairCondition, `workDone`) === 'other'"
              v-model="hairCondition.otherThickness"
              hide-details
              outlined
              dense
              style="max-width: 200px"
            />
          </v-col>
        </v-row>
        <!--    ** start hide as pre request on 4 Feb2022   -->
        <!--        <v-row>-->
        <!--          <v-col align-self="center" cols="auto" style="min-width: 200px">Hair Volume</v-col>-->
        <!--          <v-col style="margin-left: 29px">-->
        <!--            <v-select-->
        <!--              v-model="hairCondition.volume"-->
        <!--              :items="hairVolumeOption"-->
        <!--              hide-details-->
        <!--              outlined-->
        <!--              dense-->
        <!--              style="max-width: 200px"-->
        <!--            />-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <!--        <v-row>-->
        <!--          <v-col align-self="center" cols="auto" style="min-width: 200px">Hair Condition</v-col>-->
        <!--          <v-col style="margin-left: 29px">-->
        <!--            <v-select-->
        <!--              v-model="hairCondition.condition"-->
        <!--              :items="hairConditionOption"-->
        <!--              hide-details-->
        <!--              outlined-->
        <!--              dense-->
        <!--              style="max-width: 200px"-->
        <!--            ></v-select>-->
        <!--          </v-col>-->
        <!--        </v-row>-->
        <!--    ** end hide as pre request on 4 Feb2022   -->
        <v-row class="mb-5">
          <v-col align-self="center" cols="auto" style="min-width: 200px"
            >Expected color result
          </v-col>
          <v-col class="d-flex" style="margin-left: 29px">
            <div class="d-flex" style="gap: 40px">
              <v-radio-group v-model="hairCondition.expectedColor" row style="color: initial">
                <div
                  style="display: flex"
                  v-for="(item, i) in expectedColorResultOption"
                  :key="`expect-${i}`"
                >
                  <div class="text-center">
                    <v-radio class="align-start" color="primary" :value="String(i)">
                      <template v-slot:label>
                        <img-with-placeholder :item="item" />
                      </template>
                    </v-radio>
                  </div>
                </div>
              </v-radio-group>
            </div>
            <v-text-field
              v-if="get(hairCondition, `expected`) === 'Other'"
              v-model="hairCondition.otherExpected"
              hide-details
              dense
              outlined
              style="max-width: 200px"
            />
          </v-col>
        </v-row>
        <div class="text-right">
          <v-btn color="primary" @click="patchHairCondition" :disabled="!masterHasAuth">Save</v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { get } from 'lodash';
import moment from 'moment';
import { crudUpdate } from '@/api/crud';
import api from '@/api';
import { getAdminPreference } from '@/api/systemConfig';
import { attnThumbHref } from '@/services/filters';
import ImgWithPlaceholder from '@/components/imgWithPlaceholder';
import { getOid } from '@/services/utils';
import { mapGetters } from 'vuex';

export default {
  name: 'hairCondition',
  components: { ImgWithPlaceholder },
  props: {
    id: {
      type: String,
    },
    hairCondition: {},
  },
  data() {
    return {
      scalpSituationOption: [],
      hairThicknessOption: [],
      hairVolumeOption: [],
      hairConditionOption: [],
      expectedColorResultOption: [],
      hairConditionReferencePhoto: [],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'profileId', 'isAdmin']),
    compareDate() {
      if (!this.hairCondition?.last_updated) return;
      let date = 0;
      const diff = +moment()
        .startOf('d')
        .diff(moment(this.hairCondition?.last_updated).startOf('d'), 'days');
      if (diff > 0) date = diff + ' Days before';
      if (diff === 0) date = `Today`;
      return date;
    },
    masterHasAuth() {
      return (
        this.isAdmin || (this.isMaster && this.profileId === getOid(this.docs?._timetable?.master))
      );
    },
  },
  methods: {
    get,
    attnThumbHref,
    async reload() {
      const adminPreference = await getAdminPreference(api);
      this.scalpSituationOption = adminPreference.scalpCondition;
      this.hairThicknessOption = adminPreference.workDone;
      this.hairVolumeOption = adminPreference.volume;
      this.hairConditionOption = adminPreference.condition;
      this.hairConditionReferencePhoto = adminPreference.hairConditionReferencePhoto;
      this.expectedColorResultOption = adminPreference.expectedColor;
    },
    async patchHairCondition() {
      await crudUpdate(api, 'toca/admin/ClientProfiles', this.id, {
        hairCondition: this.hairCondition,
      });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Saved',
        type: 'success',
        color: 'success',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.lastUpdatedLabel {
  right: 0px;
  top: -46px;
  position: absolute;
  border: 1px solid #e0e0e0;
  padding: 10px;
  color: red;
  font-weight: bold;
}
</style>
