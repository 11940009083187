<template>
  <div>
    <v-file-input
      v-model="waitForUploadAttachments"
      dense
      outlined
      hide-details="auto"
      small-chips
      multiple
      truncate-length="20"
      class="mb-2"
    >
    </v-file-input>
    <div :class="isGrid ? 'grid' : 'list'">
      <template v-for="(att, index) in richAttachments">
        <v-sheet :key="att._id" class="pa-2 d-flex" rounded outlined>
          <span class="font-weight-bold mr-1">{{ index + 1 }}.</span>
          <div>
            <div>
              <a :href="attnDownloadHref(att)" target="_blank">
                {{ att.filename }} ({{ att.length | byte }})
                <v-img :src="attnThumbHref(att, 128)" max-height="128px" max-width="128px" />
              </a>
            </div>
            <span class="text-caption"> {{ att.created_at | moment }}</span>
          </div>
          <v-btn class="ml-auto" icon @click="removeAtt(att)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </v-sheet>
      </template>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import { attnDownloadHref, attnThumbHref } from '@/services/filters';
import { getId, getOid, oidEquals } from '@/services/utils';
import { deleteAttachment, readAttachment, uploadSingleAttachment } from '@/api/attachment';
import api from '@/api';
export default {
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    isGrid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      richAttachments: [],
      waitForUploadAttachments: [],
      waitForDeleteAttachments: [],
    };
  },
  watch: {
    attachments: {
      async handler(newValue, oldValue) {
        this.richAttachments = [];
        this.waitForUploadAttachments = [];
        if (!this.attachments?.length) return;
        this.richAttachments = Object.values(
          await readAttachment(api, this.attachments.map(getOid)),
        );
      },
      immediate: true,
    },
  },
  methods: {
    get,
    attnDownloadHref,
    attnThumbHref,
    async startUploadAttachments() {
      const attachments = await Promise.all(
        this.waitForUploadAttachments.map(uploadSingleAttachment.bind(null, api)),
      );
      attachments.map(({ _id }) => _id);

      await Promise.all(this.waitForDeleteAttachments.map(deleteAttachment.bind(null, api)));

      return [...this.richAttachments.map(({ _id }) => _id)].concat(attachments);
    },
    removeAtt(att) {
      this.richAttachments = this.richAttachments.filter(rAtt => getOid(rAtt) !== getOid(att));
      this.waitForDeleteAttachments.push(getOid(att));
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.grid {
  --grid-layout-gap: 10px;
  --grid-column-count: 8;
  --grid-item--min-width: 300px;

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
}
</style>
