var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{attrs:{"min-height":"250"}},[_c('v-card-title',[_c('span',{staticClass:"mainTitle",staticStyle:{"font-size":"20px"}},[_vm._v(" "+_vm._s('Product')+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){_vm.valueProxy = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Product Name (HK)"}}),_c('ValidationProvider',{attrs:{"name":"Product Name (HK)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Product Name (HK)","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.localizedName['zh-hk']),callback:function ($$v) {_vm.$set(_vm.localizedName, 'zh-hk', $$v)},expression:"localizedName['zh-hk']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Product Name (EN)"}}),_c('ValidationProvider',{attrs:{"name":"Product Name (EN)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Product Name (EN)","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.localizedName['en']),callback:function ($$v) {_vm.$set(_vm.localizedName, 'en', $$v)},expression:"localizedName['en']"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Listed Price"}}),_c('ValidationProvider',{attrs:{"name":"Listed Price","rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Listed Price","min":"0","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.model.listedPrice),callback:function ($$v) {_vm.$set(_vm.model, "listedPrice", $$v)},expression:"model.listedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Discount Price"}}),_c('ValidationProvider',{attrs:{"name":"Discount Price","rules":{
                  required: true,
                  min_value: 0,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Discount Price","min":"0","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.model.discountedPrice),callback:function ($$v) {_vm.$set(_vm.model, "discountedPrice", $$v)},expression:"model.discountedPrice"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Cost"}}),_c('ValidationProvider',{attrs:{"name":"Cost","rules":{ min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Cost","min":"0","outlined":"","type":"number","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.model.cost),callback:function ($$v) {_vm.$set(_vm.model, "cost", $$v)},expression:"model.cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Product Category"}}),_c('ValidationProvider',{attrs:{"name":"Product Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"pl-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.productCategories,"item-text":"title","item-value":"_id","label":"Category","outlined":"","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.model.category),callback:function ($$v) {_vm.$set(_vm.model, "category", $$v)},expression:"model.category"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Description"}}),_c('ValidationProvider',{attrs:{"name":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-textarea',{attrs:{"outlined":"","label":"Description","disabled":_vm.loading,"error-messages":errors},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Product Supplier"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return undefined}}],null,true)}),_c('v-select',{staticClass:"pl-2",staticStyle:{"max-width":"300px"},attrs:{"items":_vm.productSuppliers,"item-text":"name","item-value":"_id","label":"Supplier","outlined":""},model:{value:(_vm.model.supplier),callback:function ($$v) {_vm.$set(_vm.model, "supplier", $$v)},expression:"model.supplier"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('div',{staticClass:"d-flex"},[_c('ValidationProvider',{attrs:{"name":"barcode","rules":{ uniqueBarcodes: [_vm.computedBarcodeList] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"flat":"","label":"Barcode","outlined":"","disabled":_vm.loading},model:{value:(_vm.model.barcode[0]),callback:function ($$v) {_vm.$set(_vm.model.barcode, 0, $$v)},expression:"model.barcode[0]"}})]}}],null,true)})],1),(_vm.model && _vm.model.barcode && _vm.model.barcode.length > 1)?_c('div',[_vm._l((_vm.model &&
                    _vm.model.barcode &&
                    _vm.model.barcode.slice(1)),function(itemBarcode,index){return [_c('div',{staticClass:"d-flex align-baseline"},[_c('ValidationProvider',{attrs:{"name":"barcode","rules":{ required: true, uniqueBarcodes: [_vm.computedBarcodeList] }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"flat":"","label":"Barcode","outlined":"","disabled":_vm.loading},model:{value:(_vm.model.barcode[index + 1]),callback:function ($$v) {_vm.$set(_vm.model.barcode, index + 1, $$v)},expression:"model.barcode[index + 1]"}})]}}],null,true)}),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loading},on:{"click":function($event){return _vm.deleteBarcode(index + 1)}}},[_c('v-icon',[_vm._v("mdi-minus-circle-outline ")])],1)],1)]})],2):_vm._e(),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":_vm.addBarcode}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)],1),_c('small',{staticClass:"error--text"},[_vm._v(" Barcode(s) will not be able to edit or delete once saved. ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Featured Image"}}),_c('ValidationProvider',{attrs:{"name":"Featured Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-file-input',{attrs:{"outlined":"","label":"Image","error-messages":errors,"accept":"image/jpeg,image/png","loading":_vm.updateFeaturedImageLoading,"disabled":_vm.updateFeaturedImageLoading},model:{value:(_vm.model.featuredImage),callback:function ($$v) {_vm.$set(_vm.model, "featuredImage", $$v)},expression:"model.featuredImage"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading},on:{"click":function($event){return _vm.doClose()}}},[_vm._v("Close")]),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Create ")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary","disabled":invalid,"loading":_vm.loading},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Update ")]):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }