import {
  GlobalSchemaFields,
  IDateRange,
  populatedCtor,
  populatedListCtor,
} from '@/api/mongooseTypes';
import { IBooking, IServiceDetails, IServiceRecord } from '@/api/bookings';
import { IMasterProfile } from '@/api/auth/membership';

/**
 * @param {AxiosInstance} api
 * @param {{offset?: number, page?: number, limit?: number, search?: string, filter?: string}} [options]
 */
export async function readOrder(api, id, options) {
  const data = (await api.get(`toca/admin/ProductOrders/${id}`, { params: options })).data;
  return data;
}
export async function readPosOrder(api, id, options) {
  const data = (await api.get(`toca/admin/ProductOrder/${id}/getPosOrder`)).data;
  return data;
}

export async function readOrderPOS(api, id, options) {
  const posConfigUrl = `${process.env.VUE_APP_POS_GATEWAY}`;
  const data = (
    await api.get(posConfigUrl + `/orders/${id}/admin-panel-order-detail`, {
      params: options,
      headers: {
        Authorization: 'posfletrixlongtimetoken',
      },
    })
  ).data;
  return data;
}

export class IProductOrder extends GlobalSchemaFields {
  /** @type {string} **/
  sid;
  /** @type {IClientProfile} **/
  created_by;
  /** @type {'0$created'|'1$arrived'|'97$cancelled'|'98$absent'|'99$completed'} **/
  status;

  /** @type {IDateRange} */
  time;
  /** @type {IServiceDetails} **/
  serviceDetails;
  /** @type {number} **/
  amountPayable;
  /** @type {{name: string, description: string, percentageDiscount: number}} */
  otherDiscount;
  // /** @type {boolean} **/
  // paid;
  /**
   * remarks of order / from customer
   * @type {string}
   */
  remarks;
  /** @type {boolean} **/
  late;
  /** @type {'admin'|'client'} **/
  cancelledBy;

  /** @type {IMasterProfile} **/
  firstAssignedMaster;
  /** @type {IMasterProfile} **/
  firstAssistantMaster;
  /** @type {IMasterProfile} **/
  assignedMaster;
  /** @type {IMasterProfile} **/
  assistantMaster;
  /** @type {IServiceRecord} */
  serviceRecord;
  /**@type {ITimetable} */
  _timetable;

  constructor(obj) {
    super(obj);
    obj.assignedMaster = populatedCtor(obj.assignedMaster, IMasterProfile);
    obj.assistantMaster = populatedCtor(obj.assistantMaster, IMasterProfile);
    obj.time = populatedCtor(obj.time, IDateRange);
    obj.serviceDetails = populatedCtor(obj.serviceDetails || {}, IServiceDetails);
    obj.otherDiscount = obj.otherDiscount || {};
    obj.serviceRecord = populatedCtor(obj.serviceRecord || {}, IServiceRecord);
    obj._timetable = populatedCtor(obj._timetable, ITimetable);
    Object.assign(this, obj);
  }
}

/**
 * @param {AxiosInstance} api
 * @param {object} payload
 * @return {Promise<{IPhysicalProductOrder}>}
 */
export async function submitOrder(api, payload) {
  return (await api.post(`toca/admin/ProductOrder/createPhysicalProductOrder`, payload)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {string} bookingId
 * @return {Promise<{IPhysicalProductOrder}>}
 */
export async function updateOrderBookingItemsBounding(api, id, bookingId) {
  return (
    await api.patch(`toca/admin/ProductOrder/bookingItems/${id}/bounding`, {
      booking: bookingId,
    })
  ).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {object} data
 * @return {Promise<void>}
 */
export async function patchOrder(api, id, data) {
  return (await api.patch(`toca/admin/ProductOrders/${id}`, data)).data;
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @return {Promise<void>}
 */
export async function voidOrder(api, id) {
  return (await api.post(`toca/admin/ProductOrder/void/${id}`)).data;
}
