var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":"Extension Product","search":_vm.search,"data":_vm.data,"page":_vm.page,"header":_vm.header,"enable-create":!_vm.isMaster},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.name_cn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseLocalizedString(item.name)['zh-cn'])+" ")]}},{key:"item.name_en",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseLocalizedString(item.name).en)+" ")]}},{key:"item.name_hk",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseLocalizedString(item.name)['zh-hk'])+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"label":""}},[_vm._v(" "+_vm._s(_vm.getCategoryNameById(item.category))+" ")])]}},{key:"item.listedPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(_vm._f("toThousandFilter")(item.listedPrice))+" ")]}},{key:"item.discountedPrice",fn:function(ref){
var item = ref.item;
return [(item.discountedPrice)?_c('span',[_vm._v("$"+_vm._s(_vm._f("toThousandFilter")(item.discountedPrice)))]):_c('span',[_vm._v("-")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.description)+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }