<template>
  <v-container style="border: 1px solid black">
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-row class="ma-3">
        <v-col class="ml-auto" md="auto">
          <v-btn color="primary" @click="reload" style="margin-right: 20px"
            ><v-icon>mdi-reload</v-icon></v-btn
          >
          <v-btn :disabled="invalid" color="primary" @click="save">
            {{ isNew ? 'Save' : 'Update' }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <div v-if="supportImage">
        <h2 class="mainTitle CMSTitle">Image</h2>
        <div v-if="hasFeaturedImages">
          <v-carousel :show-arrows="hasMultiImage" :hide-delimiters="!hasMultiImage" height="300">
            <template v-for="att of model.featuredImages">
              <v-carousel-item :src="attnThumbHref(att, '2k')" cover>
                <div
                  style="position: absolute; right: 0px"
                  :style="hasMultiImage ? 'bottom: 50px;' : 'bottom: 0px'"
                >
                  <v-btn color="error" @click="confirmRemoveImage(att)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </div>
              </v-carousel-item>
            </template>
          </v-carousel>
        </div>
        <div v-else class="d-flex justify-center">No Featured Image</div>
        <div class="d-flex" style="margin-top: 20px">
          <v-file-input
            v-model="newImage"
            class="flex-grow-1"
            flat
            label="Photo Upload"
            outlined
            :multiple="supportMultiImage"
            accept="image/jpeg,image/png"
          />
          <div style="margin: 10px 20px">
            <v-btn color="success" @click="uploadImage()"> Upload </v-btn>
          </div>
        </div>
        <v-divider />
      </div>
      <div>
        <div v-if="isHomePageButNotHeader">
          <h2 class="mainTitle CMSTitle">Button Link to</h2>
          <ValidationProvider v-slot="{ errors }" name="Button Link to">
            <v-text-field
              v-model="model.buttonLink"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Button Link to"
              outlined
            />
          </ValidationProvider>
          <v-divider style="margin-top: 20px" />
        </div>
        <div>
          <h2 class="mainTitle CMSTitle">中文繁體版本</h2>
          <div v-if="isHomePageButNotHeader">
            <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
              <v-text-field
                v-model="model['titlezh-HK']"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="標題"
                outlined
              />
            </ValidationProvider>
          </div>
          <ValidationProvider v-slot="{ errors }" name="zh-HK content" rules="required">
            <quillEditor ref="quill" :options="qillOptions" v-model="model['zh-HK']" />
            <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
          <div v-if="isHomePageButNotHeader" style="margin-top: 40px">
            <ValidationProvider v-slot="{ errors }" name="按鈕內容" rules="required">
              <v-text-field
                v-model="model['buttonContentzh-HK']"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="按鈕內容"
                outlined
              />
            </ValidationProvider>
          </div>
        </div>
        <v-divider style="margin-top: 20px" />
        <div>
          <h2 class="mainTitle CMSTitle">中文简体版本</h2>
          <div v-if="isHomePageButNotHeader">
            <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
              <v-text-field
                v-model="model['titlezh']"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="标题"
                outlined
              />
            </ValidationProvider>
          </div>
          <ValidationProvider v-slot="{ errors }" name="zh content" rules="required">
            <quillEditor ref="quill" :options="qillOptions" v-model="model.zh" />
            <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
          <div v-if="isHomePageButNotHeader" style="margin-top: 40px">
            <ValidationProvider v-slot="{ errors }" name="按钮内容" rules="required">
              <v-text-field
                v-model="model['buttonContentzh']"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="按钮内容"
                outlined
              />
            </ValidationProvider>
          </div>
        </div>
        <v-divider style="margin-top: 20px" />
        <div>
          <h2 class="mainTitle CMSTitle">English Version</h2>
          <div v-if="isHomePageButNotHeader">
            <ValidationProvider v-slot="{ errors }" name="EN Title" rules="required">
              <v-text-field
                v-model="model.titleEn"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Title"
                outlined
              />
            </ValidationProvider>
          </div>
          <ValidationProvider v-slot="{ errors }" name="EN content" rules="required">
            <quillEditor ref="quill" :options="qillOptions" v-model="model.en" />
            <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
          </ValidationProvider>
          <div v-if="isHomePageButNotHeader" style="margin-top: 40px">
            <ValidationProvider v-slot="{ errors }" name="Button Content" rules="required">
              <v-text-field
                v-model="model['buttonContenten']"
                :error="!!errors.length"
                :error-messages="errors"
                flat
                label="Button Content"
                outlined
              />
            </ValidationProvider>
          </div>
        </div>
        <div v-if="isHomePageAboutUs">
          <v-divider style="margin-top: 20px" />
          <h2 class="mainTitle CMSTitle">Content 2</h2>
          <div>
            <h3>中文繁體版本</h3>
            <ValidationProvider v-slot="{ errors }" name="zh_HK content 2" rules="required">
              <quillEditor ref="quill" :options="qillOptions" v-model="model['content2zh-HK']" />
              <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div style="margin-top: 20px">
            <h3>中文简体版本</h3>
            <ValidationProvider v-slot="{ errors }" name="zh content 2" rules="required">
              <quillEditor ref="quill" :options="qillOptions" v-model="model.content2zh" />
              <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div style="margin-top: 20px">
            <h3>English Version</h3>
            <ValidationProvider v-slot="{ errors }" name="EN content 2" rules="required">
              <quillEditor ref="quill" :options="qillOptions" v-model="model.content2en" />
              <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </ValidationObserver>
    <CustomDialog ref="confirm"></CustomDialog>
  </v-container>
</template>

<script>
import CMSPostMixin from '@/services/CMSPostMixin';
import { attnThumbHref } from '@/services/filters';
import { CMSPostType } from '../../data/constants';
import CustomDialog from '@/components/CustomDialog';

export default {
  name: 'CMSEdit',
  props: {
    postType: {
      type: String,
    },
    supportImage: {
      type: Boolean,
      default: false,
    },
    supportMultiImage: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CMSPostMixin],
  components: { CustomDialog },
  data() {
    return {
      newImage: null,
    };
  },
  computed: {
    // isHomePageHeader() {
    //   return this.postType == `${CMSPostType.homePage}.header`;
    // },
    isHomePageButNotHeader() {
      const isHomePage = this.postType.includes(`${CMSPostType.homePage}.`);
      const isHeader = this.postType == `${CMSPostType.homePage}.header`;
      return isHomePage && !isHeader;
    },
    hasFeaturedImages() {
      return this.model.featuredImages?.length > 0;
    },
    hasMultiImage() {
      return this.model.featuredImages?.length > 1;
    },
    isHomePageAboutUs() {
      return this.postType == `${CMSPostType.homePage}.aboutUs`;
    },
    // isHomePage() {
    //   return this.postType.includes(`${CMSPostType.homePage}.`);
    // },
  },
  methods: {
    attnThumbHref,
    async confirmRemoveImage(id) {
      if (await this.$refs.confirm.open('Remove Image', 'Are you sure?', { color: 'red' })) {
        this.removeImage(id);
        //only remove image on local store. the user need to press button to save/update
      }
    },
  },
};
</script>
<style scoped lang="scss">
.CMSTitle {
  margin: 20px 0px;
}
</style>
