<template>
  <div class="d-flex justify-center">
    <v-btn
      class="buttonTop buttonEffect"
      @click="$emit('loadMore')"
      v-if="hasMore"
      :disabled="isLoading"
      :loading="isLoading"
    >
      Load More
    </v-btn>
    <v-btn disabled v-else class="buttonTop">Already Bottom</v-btn>
  </div>
</template>

<script>
export default {
  name: 'LoadMoreButton',
  props: {
    hasMore: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.buttonTop {
  margin-top: 32px;
  //width: 30%;
}
.buttonEffect {
  background-color: var(--v-primary-base) !important;
  padding: 0 12px !important;
  color: white;
}
</style>
