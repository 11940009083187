import { Bar, mixins } from 'vue-chartjs';
import { ChartColor, PositionType } from 'chart.js';
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    title: { type: String, default: null },
    xLabel: { type: String },
    yLabel: { type: String },
    xAxesDisplay: { type: Boolean, default: true },
    yAxesDisplay: { type: Boolean, default: true },
  },
  computed: {
    options() {
      return {
        //responsive : bool default true,
        //aspectRatio: num default 2
        //onResize : fun
        //resizeDelay num default 0
        legend: {
          display: false,
        },
        // tooltips: {
        //   enabled: false,
        // },
        maintainAspectRatio: false,
        title: {
          // display?: boolean | undefined;
          // position?: 'left' | 'right' | 'top' | 'bottom' | 'chartArea' | undefined;
          // fullWidth?: boolean | undefined;
          // fontSize?: number | undefined;
          // fontFamily?: string | undefined;
          // fontColor?: ChartColor | undefined;
          // fontStyle?: string | undefined;
          // padding?: number | undefined;
          // lineHeight?: number | string | undefined;
          // text?: string | string[] | undefined;
          text: this.title,
          display: !!this.title,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              display: this.xAxesDisplay,
              scaleLabel: {
                display: !!this.xLabel,
                labelString: this.xLabel,
              },
              ticks: {
                major: {
                  fontStyle: 'bold',
                  fontColor: '#FF0000',
                },
              },
            },
          ],
          yAxes: [
            {
              position: 'left',
              stacked: true,
              display: this.yAxesDisplay,
              type: 'linear',
              scaleLabel: {
                display: !!this.yLabel,
                labelString: this.yLabel,
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
};
