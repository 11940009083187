<template>
  <div>
    <PdfViewer ref="pdfViewer" :source="base64Pdf"></PdfViewer>
  </div>
</template>

<script>
import { get } from 'lodash';
import api from '@/api';
import { getOid } from '@/services/utils';
import PdfViewer from '@/components/PdfViewer/PdfViewer.vue';
import { getPayrollNotePdf } from '@/api/payroll';
export default {
  name: 'PayrollNote',
  components: { PdfViewer },
  props: {
    value: null,
  },
  data() {
    return {
      base64Pdf: '',
      blobPdf: null,
    };
  },
  watch: {
    value: {
      handler(newValue) {
        if (newValue) {
          this.loadPayroll(newValue);
        }
      },
      immediate: true,
    },
  },
  methods: {
    get,
    async loadPayroll(payroll) {
      if (this.$refs.pdfViewer) this.$refs.pdfViewer.isLoading = true;
      const payrollId = getOid(payroll);
      if (typeof payrollId !== 'string') return;
      try {
        const pdfArrayBuffer = await getPayrollNotePdf(api, payrollId, {
          responseType: 'arraybuffer',
        });
        this.blobPdf = new Blob([pdfArrayBuffer], { type: 'application/pdf' });
        this.base64Pdf = await this.blobToBase64(this.blobPdf);
      } catch (error) {
        this.$store.dispatch('alert/updateAlertMessage', {
          msg: '下載失敗',
        });
      }
    },
    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    print() {
      let blobURL = URL.createObjectURL(this.blobPdf);
      const win = window.open(
        blobURL,
        '',
        'left=0,top=0,width=594,height=824,toolbar=0,scrollbars=0,status=0',
      );
      setTimeout(() => {
        win.focus();
        win.print();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
