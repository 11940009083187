var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-row',{staticClass:"ma-3"},[_c('v-col',{staticClass:"ml-auto",attrs:{"md":"auto"}},[_c('v-btn',{staticStyle:{"margin-right":"20px"},attrs:{"color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-reload")])],1),_c('v-btn',{attrs:{"disabled":invalid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isNew ? 'Save' : 'Update')+" ")])],1)],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),_c('div',[_c('ValidationProvider',{attrs:{"name":"頁面","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"頁面","outlined":""},model:{value:(_vm.model['titleZh-HK']),callback:function ($$v) {_vm.$set(_vm.model, 'titleZh-HK', $$v)},expression:"model['titleZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"頁面","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"頁面","outlined":""},model:{value:(_vm.model.titleZh),callback:function ($$v) {_vm.$set(_vm.model, "titleZh", $$v)},expression:"model.titleZh"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Page","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Page","outlined":""},model:{value:(_vm.model.titleEn),callback:function ($$v) {_vm.$set(_vm.model, "titleEn", $$v)},expression:"model.titleEn"}})]}}],null,true)})],1),_c('v-divider',{staticStyle:{"margin-bottom":"20px"}}),_vm._l((_vm.model.question),function(item,index){return _c('div',{key:index,staticStyle:{"margin-left":"40px"}},[_c('ValidationProvider',{attrs:{"name":"標題","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"標題","outlined":""},model:{value:(item['questionZh-HK']),callback:function ($$v) {_vm.$set(item, 'questionZh-HK', $$v)},expression:"item['questionZh-HK']"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"标题","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"标题","outlined":""},model:{value:(item.questionZh),callback:function ($$v) {_vm.$set(item, "questionZh", $$v)},expression:"item.questionZh"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"Title","outlined":""},model:{value:(item.questionEn),callback:function ($$v) {_vm.$set(item, "questionEn", $$v)},expression:"item.questionEn"}})]}}],null,true)}),_vm._l((item.options),function(optionItem,optionIndex){return _c('div',{key:optionIndex,staticStyle:{"margin-left":"40px"}},[_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文繁體版本")]),_c('ValidationProvider',{attrs:{"name":"問題"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"問題","outlined":""},model:{value:(optionItem['questionZh-HK']),callback:function ($$v) {_vm.$set(optionItem, 'questionZh-HK', $$v)},expression:"optionItem['questionZh-HK']"}}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"Zh-HK content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(optionItem['answerZh-HK']),callback:function ($$v) {_vm.$set(optionItem, 'answerZh-HK', $$v)},expression:"optionItem['answerZh-HK']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("中文简体版本")]),_c('ValidationProvider',{attrs:{"name":"问题"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"问题","outlined":""},model:{value:(optionItem.questionZh),callback:function ($$v) {_vm.$set(optionItem, "questionZh", $$v)},expression:"optionItem.questionZh"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"Zh content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(optionItem['answerZh']),callback:function ($$v) {_vm.$set(optionItem, 'answerZh', $$v)},expression:"optionItem['answerZh']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('h2',{staticClass:"mainTitle CMSTitle"},[_vm._v("English Version")]),_c('ValidationProvider',{attrs:{"name":"question"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error":!!errors.length,"error-messages":errors,"flat":"","label":"question","outlined":""},model:{value:(optionItem.questionEn),callback:function ($$v) {_vm.$set(optionItem, "questionEn", $$v)},expression:"optionItem.questionEn"}})]}}],null,true)}),_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('ValidationProvider',{attrs:{"name":"EN content","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quillEditor',{ref:"quill",refInFor:true,attrs:{"options":_vm.qillOptions},model:{value:(optionItem['answerEn']),callback:function ($$v) {_vm.$set(optionItem, 'answerEn', $$v)},expression:"optionItem['answerEn']"}}),(errors && errors.length)?_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('v-divider',{staticStyle:{"margin-bottom":"30px"}})]}}],null,true)})],1)}),_c('v-divider',{staticStyle:{"margin":"20px 0px"}})],2)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }