var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.participants,"hide-default-footer":true,"disable-sort":true,"items-per-page":-1,"on":_vm.$listeners,"show-select":_vm.showSelect},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([(!_vm.showSelect)?{key:"header.data-table-select",fn:function(){return undefined},proxy:true}:null,{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(_vm.showSelect && !item._disabled)?_c('v-simple-checkbox',{attrs:{"large":"","ripple":false,"value":isSelected},on:{"input":select}}):_c('span',[(item._settle)?_c('div',[_c('div',[_vm._v("Settled")]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm._f("moment")(item._settle.settleAt,'YYYY-MM-DD HH:mm:ss')))])]):_c('div',[_vm._v("No Handbnand")])])]}},{key:"item.profile.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("displayPhone")(item.profile.phone))+" ")]}},{key:"item._clockOut",fn:function(ref){
var item = ref.item;
return [(item._clockOut)?_c('div',{staticClass:"d-flex align-center"},[_c('div',[_vm._v(_vm._s(_vm._f("moment")(item._clockOut,'HH:mm:ss')))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.showMoreCheckInOutHistory(item)}}},[_c('v-icon',[_vm._v("mdi-view-list-outline")])],1)],1):_vm._e()]}},{key:"item.gear",fn:function(ref){
var item = ref.item;
return [(_vm.get(item, 'gear.boots'))?_c('div',[_vm._v("Boots "+_vm._s(item.gear.boots))]):_vm._e(),(_vm.get(item, 'gear.bindings'))?_c('div',[_vm._v("Bindings "+_vm._s(item.gear.bindings))]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',_vm.$attrs,false)),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.checkInOutDialog),callback:function ($$v) {_vm.checkInOutDialog=$$v},expression:"checkInOutDialog"}},[_c('v-card',{staticClass:"pb-1 px-4",attrs:{"color":"white"}},[_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","small":""},on:{"click":function($event){_vm.checkInOutDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('CheckInRecordList',{attrs:{"timeRange":_vm.checkInOutRange,"client":_vm.selectedCheckInOutClient}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }