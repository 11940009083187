<template>
  <ListView
    title="Service Product"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="true"
  >
    <!-- <template v-slot:action>
      <v-btn text @click="reload">
        <v-icon>mdi-reload</v-icon>
      </v-btn>
    </template> -->
    <!-- <template v-slot:card="{ cardContent }">
      <ServiceProductsCard
        :content="cardContent"
        class="clickable"
        @click.native="actionDetails(cardContent)"
      />
    </template> -->
    <template v-slot:item.name_cn="{ item }">
      {{ parseLocalizedString(item.name)['zh-cn'] }}
    </template>
    <template v-slot:item.name_en="{ item }">
      {{ parseLocalizedString(item.name).en }}
    </template>
    <template v-slot:item.name_hk="{ item }">
      {{ parseLocalizedString(item.name)['zh-hk'] }}
    </template>
    <ServiceProductsEditDialog
      v-model="editDialog"
      :id="editId"
      title="Create Service Product"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </ListView>
</template>

<script>
import ServiceProductsCard from '@/components/ServiceProduct/ServiceProductsCard';
import ServiceProductsEditDialog from '@/components/ServiceProduct/ServiceProductsEditDialog';
import { RESTFUL, roles } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { mapGetters } from 'vuex';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'ServiceProduct',
  props: {
    selectedRole: {},
  },
  data() {
    return {
      header: [
        { text: 'Name(CN)', value: 'name_cn' },
        { text: 'Name(EN)', value: 'name_en' },
        { text: 'Name(HK)', value: 'name_hk' },
        { text: 'SID', value: 'sid' },
        { text: 'Listed Price', value: 'listedPrice' },
        { text: 'Description', value: 'description' },
      ],
    };
  },
  mixins: [listViewPageMixin],
  components: { ServiceProductsEditDialog, ServiceProductsCard },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return !this.isMaster ? RESTFUL.serviceProducts.list : RESTFUL.serviceProducts.masterList;
    },
    filter() {
      return this.selectedRole ? { role: this.selectedRole } : null;
    },
  },
  methods: {
    parseLocalizedString,
  },
};
</script>
