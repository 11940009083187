<template>
  <div>
    <ProductCategoryList
      :categories="data"
      :id="id"
      :booking="booking"
      :docs="docs"
      :selectedProducts="selectedProducts"
      @submit="submit"
      @updateCart="updateCart"
      @remove="removeFromCart"
    />
    <RecommendList :products="products" @addToCart="addToCart" @removeFromCart="removeFromCart" />
  </div>
</template>

<script>
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import ProductCategoryList from '@/components/ProductCategory/ProductCategoryList';
import { readBooking } from '@/api/bookings';
import RecommendList from '@/components/RecommendList';
import { clientRecommendation } from '@/api/bookings';

export default {
  name: 'productOrder',
  components: { ProductCategoryList, RecommendList },
  props: {
    id: String,
    docs: {},
  },
  data() {
    return {
      data: [],
      booking: {},
      products: [],
      selectedProducts: [],
    };
  },
  async mounted() {
    await this.getProductList();
    await this.getBookingPurchasedProducts();
    await this.getClientRecommendations();
  },
  methods: {
    async getProductList() {
      this.data = await listProductCategories(api);
    },
    async getBookingPurchasedProducts() {
      this.booking = await readBooking(api, this.id, {
        populate: { path: 'purchasedProducts.product' },
      });
    },
    async getClientRecommendations() {
      const result = (await clientRecommendation(api, this.booking?.created_by))?.docs;

      result?.map(y => this.products.push({ ...y, selectedQty: 1 }));

      // preselect purchased products from recommendations
      this.selectedProducts?.map(x => {
        let index = this.products?.findIndex(y => y?._id == x?._id);
        if (index !== -1) {
          this.products[index].checkbox = true;
          this.products[index].selectedQty = x?.selectedQty;
        }
      });
    },
    addToCart(product) {
      if (product && product.selectedQty > 0) {
        const p = this.selectedProducts.find((el, i) => el._id === product._id);
        if (p) {
          //p.selectedQty = product.selectedQty;
          this.$set(p, 'selectedQty', product.selectedQty);
        } else {
          this.selectedProducts.push(product);
        }
      }
    },
    removeFromCart(product) {
      const i = this.selectedProducts.findIndex(el => el._id === product._id);
      const recommendedProduct = this.products.find(el => el._id === product._id);
      if (recommendedProduct) {
        delete recommendedProduct.checkbox;
        recommendedProduct.selectedQty = 1;
      }
      if (i >= 0) this.selectedProducts.splice(i, 1);
    },
    updateCart(list) {
      this.$set(this, 'selectedProducts', list);
    },
    submit(model) {
      this.$emit('update', model);
    },
  },
};
</script>

<style scoped></style>
