import { mixins, Doughnut } from 'vue-chartjs';
import { ChartColor, PositionType } from 'chart.js';
const { reactiveProp } = mixins;

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: {
    title: { type: String, default: null },
  },
  computed: {
    options() {
      return {
        //responsive : bool default true,
        //aspectRatio: num default 2
        //onResize : fun
        //resizeDelay num default 0
        legend: {
          // display: true,
          position: 'bottom',
        },
        // tooltips: {
        //   enabled: false,
        // },
        maintainAspectRatio: false,
        title: {
          // display?: boolean | undefined;
          // position?: 'left' | 'right' | 'top' | 'bottom' | 'chartArea' | undefined;
          // fullWidth?: boolean | undefined;
          // fontSize?: number | undefined;
          // fontFamily?: string | undefined;
          // fontColor?: ChartColor | undefined;
          // fontStyle?: string | undefined;
          // padding?: number | undefined;
          // lineHeight?: number | string | undefined;
          // text?: string | string[] | undefined;
          text: this.title,
          display: !!this.title,
        },
      };
    },
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  },
};
