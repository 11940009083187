/**
 * fix differences between Sent and Received stock transfers
 * @param {AxiosInstance} api - the api instance to use
 * @param {string} id - the stock transfer id
 * @param {string} warehouseId - the warehouse id to add to
 * @return {Promise<Any>}
 */
export async function fixDifferences(api, id, payload) {
  return await api.post(`toca/admin/ProductActivityLog/${id}/fixDifferences`, payload).data;
}
