import { GlobalSchemaFields, IDateRange } from '@/api/mongooseTypes';
import { RESTFUL } from '@/data/constants';
import { crudRead, crudUpdate, crudCreate } from '@/api/crud';

export class CreditRecord extends GlobalSchemaFields {
  /** @type {string} **/
  created_by; /** sid of {IClientProfile} **/
  /** @type {number} **/
  amount;
  /** @type {string} */
  remarks;
  /** @type {string} **/
  action_by; /** sid of {IProfile} **/
  /** enum <'topup' | 'usage' | 'refund' | 'settlement'> **/
  action;
  /** @type {enum} */
  status;
  /** @type {string} **/
  ref_VIP; /** sid of {IVIPProductPurchaseRecord} **/

  constructor(obj) {
    super(obj);
    Object.assign(this, obj);
  }
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 */
export async function readCreditRecord(api, id, options) {
  return crudRead(api, RESTFUL.creditRecord.read, id, options);
}

/**
 * @param {AxiosInstance} api
 * @param {string} id
 * @param {Partial<CreditRecord>} creditRecordFields
 */
export async function updateCreditRecord(api, id, creditRecordFields) {
  return await crudUpdate(api, RESTFUL.creditRecord.update, id, creditRecordFields);
}

/**
 * @param {AxiosInstance} api
 * @param {Partial<CreditRecord>} creditRecordFields
 */

export async function createCreditMultipleRecords(api, creditRecordFields) {
  return (await api.post(`toca/admin/CreditRecord/createRecords`, creditRecordFields)).data;
}
