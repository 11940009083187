var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":"Receive Product","search":_vm.search,"data":_vm.data,"page":_vm.page,"header":_vm.header,"enable-create":false},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-select',{staticStyle:{"max-width":"350px"},attrs:{"items":_vm.productIdentityOptions,"outlined":"","label":"Product","placeholder":"Product"},model:{value:(_vm.productIdentityFilter),callback:function ($$v) {_vm.productIdentityFilter=$$v},expression:"productIdentityFilter"}}),_c('v-select',{staticStyle:{"max-width":"350px"},attrs:{"items":_vm.storeOptions,"outlined":"","label":"Store","placeholder":"Store","clearable":""},model:{value:(_vm.queryFilter.to),callback:function ($$v) {_vm.$set(_vm.queryFilter, "to", $$v)},expression:"queryFilter.to"}})],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at))+" ")]}},{key:"item.items.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-img',{staticClass:"grey lighten-3 ma-2 clickable",style:(_vm.haveImage(item) ? {} : { cursor: 'unset' }),attrs:{"max-width":"80","height":"80","contain":"","src":_vm.getThumbnailUrlById(
            item.items.unknownProduct ? item.items.attachment : item.items.product.featuredImage
          )},on:{"click":function($event){return _vm.viewImage(item)}}}),(item.items.unknownProduct)?_c('div',{staticClass:"red--text font-weight-bold"},[_vm._v(" Unknown Product ")]):_c('div',[_c('div',[_vm._v(_vm._s(_vm.parseLocalizedString(item.items.product.name).en))]),_c('div',[_vm._v(_vm._s(_vm.parseLocalizedString(item.items.product.name)['zh-hk']))])])],1)]}},{key:"item.to",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStoreName(item.to))+" ")]}},{key:"item.remarks",fn:function(ref){
          var item = ref.item;
return [(item.remarks)?_c('div',{staticClass:"d-flex align-center overflow-y-auto",staticStyle:{"max-height":"90px","width":"250px"}},[_c('div',{staticStyle:{"flex":"1","max-height":"90px"}},[_vm._v(_vm._s(item.remarks))]),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","elevation":"0","fab":"","x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openRemarksDialog(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1):_c('v-btn',{staticClass:"d-block mx-auto",attrs:{"fab":"","elevation":"0","dark":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openRemarksDialog(item)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)]}}])},[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.remarksDialog),callback:function ($$v) {_vm.remarksDialog=$$v},expression:"remarksDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Remarks "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","disabled":_vm.loadingUpdateRemarks},on:{"click":function($event){_vm.remarksDialog = false}}},[_c('v-icon',[_vm._v(" mdi-close")])],1)],1),_c('v-card-text',[_c('v-textarea',{attrs:{"auto-grow":"","outlined":"","hide-details":"auto"},model:{value:(_vm.computedRemarks),callback:function ($$v) {_vm.computedRemarks=$$v},expression:"computedRemarks"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loadingUpdateRemarks},on:{"click":function($event){$event.stopPropagation();return _vm.saveRemarks.apply(null, arguments)}}},[_vm._v(" Save")])],1)],1)],1),_c('CarouselDialog',{attrs:{"images":_vm.selectedImages},model:{value:(_vm.displayImage),callback:function ($$v) {_vm.displayImage=$$v},expression:"displayImage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }