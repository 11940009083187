<template>
  <ValidationObserver ref="form">
    <v-row>
      <v-col cols="4">
        <ValidationProvider
          v-slot="{ errors }"
          name="country code"
          :rules="{ required: true, isDigit: true, validCountryCode: [phone, phoneNumber] }"
        >
          <v-text-field
            v-model="countryCode"
            hide-details="auto"
            outlined
            placeholder="852"
            :error="!!errors.length"
            :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
      <v-col cols="8">
        <ValidationProvider
          v-slot="{ errors }"
          name="phone"
          :rules="{ required: true, isDigit: true, validPhone: [phone] }"
        >
          <v-text-field
            v-model="phoneNumber"
            hide-details="auto"
            outlined
            placeholder="Phone"
            :error="!!errors.length"
            :error-messages="errors"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
import { parsePhoneNumber } from 'libphonenumber-js';

const onlyDigitRegex = /^\d+$/g;

Validator.extend('isDigit', {
  getMessage: (field, params, data) => {
    return 'Please input numbers only';
  },
  validate: value => {
    return value?.match(onlyDigitRegex) !== null;
  },
});

Validator.extend('validCountryCode', {
  getMessage: (field, params, data) => {
    return 'Country code is not valid';
  },
  validate: (value, [phone, phoneNumber]) => {
    if (phoneNumber?.length >= 5) {
      try {
        const pn = parsePhoneNumber(phone);
        return value === pn?.countryCallingCode;
      } catch (error) {
        console.log('🚀 ~ error:', error);
        return false;
      }
    } else {
      return true;
    }
  },
});

Validator.extend('validPhone', {
  getMessage: (field, params, data) => {
    return 'Phone number is not valid';
  },
  validate: (value, [phone]) => {
    try {
      const phoneNumber = parsePhoneNumber(phone);
      return phoneNumber && phoneNumber?.isValid();
    } catch (error) {
      console.log('🚀 ~ error:', error);
      return false;
    }
  },
});

export default {
  name: 'PhoneValidator',
  components: { ValidationObserver, ValidationProvider },
  props: {
    phone: { type: String, default: '' },
  },
  data() {
    return {
      countryCode: '',
      phoneNumber: '',
      isInit: true,
    };
  },
  computed: {
    computedCountryCode: {
      get() {
        return this.countryCode;
      },
      set(v) {
        this.countryCode = v;
      },
    },
    computedPhoneNumber: {
      get() {
        return this.phoneNumber;
      },
      set(v) {
        this.phoneNumber = v;
      },
    },
    computedPhoneWatcher() {
      return `${this.countryCode}|${this.phoneNumber}`;
    },
  },
  watch: {
    phone: {
      once: true,
      handler(v) {
        if (v && this.isInit) {
          try {
            this.countryCode = parsePhoneNumber(v)?.countryCallingCode;
            this.phoneNumber = parsePhoneNumber(v)?.nationalNumber;
          } catch (error) {
            console.log('🚀 ~ error:', error);
          }
        }
        if (this.isInit) this.isInit = false;
      },
    },
    computedPhoneWatcher(v) {
      const phone = '+' + this.countryCode + this.phoneNumber;
      this.$emit('update:phone', phone);
    },
  },
  methods: {
    reset() {
      this.countryCode = '';
      this.phoneNumber = '';
    },
  },
};
</script>
