var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-card',{attrs:{"elevation":"0","min-height":"250"}},[_c('v-card-text',{class:_vm.isCreate ? '' : 'overrideVCardText'},[_c('v-form',[_c('v-row',[(!_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Member ID"}}),_c('ValidationProvider',{attrs:{"name":"member ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"disabled":"","outlined":"","error":!!errors.length,"error-messages":errors},model:{value:(_vm.memberId),callback:function ($$v) {_vm.memberId=$$v},expression:"memberId"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Client"}}),_c('ValidationProvider',{attrs:{"name":"client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"disabled":!_vm.isCreate || _vm.isProfileCreate,"path":'toca/admin/clientProfiles',"params":{ sort: { created_at: -1 }, limit: -1 },"item-text":function (item) { return _vm.getNameFromProfile(item); },"item-value":"_id","clearable":true,"error-messages":errors,"has-items-slot":true,"multiple":true},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.getNameFromProfile(content.item)))])]}}],null,true),model:{value:(_vm.model.created_by),callback:function ($$v) {_vm.$set(_vm.model, "created_by", $$v)},expression:"model.created_by"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Action"}}),_c('ValidationProvider',{attrs:{"name":"action","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!_vm.isCreate,"outlined":"","items":_vm.creditRecordAction,"item-text":function (item) { return item.label; },"value":function (item) { return item.sid; },"error-messages":errors},model:{value:(_vm.model.action),callback:function ($$v) {_vm.$set(_vm.model, "action", $$v)},expression:"model.action"}})]}}],null,true)})],1),(!_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Credit Transaction Date"}}),_c('ValidationProvider',{attrs:{"name":"credit_transaction_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error":!!errors.length,"error-messages":errors,"readonly":""},model:{value:(_vm.formattedDate),callback:function ($$v) {_vm.formattedDate=$$v},expression:"formattedDate"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Credit Amount"}}),_c('ValidationProvider',{attrs:{"name":"Credit Amount","rules":{ required: true, min_value: 0.00001, decimal: 5 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error":!!errors.length,"error-messages":errors,"type":"number"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=_vm._n($$v)},expression:"amount"}})]}}],null,true)})],1),(!_vm.isCreate)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Credit Balance"}}),_c('ValidationProvider',{attrs:{"name":"credit balance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","disabled":"","error":!!errors.length,"error-messages":errors,"type":"number"},model:{value:(_vm.creditBalance),callback:function ($$v) {_vm.creditBalance=$$v},expression:"creditBalance"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Remarks"}}),_c('ValidationProvider',{attrs:{"name":"remarks"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error":!!errors.length,"error-messages":errors,"type":"string"},model:{value:(_vm.model.remarks),callback:function ($$v) {_vm.$set(_vm.model, "remarks", $$v)},expression:"model.remarks"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Action By"}}),_c('ValidationProvider',{attrs:{"name":"action by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('autocomplete',{attrs:{"disabled":!_vm.isCreate,"path":'toca/admin/Profiles',"params":{
                  sort: { created_at: -1 },
                  filter: {
                    $or: [{ role: { $ne: 'toca.client' } }, { _id: _vm.clientId }],
                  },
                  limit: -1,
                },"item-text":function (item) { return _vm.getNameFromProfile(item); },"item-value":"_id","clearable":true,"error-messages":errors,"has-items-slot":true},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.getNameFromProfile(content.item)))])]}}],null,true),model:{value:(_vm.model.action_by),callback:function ($$v) {_vm.$set(_vm.model, "action_by", $$v)},expression:"model.action_by"}})]}}],null,true)})],1),(_vm.model.ref_VIP)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Related VIP Purchase"}}),_c('div',[_vm._v("Product : "+_vm._s(_vm._f("parseAndLocalize")(_vm.get(_vm.model, 'ref_VIP.VIPProduct.name'))))]),_c('div',[_vm._v("Amount : $"+_vm._s(_vm._f("toThousandFilter")(_vm.get(_vm.model, 'ref_VIP.amountPayable'))))]),_c('div',[_vm._v("Payment Method : "+_vm._s(_vm.get(_vm.model, 'ref_VIP.paymentMethod')))])],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),(_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.promptCreate()}}},[_vm._v(" Create")]):_vm._e(),(!_vm.isCreate)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.promptUpdate()}}},[_vm._v(" Update")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }