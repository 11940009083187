var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"100vw","width":"60%"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('span',{staticClass:"d-flex justify-space-between"},[_c('v-card-title',{staticStyle:{"align-items":"center"}},[_vm._v("Edit Package")]),_c('v-icon',{staticStyle:{"padding-right":"16px"},on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.isAdmin)?_c('ValidationProvider',{attrs:{"name":"Expiry Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('custom-label',{attrs:{"item-field-name":"Expiry Date (Admin Only)"}}),_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.showExpiryDatePicker),callback:function ($$v) {_vm.showExpiryDatePicker=$$v},expression:"showExpiryDatePicker"}},[_c('v-date-picker',{model:{value:(_vm.expiryDate),callback:function ($$v) {_vm.expiryDate=$$v},expression:"expiryDate"}})],1)]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Purchase Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"min-width":"auto","offset-y":"","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('custom-label',{attrs:{"item-field-name":"Purchase Date"}}),_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.pickerDate),callback:function ($$v) {_vm.pickerDate=$$v},expression:"pickerDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('v-date-picker',{model:{value:(_vm.pickerDate),callback:function ($$v) {_vm.pickerDate=$$v},expression:"pickerDate"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.isAdmin)?_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 },"name":"Quota Left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-label',{attrs:{"item-field-name":"Quota left (Admin Only)"}}),_c('v-text-field',{attrs:{"error-messages":errors,"flat":"","label":"Quota Left","outlined":"","type":"number"},model:{value:(_vm.doc.quotaLeft),callback:function ($$v) {_vm.$set(_vm.doc, "quotaLeft", _vm._n($$v))},expression:"doc.quotaLeft"}})]}}],null,true)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Remark"}},[_c('custom-label',{attrs:{"item-field-name":"Remark"}}),_c('v-text-field',{attrs:{"label":"Remark","outlined":"","flat":""},model:{value:(_vm.doc.remarks),callback:function ($$v) {_vm.$set(_vm.doc, "remarks", $$v)},expression:"doc.remarks"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":invalid},on:{"click":function($event){return _vm.patchTicket()}}},[_vm._v("Save")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }