<template>
  <div class="py-3">
    <div class="d-flex flex-column">
      <div class="align-self-end"><v-btn @click="showSelectItemsPopup = true">Add</v-btn></div>
      <div>
        <template>
          <v-divider class="mt-3" />
          <v-row class="d-flex align-center justify-center justify-space-between">
            <v-col cols="2"></v-col>
            <v-col cols="2">Product Name</v-col>
            <v-col cols="2">Category</v-col>
            <v-col cols="2">Unit Price</v-col>
            <v-col cols="1">Quantity</v-col>
            <v-col cols="1">SubTotal</v-col>
            <v-col cols="1"></v-col>
          </v-row>
          <v-divider />
        </template>
      </div>
      <div v-for="product in selectedProducts" :key="product._id">
        <cart-item-row
          :item="product"
          :productCategories="categories"
          @remove="remove"
          @editItemQty="editItemQty"
        ></cart-item-row>
        <v-divider class="my-3" />
      </div>
    </div>
    <product-selector-dialog
      ref="productSelectorDialog"
      v-model="showSelectItemsPopup"
      :categories="categories"
      :selectedProducts.sync="selectedProducts"
    />
  </div>
</template>

<script>
import ImgWithPlaceholder from '@/components/imgWithPlaceholder';
import { get } from 'lodash';
import CartItemRow from '@/components/ServiceDetails/CartItemRow';
import LoadMoreButton from '@/components/LoadMoreButton';
import { mapGetters } from 'vuex';
import ProductSelectorDialog from './ProductSelectorDialog.vue';

export default {
  name: 'ProductOrderItems',
  components: { LoadMoreButton, CartItemRow, ImgWithPlaceholder, ProductSelectorDialog },
  props: {
    id: String,
    categories: [],
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSelectItemsPopup: false,
      imageSize: 100,
      selectedCategory: '',
      isLoadingProduct: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'profileId', 'isAdmin']),
    selectedProducts: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      },
    },
  },
  watch: {},
  methods: {
    get,
    remove(item) {
      this.$refs.productSelectorDialog.remove(item);
    },
    editItemQty(item, qty) {
      this.$refs.productSelectorDialog.editItemQty(item, qty);
    },
  },
};
</script>
