<template>
  <v-list>
    <v-list-item>
      <v-list-item-content>
        <v-row>
          <v-col cols="4">
            <b>Booking Section</b>
          </v-col>
          <v-col cols="2">
            <b>Status</b>
          </v-col>
          <v-col v-if="!isMaster" coks="3">
            <b>Facility</b>
          </v-col>
        </v-row>
        <v-divider />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-for="item in list" :key="item._id">
      <v-list-item-content>
        <v-row>
          <v-col cols="4">{{ item._timetableTime | datetimeRange }}</v-col>
          <v-col cols="2" v-if="item.isVoided === true">Voided</v-col>
          <v-col cols="2" v-else>{{ item.status | status }}</v-col>
          <v-col cols="3" v-if="!isMaster"
            >{{
              (item.serviceDetails.masterSpecified && item.serviceDetails.masterSpecified.name) ||
              '-'
            }}
          </v-col>
          <v-col cols="2"><v-btn @click="show(item._id)">Show Detail</v-btn></v-col>
        </v-row>
        <v-divider />
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-if="!list || !list.length" class="text-center">
      <v-row>
        <v-col> No Data </v-col>
      </v-row>
    </v-list-item>
    <v-pagination class="mt-4" v-model="pageVal" :length="data.pages || 1" />
  </v-list>
</template>

<script>
import api from '@/api';
import { crudList } from '@/api/crud';

export default {
  name: 'IdentityBookingList',
  props: {
    id: {},
    isMaster: Boolean,
    isVoided: Boolean,
  },
  data() {
    return {
      list: [],
      data: {},
      page: 1,
    };
  },
  computed: {
    pageVal: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
        this.reload();
      },
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  methods: {
    show(id) {
      this.$emit('show', id);
    },
    async reload() {
      try {
        if (this.isMaster) {
          this.data = await crudList(api, 'toca/admin/bookings', {
            filter: { coaches: this.id },
            sort: { 'time.start': -1 },
            limit: 5,
            page: this.page,
          });
          this.list = this.data.docs;
        } else {
          this.data = await crudList(api, 'toca/admin/bookings', {
            sort: { 'time.start': -1 },
            filter: { created_by: this.id },
            limit: 5,
            page: this.page,
            populate: [{ path: 'serviceDetails.masterSpecified' }],
          });
          this.list = this.data.docs;
        }
      } catch (e) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: e,
        });
      }
    },
  },
};
</script>

<style scoped></style>
