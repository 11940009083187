<template>
  <ListView
    title="Product Categories"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :enable-create="checkRole"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
  >
    <template v-slot:card="{ cardContent }">
      <ProductCategoryCard
        :content="cardContent"
        class="clickable"
        @click.native="actionDetails(cardContent)"
      />
    </template>
    <ProductsCategoryEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      @create="reload"
      @update="reload"
      @delete="reload"
    />
  </ListView>
</template>

<script>
import ProductCategoryCard from '@/components/ProductCategory/ProductCategoryCard';
import ProductsCategoryEditDialog from '@/components/ProductCategory/ProductCategoryEditDialog';
import { RESTFUL, roles } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductCategories',
  data() {
    return {
      header: [
        { text: 'Product Name', value: 'title' },
        { text: 'Description', value: 'description' },
      ],
    };
  },
  mixins: [listViewPageMixin],
  components: { ProductsCategoryEditDialog, ProductCategoryCard },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return !this.isMaster ? RESTFUL.productCategories.list : RESTFUL.productCategories.masterList;
    },
    checkRole() {
      return this.role !== roles.master;
    },
  },
};
</script>
