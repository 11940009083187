<template>
  <ListView
    title="Vip Product"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="!isMaster"
  >
    <template v-slot:item.name_cn="{ item }">
      {{ parseLocalizedString(item.name)['zh-cn'] }}
    </template>
    <template v-slot:item.name_en="{ item }">
      {{ parseLocalizedString(item.name).en }}
    </template>
    <template v-slot:item.name_hk="{ item }">
      {{ parseLocalizedString(item.name)['zh-hk'] }}
    </template>
    <template v-slot:item.listedPrice="{ item }">
      ${{ item.listedPrice | toThousandFilter }}
    </template>
    <template v-slot:item.discountedPrice="{ item }">
      <span v-if="item.discountedPrice">${{ item.discountedPrice | toThousandFilter }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item.description="{ item }">
      <div style="max-width: 150px" class="d-inline-block text-truncate">
        {{ item.description }}
      </div>
    </template>
    <!-- <ProductEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      :productCategories="productCategories"
      @create="reload"
      @update="reload"
      @delete="reload"
    /> -->
  </ListView>
</template>

<script>
// import ProductCategoryCard from '@/components/ProductCategory/ProductCategoryCard';
// import ProductEditDialog from '@/components/PhysicalProduct/ProductEditDialog';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import { mapGetters } from 'vuex';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'VipProducts',
  data() {
    return {
      header: [
        { text: 'Name(CN)', value: 'name_cn' },
        { text: 'Name(EN)', value: 'name_en' },
        { text: 'Name(HK)', value: 'name_hk' },
        { text: 'Description', value: 'description' },
        { text: 'Listed Price', value: 'listedPrice' },
        { text: 'Discounted Price', value: 'discountedPrice' },
        { text: 'VIP Index', value: 'discount.VIPIndex' },
      ],
      productCategories: [],
      sort: { 'discount.VIPIndex': -1, created_at: -1 },
    };
  },
  mixins: [listViewPageMixin],
  //components: { ProductEditDialog, ProductCategoryCard },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return RESTFUL.vipProducts.list;
    },
  },
  mounted() {},
  methods: {
    parseLocalizedString,
    actionCreate() {
      this.$router.push({
        name: 'vipProductsNew',
      });
    },
  },
};
</script>
