var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"60%","max-width":"100vw"},on:{"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.valueProxy),callback:function ($$v) {_vm.valueProxy=$$v},expression:"valueProxy"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_vm._v("Add Packages "),_c('v-spacer'),_c('v-icon',{on:{"click":function($event){return _vm.close()}}},[_vm._v("mdi-close")])],1),_c('v-card-text',[_c('v-form',[_c('v-row',[(!_vm.hasClient)?_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-label',{attrs:{"item-field-name":"Customer"}}),_c('autocomplete',{ref:"autoComplete",attrs:{"label":"Customer","path":_vm.computedCustomerProfileUrl,"params":{ sort: { created_at: -1 } },"item-text":"name","item-value":"_id","error-messages":errors,"clearable":"","has-items-slot":""},scopedSlots:_vm._u([{key:"items",fn:function(content){return [_c('span',[_vm._v(_vm._s(_vm.get(content, 'item.name', '') + ' ' + _vm.displayPhone(_vm.get(content, 'item.phone', ''))))])]}}],null,true),model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,true)}),(!_vm.client)?_c('div',[_c('v-btn',{on:{"click":function($event){_vm.createUser = true}}},[_vm._v("Add New Customer")])],1):_vm._e(),_c('IdentityEditDialog',{attrs:{"max-width":"600"},on:{"create":_vm.updateProfile},model:{value:(_vm.createUser),callback:function ($$v) {_vm.createUser=$$v},expression:"createUser"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Package","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-label',{attrs:{"item-field-name":"Package"}}),_c('autocomplete',{attrs:{"label":"Package","path":_vm.computedServiceProductsUrl,"return-object":"","item-value":"_id","item-text":"name","error-messages":errors},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('custom-label',{attrs:{"item-field-name":"Quota"}}),_c('ValidationProvider',{attrs:{"name":"Quota","rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":[10],"label":"Quota","outlined":""},model:{value:(_vm.doc.quota),callback:function ($$v) {_vm.$set(_vm.doc, "quota", $$v)},expression:"doc.quota"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 },"name":"Amount Paid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-label',{attrs:{"item-field-name":"Amount Paid"}}),_c('v-text-field',{attrs:{"label":"Amount Paid","outlined":"","flat":"","error-messages":errors},model:{value:(_vm.doc.amountPayable),callback:function ($$v) {_vm.$set(_vm.doc, "amountPayable", $$v)},expression:"doc.amountPayable"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Payment Method"}},[_c('custom-label',{attrs:{"item-field-name":"Payment Method"}}),_c('v-autocomplete',{attrs:{"outlined":"","search-input":_vm.search,"items":_vm.computedPaymentMethod},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.selectedPaymentMethod),callback:function ($$v) {_vm.selectedPaymentMethod=$$v},expression:"selectedPaymentMethod"}})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Remark"}},[_c('custom-label',{attrs:{"item-field-name":"Remark"}}),_c('v-text-field',{attrs:{"label":"Remark","outlined":"","flat":""},model:{value:(_vm.doc.remarks),callback:function ($$v) {_vm.$set(_vm.doc, "remarks", $$v)},expression:"doc.remarks"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":invalid},on:{"click":function($event){return _vm.addTicket()}}},[_vm._v("Add")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }