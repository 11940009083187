<template>
  <ListView
    title="Vip Product Purchase Record"
    @reload="reload"
    @create="actionCreate"
    @navDetails="actionDetails"
    :search.sync="search"
    :data="data"
    :page.sync="page"
    :header="header"
    :enable-create="!isMaster"
  >
    <template v-slot:item.VIPProduct="{ item }">
      {{ item.VIPProduct.name | parseAndLocalize }}
    </template>
    <template v-slot:item.description="{ item }">
      <div style="max-width: 150px" class="d-inline-block text-truncate">
        {{ item.description }}
      </div>
    </template>
    <template v-slot:item.amountPayable="{ item }">
      {{ item.amountPayable | toThousandFilter }}
    </template>
    <!-- <ProductEditDialog
      v-model="editDialog"
      :id="editId"
      :title="title"
      :productCategories="productCategories"
      @create="reload"
      @update="reload"
      @delete="reload"
    /> -->
  </ListView>
</template>

<script>
// import ProductCategoryCard from '@/components/ProductCategory/ProductCategoryCard';
// import ProductEditDialog from '@/components/PhysicalProduct/ProductEditDialog';
import { RESTFUL } from '@/data/constants';
import listViewPageMixin from '@/services/listViewPageMixin';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import { mapGetters } from 'vuex';

export default {
  name: 'VipProducts',
  data() {
    return {
      header: [
        { text: 'Name', value: 'VIPProduct' },
        { text: 'Client', value: 'owner.name' },
        { text: 'Amount', value: 'amountPayable' },
        { text: 'Payment Method', value: 'paymentMethod' },
        { text: 'Payment Status', value: 'paymentStatus' },
        { text: 'VIP Status', value: 'status' },
      ],
      sort: { created_at: -1 },
      populate: [
        {
          path: 'VIPProduct',
        },
        {
          path: 'owner',
        },
      ],
    };
  },
  mixins: [listViewPageMixin],
  //components: { ProductEditDialog, ProductCategoryCard },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    // used in mixin
    restfulURL() {
      return RESTFUL.vipProductPurchaseRecords.list;
    },
  },
  mounted() {},
  methods: {
    actionCreate() {
      this.$router.push({
        name: 'vipRecordsNew',
      });
    },
  },
};
</script>
