<template>
  <v-card outlined shaped tile width="200" min-height="320" class="mx-3 mb-3">
    <div
      class="d-flex flex-column full-height"
      @click="actionClickCard(product)"
      style="cursor: pointer"
    >
      <v-checkbox
        class="px-3 flex-grow-0 flex-shrink-0"
        v-model="product.checkbox"
        @click.stop
        @change="actionClickCheckBox($event, product)"
        color="primary"
      >
      </v-checkbox>
      <div class="d-flex flex-column justify-start align-center flex-grow-1">
        <v-img
          :max-height="imageSize"
          :max-width="imageSize"
          :min-height="imageSize"
          :min-width="imageSize"
          contain
          :key="product.featuredImage"
          :src="attnThumbHref(product.featuredImage, 512)"
        >
          <template v-slot:placeholder>
            <v-img
              :max-height="imageSize"
              :max-width="imageSize"
              :min-height="imageSize"
              :min-width="imageSize"
              contain
              src="@/assets/TocaLogo.svg"
            />
          </template>
        </v-img>
        <div style="min-height: 80px; text-align: center">
          <div style="min-height: 50px">{{ product.name }}</div>
          <div class="d-flex justify-center">
            <h4>HK$</h4>
            <h4 :class="product.discountedPrice ? 'stripeClass' : ''">
              {{ get(product, 'listedPrice', '-') }}
            </h4>
            <h4
              :class="product.discountedPrice ? 'discountClass' : ''"
              v-if="get(product, 'discountedPrice')"
            >
              {{ get(product, 'discountedPrice', '-') }}
            </h4>
          </div>
        </div>
      </div>
      <div class="ma-3" @click.stop>
        <v-text-field
          class="px-0 custom-number-input mt-auto flex-grow-0 flex-shrink-0"
          hide-details
          outlined
          rounded
          type="number"
          :min="1"
          v-model.number="product.selectedQty"
          :disabled="!product.checkbox"
          append-icon=" mdi-plus"
          prepend-inner-icon="mdi-minus"
          @input="addToCart(product)"
          @click:append="increment(product)"
          @click:prepend-inner="decrement(product)"
        >
        </v-text-field>
      </div>
    </div>
  </v-card>
</template>

<script>
import { get } from 'lodash';
import { attnThumbHref } from '@/services/filters';

export default {
  name: 'ProductSelectionCard',
  emits: ['actionClickCheckBox', 'actionClickCard', 'addToCart', 'increment', 'decrement'],
  props: {
    product: {},
    imageSize: { type: Number, default: 100 },
  },
  methods: {
    get,
    attnThumbHref,
    actionClickCheckBox(e, p) {
      this.$emit('actionClickCheckBox', { e, p });
    },
    actionClickCard(p) {
      this.$emit('actionClickCard', p);
    },
    addToCart(p) {
      this.$emit('addToCart', p);
    },
    increment(p) {
      this.$emit('increment', p);
    },
    decrement(p) {
      this.$emit('decrement', p);
    },
  },
};
</script>

<style scoped></style>
