<template>
  <v-dialog
    v-model="valueProxy"
    width="60%"
    max-width="100vw"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <ValidationObserver ref="form" v-slot="{ invalid }">
      <v-card min-height="250">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px">
            {{ title || 'Product Category' }}
          </span>
          <v-spacer />
          <v-btn icon @click="valueProxy = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Product Name" />
                <ValidationProvider v-slot="{ errors }" name="Product Name" rules="required">
                  <v-text-field
                    outlined
                    v-model="model.title"
                    label="Product Name"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Description" />
                <ValidationProvider v-slot="{ errors }" name="Description">
                  <v-textarea
                    outlined
                    v-model="model.description"
                    label="Description"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Discount" />
                <ValidationProvider v-slot="{ errors }" name="Discount">
                  <v-text-field
                    outlined
                    type="number"
                    v-model.number="model.discount"
                    label="Discount"
                    placeholder="should be larger than 0, smaller than 1, eg. 0.8 (20% off)"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6">
                <custom-label item-field-name="Featured Image" />
                <ValidationProvider v-slot="{ errors }" name="Featured Image" rules="required">
                  <v-file-input
                    outlined
                    v-model="model.featuredImage"
                    label="Image"
                    :error-messages="errors"
                    accept="image/jpeg,image/png"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="doClose()">Close</v-btn>
          <v-btn color="primary" @click="promptCreate()" :disabled="invalid" v-if="isCreate">
            Create
          </v-btn>
          <v-btn color="primary" @click="promptUpdate()" :disabled="invalid" v-if="!isCreate">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import api from '@/api';
import ApiError from '@/api/ApiError';
import { uploadSingleAttachment } from '@/api/attachment';
import { crudCreate } from '@/api/crud';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { RESTFUL } from '@/data/constants';
import editDialogMixin from '@/services/editDialogMixin';

export default {
  name: 'ProductCategoryEditDialog',
  components: { CustomLabel },
  mixins: [editDialogMixin],
  props: {
    title: {},
  },
  watch: {},
  mounted() {
    this.reload();
  },
  methods: {
    async reload() {
      this.model = {};
    },
    async promptCreate() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const attachmentId = await uploadSingleAttachment(api, this.model.featuredImage);
        this.model.featuredImage = attachmentId._id;
        const model = await crudCreate(api, RESTFUL.productCategories.create, this.model);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Created',
          type: 'success',
          color: 'success',
        });
        this.$emit('create', model);
      } catch (err) {
        this.handleError(err);
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
  },
};
</script>

<style scoped></style>
