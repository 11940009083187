<template>
  <v-dialog v-model="valueProxy" :width="width">
    <v-card>
      <!--      <div v-if="isDiscountLabels">-->
      <div v-if="showDiscountLabels">
        <v-card-title class="justify-center">Discount Labels</v-card-title>
        <v-card-text>
          <v-chip
            class="mr-5 mb-5"
            label
            style="font-weight: bold"
            color="#96734B"
            outlined
            close
            close-icon="mdi-close-circle"
            :key="index"
            v-for="(item, index) in discountLabels"
            @click:close="deleteCustomerDiscountLabel(index)"
            >{{ item }}
          </v-chip>
          <v-autocomplete
            outlined
            :search-input.sync="search"
            :items="discountTags"
            @input="textFunction"
            append-outer-icon="mdi-plus-circle-outline"
            @click:append-outer="addCustomerDiscountLabel()"
          >
          </v-autocomplete>

          <v-card-actions class="justify-space-between">
            <span>&nbsp;</span>
            <span>
              <v-btn class="mr-5" @click="valueProxy = false">Cancel</v-btn>
              <v-btn @click="saveCustomerDiscountLabel" color="primary" :disabled="!masterHasAuth"
                >Save</v-btn
              >
            </span>
          </v-card-actions>
        </v-card-text>
      </div>

      <div v-else>
        <v-card-title class="justify-center">Customer Label</v-card-title>
        <v-card-text>
          <v-chip
            class="mr-5 mb-5"
            label
            style="font-weight: bold"
            color="#96734B"
            outlined
            close
            close-icon="mdi-close-circle"
            :key="index"
            v-for="(item, index) in labels"
            @click:close="deleteCustomerLabel(index)"
            >{{ item }}
          </v-chip>
          <v-autocomplete
            outlined
            :search-input.sync="search"
            :items="tags"
            @input="x => addCustomerLabel(x)"
            append-outer-icon="mdi-plus-circle-outline"
            @click:append-outer="addCustomerLabel()"
            v-model="customerLabelSelect"
          >
          </v-autocomplete>

          <v-card-actions class="justify-space-between">
            <!--          <span style="color: red" class="font-weight-bold" v-if="labels.length >= 10">-->
            <!--            *Already reached max 10 tags-->
            <!--          </span>-->
            <span>&nbsp;</span>
            <span>
              <v-btn class="mr-5" @click="valueProxy = false">Cancel</v-btn>
              <v-btn @click="saveCustomerLabel" color="primary" :disabled="!masterHasAuth"
                >Save</v-btn
              >
            </span>
          </v-card-actions>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { autocompleteLabels } from '@/api/bookings';
import { autocompleteDiscountLabels } from '@/api/bookings';
import api from '@/api';
import { mapGetters } from 'vuex';
import { getOid } from '@/services/utils';

export default {
  name: 'remarksDialog',
  props: {
    width: { type: Number, default: 500 },
    // userLabels: { default: [] },
    userLabels: {
      type: Array,
      default() {
        return [];
      },
    },
    // userDiscountLabels: { default: [] },
    userDiscountLabels: {
      type: Array,
      default() {
        return [];
      },
    },
    show: Boolean,
    showDiscountLabels: Boolean,
    docs: {},
    // isDiscountLabels: Boolean,
    // discountLabels: { default: [] },
  },
  model: {
    prop: 'show',
    event: 'update:show',
  },
  data() {
    return {
      labels: [],
      tags: [],
      search: null,
      discountLabels: [],
      discountTags: [],
      customerLabelSelect: null,
    };
  },
  watch: {
    userLabels: {
      immediate: true,
      handler() {
        this.reload();
      },
    },
    userDiscountLabels: {
      immediate: true,
      handler() {
        this.reload();
      },
    },
    search: {
      immediate: true,
      async handler(v) {
        this.tags = await autocompleteLabels(api, v);
        this.discountTags = await autocompleteDiscountLabels(api, v);
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'profileId', 'isAdmin', 'isCashier']),
    masterHasAuth() {
      return (
        this.isAdmin || this.isCashier || this.isMaster //(this.isMaster && this.profileId === getOid(this.docs?._timetable?.master))
      );
    },
    valueProxy: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
  },
  methods: {
    async reload() {
      this.labels = this.userLabels.slice();
      this.discountLabels = this.userDiscountLabels.slice();
    },
    addCustomerLabel(x) {
      if (x) {
        this.labels.push(x);
        //return;
      } else if (!this.search) {
        return;
      } else {
        this.labels.push(this.search);
      }
      this.labels = [...new Set(this.labels)];
      this.customerLabelSelect = '';
      this.$nextTick(() => {
        this.customerLabelSelect = null;
      });
    },
    deleteCustomerLabel(index) {
      this.labels.splice(index, 1);
    },
    async saveCustomerLabel() {
      this.$emit('saveLabel', this.labels);
    },
    /////////////////////
    addCustomerDiscountLabel(x) {
      if (x) {
        this.discountLabels.push(x);
        return;
      }
      if (!this.search) {
        return;
      } else {
        this.discountLabels.push(this.search);
      }
      this.discountLabels = [...new Set(this.discountLabels)];
      console.log(this.discountLabels);
    },
    textFunction() {
      console.log('hi');
    },
    deleteCustomerDiscountLabel(index) {
      this.discountLabels.splice(index, 1);
    },
    async saveCustomerDiscountLabel() {
      this.$emit('saveDiscountLabel', this.discountLabels);
    },
  },
};
</script>

<style scoped></style>
