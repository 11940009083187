var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex full-height flex-column align-center",staticStyle:{"border-right":"1px solid #e0e0e0"}},[_c('b',{staticStyle:{"color":"#4d4d4d","padding-top":"43px"}},[_vm._v("Round "+_vm._s(_vm.roundNumber))]),(_vm.roundNumber === 1)?_c('v-select',{staticClass:"inlineSelect my-5 mx-5",attrs:{"items":_vm.durationOption,"placeholder":"30 mins","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',[_vm._v(_vm._s(item)+" mins")])]}}],null,false,3337563254),model:{value:(_vm.target.duration),callback:function ($$v) {_vm.$set(_vm.target, "duration", $$v)},expression:"target.duration"}}):_vm._e(),(_vm.roundNumber === 2)?_c('v-select',{staticClass:"inlineSelect my-5 mx-5",attrs:{"items":_vm.durationOption2,"outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item === "Only 1 Round")?_c('div',[_vm._v(_vm._s(item))]):_c('div',[_vm._v(_vm._s(item)+" mins")])]}}],null,false,1864117185),model:{value:(_vm.target.duration),callback:function ($$v) {_vm.$set(_vm.target, "duration", $$v)},expression:"target.duration"}}):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"10"}},[_vm._l((3),function(pIndex){return [_c('div',{key:("firstRound-" + pIndex),staticClass:"borderBottom"},[_c('div',{staticStyle:{"padding":"15px"}},[(!(_vm.roundNumber === 2 && pIndex === 1))?_c('div',{staticClass:"d-flex justify-space-between responsiveGap",staticStyle:{"padding-top":"20px","padding-bottom":"20px"}},[_c('div',{staticClass:"d-flex align-center flex-grow-0 flex-shrink-1 flex-wrap",staticStyle:{"gap":"12px"}},[_c('span',[_vm._v(" "+_vm._s(pIndex - (_vm.roundNumber - 1))+". ")]),_vm._l((_vm.target[("color" + pIndex)]),function(item,i){return _c('div',{key:item + i,staticClass:"d-flex align-center flex-shrink-1",staticStyle:{"gap":"12px","flex-basis":"200px"}},[_c('div',{staticClass:"d-flex align-center flex-grow-0 flex-shrink-1",staticStyle:{"gap":"12px","border":"1px solid black","padding":"5px","border-radius":"5px"}},[_c('v-select',{key:("select-" + i),staticClass:"inlineSelect",attrs:{"items":_vm.colorOptions,"dense":"","hide-details":"","item-text":"label","item-value":"label","outlined":""},model:{value:(_vm.target[("color" + pIndex)][i]),callback:function ($$v) {_vm.$set(_vm.target[("color" + pIndex)], i, $$v)},expression:"target[`color${pIndex}`][i]"}}),_c('div',{key:("div-" + i),staticClass:"flex-shrink-0",staticStyle:{"width":"16px","height":"16px"},style:({
                        backgroundColor: _vm.getColor(_vm.get(_vm.target, [("color" + pIndex), i])),
                      })},[_vm._v("   ")]),_c('v-select',{staticClass:"inlineSelect",attrs:{"items":_vm.doseOption,"dense":"","hide-details":"","outlined":"","placeholder":"Dose"},model:{value:(_vm.target[("unit" + pIndex)][i]),callback:function ($$v) {_vm.$set(_vm.target[("unit" + pIndex)], i, $$v)},expression:"target[`unit${pIndex}`][i]"}}),(i >= 1)?[_c('v-icon',{on:{"click":function () {
                            _vm.target[("unit" + pIndex)].splice(i, 1);
                            _vm.target[("color" + pIndex)].splice(i, 1);
                          }}},[_vm._v(" mdi-minus-circle-outline ")])]:_vm._e()],2),(_vm.target[("color" + pIndex)].length > i + 1)?[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-thick")])]:_vm._e()],2)})],2),_c('v-icon',{staticClass:"flex-shrink-0 flex-grow-0",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('addNew', _vm.roundNumber, pIndex)}}},[_vm._v("mdi-plus-circle-outline ")])],1):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.roundNumber === 2 && pIndex === 1)?[_c('v-checkbox',{attrs:{"label":"洗補"},model:{value:(_vm.washAndDye),callback:function ($$v) {_vm.washAndDye=$$v},expression:"washAndDye"}})]:_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.target[("type" + pIndex)]),callback:function ($$v) {_vm.$set(_vm.target, ("type" + pIndex), $$v)},expression:"target[`type${pIndex}`]"}},[[_c('v-radio',{staticClass:"mr-16",attrs:{"color":"primary","label":"根","value":"hairRoot"}}),_c('v-radio',{staticClass:"mr-16",attrs:{"color":"primary","label":"前","value":"hairFront"}}),_c('v-radio',{staticClass:"mr-16",attrs:{"color":"primary","label":"尾","value":"hairTail"}}),_c('v-radio',{staticClass:"mr-16",attrs:{"color":"primary","label":"全","value":"full"}}),_c('v-radio',{staticClass:"mr-16",attrs:{"color":"primary","label":"頭頂","value":"top"}})]],2),(!(_vm.roundNumber === 2 && pIndex === 1))?_c('v-icon',{on:{"click":function($event){return _vm.$emit('removeRow', _vm.roundNumber, pIndex)}}},[_vm._v(" mdi-trash-can-outline ")]):_vm._e()],2)])])]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }