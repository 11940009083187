<template>
  <v-card height="100%" min-height="150" style="position: relative">
    <v-card-title></v-card-title>
    <v-card-subtitle style="padding-bottom: 0"> {{ content.sid }}</v-card-subtitle>
    <v-card-title>
      <span>{{ content.name }}</span>
      <v-spacer />
      <v-icon large> mdi-chevron-right</v-icon>
    </v-card-title>
    <v-card-actions style="padding: 16px">
      <v-spacer />
      <slot name="deleteCard"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'IdentityCard',
  props: {
    content: {
      default: () => ({}),
    },
  },
};
</script>
