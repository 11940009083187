<template>
  <v-dialog
    v-model="valueProxy"
    width="60%"
    max-width="100vw"
    @create="reload"
    @update="reload"
    @delete="reload"
  >
    <v-container style="background-color: #ffffff">
      <div class="d-flex">
        <h2>
          {{ title }}
        </h2>
        <v-spacer />
        <v-btn icon @click="valueProxy = false">
          <v-icon> mdi-close</v-icon>
        </v-btn>
      </div>
      <credit-record-edit-card @create="refresh" @update="refresh" />
    </v-container>
  </v-dialog>
</template>

<script>
import editDialogMixin from '@/services/editDialogMixin';
import CreditRecordEditCard from '@/components/CreditRecord/CreditRecordEditCard.vue';

export default {
  name: 'CreditRecordEditDialog',
  components: { CreditRecordEditCard },
  mixins: [editDialogMixin],
  props: {
    title: { default: 'Credit Record' },
  },
  methods: {
    refresh() {
      this.$emit('reload');
    },
  },
};
</script>
