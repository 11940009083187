<template>
  <v-container>
    <v-card flat>
      <v-card-title>{{ title }}</v-card-title>
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row class="ma-3">
          <v-col class="ml-auto" md="auto">
            <v-btn color="primary" @click="reload" style="margin-right: 20px"
              ><v-icon>mdi-reload</v-icon></v-btn
            >
            <v-btn :disabled="invalid" color="primary" @click="save">
              {{ isNew ? 'Save' : 'Update' }}
            </v-btn>
          </v-col>
        </v-row>
        <div id="title">
          <ValidationProvider v-slot="{ errors }" name="標題" rules="required">
            <v-text-field
              v-model="model['titleZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="標題"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="标题" rules="required">
            <v-text-field
              v-model="model.titleZh"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="标题"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Title" rules="required">
            <v-text-field
              v-model="model.titleEn"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Title"
              outlined
            />
          </ValidationProvider>
          <v-divider style="margin-bottom: 20px" />
        </div>
        <div id="content">
          <ValidationProvider v-slot="{ errors }" name="內容" rules="required">
            <v-text-field
              v-model="model['contentZh-HK']"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="內容"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="内容" rules="required">
            <v-text-field
              v-model="model.contentZh"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="内容"
              outlined
            />
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }" name="Content" rules="required">
            <v-text-field
              v-model="model.contentEn"
              :error="!!errors.length"
              :error-messages="errors"
              flat
              label="Content"
              outlined
            />
          </ValidationProvider>
          <v-divider style="margin-bottom: 20px" />
        </div>
        <div id="package">
          <div v-for="(packageItem, index) in model.VIPPackage" :key="index">
            <h3 style="margin-bottom: 10px">Package {{ index + 1 }}</h3>
            <div id="VIPPackage">
              <ValidationProvider v-slot="{ errors }" name="VIPPackage" rules="required">
                <autocomplete
                  v-model="packageItem.VIPPackage"
                  :path="'toca/admin/VIPProducts'"
                  item-text="name"
                  :item-value="item => item"
                  label="VIP Package"
                  :clearable="true"
                  :error-messages="errors"
                  :has-items-slot="true"
                >
                  <template v-slot:items="content">
                    <span>{{ getVIPPackageInfo(content.item) }}</span>
                  </template>
                </autocomplete>
              </ValidationProvider>
            </div>
            <div id="packageIconBackgroundColor">
              <v-row>
                <v-col col="6">
                  <ValidationProvider v-slot="{ errors }" name="VIPIcon" rules="required">
                    <v-text-field
                      v-model="packageItem.icon"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIPIcon"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="VIPBackgroundColor"
                    rules="required"
                  >
                    <v-text-field
                      v-model="packageItem.bgColor"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP Background Color"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="packageTitle">
              <v-row>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP標題" rules="required">
                    <v-text-field
                      v-model="packageItem['titleZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP標題"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP标题" rules="required">
                    <v-text-field
                      v-model="packageItem.titleZh"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP标题"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP Title" rules="required">
                    <v-text-field
                      v-model="packageItem.titleEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP Title"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="packagePrice">
              <v-row>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP價格" rules="required">
                    <v-text-field
                      v-model="packageItem['priceZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP價格"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP价格" rules="required">
                    <v-text-field
                      v-model="packageItem.priceZh"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP价格"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIPPrice" rules="required">
                    <v-text-field
                      v-model="packageItem.priceEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP Price"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="packageButtonText">
              <v-row>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP按鈕文字" rules="required">
                    <v-text-field
                      v-model="packageItem['buttonTextZh-HK']"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP按鈕文字"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIP按钮文字" rules="required">
                    <v-text-field
                      v-model="packageItem.buttonTextZh"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP按钮文字"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="4">
                  <ValidationProvider v-slot="{ errors }" name="VIPButtonText" rules="required">
                    <v-text-field
                      v-model="packageItem.buttonTextEn"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP Button Text"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="packageButtonSetting">
              <v-row>
                <v-col col="6">
                  <ValidationProvider v-slot="{ errors }" name="VIPButtonLink" rules="required">
                    <v-text-field
                      v-model="packageItem.buttonLink"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP Button Link"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="6">
                  <ValidationProvider v-slot="{ errors }" name="VIPButtonColor" rules="required">
                    <!-- <v-color-picker
                      v-model="packageItem.buttonColor"
                      elevation="0"
                    ></v-color-picker> -->
                    <v-text-field
                      v-model="packageItem.buttonColor"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="VIP Button Color"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row>
                <v-col col="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="BackgroundColorLight"
                    rules="required"
                  >
                    <v-text-field
                      v-model="packageItem.bgColorLight"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="Background Color Light"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col col="6">
                  <ValidationProvider v-slot="{ errors }" name="fontColorLight" rules="required">
                    <v-text-field
                      v-model="packageItem.fontColorLight"
                      :error="!!errors.length"
                      :error-messages="errors"
                      flat
                      label="Font Color Light"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
            </div>
            <div id="packageFeatureText">
              <div style="margin-bottom: 10px">
                <h4 class="mainTitle CMSTitle">中文繁體版本</h4>
                <ValidationProvider v-slot="{ errors }" name="VIP詳情" rules="required">
                  <quillEditor
                    ref="quill"
                    :options="qillOptions"
                    v-model="packageItem['featuresTextZh-HK']"
                  />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div style="margin-bottom: 10px">
                <h4 class="mainTitle CMSTitle">中文简体版本</h4>
                <ValidationProvider v-slot="{ errors }" name="VIP详情" rules="required">
                  <quillEditor
                    ref="quill"
                    :options="qillOptions"
                    v-model="packageItem.featuresTextZh"
                  />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div style="margin-bottom: 10px">
                <h4 class="mainTitle CMSTitle">English Version</h4>
                <ValidationProvider v-slot="{ errors }" name="VIPFeature" rules="required">
                  <quillEditor
                    ref="quill"
                    :options="qillOptions"
                    v-model="packageItem.featuresTextEn"
                  />
                  <span class="error--text" v-if="errors && errors.length">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <v-divider style="margin-top: 30px; margin-bottom: 20px" />
            </div>
          </div>
        </div>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import { CMSPostType } from '../../data/constants';
import { crudCreate, crudList, crudUpdate } from '@/api/crud';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { generatorObjectFromJSONFormat } from '@/services/CMS';
import { getVIPPackageInfo } from '@/services/filters';
import Autocomplete from '@/components/Autocomplete/Autocomplete';

export default {
  name: 'homePagePricingCMS',
  components: {
    quillEditor,
    Autocomplete,
  },
  data() {
    return {
      title: 'Home Page Pricing',
      postType: CMSPostType.homePagePricing,
      contentID: null,
      model: {},
      qillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ header: 1 }, { header: 2 }],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }],
              [{ color: [] }, { background: [] }],
            ],
          },
        },
        readOnly: false,
      },
    };
  },
  computed: {
    isNew() {
      return !this.contentID;
    },
  },
  async beforeMount() {
    await this.reload();
  },
  methods: {
    getVIPPackageInfo,
    async reload() {
      const computedQuery = {
        filter: { 'metadata.type': this.postType },
        sort: { created_at: -1 },
      };
      try {
        const res = await crudList(api, 'cms/admin/posts', computedQuery);
        this.model = res?.docs?.[0] || {};
        this.contentID = this.model?._id;

        if (this.contentID) {
          if (this.model.title) {
            let result = generatorObjectFromJSONFormat('title', this.model.title);
            Object.assign(this.model, result);
          }
          if (this.model.content) {
            let result = generatorObjectFromJSONFormat('content', this.model.content);
            Object.assign(this.model, result);
          }
          if (this.model.metadata.data) {
            let vip = this.model.metadata.data.map(item => {
              let obj = {
                bgColor: item.bg_color,
                icon: item.icon,
                buttonLink: item.button_link,
                buttonColor: item.button_color,
                VIPPackage: item.vipProduct,
                bgColorLight: item.bg_color_light,
                fontColorLight: item.font_color_light,
              };

              let title = generatorObjectFromJSONFormat('title', item.title);
              let price = generatorObjectFromJSONFormat('price', item.price);
              let featuresText = generatorObjectFromJSONFormat('featuresText', item.features_text);
              let buttonText = generatorObjectFromJSONFormat('buttonText', item.button_text);

              Object.assign(obj, title);
              Object.assign(obj, price);
              Object.assign(obj, featuresText);
              Object.assign(obj, buttonText);
              return obj;
            });
            Object.assign(this.model, { VIPPackage: vip });
          }
        } else {
          let tempData = [];
          for (let i = 0; i < 5; i++) {
            const emptyObject = {
              ['titleZh-HK']: '',
              titleZh: '',
              titleEn: '',
            };
            tempData.push(emptyObject);
          }

          this.model.VIPPackage = tempData;
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS load ${error}`,
          type: 'error',
          color: 'error',
        });
      }
    },
    async save() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      try {
        const mainTitleJsonObj = {
          'zh-HK': this.model['titleZh-HK'],
          zh: this.model['titleZh'],
          en: this.model.titleEn,
        };

        const contentJsonObj = {
          'zh-HK': this.model['contentZh-HK'],
          zh: this.model['contentZh'],
          en: this.model.contentEn,
        };

        let tempData = [];
        tempData = this.model.VIPPackage.map(item => {
          const titleJsonObj = {
            'zh-HK': item['titleZh-HK'],
            zh: item['titleZh'],
            en: item.titleEn,
          };
          const priceJsonObj = {
            'zh-HK': item['priceZh-HK'],
            zh: item['priceZh'],
            en: item.priceEn,
          };
          const featuresTextJsonObj = {
            'zh-HK': item['featuresTextZh-HK'],
            zh: item['featuresTextZh'],
            en: item.featuresTextEn,
          };
          const buttonTextJsonObj = {
            'zh-HK': item['buttonTextZh-HK'],
            zh: item['buttonTextZh'],
            en: item.buttonTextEn,
          };
          return {
            bg_color: item.bgColor,
            icon: item.icon,
            title: JSON.stringify(titleJsonObj),
            price: JSON.stringify(priceJsonObj),
            features_text: JSON.stringify(featuresTextJsonObj),
            button_text: JSON.stringify(buttonTextJsonObj),
            button_link: item.buttonLink,
            button_color: item.buttonColor,
            vipProduct: item.VIPPackage,
            bg_color_light: item.bgColorLight,
            font_color_light: item.fontColorLight,
          };
        });

        if (this.isNew) {
          //create
          const payload = {
            metadata: {
              type: this.postType,
              data: tempData,
            },
            content: JSON.stringify(contentJsonObj),
            title: JSON.stringify(mainTitleJsonObj),
          };
          const res = await crudCreate(api, 'cms/admin/posts', payload);
          // alert & update
          this.contentID = res._id;
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Saved',
            type: 'success',
            color: 'success',
          });
        } else {
          // update
          delete this.model.__v;
          delete this.model.updated_at;
          delete this.model.created_at;
          this.model.title = JSON.stringify(mainTitleJsonObj);
          this.model.content = JSON.stringify(contentJsonObj);
          this.model.metadata = {
            type: this.postType,
            data: tempData,
          };

          const res = await crudUpdate(api, 'cms/admin/posts', this.contentID, this.model);
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'Updated',
            type: 'success',
            color: 'success',
          });
        }
      } catch (error) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: `CMS post ${error}`,
          type: 'error',
          color: 'error',
        });
      } finally {
        this.reload();
      }
    },
  },
};
</script>
