<template>
  <div>
    <v-row class="d-flex align-center justify-center justify-space-between">
      <v-col cols="2">
        <div v-if="product.featuredImage">
          <v-img
            :max-height="imageSize"
            :max-width="imageSize"
            :min-height="imageSize"
            :min-width="imageSize"
            :key="product.featuredImage"
            :src="attnThumbHref(product.featuredImage, 128)"
          ></v-img>
        </div>
        <div v-else>
          <v-img
            :max-height="imageSize"
            :max-width="imageSize"
            :min-height="imageSize"
            :min-width="imageSize"
            contain
            src="@/assets/TocaLogo.svg"
          />
        </div>
      </v-col>
      <v-col cols="2">
        <h4 class="d-inline-flex">{{ get(product, 'name', '-') }}</h4>
      </v-col>
      <v-col cols="2" v-if="productCategories.length > 0">
        <h4 class="d-inline-flex">
          {{ getCategoryNameById(product.category) }}
        </h4>
      </v-col>
      <v-col cols="2">
        <div class="d-flex">
          <h4>HK$</h4>
          <h4 :class="product.discountedPrice ? 'stripeClass' : ''">
            {{ get(product, 'listedPrice', '-') }}
          </h4>
          <h4 :class="product.discountedPrice ? 'discountClass' : ''">
            {{ get(product, 'discountedPrice', '-') }}
          </h4>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex align-center justify-center">
          <!--          <h4 style="width: 100%" class="d-flex justify-center">-->
          <!--            x{{ get(product, 'selectedQty', '-') }}-->
          <!--          </h4>-->
          <v-text-field
            class="ma-3 shrink px-0 custom-number-input mt-auto"
            hide-details
            outlined
            rounded
            type="number"
            :min="1"
            v-model.number="product.selectedQty"
            append-icon=" mdi-plus"
            prepend-inner-icon="mdi-minus"
            @click:append="increment(product)"
            @click:prepend-inner="decrement(product)"
          >
          </v-text-field>
        </div>
      </v-col>
      <v-col cols="1">
        <div class="d-flex">
          <h4>HK$</h4>
          <h4 class="ml-3">{{ computedSubTotal(product) }}</h4>
        </div>
      </v-col>
      <v-col cols="1">
        <v-btn icon v-if="!hideBinIcon" @click="removeItem(product)">
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { attnThumbHref } from '@/services/filters';
import { get } from 'lodash';
import { listProductCategories } from '@/api/productCategory';
import api from '@/api';
import ImgWithPlaceholder from '@/components/imgWithPlaceholder';

export default {
  name: 'CartItemRow',
  components: { ImgWithPlaceholder },
  props: {
    item: { type: Object, default: () => {} },
    productCategories: { type: Array, default: () => [] },
    hideBinIcon: { type: Boolean, default: false },
  },
  data() {
    return {
      imageSize: 100,
      product: {},
    };
  },
  mounted() {
    this.product = this.item;
  },
  methods: {
    get,
    attnThumbHref,
    getCategoryNameById(categoryId) {
      return (
        this.productCategories.find(el => {
          return el._id === categoryId;
        })?.title || '-'
      );
    },
    computedSubTotal(item) {
      const amount = item.discountedPrice ? item.discountedPrice : item.listedPrice;
      return Number((amount * item.selectedQty).toFixed(2));
    },
    removeItem(item) {
      this.$emit('remove', item);
    },
    increment(product) {
      product.selectedQty += 1;
    },
    decrement(product) {
      if (product.selectedQty > 0) {
        product.selectedQty -= 1;
      }
    },
  },
};
</script>

<style scoped>
.stripeClass {
  text-decoration: line-through red;
}
.discountClass {
  margin-left: 5px;
  color: red;
}
</style>
