<template>
  <div>
    <div class="mb-2">
      <v-select
        class="ml-auto"
        dense
        hide-details="auto"
        style="max-width: 150px"
        outlined
        :items="[2022]"
      />
    </div>

    <v-simple-table dense class="payrollTable">
      <thead>
        <tr>
          <th class="headerCell" v-for="item in tableSetting">{{ item.text }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="i in 12">
          <td :class="item.class" v-for="item in tableSetting">{{ item.value }} 0</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tableSetting() {
      return [
        { text: '', value: 'Jan 2022', class: { rowHeader: true }, filter: '' },
        { text: 'Basic', value: '', class: '', filter: '' },
        { text: 'Good Attend', value: '', class: '', filter: '' },
        { text: 'Handwork', value: '', class: '', filter: '' },
        { text: 'Upsell Com.', value: '', class: '', filter: '' },
        { text: 'Teacher Fee', value: '', class: '', filter: '' },
        { text: 'MPF', value: '', class: '', filter: '' },
        { text: 'AL', value: '', class: '', filter: '' },
        { text: 'SL', value: '', class: '', filter: '' },
        { text: 'NPL', value: '', class: '', filter: '' },
        { text: 'Absent (no notice)', value: '', class: '', filter: '' },
        { text: 'Total ($HK)', value: '', class: { 'font-weight-bold': true }, filter: '' },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.payrollTable {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  th,
  td {
    text-align: center;
    font-size: 16px !important;
    border-right: thin solid rgba(0, 0, 0, 0.12);
  }
  th {
    text-align: center !important;
  }
  td:first-child {
    border-left: thin solid rgba(0, 0, 0, 0.12);
  }
  .headerCell {
    background: #e1551a;
    color: #fff !important;
    border-color: #fff;
  }
  .rowHeader {
    background: #e8f5e9;
    &.dayOff {
      background: #ffebee;
      border: none;
    }
  }
  .totalRow {
    background: #9ccc65;
    color: #fff;
    td {
      border: none;
    }
  }
  .subTotalRow {
    background: #e1551a;
    color: #fff;
    td {
      border: none;
    }
  }
}
</style>
