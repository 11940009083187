<template>
  <div v-if="serviceRecordList" class="py-3">
    <div class="mb-5">
      <div class="align-self-center" style="min-width: 160px"><b>Paper Record</b></div>
    </div>
    <div class="d-flex">
      <v-spacer />
      <v-btn @click="dialog = true" :disabled="!masterHasAuth">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="serviceRecordList"
      :items-per-page="limit"
      :server-items-length="totalLength"
      @update:page="changePage"
      @update:items-per-page="changeRowsPerPage"
    >
      <template v-slot:item.Date="{ item }">
        <span class="tableCol">
          {{ moment(item.created_at).format('YYYY-MM-DD') }}
        </span>
      </template>
      <!--      <template v-slot:item.Time="{ item }">-->
      <!--        <span class="tableCol">-->
      <!--          {{ moment(item.created_at).format('HH:mm-') + moment(item.created_at).format('HH:mm') }}-->
      <!--        </span>-->
      <!--      </template>-->
      <template v-slot:item.Name="{ item }">
        <span class="tableCol"> {{ get(item, 'serviceProduct.name', '-') }}</span>
      </template>
      <template v-slot:item.colorProportion="{ item }">
        <span class="tableCol">
          {{ get(item, 'colorProportion', '-') }}
        </span>
      </template>
      <template v-slot:item.Master="{ item }">
        <span class="tableCol"> {{ get(item, 'firstAssignedMaster.name', '-') }}</span>
      </template>
      <template v-slot:item.MasterSpecified="{ item }">
        <span class="tableCol"> {{ get(item, 'masterSpecified.name', '-') }}</span>
      </template>
      <template v-slot:item.Remarks="{ item }">
        <span class="tableCol"> {{ get(item, 'remarks', '-') }}</span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-card-title>
          <p class="mb-0">Add Service Record</p>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <p>Date</p>
              <DatePickerWrapperInputMenu v-model="data.created_at" />
            </v-col>
            <v-col cols="6">
              <p>Service</p>
              <autocomplete
                label="Service"
                v-model="data.serviceProduct"
                :path="'toca/admin/ServiceProducts'"
                item-value="_id"
                item-text="name"
                :clearable="true"
              />
            </v-col>
            <v-col cols="6">
              <p>Color Proportion</p>
              <v-text-field outlined label="Color Proportion" v-model="data.colorProportion" />
            </v-col>
            <v-col cols="6">
              <p>Serving Facility</p>
              <autocomplete
                label="Serving Facility"
                v-model="data.firstAssignedMaster"
                :path="'toca/admin/MasterProfiles'"
                :params="{ sort: { created_at: -1 } }"
                item-value="_id"
                item-text="name"
                :clearable="true"
              />
            </v-col>
            <v-col cols="6">
              <p>Remarks of the case</p>
              <v-textarea outlined v-model="data.remarks" />
            </v-col>
            <v-col cols="6">
              <p>Requested Facility</p>
              <autocomplete
                label="Requested Facility"
                v-model="data.masterSpecified"
                :path="'toca/admin/MasterProfiles'"
                :params="{ sort: { created_at: -1 } }"
                item-value="_id"
                item-text="name"
                :clearable="true"
              />
              <!--              <p>Assistant Master</p>-->
              <!--              <autocomplete-->
              <!--                label="Assistant Master"-->
              <!--                v-model="data.firstAssistantMaster"-->
              <!--                :path="'toca/admin/MasterProfiles'"-->
              <!--                :params="{ sort: { created_at: -1 } }"-->
              <!--                item-value="_id"-->
              <!--                item-text="name"-->
              <!--                :clearable="true"-->
              <!--              />-->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="save"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { get } from 'lodash';
import { crudList, crudCreate } from '@/api/crud';
import api from '@/api';
import DatePickerWrapperInputMenu from '@/components/DatePickerWrapperInputMenu';
import autocomplete from '@/components/Autocomplete/Autocomplete';
import { mapGetters } from 'vuex';
import { getOid } from '@/services/utils';

export default {
  name: 'serviceRecord',
  props: {
    id: String,
  },
  components: {
    DatePickerWrapperInputMenu,
    autocomplete,
  },
  data() {
    return {
      page: 1,
      totalLength: 0,
      serviceRecordList: [],
      dialog: false,
      limit: 5,
      data: {},
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'Date',
        },
        // {
        //   text: 'Time',
        //   align: 'center',
        //   sortable: false,
        //   value: 'Time',
        // },
        {
          text: 'Service',
          align: 'center',
          sortable: false,
          value: 'Name',
        },
        {
          text: 'Color Proportion',
          align: 'center',
          sortable: false,
          value: 'colorProportion',
        },
        {
          text: 'Serving Facility',
          align: 'center',
          sortable: false,
          value: 'Master',
        },
        {
          text: 'Requested Facility',
          align: 'center',
          sortable: false,
          value: 'MasterSpecified',
        },
        {
          text: 'Remarks of the case',
          align: 'center',
          sortable: false,
          value: 'Remarks',
        },
      ],
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['isMaster', 'profileId', 'isAdmin']),
    masterHasAuth() {
      return (
        this.isAdmin || (this.isMaster && this.profileId === getOid(this.docs?._timetable?.master))
      );
    },
  },
  methods: {
    get,
    moment,
    changeRowsPerPage(val) {
      this.limit = val;
      this.reload();
    },
    changePage(page) {
      this.page = page;
      this.reload();
    },
    async save() {
      try {
        await crudCreate(api, 'toca/admin/PreviousServiceRecords', {
          ...this.data,
          created_by: this.id,
        });
        await this.reload();
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
      } catch (e) {
        console.log(e);
      }
      this.dialog = false;
    },
    async reload() {
      try {
        const recordRes = await crudList(api, 'toca/admin/PreviousServiceRecords', {
          filter: { created_by: this.id },
          limit: this.limit,
          page: this.page,
          populate: [
            { path: 'masterSpecified' },
            { path: 'serviceProduct' },
            { path: 'firstAssignedMaster' },
            { path: 'firstAssistantMaster' },
          ],
          sort: { created_at: -1 },
        });
        this.totalLength = recordRes.total;
        this.serviceRecordList = recordRes.docs;
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="scss">
.tableCol {
  color: var(--v-primary-base);
  font-weight: 500;
}
</style>
