<template>
  <v-container>
    <div><h2>Service Product Details</h2></div>
    <v-divider class="mb-5" />
    <div v-if="docs">
      <ValidationObserver ref="form" v-slot="{ invalid }">
        <v-row class="mr-3 ml-3">
          <v-col class="ml-auto" md="auto">
            <v-btn :disabled="invalid || isMaster" color="primary" @click="save">Save</v-btn>
          </v-col>
        </v-row>
        <v-form>
          <v-row class="mr-3 ml-3">
            <v-col cols="12" md="6">
              <custom-label item-field-name="Sid" />
              <ValidationProvider v-slot="{ errors }" name="sid" rules="required">
                <v-text-field
                  outlined
                  v-model="docs.sid"
                  label="Service Price"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Enabled" />
              <ValidationProvider v-slot="{ errors }" name="enabled">
                <v-switch v-model="docs.enabled" class="mt-0 pt-0 ml-3" hide-details />
              </ValidationProvider>
            </v-col>
            <!-- TODO: handle localize string validation -->
            <v-col cols="12" md="6">
              <ValidationProvider v-slot="{ errors }" name="Name(CN)">
                <v-text-field
                  :value="localizedName['zh-cn']"
                  @input="localizedName = { ...localizedName, 'zh-cn': $event }"
                  :error-messages="errors"
                  flat
                  label="Name(CN)"
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider v-slot="{ errors }" name="Name(EN)">
                <v-text-field
                  :value="localizedName.en"
                  @input="localizedName = { ...localizedName, en: $event }"
                  :error-messages="errors"
                  flat
                  label="Name(EN)"
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <ValidationProvider v-slot="{ errors }" name="Name(HK)">
                <v-text-field
                  :value="localizedName['zh-hk']"
                  @input="localizedName = { ...localizedName, 'zh-hk': $event }"
                  :error-messages="errors"
                  flat
                  label="Name(HK)"
                  outlined
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Listed Price" />
              <ValidationProvider v-slot="{ errors }" name="listedPrice">
                <v-text-field
                  outlined
                  v-model="docs.listedPrice"
                  label="Listed Price"
                  :error="!!errors.length"
                  :error-messages="errors"
                  type="number"
                />
              </ValidationProvider>
            </v-col>
            <!--            <v-col cols="12" md="6">-->
            <!--              <custom-label item-field-name="N Diff Index" />-->
            <!--              <ValidationProvider-->
            <!--                v-slot="{ errors }"-->
            <!--                name="NDiffIndex"-->
            <!--                :rules="{ min_value: 1, max_value: 99 }"-->
            <!--              >-->
            <!--                <v-text-field-->
            <!--                  outlined-->
            <!--                  v-model="docs.NDiffIndex"-->
            <!--                  label="N Diff Index"-->
            <!--                  :error="!!errors.length"-->
            <!--                  :error-messages="errors"-->
            <!--                  type="number"-->
            <!--                />&lt;!&ndash;TODO need to change label&ndash;&gt;-->
            <!--              </ValidationProvider>-->
            <!--            </v-col>-->
            <v-col cols="12" md="6">
              <custom-label item-field-name="Description" />
              <ValidationProvider v-slot="{ errors }" name="description">
                <v-text-field
                  outlined
                  v-model="docs.description"
                  label="Description"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Discounted Price" />
              <ValidationProvider v-slot="{ errors }" name="discountedPrice">
                <v-text-field
                  outlined
                  v-model="docs.discountedPrice"
                  label="Discounted Price"
                  :error="!!errors.length"
                  :error-messages="errors"
                  type="number"
                />
              </ValidationProvider>
            </v-col>
            <!-- <v-col cols="12" md="6">
              <custom-label item-field-name="Discounted Price" />
              <ValidationProvider v-slot="{ errors }" name="discountedPrice10">
                <v-text-field
                  outlined
                  v-model="docs.discountedPrice10"
                  label="Discounted Price"
                  :error="!!errors.length"
                  :error-messages="errors"
                  type="number"
                />
              </ValidationProvider>
            </v-col> -->
            <v-col cols="12" md="6">
              <custom-label item-field-name="Featured Image" />
              <v-file-input
                v-model="newFeaturedImage"
                class="flex-grow-1"
                flat
                label="Featured Image Upload"
                outlined
                accept="image/jpeg,image/png"
              />
              <span v-if="featuredImage">
                <h3>Featured Image</h3>
                <v-sheet class="px-3 py-1">
                  <v-img
                    :src="attnThumbHref(featuredImage, 128)"
                    max-height="128px"
                    max-width="128px"
                  />
                  <a :href="attnDownloadHref(featuredImage)" target="_blank">
                    {{ featuredImage.filename }} ({{ featuredImage.length | byte }})
                  </a>
                  <v-btn icon @click="removePreviousFeaturedImage(featuredImage)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-sheet>
              </span>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Image" />
              <ValidationProvider v-slot="{ errors }" name="Images" :rules="imageRule">
                <v-file-input
                  v-model="newImage"
                  class="flex-grow-1"
                  flat
                  label="Image Upload"
                  outlined
                  accept="image/jpeg,image/png"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
              <span v-for="att of images" :key="`i-${att._id}`">
                <h3>Image</h3>
                <v-sheet :key="att._id" class="px-3 py-1">
                  <v-img :src="attnThumbHref(att, 128)" max-height="128px" max-width="128px" />
                  <a :href="attnDownloadHref(att)" target="_blank">
                    {{ att.filename }} ({{ att.length | byte }})
                  </a>
                  <v-btn icon @click="removePreviousImage(att)">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-sheet>
              </span>
            </v-col>
            <!--            <v-col cols="12" md="6">-->
            <!--              <custom-label item-field-name="Commission Rate" />-->
            <!--              <ValidationProvider v-slot="{ errors }" name="commissionRate">-->
            <!--                <v-text-field-->
            <!--                  outlined-->
            <!--                  v-model="docs.commissionRate"-->
            <!--                  label="Commission Rate"-->
            <!--                  :error="!!errors.length"-->
            <!--                  :error-messages="errors"-->
            <!--                  type="number"-->
            <!--                />-->
            <!--              </ValidationProvider>-->
            <!--            </v-col>-->
            <v-col cols="12" md="6">
              <custom-label item-field-name="Session Type" />
              <ValidationProvider v-slot="{ errors }" name="sessionType" rules="required">
                <v-select
                  outlined
                  v-model="docs.sessionType"
                  label="Session Type"
                  :items="sessionTypeOptions"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Game Type" />
              <ValidationProvider v-slot="{ errors }" name="gameType" rules="required">
                <v-select
                  outlined
                  v-model="docs.gameType"
                  label="Game Type"
                  :items="gameTypeOptions"
                  :error="!!errors.length"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12" md="6">
              <custom-label item-field-name="Facility" />
              <ValidationProvider v-slot="{ errors }" name="Facility" rules="required">
                <autocomplete
                  v-model="computedFacility"
                  :path="computedAutoCompleteEndpoint"
                  :params="{ filter: { status: '1$accepted', isFacility: true } }"
                  clearable
                  item-text="name"
                  item-value="_id"
                  label="Facility"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>
    </div>
    <div v-else class="d-flex justify-center" style="padding-top: 20%">
      <h2>No Record to found</h2>
    </div>
  </v-container>
</template>

<script>
import api from '@/api';
import { crudRead, crudUpdate } from '@/api/crud';
import { mapGetters } from 'vuex';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { sessionTypeOptions, gameTypeOptions } from '@/data/optionsConstants';
import { uploadSingleAttachment, readAttachment, deleteAttachment } from '@/api/attachment';
import { attnThumbHref, attnDownloadHref } from '@/services/filters';
import { getId } from '@/services/utils';
import { RESTFUL } from '@/data/constants';
import ApiError from '@/api/ApiError';
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'ServiceProductDetails',
  components: { CustomLabel, Autocomplete },
  props: {
    id: {},
  },
  data() {
    return {
      docs: null,
      sessionTypeOptions,
      gameTypeOptions,
      newFeaturedImage: null,
      newImage: null,
      images: null,
      featuredImage: null,
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster', 'isAdmin']),
    imageRule() {
      return this.images ? null : 'required';
    },
    computedAutoCompleteEndpoint() {
      return this.isAdmin ? 'toca/admin/masterProfiles' : 'toca/master/masterProfiles';
    },
    computedFacility: {
      get() {
        return this.docs?.facilities[0];
      },
      set(v) {
        this.docs.facilities = [v];
      },
    },
    localizedName: {
      get() {
        return this.parseLocalizedString(this.docs?.name);
      },
      set(val) {
        this.docs.name = JSON.stringify(val);
      },
    },
  },
  methods: {
    parseLocalizedString,
    attnThumbHref,
    attnDownloadHref,
    async reload() {
      this.images = null;
      this.newImage = null;
      this.featuredImage = null;
      this.newFeaturedImage = null;
      this.docs = await crudRead(api, RESTFUL.serviceProducts.read, this.id);
      if (this.docs.images) {
        this.images = await readAttachment(api, this.docs.images, {
          returnArray: 1,
        });
      }
      if (this.docs.featuredImage) {
        this.featuredImage = await readAttachment(api, this.docs.featuredImage, {});
      }
    },
    async removePreviousImage(v) {
      await deleteAttachment(api, getId(v));
      await crudUpdate(api, RESTFUL.serviceProducts.update, this.id, { images: null });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Deleted',
        type: 'success',
        color: 'success',
      });
      this.reload();
    },
    async removePreviousFeaturedImage(v) {
      await deleteAttachment(api, getId(v));
      await crudUpdate(api, RESTFUL.serviceProducts.update, this.id, { featuredImage: null });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Deleted',
        type: 'success',
        color: 'success',
      });
      this.reload();
    },
    async save() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        if (this.newImage) {
          const newImage = await uploadSingleAttachment(api, this.newImage);
          this.docs = {
            ...this.docs,
            images: newImage._id,
          };
        }
        if (this.newFeaturedImage) {
          const newFeaturedImage = await uploadSingleAttachment(api, this.newFeaturedImage);
          this.docs = {
            ...this.docs,
            featuredImage: newFeaturedImage._id,
          };
        }

        await crudUpdate(api, RESTFUL.serviceProducts.update, this.id, this.docs);
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        this.reload();
      } catch (e) {
        this.handleError(e);
      }
    },
    handleError(err) {
      const ae = ApiError.wrap(err);
      this.$store.dispatch('alert/updateAlertMessage', {
        msg: ae.message,
      });
    },
  },
};
</script>
