export function generatorObjectFromJSONFormat(name, data) {
  if (!data) {
    return {};
  }
  let result = {};
  const dataJson = JSON.parse(data);
  let x = Object.keys(dataJson);
  x.map(item => {
    const itemName = item.charAt(0).toUpperCase() + item.slice(1);
    let keyName = `${name}${itemName}`;
    let objResult = { [keyName]: dataJson[`${item}`] };
    Object.assign(result, objResult);
  });
  return result;
}
