<template>
  <v-container>
    <div class="d-flex justify-space-between mb-2">
      <h2 class="ml-3">{{ title }}</h2>
      <v-btn v-if="!isNew" color="danger" @click="promptDelete" class="mr-3" :disabled="isMaster"
        >Delete</v-btn
      >
    </div>
    <v-divider class="mb-5 mx-3" />
    <v-card v-if="docs" class="px-3 py-3" flat>
      <v-row>
        <v-col sx="12" sm="6" md="6">
          <ValidationObserver ref="form" v-slot="{ invalid }">
            <v-card min-height="600">
              <v-row class="mr-3 ml-3">
                <v-col md="auto">
                  <h4>Sid: {{ docs.sid }}</h4>
                </v-col>
                <v-col class="ml-auto" md="auto">
                  <v-btn :disabled="invalid || isMaster" color="primary" @click="saveLeft"
                    >Save</v-btn
                  >
                </v-col>
              </v-row>
              <v-row class="mr-3 ml-3">
                <v-col cols="12" md="6">
                  <custom-label item-field-name="Session Type" />
                  <ValidationProvider v-slot="{ errors }" name="sessionType" rules="required">
                    <v-select
                      outlined
                      v-model="docs.extendInfo.sessionType"
                      label="Session Type"
                      :items="sessionTypeOptions"
                      :error="!!errors.length"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <custom-label item-field-name="Game Type" />
                  <ValidationProvider v-slot="{ errors }" name="gameType" rules="required">
                    <v-select
                      outlined
                      v-model="docs.extendInfo.gameType"
                      label="Game Type"
                      :items="gameTypeOptions"
                      :error="!!errors.length"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <!-- TODO: handle localize string validation -->
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Product Name(CN)">
                    <v-text-field
                      :value="localizedName['zh-cn']"
                      @input="localizedName = { ...localizedName, 'zh-cn': $event }"
                      :error-messages="errors"
                      flat
                      label="Product Name(CN)"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Product Name(EN)">
                    <v-text-field
                      :value="localizedName.en"
                      @input="localizedName = { ...localizedName, en: $event }"
                      :error-messages="errors"
                      flat
                      label="Product Name(EN)"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Product Name(HK)">
                    <v-text-field
                      :value="localizedName['zh-hk']"
                      @input="localizedName = { ...localizedName, 'zh-hk': $event }"
                      :error-messages="errors"
                      flat
                      label="Product Name(HK)"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <!-- <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="Product Category" rules="required">
                    <v-select
                      v-model="docs.category"
                      :items="productCategories"
                      class="pl-2"
                      item-text="title"
                      item-value="_id"
                      label="Category"
                      style="max-width: 300px"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider v-slot="{ errors }" name="Assign Priority" rules="required">
                    <v-text-field
                      v-model="docs.priority"
                      :error-messages="errors"
                      flat
                      label="Assign Priority"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->

                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" name="Description" rules="required">
                    <v-textarea
                      v-model="docs.description"
                      :error-messages="errors"
                      flat
                      label="Description"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" name="listed Price" rules="required">
                    <v-text-field
                      type="number"
                      v-model="docs.listedPrice"
                      :error-messages="errors"
                      flat
                      prefix="$"
                      label="Listed Price"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Discounted Price"
                    :rules="{
                      required: true,
                      max_value: docs.listedPrice,
                    }"
                  >
                    <v-text-field
                      type="number"
                      v-model.number="docs.inputDiscountedPrice"
                      @input="syncDiscountedPrice($event)"
                      :error-messages="errors"
                      flat
                      prefix="$"
                      label="Discounted Price"
                      outlined
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" v-if="docs.categoryDiscountedPrice">
                  <v-card elevation="4">
                    <v-list-item two-line class="d-flex justify-space-between">
                      <v-list-item-content
                        >Discounted Price according to category</v-list-item-content
                      >
                      <v-list-item-content class="red--text">
                        HK${{ get(docs, 'categoryDiscountedPrice', '-') }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
                <!-- <v-col cols="6">
                  <ValidationProvider v-slot="{ errors }" name="Stock" rules="required">
                    <v-text-field
                      type="number"
                      v-model="docs.stock"
                      :error-messages="errors"
                      flat
                      label="Stock"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->
                <!-- <v-col cols="6">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Commission Rate"
                    :rules="{ required: true, min_value: 0, max_value: 100, decimal: 1 }"
                  >
                    <v-text-field
                      type="number"
                      v-model="computedCommissionRate"
                      suffix="%"
                      :error-messages="errors"
                      flat
                      label="Commission Rate"
                      outlined
                    />
                  </ValidationProvider>
                </v-col> -->
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="justify-center">Tags</v-card-title>
                    <v-list-item>
                      <v-list-item-content>
                        <v-chip
                          class="mr-5 mb-5"
                          label
                          style="font-weight: bold"
                          color="#96734B"
                          outlined
                          close
                          close-icon="mdi-close-circle"
                          :key="index"
                          v-for="(item, index) in docs.tags"
                          @click:close="deleteProductTag(index)"
                          >{{ item }}
                        </v-chip>
                        <v-autocomplete
                          outlined
                          :search-input.sync="search"
                          :items="tags"
                          @input="x => addProductTag(x)"
                          append-outer-icon="mdi-plus-circle-outline"
                          @click:append-outer="addProductTag()"
                        >
                        </v-autocomplete>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </ValidationObserver>
        </v-col>
        <v-col style="margin-top: -12px" xs="12" sm="6" md="6">
          <v-card min-height="484">
            <v-card-title v-if="featuredImage">
              <h2>Featured Image</h2>
              <v-img :src="featuredImage" max-width="200" max-height="250" min-height="230" />
              <v-btn v-if="featuredImage" icon @click="removeFeaturedImage">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-title v-else>
              <v-img
                src="@/assets/TocaLogo.svg"
                max-width="200"
                max-height="250"
                min-height="230"
                contain
              />
            </v-card-title>
            <v-card-text>
              <div class="d-flex mt-3">
                <v-file-input
                  class="flex-grow-1"
                  outlined
                  v-model="newFeaturedImage"
                  label="Image"
                  flat
                  accept="image/jpeg,image/png"
                />
                <v-btn
                  v-if="!isNew"
                  class="ml-3 flex-shrink-0"
                  @click="saveFeaturedImage"
                  color="primary"
                  :disabled="isMaster"
                  >Save</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
          <v-card min-height="484">
            <v-card-title v-if="otherImages.length > 0">
              <h2>Other Images</h2>
              <template v-for="(image, index) in otherImages">
                <v-img :src="image" max-width="200" max-height="250" min-height="230" />
                <v-btn v-if="image" icon @click="removeOtherImage(index)">
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
            </v-card-title>
            <v-card-title v-else>
              <v-img
                src="@/assets/TocaLogo.svg"
                max-width="200"
                max-height="250"
                min-height="230"
                contain
              />
            </v-card-title>
            <v-card-text>
              <div class="d-flex mt-3">
                <v-file-input
                  class="flex-grow-1"
                  outlined
                  show-size
                  counter
                  multiple
                  v-model="newOtherImages"
                  label="Images"
                  accept="image/jpeg,image/png"
                />
                <v-btn
                  class="ml-3 flex-shrink-0"
                  @click="saveOtherImage"
                  color="primary"
                  :disabled="isMaster"
                  v-if="!isNew"
                  >Save</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import api from '@/api';
import {
  deleteAttachment,
  uploadMultipleAttachment,
  uploadSingleAttachment,
} from '@/api/attachment';
import { crudCreate, crudDelete, crudRead, crudUpdate } from '@/api/crud';
import { attnThumbHref } from '@/services/filters';
import { mapActions, mapGetters } from 'vuex';
import { RESTFUL } from '@/data/constants';
import { listProductCategories } from '@/api/productCategory';
import { get } from 'lodash';
import { autocompleteLabels } from '@/api/bookings';
import { autocompleteTags } from '@/api/physicalProduct';
import { sessionTypeOptions, gameTypeOptions } from '@/data/optionsConstants';
import CustomLabel from '@/components/customFormLabel/customLabel';
import { parseLocalizedString } from '@/services/utils';

export default {
  name: 'PhysicalProductDetails',
  components: { CustomLabel },
  props: {
    id: {},
  },
  data() {
    return {
      docs: null,
      sessionTypeOptions,
      gameTypeOptions,
      newFeaturedImage: null,
      newOtherImages: null,
      productCategories: [],
      tags: [],
      search: null,
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
    search: {
      immediate: true,
      async handler(v) {
        this.tags = await autocompleteTags(api, v);
      },
    },
    autoFillName: {
      handler(v) {
        if (v && this.docs && !this.docs.name) {
          this.docs.name = v;
        }
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['role', 'isMaster']),
    isNew() {
      return this.id === 'new';
    },
    title() {
      return this.isNew ? 'New Extension Product' : 'Extension Details';
    },
    restfulURL() {
      return this.isMaster ? RESTFUL.extensionProducts.masterList : RESTFUL.extensionProducts.list;
    },
    featuredImage() {
      return this.featuredImageId && attnThumbHref(this.docs.featuredImage);
    },
    featuredImageId() {
      return this.docs?.featuredImage;
    },
    otherImages() {
      let imageSrcList = [];
      if (this.otherImagesIds) {
        for (const image of this.docs.images) {
          imageSrcList.push(attnThumbHref(image));
        }
      }
      return imageSrcList;
    },
    otherImagesIds() {
      return this.docs?.images;
    },
    computedCommissionRate: {
      // rule {decimal: 1} is required in this logic
      get() {
        return (Math.round(this.docs?.commissionRate * 10000) / 100).toString();
      },
      set(v) {
        this.$set(this.docs, 'commissionRate', Math.round(v * 100) / 10000);
      },
    },
    autoFillName() {
      const sessionTypeText = sessionTypeOptions.find(
        ({ value }) => value === this.docs?.extendInfo?.sessionType,
      )?.text;
      const gameTypeText = gameTypeOptions.find(
        ({ value }) => value === this.docs?.extendInfo?.gameType,
      )?.text;
      if (sessionTypeText && gameTypeText) {
        return `${sessionTypeText} ${gameTypeText} ${'(1 Hour extension)'}`;
      }
      return '';
    },
    localizedName: {
      get() {
        return this.parseLocalizedString(this.docs?.name);
      },
      set(val) {
        this.docs.name = JSON.stringify(val);
      },
    },
  },
  methods: {
    parseLocalizedString,
    get,
    ...mapActions('alert', ['updateAlertMessage']),
    async reload() {
      if (this.isNew) {
        this.docs = {
          extendInfo: {
            extendMinutes: 60,
            sessionType: undefined,
            gameType: undefined,
          },
          discount: {},
        };
      } else {
        this.docs = await crudRead(api, this.restfulURL, this.id);
      }
      await this.getProductCategories();
    },
    async getProductCategories() {
      this.productCategories = await listProductCategories(api);
    },
    async removeFeaturedImage() {
      try {
        try {
          await deleteAttachment(api, this.featuredImageId);
        } catch (e) {
          //no-op
        }
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          featuredImage: null,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async removeOtherImage(index) {
      try {
        try {
          console.log(index);
          await deleteAttachment(api, this.otherImagesIds[index]);
        } catch (e) {
          //no-op
        }
        this.otherImagesIds.splice(index, 1);
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          images: this.otherImagesIds,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveFeaturedImage() {
      try {
        const attachment = await uploadSingleAttachment(api, this.newFeaturedImage);
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          featuredImage: attachment._id,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveOtherImage() {
      try {
        const attachmentIds = await uploadMultipleAttachment(api, this.newOtherImages);
        const newAttachmentIds = this.otherImagesIds.concat(attachmentIds);
        await crudUpdate(api, RESTFUL.physicalProducts.read, this.id, {
          images: newAttachmentIds,
        });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        await this.reload();
      } catch (e) {
        console.log(e);
      }
    },
    async saveLeft() {
      try {
        if (!(await this.$refs.form?.validate?.())) {
          return;
        }
        if (this.isNew) {
          if (this.newFeaturedImage) {
            const attachmentId = await uploadSingleAttachment(api, this.newFeaturedImage);
            this.docs.featuredImage = attachmentId._id;
          }
          if (this.newOtherImages?.length > 0) {
            const attachmentIds = await uploadMultipleAttachment(api, this.newOtherImages);
            this.docs.images = attachmentIds;
          }
        }
        const model = this.isNew
          ? await crudCreate(api, RESTFUL.extensionProducts.create, this.docs)
          : await crudUpdate(api, RESTFUL.extensionProducts.read, this.id, {
              name: this.docs.name,
              category: this.docs.category,
              description: this.docs.description,
              listedPrice: this.docs.listedPrice,
              inputDiscountedPrice: this.docs.inputDiscountedPrice,
              stock: this.docs.stock,
              tags: this.docs.tags,
              priority: this.docs.priority,
            });
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: 'Saved',
          type: 'success',
          color: 'success',
        });
        if (this.isNew) {
          this.$router.replace({
            name: 'extensionProduct',
            params: {
              id: model._id,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async promptDelete() {
      if (!confirm('Do you confirm to delete?')) return;
      try {
        await crudDelete(api, RESTFUL.physicalProducts.read, this.id);
        //TODO: change endpoint / handle in endpoint hook if products (phase2)
        await this.$router.push({ name: 'physicalProducts' });
        alert('delete success');
      } catch (e) {
        alert('delete fail');
      }
    },
    syncDiscountedPrice(e) {
      this.docs.inputDiscountedPrice = Number(e);
    },
    addProductTag(x) {
      if (x) {
        this.docs.tags.push(x);
        return;
      }
      if (!this.search) {
        return;
      } else {
        this.docs.tags.push(this.search);
      }
      this.docs.tags = [...new Set(this.docs.tags)];
    },
    deleteProductTag(index) {
      this.docs.tags.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
