var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ListView',{attrs:{"title":"Product Order","enable-create":false,"search":_vm.search,"data":_vm.data,"page":_vm.page,"header":_vm.header},on:{"reload":_vm.reload,"create":_vm.actionCreate,"navDetails":_vm.actionDetails,"update:search":function($event){_vm.search=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,'YYYY/MM/DD HH:mm'))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({ color: _vm.getColor(item.status) })},[_vm._v(" "+_vm._s(_vm._f("status")(item.status))+" ")]),_c('span',{staticStyle:{"color":"red"}},[_vm._v(" "+_vm._s(item.isVoided ? '(Voided)' : '')+" ")])]}},{key:"item._product",fn:function(ref){
var item = ref.item;
return [_vm._l((item.items),function(ref){
var product = ref.product;
return _c('div',[_vm._v(_vm._s(product.name))])}),_vm._l((item.vipItems),function(ref){
var product = ref.product;
return _c('div',[_vm._v(_vm._s(product.name))])}),_vm._l((item.bookingItems),function(ref){
var product = ref.product;
return _c('div',[_vm._v(_vm._s(product.name))])})]}},{key:"btn",fn:function(){return undefined},proxy:true}])},[_c('TicketCreateDialog',{attrs:{"show":_vm.createTicket,"hasClient":false},on:{"close":function($event){_vm.createTicket = false},"reload":_vm.reload}}),_c('ProductOrderEditDialog',{attrs:{"id":_vm.editId,"title":_vm.title,"productCategories":_vm.productCategories},on:{"create":_vm.reload,"update":_vm.reload,"delete":_vm.reload},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }