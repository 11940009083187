<template>
  <div v-if="model" class="py-3">
    <v-row>
      <div class="mb-5">
        <div class="align-self-center" style="min-width: 160px; padding: 0px 15px 0px 15px">
          <b>Previous Hardcopy</b>
        </div>
      </div>

      <v-col cols="12" md="12">
        <v-file-input
          v-model="previousHardCopy"
          label="files"
          multiple
          outlined
          accept="image/jpeg,image/png"
        >
          <template v-if="previousHardCopy.length" #append>
            <v-btn color="primary" small @click="uploadPreviousHardCopy" :disabled="!masterHasAuth"
              >Upload</v-btn
            >
          </template>
        </v-file-input>
        <div class="d-flex flex-wrap">
          <template v-for="att of previousHardCopy1">
            <v-sheet
              :key="att._id"
              class="px-3 py-1 d-flex flex-wrap mx-3 my-3"
              elevation="8"
              rounded
            >
              <a :href="attnDownloadHref(att)" target="_blank">
                {{ att.created_at | moment }} ({{ att.length | byte }})
                <v-img :src="attnThumbHref(att, 128)" max-height="128px" max-width="128px" />
              </a>
              <v-btn icon @click="removePreviousHardCopy(att)">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-sheet>
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { get } from 'lodash';
import { attnDownloadHref, attnThumbHref } from '@/services/filters';
import { getId, getOid, oidEquals } from '@/services/utils';
import { deleteAttachment, readAttachment, uploadSingleAttachment } from '@/api/attachment';
import api from '@/api';
import { crudUpdate } from '@/api/crud';
import { mapGetters } from 'vuex';

export default {
  name: 'previousHardCopyList',
  props: {
    model: {},
  },
  emits: ['reload'],
  data() {
    return {
      previousHardCopy: [],
      previousHardCopy1: [],
    };
  },
  watch: {
    model: {
      immediate: true,
      handler(v) {
        if (v) {
          this.reload();
        }
      },
    },
  },
  computed: {
    // image() {
    //   return this.model?.avatar ? attnThumbHref(this.model.avatar) : null;
    // },
    ...mapGetters('auth', ['isMaster', 'profileId', 'isAdmin']),
    masterHasAuth() {
      return (
        this.isAdmin || (this.isMaster && this.profileId === getOid(this.docs?._timetable?.master))
      );
    },
    id() {
      return getOid(this.model);
    },
  },
  methods: {
    get,
    attnDownloadHref,
    attnThumbHref,
    async reload() {
      this.previousHardCopy = [];
      if (this.model?.previousHardCopy) {
        this.previousHardCopy1 = Object.values(
          await readAttachment(api, this.model.previousHardCopy),
        );
      } else {
        this.previousHardCopy1 = [];
      }
    },
    async uploadPreviousHardCopy() {
      if (!this.previousHardCopy.length) {
        return;
      }
      const attachments = await Promise.all(
        this.previousHardCopy.map(uploadSingleAttachment.bind(null, api)),
      );
      await crudUpdate(api, 'profiles', this.id, {
        previousHardCopy: this.model.previousHardCopy.concat(attachments).map(getId),
      });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Uploaded',
        type: 'success',
        color: 'success',
      });
      this.$emit('reload');
    },
    async removePreviousHardCopy(att) {
      await deleteAttachment(api, getId(att));
      await crudUpdate(api, 'profiles', this.id, {
        previousHardCopy: this.model.previousHardCopy.filter(x => !oidEquals(att, x)),
      });
      await this.$store.dispatch('alert/updateAlertMessage', {
        msg: 'Deleted',
        type: 'success',
        color: 'success',
      });
      this.$emit('reload');
    },
  },
};
</script>

<style scoped></style>
