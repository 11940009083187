<template>
  <v-dialog v-model="valueProxy" max-width="100vw" width="60%" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="justify-center">Share Package</v-card-title>
      <v-card-text>
        <ValidationProvider v-slot="{ errors }" name="Customer" rules="required">
          <autocomplete
            label="Customer"
            :path="'toca/admin/clientProfiles'"
            :params="{ sort: { created_at: -1 } }"
            item-text="name"
            item-value="_id"
            v-model="client"
            :error-messages="errors"
            :clearable="true"
            :has-items-slot="true"
          >
            <template v-slot:items="content">
              <span>{{
                get(content, 'item.name', '') + ' ' + displayPhone(get(content, 'item.phone', ''))
              }}</span>
            </template>
          </autocomplete>
        </ValidationProvider>
        <v-card-actions class="justify-space-between">
          <span>
            <v-btn class="mr-5" @click="$emit('close')">Cancel</v-btn>
            <v-btn @click="saveCustomerShareWith" color="primary">Save</v-btn>
          </span>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Autocomplete from '@/components/Autocomplete/Autocomplete';
import { get } from 'lodash';
import { displayPhone } from '@/services/filters';
import { patchTicketShareWith } from '@/api/ticketPackage';
import api from '@/api';

export default {
  name: 'TicketShareDialog',
  components: { Autocomplete },
  props: {
    show: Boolean,
    content: {},
  },
  data() {
    return {
      search: null,
      client: null,
    };
  },
  methods: {
    get,
    displayPhone,
    close() {
      this.$emit('close');
    },
    async saveCustomerShareWith() {
      try {
        await patchTicketShareWith(api, this.content._id, this.client);
        this.$emit('reload');
        this.close();
      } catch (err) {
        await this.$store.dispatch('alert/updateAlertMessage', {
          msg: err,
        });
      }
    },
  },
  computed: {
    valueProxy: {
      get() {
        return this.show;
      },
      set(v) {
        this.$emit('update:show', v);
      },
    },
  },
};
</script>

<style scoped></style>
