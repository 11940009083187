<template>
  <v-container>
    <v-tabs v-model="tab">
      <v-tab
        v-for="item in CMSHomePageSectionOptions"
        :key="item.value"
        @click="tabClickHandler(item)"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in CMSHomePageSectionOptions" :key="item.value">
        <v-card flat style="margin-top: 10px">
          <CMSEdit
            :postType="postType"
            :supportImage="true"
            :supportMultiImage="supportMultiImage()"
          />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { CMSPostType } from '../../data/constants';
import { CMSHomePageSectionOptions } from '../../data/optionsConstants';
import CMSEdit from '../../components/CMS/CMSEdit.vue';

export default {
  name: 'homePageCMS',
  components: { CMSEdit },

  data() {
    return {
      title: 'Home Page CMS',
      CMSHomePageSectionOptions: CMSHomePageSectionOptions,
      postType: `${CMSPostType.homePage}.${CMSHomePageSectionOptions[0].value}`,
      tab: '',
    };
  },
  methods: {
    tabClickHandler(item) {
      const index = this.CMSHomePageSectionOptions.indexOf(item);
      this.postType = `${CMSPostType.homePage}.${this.CMSHomePageSectionOptions[index].value}`;
    },
    supportMultiImage() {
      if (
        this.postType == `${CMSPostType.homePage}.header` ||
        this.postType == `${CMSPostType.homePage}.introduction1` ||
        this.postType == `${CMSPostType.homePage}.introduction2`
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
